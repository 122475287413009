import {Component, Input, OnInit, Type} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-more-less',
    standalone: true,
  imports: [
    NgIf
  ],
    templateUrl: './more-less.component.html',
    styleUrl: './more-less.component.scss'
})
export class MoreLessComponent implements OnInit {
    public list: Array<any>;
    @Input() input: Array<any>;
    @Input() size: number;
    index: number;

    slice() {
        if(this.input){
            this.list = this.input.slice(0, this.index);
        }
    }

    ngOnInit(): void {
      this.index = this.size;
      this.slice();
    }

    addSize() {
        this.index += this.size;
        this.slice();
    }
  cleanSize(){
    this.index = this.size;
    this.slice();
  }
}
