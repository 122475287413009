<div class="row">
    <div class="col-12 col-sm-4 col-md-3 ">
        <a *ngIf="!hideTotalCount">{{'TotalNumber' |localize}}{{': ' + (pageModel && pageModel.totalCount ? (! pageModel.totalCount ? 0 : (( pageModel.totalCount|number: '1.') + '').replace(',', '.')) : 0)}}</a>
    </div>


    <div class="col-12 col-sm-7 col-md-9 d-flex justify-content-center"
         *ngIf="pageModel && pageModel.totalPages && pageModel.totalPages >1">

        <a (click)="changePage(1)" class="mx-2 pt-2  pointer {{pageModel.currentPage==1 ? 'disabled':''}}">
            <i class="fas fa-fast-backward fsize-13" aria-hidden="true"></i>
        </a>

        <a (click)="changePage(pageModel.currentPage-1)"
           class="mx-2 pt-2 pointer {{pageModel.currentPage==1 ? 'disabled':''}}">
            <i class="fas fa-caret-left fsize-13" aria-hidden="true"></i>
        </a>

        <div class="d-flex justify-content-center pt-1 mx-2 ">
            <small class=" text-center">
                <input type="input" class="w-50 text-center"
                       [ngModel]="pageModel.currentPage" (keyup.enter)="changeBySelectedPage($event)"
                       (blur)="onInputBlur($event)">
                <span class="fsize-12 ms-1 pt-3 w-50">{{' / ' + (! pageModel.totalPages ? 0 : (( pageModel.totalPages|number: '1.') + '').replace(',', '.'))}}</span>
            </small>
        </div>


        <a class="mx-2 pt-2  pointer {{pageModel.currentPage==pageModel.totalPages ? 'disabled':''}}" aria-label="Next"
           (click)="changePage(Number(pageModel.currentPage)+1)">
            <i class="fas fa-caret-right fsize-13" aria-hidden="true"></i>
        </a>


        <a class="mx-1 pt-2  pointer {{pageModel.currentPage==pageModel.totalPages ? 'disabled':''}}" aria-label="Next"
           (click)="changePage(pageModel.totalPages)">
            <i class="fas fa-fast-forward fsize-13" aria-hidden="true"></i>
        </a>

        <select class=" fsize-1 form-select pointer ms-2 w-auto text-center" style="background-color: transparent;"
                [(ngModel)]="pageModel.size" (ngModelChange)="changeSize()">
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
        </select>

        <!--                <ul class=" justify-content-around " *ngIf="!hideSizeCombo">-->
        <!--                    <li class="" *ngIf="pageList && pageList.length > 0">-->
        <!--                        <select class="form-select" [(ngModel)]="pageModel.size" (ngModelChange)="changeSize()">-->
        <!--                            <option *ngFor="let size of pageList" value="{{size}}">{{size}}</option>-->
        <!--                        </select>-->
        <!--                    </li>-->
        <!--                    <li class="" *ngIf="!pageList">-->
        <!--                        <select class="form-select" [(ngModel)]="pageModel.size" (ngModelChange)="changeSize()">-->
        <!--                            <option value='10'>10</option>-->
        <!--                            <option value='20'>20</option>-->
        <!--                            <option value='30'>30</option>-->
        <!--                            <option value='50'>50</option>-->
        <!--                            <option value='100'>100</option>-->
        <!--                        </select>-->
        <!--                    </li>-->
        <!--                </ul>-->


    </div>
</div>
