import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {SearchService} from "../search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgIf} from "@angular/common";
import {NgxEchartsDirective} from "ngx-echarts";
import {PublicationListComponent} from "../publication-list/publication-list.component";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {AuthorDTO} from "./AuthorDTO";
import {EChartsOption} from "echarts";
import {AuthorPublicationFieldsDTO} from "./AuthorPublicationFieldsDTO";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";
import {PublicationDTO} from "../publication/PublicationDTO";
import {getCurrentYear} from "../../app.module";
import {ExportComponent} from "../export/export.component";

declare var vis: any;

@Component({
    selector: 'app-author-detail',
    standalone: true,
    imports: [
        NgIf,
        NgxEchartsDirective,
        PublicationListComponent,
        TrdCoreModule,
        ExportComponent
    ],
    templateUrl: './author-detail.component.html',
    styleUrl: './author-detail.component.scss'
})
export class AuthorDetailComponent implements OnInit {
    selectedAuthorId: string;
    selectedAuthor: AuthorDTO;
    publicationIdList: Array<string>;
    publicationPageModel: PageModel = new PageModel(20);
    publicationList: Array<PublicationDTO>;
    authorCitationIdList: Array<string>;
    authorCitationList: Array<PublicationDTO>;
    authorCitationPageModel: PageModel = new PageModel(20);
    publicationFields: AuthorPublicationFieldsDTO[] = new Array<AuthorPublicationFieldsDTO>;
    chartOptionPublicationAndCitationCountsOfYears: EChartsOption;
    chartOptionInstitutionPublicationCount: EChartsOption;
    chartOptionInstitutionCollaborationCount: EChartsOption;
    chartOptionPublicationSubjectCount: EChartsOption;
    chartOptionAuthorCollaborationCount: EChartsOption;

    @ViewChild("inComingCitationNetwork") inComingCitationNetworkContainer: ElementRef;
    @ViewChild("outGoingCitationNetwork") outGoingCitationNetworkContainer: ElementRef;
    @ViewChild("collaborationNetwork") collaborationNetworkContainer: ElementRef;

    inComingCitationColors = ['#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063',
        '#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063'
    ];
    outGoingCitationColors = ['#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063',
        '#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063'
    ];
    collaborationColors = ['#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063',
        '#566D8C', '#8A6760', '#0D3947', '#006D02', '#424242', '#E4261F', '#7A577A', '#593825', '#257799', '#287A70', '#DC1043', '#7A6D31', '#921C70', '#314D9B', '#B36200', '#6F7063'
    ];
    public inComingCitationNodes = [];
    public inComingCitationEdges = [];
    public outGoingCitationNodes = [];
    public outGoingCitationEdges = [];
    public collaborationNodes = [];
    public collaborationEdges = [];


    public inComingCitationNetwork: any;
    public outGoingCitationNetwork: any;
    public collaborationNetwork: any;

    constructor(
        private localizationService: LocalizationService,
        private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router,) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
    }


    ngOnInit(): void {


        this.selectedAuthorId = this.activatedRoute.snapshot.paramMap.get('id');
        this.searchService.getAuthorById(this.selectedAuthorId).subscribe({
            next: (res) => {
                if (res["hits"]["hits"] && res["hits"]["hits"].length>0) {
                    this.selectedAuthor = new AuthorDTO();
                    this.selectedAuthor.bindData(res["hits"]["hits"][0]["_source"]);

                    this.searchService.getAuthorPublicationFieldById(this.selectedAuthorId).subscribe({
                        next: (res) => {
                            if (res["hits"] && res["hits"]["hits"]) {
                                this.publicationFields = new Array<AuthorPublicationFieldsDTO>;
                                if (res["hits"] && res["hits"]["hits"]) {
                                    for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                        let _publicationFields = new AuthorPublicationFieldsDTO();
                                        _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                        this.publicationFields.push(_publicationFields);
                                    }
                                    this.getCitationChartOptions();
                                    this.getInstitutionPublicationCount();
                                    this.getChartOptionInstitutionCollaborationCount();
                                    this.getChartOptionPublicationSubjectCount();
                                    this.getChartOptionAuthorCollaborationCount();
                                    this.fillPublicationIdList();
                                }

                                if (this.inComingCitationNetworkContainer) {
                                    let treeData = this.getInComingCitationTreeData();
                                    this.loadInComingCitationNetwork(treeData);
                                }

                                if (this.outGoingCitationNetworkContainer) {
                                    let treeData = this.getOutGoingCitationTreeData();
                                    this.loadOutGoingCitationNetwork(treeData);
                                }


                                if (this.collaborationNetworkContainer) {
                                    let treeData = this.getCollaborationTreeData();
                                    this.loadCollaborationNetwork(treeData);
                                }

                            }
                        },
                        error: (e) => console.error(e)
                    });


                }else{
                    window.location.href="https://static.trdizin.gov.tr/404";
                }
            },
            error: (e) => console.error(e)
        });
    }


    getPaperCountByDocType(_type: string) {
        let count = 0;
        this.publicationFields.forEach(f => {
            if (f.docType == _type) {
                count += 1;
            }
        });
        return count;
    }

    getTotalCitationCountByFields() {
        let count = 0;
        if (this.publicationFields && this.publicationFields.length > 0) {
            this.publicationFields.forEach(f => {
                if (f.citedReferencesSourceIds && f.citedReferencesSourceIds.length > 0) {
                    let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                    count += uniqueSourceCount.size;
                }
            });
        }
        return count;
    }

    getCitationAvg() {
        let _citationCount = this.getTotalCitationCountByFields();
        if (!this.publicationFields || this.publicationFields.length == 0 || _citationCount == 0) {
            return "0,0";
        }
        return parseFloat(parseFloat(("" + (_citationCount / this.publicationFields.length))).toFixed(2)).toLocaleString('tr-TR');
    }

    getTotalSelfCitationCountByFields() {
        let count = 0;
        if (this.publicationFields && this.publicationFields.length > 0) {
            this.publicationFields.forEach(f => {
                if (f.citedReferencesAuthorIds && f.citedReferencesAuthorIds.length > 0) {
                    f.citedReferencesAuthorIds.forEach(j => {
                        if (j.toString() === this.selectedAuthorId) {
                            count += 1;
                        }
                    });
                }
            });
        }
        return count;
    }

    getSelfCitationRatio() {
        let _selfCitationCount = this.getTotalSelfCitationCountByFields();
        let _citationCount = this.getTotalCitationCountByFields();

        if (!this.publicationFields || this.publicationFields.length == 0 || _selfCitationCount == 0) {
            return "0,0";
        }
        return parseFloat(parseFloat(("" + (_selfCitationCount / _citationCount) * 100)).toFixed(2)).toLocaleString('tr-TR');
    }

    getCitationChartOptions() {
        let yearsPapers = new Map<string, number>();
        let yearsCitations = new Map<string, number>();
        this.publicationFields.forEach(f => {
            // if(!f.issueYear){
            //     console.log(f);
            // }
            if (yearsPapers.get(f.issueYear)) {
                yearsPapers.set(f.issueYear, yearsPapers.get(f.issueYear) + 1);
            } else {
                yearsPapers.set(f.issueYear, 1);
            }

            if (!yearsCitations.get(f.issueYear)) {
                let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                yearsCitations.set(f.issueYear, uniqueSourceCount.size);
            } else {
                let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                yearsCitations.set(f.issueYear, yearsCitations.get(f.issueYear) + uniqueSourceCount.size);
            }


        });


        let _years = [...yearsPapers.keys(), ...yearsCitations.keys()];
        _years = _years.sort((a, b) => Number(a) - Number(b));
        let citationYear = new Set(_years);

        let citationCount: number[] = [];
        let paperCount: number[] = [];
        citationYear.forEach(cy => {
            let _citationCountOfYear = yearsCitations.get(cy)
            if (_citationCountOfYear) {
                citationCount.push(_citationCountOfYear);
            } else {
                citationCount.push(0);
            }

            let _paperCountOfYear = yearsPapers.get(cy)
            if (_paperCountOfYear) {
                paperCount.push(_paperCountOfYear);
            } else {
                paperCount.push(0);
            }

        });

        this.chartOptionPublicationAndCitationCountsOfYears = {
            legend: {},
            grid: {
                top: 35,
                bottom: 30,
                right: 10,
                left: 30
            },
            tooltip: {
                show: true,
            },
            yAxis: {
                nameTextStyle: {
                    fontSize: 0.1,
                },
                type: 'value',
                splitLine: {
                    show: true
                },
            },
            xAxis: {
                type: 'category',
                data: [...new Set(citationYear)],
                axisTick: {
                    length: 1,
                    interval: 1
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    inside: false,
                    rotate: 60,
                    fontSize: 10,
                },
                axisLine: {}
            },
            series: [
                {
                    data: citationCount,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('citationCount')),
                    // barWidth: '25%'
                },
                {
                    data: paperCount,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('paperCount')),
                    // barWidth: '25%'
                }
            ],
        };
    }


    getInstitutionPublicationCount() {

        let institutionCountMap = new Map<string, number>;
        if (this.publicationFields) {
            this.publicationFields.forEach(p => {
                if (p.authorId) {
                    let _index = p.authorId.indexOf(Number(this.selectedAuthorId));
                    let _institution = p.authorInstitutionRootTitle[_index];
                    if (!institutionCountMap.get(_institution)) {
                        institutionCountMap.set(_institution, 1);
                    } else {
                        institutionCountMap.set(_institution, (institutionCountMap.get(_institution) + 1));
                    }
                }
            });
        }
        let _data = [];
        for (var _institution of [...institutionCountMap.keys()]) {
            let _count = institutionCountMap.get(_institution);
            _data.push({name: (_institution + " (" + _count + ")"), value: _count});
        }

        this.chartOptionInstitutionPublicationCount = {
            series: [
                {
                    type: 'treemap',
                    data: _data
                }
            ]
        };


    }

    getChartOptionInstitutionCollaborationCount() {

        let institutionCountMap = new Map<string, number>;
        if (this.publicationFields) {
            this.publicationFields.forEach(p => {
                if (p.authorId) {
                    let _index = p.authorId.indexOf(Number(this.selectedAuthorId));
                    let selectedAuthorInstitution = p.authorInstitutionRootTitle[_index];
                    let _institutionList = p.authorInstitutionRootTitle;
                    _institutionList[_index] = null;
                    for (var _institution of new Set(_institutionList)) {
                        if (_institution != null) {
                            if (!institutionCountMap.get(_institution)) {
                                institutionCountMap.set(_institution, 1);
                            } else {
                                institutionCountMap.set(_institution, (institutionCountMap.get(_institution) + 1));
                            }
                        }
                    }
                }
            });
        }
        let _data = [];
        for (var _institution of [...institutionCountMap.keys()]) {
            let _count = institutionCountMap.get(_institution);
            _data.push({name: (_institution + " (" + _count + ")"), value: _count});
        }

        this.chartOptionInstitutionCollaborationCount = {
            series: [
                {
                    type: 'treemap',
                    data: _data
                }
            ]
        };


    }


    getChartOptionPublicationSubjectCount() {
        let _data = [];
        let subjectMap = new Map<string, number>;
        if (this.publicationFields) {
            this.publicationFields.forEach(p => {
                if (p.subjects) {
                    p.subjects.forEach(s => {
                        if (!subjectMap.get(s)) {
                            subjectMap.set(s, 1);
                        } else {
                            subjectMap.set(s, (subjectMap.get(s) + 1));
                        }
                    });
                }
            });
        }

        for (var _subject of [...subjectMap.keys()]) {
            let _count = subjectMap.get(_subject);
            _data.push({name: (_subject + " (" + _count + ")"), value: _count});
        }

        _data = _data.sort((a, b) => b.value - a.value);
        _data = _data.slice(0, 10);

        this.chartOptionPublicationSubjectCount = {
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    radius: '90%',
                    data: _data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };


    }

    getChartOptionAuthorCollaborationCount() {
        let _data = [];
        let authorMap = new Map<number, number>;
        let authorNameMap = new Map<number, string>;
        if (this.publicationFields) {
            this.publicationFields.forEach(p => {
                p.authorId.forEach((a, index) => {
                    if (a != Number(this.selectedAuthorId)) {
                        if (!authorMap.get(a)) {
                            authorMap.set(a, 1);
                        } else {
                            authorMap.set(a, (authorMap.get(a) + 1));
                        }
                        authorNameMap.set(a, p.authorName[index]);
                    }
                });
            });
        }

        for (var author of [...authorMap.keys()]) {
            let _count = authorMap.get(author);
            _data.push({name: (authorNameMap.get(author) + " (" + _count + ")"), value: _count});
        }

        _data = _data.sort((a, b) => b.value - a.value);
        _data = _data.slice(0, 10);

        this.chartOptionAuthorCollaborationCount = {
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    radius: '90%',
                    data: _data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }


    getInComingCitationTreeData(_selectedAuthor?: AuthorDTO) {
        let arrow = {
            to: {
                enabled: true,
                scaleFactor: 0.5,
            },
        }
        let authorNameMap = new Map<number, string>;
        let citationAuthorMap = new Map<number, number>;

        let _authorFields: Array<AuthorPublicationFieldsDTO>;

        if (_selectedAuthor && _selectedAuthor.id && !this.inComingCitationEdges.find(n => n.to == _selectedAuthor.id)) {
            let _color = this.inComingCitationColors.pop();

            // bir yazarın üstüne tıklandığında o yazarın atıf ilişkilerini ekler...
            this.searchService.getAuthorPublicationFieldById(_selectedAuthor.id).subscribe({
                next: (res) => {
                    if (res["hits"]["hits"]) {
                        _authorFields = new Array<AuthorPublicationFieldsDTO>;
                        if (res["hits"] && res["hits"]["hits"]) {
                            for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                let _publicationFields = new AuthorPublicationFieldsDTO();
                                _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                _authorFields.push(_publicationFields);
                            }
                            //---------------
                            if (_authorFields) {
                                _authorFields.forEach(p => {
                                    if (p.citedReferencesAuthorIds) {
                                        p.citedReferencesAuthorIds.forEach((ca, index) => {
                                            if (!citationAuthorMap.get(ca)) {
                                                citationAuthorMap.set(ca, 1);
                                            } else {
                                                citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                                            }
                                            authorNameMap.set(ca, p.citedReferencesAuthorNames[index]);
                                        });
                                    }
                                });
                                let _data = []
                                for (var author of [...citationAuthorMap.keys()]) {
                                    let _count = citationAuthorMap.get(author);
                                    _data.push({name: (author), value: _count});
                                }

                                _data = _data.sort((a, b) => b.value - a.value);
                                _data = _data.slice(0, 10);

                                _data.forEach(d => {
                                    let oldNode = this.inComingCitationNodes.find(n => {
                                        return n.id == d.name;
                                    });
                                    if (!oldNode) {
                                        this.inComingCitationNodes.push({
                                            id: d.name,
                                            label: authorNameMap.get(d.name),
                                            color: _color
                                        })
                                    } else {
                                        this.inComingCitationNodes.forEach((n, index) => {
                                            if (n.id == d.name || n.id == _selectedAuthor.id) {
                                                n.color = _color;
                                            }
                                            this.inComingCitationNodes[index] = n;
                                        });
                                    }


                                    this.inComingCitationEdges.push({
                                        from: d.name,
                                        to: _selectedAuthor.id,
                                        arrows: arrow,
                                        width: Math.log1p(d.value) * 2,
                                        title: `${authorNameMap.get(d.name)} → ${_selectedAuthor.fullName} \n ${this.localizationService.getLocalizationValue("CitationCount")}: ${d.value}`,
                                        selfReference: {
                                            size: 30,
                                            angle: Math.PI / 2,
                                            renderBehindTheNode: false
                                        }
                                    });
                                });


                                this.inComingCitationNetwork.body.data.nodes.update(this.inComingCitationNodes);
                                this.inComingCitationNetwork.body.data.edges.update(this.inComingCitationEdges);
                                //this.network.focusOnNode(_selectedAuthor.id,{scale: 1, offset:{x: -(1/3)}});
                            }
                            //---------------


                        }
                    }
                    // this.loadVisTree(treeData);
                }
            });
        } else if (!_selectedAuthor || !_selectedAuthor.id) {
            let _color = this.inComingCitationColors.pop();

            //seçili yazarın aldığı atıf ilişkileri
            if (this.publicationFields) {
                this.publicationFields.forEach(p => {
                    if (p.citedReferencesAuthorIds) {
                        p.citedReferencesAuthorIds.forEach((ca, index) => {
                            if (!citationAuthorMap.get(ca)) {
                                citationAuthorMap.set(ca, 1);
                            } else {
                                citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                            }
                            authorNameMap.set(ca, p.citedReferencesAuthorNames[index]);
                        });
                    }
                });
                let _data = []
                for (var author of [...citationAuthorMap.keys()]) {
                    let _count = citationAuthorMap.get(author);
                    _data.push({name: (author), value: _count});
                }

                _data = _data.sort((a, b) => b.value - a.value);
                _data = _data.slice(0, 10);
                // console.log(_data);

                if (!_data.find(d => {
                    return d.name == this.selectedAuthor.id
                })) {
                    this.inComingCitationNodes.push({
                        id: this.selectedAuthor.id,
                        label: this.selectedAuthor.fullName,
                        color: _color
                    })

                }
                _data.forEach(d => {
                    this.inComingCitationNodes.push({id: d.name, label: authorNameMap.get(d.name), color: _color})
                    this.inComingCitationEdges.push({
                        from: d.name,
                        to: this.selectedAuthor.id,
                        arrows: arrow,
                        width: Math.log1p(d.value) * 2,
                        title: `${authorNameMap.get(d.name)} → ${this.selectedAuthor.fullName} \n ${this.localizationService.getLocalizationValue("CitationCount")}: ${d.value}`,
                        selfReference: {
                            size: 30,
                            angle: Math.PI / 2,
                            renderBehindTheNode: false
                        }
                    })
                });
            }
        }

        var treeData = {
            nodes: this.inComingCitationNodes,
            edges: this.inComingCitationEdges
        };

        return treeData;
    }

    loadInComingCitationNetwork(treedata) {

        var options = {
            nodes: {
                font: {color: "#fff"},
                shape: "box"
            },
            height: '600px',
            layout: {
                randomSeed: 482,
            },
            physics: {
                barnesHut: {
                    springLength: 200,
                    avoidOverlap: 1
                },
                forceAtlas2Based: {
                    centralGravity: 0.005,
                    springLength: 200,
                    springConstant: 0.18,
                },
                solver: "forceAtlas2Based",
                timestep: 0.35,
                stabilization: {iterations: 150},
            },
            interaction: {
                navigationButtons: true,
                keyboard: false,
                tooltipDelay: 200,
                hover: true,
            },
            autoResize: true
        };
        var container = this.inComingCitationNetworkContainer.nativeElement;
        this.inComingCitationNetwork = new vis.Network(container, treedata, options);


        const self = this;
        var that = this;
        this.inComingCitationNetwork.on("selectNode", function (params) {
            let clickedId = params.nodes[0];
            let _selectedNode = self.inComingCitationNodes.filter(x => x.id === clickedId).pop();
            let _selectedAuthor = new AuthorDTO();
            _selectedAuthor.id = _selectedNode.id;
            _selectedAuthor.fullName = _selectedNode.label;

            let treeData = self.getInComingCitationTreeData(_selectedAuthor);
        });

    }


    getOutGoingCitationTreeData(_selectedAuthor?: AuthorDTO) {
        let arrow = {
            to: {
                enabled: true,
                scaleFactor: 0.5,
            },
        }
        let authorNameMap = new Map<number, string>;
        let citationAuthorMap = new Map<number, number>;

        let _authorFields: Array<AuthorPublicationFieldsDTO>;


        if (_selectedAuthor && _selectedAuthor.id && !this.outGoingCitationEdges.find(n => n.from == _selectedAuthor.id)) {
            let _color = this.outGoingCitationColors.pop();

            // bir yazarın üstüne tıklandığında o yazarın atıf ilişkilerini ekler...
            this.searchService.getAuthorPublicationFieldById(_selectedAuthor.id).subscribe({
                next: (res) => {
                    if (res["hits"]["hits"]) {
                        _authorFields = new Array<AuthorPublicationFieldsDTO>;
                        if (res["hits"] && res["hits"]["hits"]) {
                            for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                let _publicationFields = new AuthorPublicationFieldsDTO();
                                _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                _authorFields.push(_publicationFields);
                            }
                            if (_authorFields) {
                                _authorFields.forEach(p => {
                                    if (p.referencesAuthorIds) {
                                        p.referencesAuthorIds.forEach((ca, index) => {
                                            if (!citationAuthorMap.get(ca)) {
                                                citationAuthorMap.set(ca, 1);
                                            } else {
                                                citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                                            }
                                            authorNameMap.set(ca, p.referencesAuthorNames[index]);
                                        });
                                    }
                                });
                                let _data = []
                                for (var author of [...citationAuthorMap.keys()]) {
                                    let _count = citationAuthorMap.get(author);
                                    _data.push({name: (author), value: _count});
                                }

                                _data = _data.sort((a, b) => b.value - a.value);
                                _data = _data.slice(0, 10);

                                _data.forEach(d => {
                                    let oldNode = this.outGoingCitationNodes.find(n => {
                                        return n.id == d.name;
                                    });
                                    if (!oldNode) {
                                        this.outGoingCitationNodes.push({
                                            id: d.name,
                                            label: authorNameMap.get(d.name),
                                            color: _color
                                        })
                                    } else {
                                        this.outGoingCitationNodes.forEach((n, index) => {
                                            if (n.id == d.name || n.id == _selectedAuthor.id) {
                                                n.color = _color;
                                            }
                                            this.outGoingCitationNodes[index] = n;
                                        });
                                    }


                                    this.outGoingCitationEdges.push({
                                        from: _selectedAuthor.id,
                                        to: d.name,
                                        arrows: arrow,
                                        width: Math.log1p(d.value) * 2,
                                        title: `${_selectedAuthor.fullName} → ${authorNameMap.get(d.name)} \n ${this.localizationService.getLocalizationValue("CitationCount")}: ${d.value}`,
                                        selfReference: {
                                            size: 30,
                                            angle: Math.PI / 2,
                                            renderBehindTheNode: false
                                        }
                                    });
                                });


                                this.outGoingCitationNetwork.body.data.nodes.update(this.outGoingCitationNodes);
                                this.outGoingCitationNetwork.body.data.edges.update(this.outGoingCitationEdges);
                            }

                        }
                    }
                    // this.loadVisTree(treeData);
                }
            });
        } else if (!_selectedAuthor || !_selectedAuthor.id) {
            let _color = this.outGoingCitationColors.pop();

            //seçili yazarın aldığı atıf ilişkileri
            if (this.publicationFields) {
                this.publicationFields.forEach(p => {

                    if (p.referencesAuthorIds) {
                        p.referencesAuthorIds.forEach((ca, index) => {
                            if (!citationAuthorMap.get(ca)) {
                                citationAuthorMap.set(ca, 1);
                            } else {
                                citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                            }
                            authorNameMap.set(ca, p.referencesAuthorNames[index]);
                        });
                    }
                });
                let _data = []
                for (var author of [...citationAuthorMap.keys()]) {
                    let _count = citationAuthorMap.get(author);
                    _data.push({name: (author), value: _count});
                }

                _data = _data.sort((a, b) => b.value - a.value);
                _data = _data.slice(0, 10);

                if (!_data.find(d => {
                    return d.name == this.selectedAuthor.id
                })) {
                    this.outGoingCitationNodes.push({
                        id: this.selectedAuthor.id,
                        label: this.selectedAuthor.fullName,
                        color: _color
                    })

                }
                _data.forEach(d => {
                    this.outGoingCitationNodes.push({id: d.name, label: authorNameMap.get(d.name), color: _color})
                    this.outGoingCitationEdges.push({
                        from: this.selectedAuthor.id,
                        to: d.name,
                        arrows: arrow,
                        width: Math.log1p(d.value) * 2,
                        title: `${this.selectedAuthor.fullName} → ${authorNameMap.get(d.name)} \n ${this.localizationService.getLocalizationValue("CitationCount")}: ${d.value}`,
                        selfReference: {
                            size: 30,
                            angle: Math.PI / 2,
                            renderBehindTheNode: false
                        }
                    })
                });
            }
        }

        var treeData = {
            nodes: this.outGoingCitationNodes,
            edges: this.outGoingCitationEdges
        };

        return treeData;
    }


    loadOutGoingCitationNetwork(treedata) {

        var options = {
            nodes: {
                font: {color: "#fff"},
                shape: "box"
            },
            height: '600px',
            layout: {
                randomSeed: 482,
            },
            physics: {
                barnesHut: {
                    springLength: 200,
                    avoidOverlap: 1
                },
                forceAtlas2Based: {
                    centralGravity: 0.005,
                    springLength: 200,
                    springConstant: 0.18,
                },
                solver: "forceAtlas2Based",
                timestep: 0.35,
                stabilization: {iterations: 150},
            },
            interaction: {
                navigationButtons: true,
                keyboard: false,
                tooltipDelay: 200,
                hover: true,
            },
            autoResize: true
        };
        var container = this.outGoingCitationNetworkContainer.nativeElement;
        this.outGoingCitationNetwork = new vis.Network(container, treedata, options);


        const self = this;
        var that = this;
        this.outGoingCitationNetwork.on("selectNode", function (params) {
            let clickedId = params.nodes[0];
            let _selectedNode = self.outGoingCitationNodes.filter(x => x.id === clickedId).pop();
            let _selectedAuthor = new AuthorDTO();
            _selectedAuthor.id = _selectedNode.id;
            _selectedAuthor.fullName = _selectedNode.label;

            let treeData = self.getOutGoingCitationTreeData(_selectedAuthor);
        });

    }


    getCollaborationTreeData(_selectedAuthor?: AuthorDTO) {
        let arrow = {
            to: {
                enabled: true,
                scaleFactor: 0.5,
            },
        }
        let authorNameMap = new Map<number, string>;
        let citationAuthorMap = new Map<number, number>;

        let _authorFields: Array<AuthorPublicationFieldsDTO>;


        if (_selectedAuthor && _selectedAuthor.id && !this.collaborationEdges.find(n => n.from == _selectedAuthor.id)) {
            let _color = this.collaborationColors.pop();

            // bir yazarın üstüne tıklandığında o yazarın atıf ilişkilerini ekler...
            this.searchService.getAuthorPublicationFieldById(_selectedAuthor.id).subscribe({
                next: (res) => {
                    if (res["hits"]["hits"]) {
                        _authorFields = new Array<AuthorPublicationFieldsDTO>;
                        if (res["hits"] && res["hits"]["hits"]) {
                            for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                let _publicationFields = new AuthorPublicationFieldsDTO();
                                _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                _authorFields.push(_publicationFields);
                            }
                            if (_authorFields) {
                                _authorFields.forEach(p => {
                                    if (p.authorId) {
                                        p.authorId.forEach((ca, index) => {
                                            if (ca != Number(_selectedAuthor.id) && !this.collaborationEdges.find(n => n.from == ca)) {
                                                if (!citationAuthorMap.get(ca)) {
                                                    citationAuthorMap.set(ca, 1);
                                                } else {
                                                    citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                                                }
                                            }
                                            authorNameMap.set(ca, p.authorName[index]);
                                        });
                                    }
                                });
                                let _data = []
                                for (var author of [...citationAuthorMap.keys()]) {
                                    let _count = citationAuthorMap.get(author);
                                    _data.push({name: (author), value: _count});
                                }

                                _data = _data.sort((a, b) => b.value - a.value);
                                _data = _data.slice(0, 10);

                                _data.forEach(d => {
                                    let oldNode = this.collaborationNodes.find(n => {
                                        return n.id == d.name;
                                    });
                                    if (!oldNode) {
                                        this.collaborationNodes.push({
                                            id: d.name,
                                            label: authorNameMap.get(d.name),
                                            color: _color
                                        })
                                    } else {
                                        this.collaborationNodes.forEach((n, index) => {
                                            if (n.id == d.name || n.id == _selectedAuthor.id) {
                                                n.color = _color;
                                            }
                                            this.collaborationNodes[index] = n;
                                        });
                                    }


                                    this.collaborationEdges.push({
                                        from: _selectedAuthor.id,
                                        to: d.name,
                                        width: Math.log1p(d.value) * 2,
                                        title: `${authorNameMap.get(d.name)} — ${_selectedAuthor.fullName} \n ${this.localizationService.getLocalizationValue("PublicationCount")}: ${d.value}`,
                                        selfReference: {
                                            size: 30,
                                            angle: Math.PI / 2,
                                            renderBehindTheNode: false
                                        }
                                    });
                                });


                                this.collaborationNetwork.body.data.nodes.update(this.collaborationNodes);
                                this.collaborationNetwork.body.data.edges.update(this.collaborationEdges);
                            }

                        }
                    }
                    // this.loadVisTree(treeData);
                }
            });
        } else if (!_selectedAuthor || !_selectedAuthor.id) {
            let _color = this.collaborationColors.pop();

            //seçili yazarın aldığı atıf ilişkileri
            if (this.publicationFields) {
                this.publicationFields.forEach(p => {
                    if (p.authorId) {
                        p.authorId.forEach((ca, index) => {
                            if (ca != Number(this.selectedAuthor.id)) {
                                if (!citationAuthorMap.get(ca)) {
                                    citationAuthorMap.set(ca, 1);
                                } else {
                                    citationAuthorMap.set(ca, (citationAuthorMap.get(ca) + 1));
                                }
                            }
                            authorNameMap.set(ca, p.authorName[index]);

                        });
                    }
                });
                let _data = []
                for (var author of [...citationAuthorMap.keys()]) {
                    let _count = citationAuthorMap.get(author);
                    _data.push({name: (author), value: _count});
                }

                _data = _data.sort((a, b) => b.value - a.value);
                _data = _data.slice(0, 10);

                if (!_data.find(d => {
                    return d.name == this.selectedAuthor.id
                })) {
                    this.collaborationNodes.push({
                        id: this.selectedAuthor.id,
                        label: this.selectedAuthor.fullName,
                        color: _color,
                    })

                }
                _data.forEach(d => {
                    this.collaborationNodes.push({id: d.name, label: authorNameMap.get(d.name), color: _color})
                    this.collaborationEdges.push({
                        from: this.selectedAuthor.id,
                        to: d.name,
                        width: Math.log1p(d.value) * 2,
                        title: `${authorNameMap.get(d.name)} — ${this.selectedAuthor.fullName} \n ${this.localizationService.getLocalizationValue("PublicationCount")}: ${d.value}`,
                        selfReference: {
                            size: 30,
                            angle: Math.PI / 2,
                            renderBehindTheNode: false
                        }
                    })
                });
            }
        }

        var treeData = {
            nodes: this.collaborationNodes,
            edges: this.collaborationEdges
        };

        return treeData;
    }

    loadCollaborationNetwork(treedata) {

        var options = {
            nodes: {
                font: {color: "#fff"},
                shape: "box"
            },
            height: '600px',
            layout: {
                randomSeed: 482,
            },
            physics: {
                barnesHut: {
                    springLength: 200,
                    avoidOverlap: 1
                },
                forceAtlas2Based: {
                    centralGravity: 0.005,
                    springLength: 200,
                    springConstant: 0.18,
                },
                solver: "forceAtlas2Based",
                timestep: 0.35,
                stabilization: {iterations: 150},
            },
            interaction: {
                navigationButtons: true,
                keyboard: false,
                tooltipDelay: 200,
                hover: true,
            },
            autoResize: true
        };
        var container = this.collaborationNetworkContainer.nativeElement;
        this.collaborationNetwork = new vis.Network(container, treedata, options);


        const self = this;
        var that = this;
        this.collaborationNetwork.on("selectNode", function (params) {
            let clickedId = params.nodes[0];
            let _selectedNode = self.collaborationNodes.filter(x => x.id === clickedId).pop();
            let _selectedAuthor = new AuthorDTO();
            _selectedAuthor.id = _selectedNode.id;
            _selectedAuthor.fullName = _selectedNode.label;

            let treeData = self.getCollaborationTreeData(_selectedAuthor);
        });
    }

    changePublicationResultPage(event) {
        this.publicationPageModel.currentPage = event.currentPage;
        if (this.publicationPageModel.size != event.size) {
            this.publicationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.publicationPageModel.size = event.size;
        }
        this.publicationPageModel.totalCount = this.publicationIdList.length;
        this.publicationPageModel.totalPages = Math.ceil(this.publicationPageModel.totalCount / this.publicationPageModel.size)
        let _array = this.publicationIdList.slice(
            (this.publicationPageModel.currentPage - 1) * this.publicationPageModel.size, this.publicationPageModel.size * this.publicationPageModel.currentPage);
        if (_array && _array.length > 0) {
            this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
                next: (res) => {
                    this.publicationList = new Array<PublicationDTO>();
                    if (res["hits"]["hits"]) {
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.publicationList.push(_publication);
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }

    }

    fillPublicationIdList() {
        this.publicationIdList = new Array<string>();
        if (this.publicationFields.length > 0) {
            this.publicationFields.forEach(p => {
                if (p.id) {
                    this.publicationIdList.push(p.id);
                }
            });
        }

        this.publicationIdList.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        this.publicationPageModel.totalCount = this.publicationIdList.length;
        this.publicationPageModel.totalPages = Math.ceil(this.publicationPageModel.totalCount / this.publicationPageModel.size)
        this.changePublicationResultPage(this.publicationPageModel.currentPage);
    }

    fillAuthorCitationIdList(fields: AuthorPublicationFieldsDTO[]) {

        this.authorCitationIdList = new Array<string>();
        let _authorCitationIdList = new Array<number>();
        let uniqeSource = new Array<number>();
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesSourceIds && f.citedReferencesSourceIds.length > 0) {
                    _authorCitationIdList = _authorCitationIdList.concat([...f.citedReferencesSourceIds]);
                }

            });
        }

        let uniqueSet = new Set(_authorCitationIdList);
        uniqeSource = Array.from(uniqueSet);

        uniqeSource.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        let stringList: string[] = uniqeSource.map(num => num.toString())
        this.authorCitationIdList = stringList;

        this.authorCitationPageModel.totalCount = this.authorCitationIdList.length;
        this.authorCitationPageModel.totalPages = Math.ceil(this.authorCitationPageModel.totalCount / this.authorCitationPageModel.size)
        this.changeAuthorCitationResultPage(this.authorCitationPageModel.currentPage);
    }

    changeAuthorCitationResultPage(event) {
        this.authorCitationPageModel.currentPage = event.currentPage;

        if (this.authorCitationPageModel.size != event.size) {
            this.authorCitationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.authorCitationPageModel.size = event.size;
        }

        this.authorCitationPageModel.totalPages = Math.ceil(this.authorCitationPageModel.totalCount / this.authorCitationPageModel.size)
        let _array = this.authorCitationIdList.slice((this.authorCitationPageModel.currentPage - 1) * this.authorCitationPageModel.size, this.authorCitationPageModel.size * this.authorCitationPageModel.currentPage);
        if (_array && _array.length > 0) {
            this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
                next: (res) => {
                    this.authorCitationList = new Array<PublicationDTO>();
                    if (res["hits"]["hits"]) {
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.authorCitationList.push(_publication);
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }
    }

    protected readonly getCurrentYear = getCurrentYear;
}
