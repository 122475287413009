export class KeyCloackUserDTO  {
  public id?: string;
  public firstName?: string;
  public lastName?: string;
  public username?: string;
  public email?: string;

  public committee?: number;
  public expert?: number;
  public authorId?: boolean;
  public journalAuthUser?: boolean;
  public authorizationList?: Array<String> = [];
  public journalCount: 0;

  constructor() {
  }

  bindData(obj: any): void {
    if (obj == null) {
      return;
    }

    if(obj.id) {
      this.id = obj.id;
    }

    if(obj.firstName) {
      this.firstName = obj.firstName;
    }

    if(obj.lastName) {
      this.lastName = obj.lastName;
    }

    if(obj.username) {
      this.username = obj.username;
    }

    if(obj.email) {
      this.email = obj.email;
    }

    if(obj.committee) {
      this.committee = obj.committee;
    }

    if(obj.expert) {
      this.expert = obj.expert;
    }

    if(obj.authorId) {
      this.authorId = obj.authorId;
    }

    if(obj.journalAuthUser) {
      this.journalAuthUser = obj.journalAuthUser;
    }
    if(obj.authorizationList) {
      this.authorizationList = obj.authorizationList;
    }

    this.journalCount =  obj.journalCount ? obj.journalCount : 0;
  }
}
