import {BaseDTO} from "../../core/BaseDTO";
import {FacetBucketDTO} from "./FacetBucketDTO";

export class FacetDTO extends BaseDTO {
    public key: string;
    public order: number;
    public valueList: FacetBucketDTO[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.key = obj.key;
        if (obj.valueList) {
            this.valueList = obj.valueList;
        }
    }


}
