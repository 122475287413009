import {OnInit} from "@angular/core";

export enum SortType {
  ASC = "ASC",
  DESC = "DESC"
}

export class PageModel {
  currentPage: number = 1;
  totalCount: number = 0;
  showTotalCount: boolean = true;
  public totalPages: number = 0;
  pageScale: number = 15;
  size: number;
  sortField: string = 'ID';
  sortType: SortType = SortType.ASC;


  constructor(_size?:number) {
    if(_size){
      this.size=_size;
    }else{
      this.size=20;
    }
  }

  bindData(obj: any): void {
    if (obj == null) {
      return;
    }
    if (obj.size == 0  || obj.size) {
      this.size = obj.size;
    }

    if (obj.sortField) {
      this.sortField = obj.sortField;
    }

    if (obj.sortType) {
      this.sortType = obj.sortType;
    }

    if (obj.currentPage) {
      this.currentPage = obj.currentPage;
    }

    if (obj.totalCount) {
      this.totalCount = obj.totalCount;
    }else{
      this.totalCount=0;
    }

    if (obj.totalPages) {
      this.totalPages = obj.totalPages;
    }else{
      this.totalPages=0;
    }
    if (obj.pageScale || obj.pageScale == 0) {
      this.pageScale = obj.pageScale;
    }
    if (obj.showTotalCount) {
      this.showTotalCount = obj.showTotalCount;
    }
  }
}
