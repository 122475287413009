import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalizationPipe} from "./pipes/localization-pipe";
import {ExternalLinkDirective} from './directive/external-link.directive';
import {PaginaterComponent} from "./component/paginater/paginater.component";
import {FormsModule} from "@angular/forms";
import {UtcToLocalTimePipe} from "./pipes/utc-to-local-time.pipe";
import {HasAuthDirective} from "../app/Authorization/has-auth.directive";
import {UrlSlugifyPipe} from "./pipes/urlSlugify-pipe";
import {ExportComponent} from "../app/search/export/export.component";

@NgModule({
    declarations: [
        UtcToLocalTimePipe,
        LocalizationPipe,
        UrlSlugifyPipe,
        PaginaterComponent,
        ExternalLinkDirective,
        HasAuthDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ExportComponent
    ],
    exports: [
        LocalizationPipe,
        UrlSlugifyPipe,
        PaginaterComponent,
        ExternalLinkDirective,
        UtcToLocalTimePipe,
        HasAuthDirective
    ],
    providers: []
})
export class TrdCoreModule {
}
