import {Injectable} from '@angular/core';
import {AuthInfoDTO} from '../dto/AuthInfoDTO';

@Injectable()
export class CookieService {
  cookieName = "TRDIZIN_TOKEN";
  headerToken = "";
  
  constructor() { }


  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    // let ca: Array<string> = this.exampleCookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }

  public deleteCookie(name) {
    this.setCookie(name, "", -1);
  }

  public setCookie(name: string, value: string, expireDays: number, path: string = "") {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + (path.length > 0 ? "; path=" + path : "");
  }


  public getUserInfo(cookieName: string){
    let authInfo: AuthInfoDTO;  
    let cookie = this.getCookie(cookieName);
    if (cookie && cookie != "") {
      let authJson = this.parseJwt(cookie);
      if(authJson){        
        authInfo = new AuthInfoDTO();
        authInfo.name = authJson["given_name"];
        authInfo.surname = authJson["family_name"];
        authInfo.email = authJson["email"];
        authInfo.userId = authJson["sub"];
        authInfo.fullName = authJson["name"];
      }
    }

    return authInfo;
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

}
