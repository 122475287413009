import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';


import {CookieService} from "../service/CookieService";
import {ConfigurationService} from "../service/ConfigurationService";
import {Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService,private configurationService:ConfigurationService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.configurationService.isRequestOfPublicScreen()){
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.cookieService.getCookie(this.cookieService.cookieName)}`,
        },
        withCredentials: true
      });
    }


    return next.handle(req);
  }
}
