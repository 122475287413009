import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";

export class PublicationProductDTO extends SearchAbstractDTO {
    public title: string;
    public order: number



    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.title=obj.title;
        this.order=obj.order;
    }
}