import {BaseDTO} from "../../core/BaseDTO";
import {JournalPersonDTO} from "./JournalPersonDTO";
import {JournalYearDTO} from "./JournalYearDTO";

export class JournalDTO extends BaseDTO {
    public id: string;
    public indexedBy: string;
    public firstIndexDate: string;
    public indexDate: string;
    public registrationDate: string;
    public applicationDate: string;
    public title: string;
    public otherTitle: string;
    public isActive: boolean;
    public webAddress: string;
    public issn: string;
    public eissn: string;
    public coverPath: string;
    public firstPublishYear: number;
    public publicationLanguage: string;
    public publicationFormats: string;
    public oldJournal: JournalDTO;
    public newJournal: JournalDTO;
    public person: JournalPersonDTO[];
    public journalYear: JournalYearDTO[];


    public selectedJournalYear: JournalYearDTO;
    public highlight: any;
    citationCount=0;
    citationFromPaper=0;
    citationFromProject=0;
    publicationCount=0;
    citedPublicationCount=0;
    citationAvg="0,0";

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.indexDate = obj.indexDate;
        this.registrationDate = obj.registrationDate;
        this.applicationDate = obj.applicationDate;
        this.title = obj.title;
        this.otherTitle = obj.otherTitle;
        this.isActive = obj.isActive;

        this.webAddress = obj.webAddress;
        if (this.webAddress && !this.webAddress.startsWith("http")) {
            this.webAddress = "http://" + this.webAddress;
        }

        this.indexedBy = obj.indexedBy;
        this.firstIndexDate = obj.firstIndexDate;
        this.issn = obj.issn;
        this.eissn = obj.eissn;
        this.coverPath = obj.coverPath;
        this.firstPublishYear = obj.firstPublishYear;
        this.publicationLanguage = obj.publicationLanguage;
        this.publicationFormats = obj.publicationFormats;

        if (obj.oldJournal) {
            this.oldJournal = new JournalDTO();
            this.oldJournal.bindData(obj.oldJournal);
        }

        if (obj.newJournal) {
            this.newJournal = new JournalDTO();
            this.newJournal.bindData(obj.newJournal);
        }

        if (obj.person) {
            this.person = [];
            if (obj.person && obj.person.length > 0) {
                for (let i = 0; i < obj.person.length; i++) {
                    let _person = new JournalPersonDTO();
                    _person.bindData(obj.person[i]);
                    this.person.push(_person);
                }
            }
        }

        if (obj.journalYear) {
            this.journalYear = [];
            if (obj.journalYear && obj.journalYear.length > 0) {
                for (let i = 0; i < obj.journalYear.length; i++) {
                    let _journalYear = new JournalYearDTO();
                    _journalYear.bindData(obj.journalYear[i]);
                    this.journalYear.push(_journalYear);
                }
                this.journalYear = this.journalYear.sort((a, b) => b.year - a.year);
            }
        }


        if (obj.highlight) {
            this.highlight = obj.highlight;
        }
        if (this.highlight) {
            let _highlight = this.highlight;
            if (_highlight["searchField"]) {
                let _searchField = _highlight["searchField"];
                _searchField.forEach(sf => {
                    let cleanedHighLight = sf.replaceAll("<strong class=\"highlight\">", "");
                    cleanedHighLight = cleanedHighLight.replaceAll("</strong>", "");
                    if (this.title == cleanedHighLight) {
                        this.title = sf;
                    }
                });
            }
        }

    }

    getEditor() {
        let _editor;
        if (this.person && this.person.length > 0) {
            _editor = this.person.find(p => {
                return p.type == "EDITOR";
            });
        }
        return _editor;
    }


}
