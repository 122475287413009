import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {SaveSearchService} from "./save-search.service";
import {SavedSearchDTO} from "./SavedSearchDTO";
import {FormsModule} from "@angular/forms";
import {AuthInfoDTO} from "../../../trdcore/dto/AuthInfoDTO";
import {AuthorizationService} from "../../Authorization/AuthorizationService";
import {ToastrService} from "ngx-toastr";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {catchError, EMPTY, of} from "rxjs";
import * as moment from "moment";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-save-search',
  standalone: true,
  imports: [
    TrdCoreModule,
    FormsModule,
    NgIf
  ],
  templateUrl: './save-search.component.html',
  styleUrl: './save-search.component.scss'
})
export class SaveSearchComponent implements OnInit {

  @Input() searchText: string;
  @Input() searchUrl: string;
  @Input() searchTotalCount: number;
  @ViewChild('searchModal') private searchModal: ElementRef;

  selectedSearchName: string = '';
  selectedSaveSearch: SavedSearchDTO = new SavedSearchDTO();
  activeUser: AuthInfoDTO
  savedSearchList: SavedSearchDTO[] = [];

  ngOnInit(): void {
    this.activeUser = this.authorizationService.getActiveUser();
  }

  constructor(private saveSearchService: SaveSearchService,
              private authorizationService: AuthorizationService,
              private toastrService: ToastrService,
              private localizationService: LocalizationService) {
  }

  setSavedSearch(selectedSearchName: string) {
    if (!this.selectedSaveSearch) {
      this.selectedSaveSearch = new SavedSearchDTO();
    }
    this.selectedSaveSearch.created_date = new Date();
    let dateCreatedDateFormat=this.transform(this.selectedSaveSearch.created_date).toString();
    this.selectedSaveSearch.setGmt3CreatedConvert(dateCreatedDateFormat);

    this.selectedSaveSearch.created_by = this.activeUser.userId;
    let urlParamsForSearchTitle = new URLSearchParams(new URL(this.searchUrl).search);
    this.selectedSaveSearch.search_title = selectedSearchName;
    this.selectedSaveSearch.search_text = urlParamsForSearchTitle.get('q');
    this.selectedSaveSearch.total_count = this.searchTotalCount;
    if (this.searchUrl && this.searchUrl.includes('dergi')) {
      this.selectedSaveSearch.type = 'journal';
    } else if (this.searchUrl && this.searchUrl.includes('yayin')){
      this.selectedSaveSearch.type = 'Search';
    } else if (this.searchUrl && this.searchUrl.includes('yazar')){
      this.selectedSaveSearch.type = 'author';
    } else {
      this.selectedSaveSearch.type = 'institution';
    }
    this.selectedSaveSearch.url = this.searchUrl;

    if (this.selectedSaveSearch.do_notification == null) {
      this.selectedSaveSearch.do_notification = false;
    }
    this.saveSearchService.checkUniqueSearchToSearchTextAndUrl(this.selectedSaveSearch)
        .subscribe(res => {
          if (res["data"] && res["data"].length > 0) {
            this.toastrService.error(this.localizationService.getLocalizationValue('DuplicateSearch'));
            this.closeModal();
            this.clearModalContent();
            this.resetSelectedSearchName();
          } else {
            this.saveSearchToServer();
          }
        });
  }

  saveSearchToServer() {
    const userId = this.activeUser.userId;
    this.saveSearchService.getSavedSearchCountByUserId(userId).pipe(
        catchError((error) => {
          this.toastrService.error(this.localizationService.getLocalizationValue('Error'));
          return of(error);
        })
    ).subscribe(res => {
      if (res >= 20) {
        this.toastrService.error(this.localizationService.getLocalizationValue('SavedSearchLimitExceed'));
        this.closeModal();
        return EMPTY;
      } else {
        this.saveSearchService.addSavedSearch(this.selectedSaveSearch).pipe(
            catchError((error) => {
              this.toastrService.error(this.localizationService.getLocalizationValue('Error'));
              return of(error);
            })
        ).subscribe(() => {
          this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessSave'));
          this.getSavedSearchList();
          this.closeModal();
          this.clearModalContent();
          this.resetSelectedSearchName();
        });
      }
    });
  }

  saveSearch(selectedSearchName: string) {
    this.setSavedSearch(selectedSearchName);
  }

  clearModalContent() {  // Aramayı kaydet modal temizleme
    const tempUrl = this.selectedSaveSearch.url;
    const tempSearchText = this.selectedSaveSearch.search_text;

    this.selectedSaveSearch = new SavedSearchDTO();
    this.selectedSaveSearch.url = tempUrl;
    this.selectedSaveSearch.search_text = tempSearchText;
    this.selectedSaveSearch.do_notification = false;

    const toggleElement = document.getElementById('flexSwitchCheckDefault') as HTMLInputElement;
    if (toggleElement) {
      toggleElement.checked = false;
    }
    this.selectedSearchName = "";
  }
  resetSelectedSearchName() {
    this.selectedSearchName = '';
    this.resetNotificationSwitch();
  }

  resetNotificationSwitch() {
    this.selectedSaveSearch.do_notification = false;
  }
  getSavedSearchList() {
    this.saveSearchService.findSavedSearchListToUserId(this.activeUser.userId).subscribe(res => {
      this.savedSearchList = [];
      if (res["data"]) {
        for (let i = 0; i < res["data"].length; i++) {
          let _savedSearch = new SavedSearchDTO();
          _savedSearch.bindData(res["data"][i]);
          this.savedSearchList.push(_savedSearch);
        }
      }
    });

  }

  isSaveButtonEnabled(): boolean {
    let trimmedSearchName = this.selectedSearchName.trim();
    return trimmedSearchName.length >= 3;
  }

  onEnterKey(): void {
    let trimmedSearchName = this.selectedSearchName.trim();
    if (trimmedSearchName.length < 3) {
      this.toastrService.error(this.localizationService.getLocalizationValue('EnterThreeCharacters'));
    } else {
      this.saveSearch(trimmedSearchName);
    }
  }

  onNotificationToggle(event: any): void {
    if (!this.selectedSaveSearch) {
      this.selectedSaveSearch = new SavedSearchDTO();
    }
    this.selectedSaveSearch.do_notification = event.target.checked;
  }
  public closeModal() {
    this.searchModal.nativeElement.click();
    this.clearModalContent();
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    let date: Date;
    let utcDate: Date;
    if (!value || value=='null' || value==null){
      return "";
    }
    if(value && value instanceof Date){
      date = (value as Date);
      utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
      date = utcDate;
    } else if(value) {
      date = moment.parseZone(value,'YYYY-MM-DDTHH:mm:SS').toDate();
      date = date;
    }
    return moment(date).format('YYYY-MM-DDTHH:mm');
  }

}
