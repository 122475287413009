<div class="container mx-auto" *ngIf="idList && idList.length>0">
    <div class="row d-flex justify-content-around mx-auto">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  col-12 mx-auto">
            <h4 class="light-blue">{{'selected'|localize}} {{idList.length}} {{'documentsAnalyzed' |localize}}</h4>
            <hr>
            <div class="row d-flex flex-wrap justify-content-around mx-auto">
                <div class="col-2 citation-card d-flex flex-column me-2 mb-3  px-4 py-4 text-center ">
                    <label>H-Indeks</label>
                    <label class=" fsize-2  mt-auto">{{getHindex()}}</label>
                </div>

                <div class=" col-2 citation-card d-flex flex-column me-2 mb-3  px-4 py-4 text-center">
                    <label>{{'citationCount' | localize}}</label>
                    <label class="mt-auto fsize-2">{{getTotalCitationCount()}}</label>
                </div>

                <div class="col-2 citation-card d-flex flex-column me-2 mb-3  px-4 py-4 text-center">
                    <label>{{'citationAvg' | localize}}<i class="ms-1 fa fa-info-circle d-inline pointer" title="{{'citationAvgInfo'|localize}}"></i></label>
                    <label class="mt-auto fsize-2">{{getTotalCitationAvg()}}</label>
                </div>
                <div class="col-2 citation-card d-flex flex-column me-2 mb-3  px-4 py-4 text-center">
                    <label>{{'outCitedPublicationCount'|localize}}</label>
                    <label class="mt-auto fsize-2">{{getOutCitedPublicationCount()}}</label>
                </div>

            </div>
            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart mx-auto">
                    <div class="wrapper chart">
                        <label class=" " role="title">{{"NumberOfPublicationsByYear"|localize}}</label>
                    </div>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts *ngIf="chartOptionPublicationCounts && chartOptionPublicationCounts.series && chartOptionPublicationCounts.series[0]&& chartOptionPublicationCounts.series[0].data.length"
                             [options]="chartOptionPublicationCounts">
                        </div>
                        <div class="label-container" *ngIf="chartOptionPublicationCounts && chartOptionPublicationCounts.series && (!chartOptionPublicationCounts.series[0] || chartOptionPublicationCounts.series[0].data.length==0)">
                            <label class="fsize-15 text-muted">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart  mx-auto">
                    <label class="wrapper chart" role="title">{{"NumberOfCitationByYear"|localize}}</label>
                <div  class="custom-border  d-flex justify-content-center align-items-center">
                    <div class="mt-1" style="width: 100%;" echarts *ngIf="chartOptionPublicationCitationCounts && chartOptionPublicationCitationCounts.series && chartOptionPublicationCitationCounts.series[0]&& chartOptionPublicationCitationCounts.series[0].data.length"
                         [options]="chartOptionPublicationCitationCounts"></div>
                    <div class="label-container"  *ngIf="chartOptionPublicationCitationCounts && chartOptionPublicationCitationCounts.series && (!chartOptionPublicationCitationCounts.series[0] || chartOptionPublicationCitationCounts.series[0].data.length==0)">
                        <label class="fsize-15 text-muted" >{{'NoData'|localize}}</label>
                    </div>
                </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>

            </div>


            <table class="table table-striped table-bordered mt-5  fsize-08" style=" display: block; overflow-x: auto;">
                <thead  style="border: 1px;">
                <tr>
                    <th colspan="1%" class="text-center light-blue-th">{{'publicationAnalysisTable'|localize}}</th>
                    <th colspan="90%" class="text-center light-blue-th"></th>
                </tr>
                <tr>
                    <th colspan="1" class="dark-blue-th text-center">{{'title'|localize}}</th>
                    <th colspan="1" class="dark-blue-th text-center" style="writing-mode: sideways-lr;text-orientation: upright;">{{'citedReferences.sourceId'|localize}}</th>
                    <th colspan="1" class="dark-blue-th text-center" style="writing-mode: sideways-lr;text-orientation: upright; height: 5em; width: 1%;text-align: center;vertical-align: middle;"
                        title="{{'citationCountRatioOfCitedYears'|localize}}">{{'citationCountAverageOfYears'|localize}}<i
                            class="fa fa-info-circle fsize-09" aria-hidden="true"></i></th>
                    <th colspan="1" *ngFor="let year of years" class="dark-blue-th" style="writing-mode: sideways-lr;text-orientation: upright;">
                        {{year}}
                    </th>
                </tr>
                </thead>
                <thead style="width: 100% !important;">
                </thead>
                <tbody>
                <tr class="" style="line-height: 25px!important;" *ngFor="let publication of publicationList; let i = index;">
                    <td class="text-left ">
                        <a class="light-blue" target="_blank"
                         [href]="getCurrentLanguage()+'/yayin/detay/'+publication.id+'/'+urlFormat(publication.abstracts[0].title)"
                           [innerHTML]="publication.abstracts[0].title">{{publication.abstracts[0].title}}</a></td>
                    <td class="text-center" >{{publication.getCitationCount()}}</td>
                    <td class="text-center">{{getCitationAvg(publication)}}</td>
                    <td class="text-center" *ngFor="let year of years">{{getCitationCountByYear(publication,year)}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
