<div class="container mx-auto" *ngIf="selectedInstitution">
    <div class="row d-flex justify-content-around mx-auto">
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11  col-11 mx-auto ">
            <div class="w-100 d-flex justify-content-between flex-wrap">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                    <h3 class="title light-blue  mt-auto mb-0">{{selectedInstitution.name}}</h3>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 mx-auto">
                    <div class=" me-0  float-right">
                        <div class="float-right" *ngIf="indexDate">
                            <label class="me-2">{{'IndexDate' |localize}}:<i></i></label>
                            <input  type="date" class="institution-date me-2"
                                   [(ngModel)]="this.fromDate" (keydown.enter)="onSortFromDateChanged($event.target.value)">
                            <input type="date" class="institution-date"
                                   (keydown.enter)="onSortTODateChanged($event.target.value)"
                                   [(ngModel)]="this.toDate">
                            <a style="background: #13859a; color: white" class="  p-1 ms-1 pointer" title="{{'ClickDate'|localize}}"
                                  (click)="onSortTODateChanged()"><i class="fa fa-search size-11"></i></a>
                        </div>
                    </div>
                    <div *ngIf="!indexDate" class="d-flex justify-content-end ">
                        <a class="institution pointer me-2 mt-1"
                           [href]="getCurrentLanguage()+'/kurum/detay/'+this.selectedInstitutionCode+'/'+this.selectedInstitution.name+'?indeks_tarihi='+fromDate + ':' + toDate" target="_blank">{{'byIndexDateLnk'|localize}}</a>
                        <select class="form-select w-25 mb-1  float-right"
                                style="border: none; background-color: transparent;"
                                (change)="onSortTypeChanged($event.target.value)"  [(ngModel)]="this.selectYear">
                            <option class="fsize-09" value="All">{{'Tümü'|localize}}</option>
                            <option class="fsize-09" *ngFor="let _sort of YearList"
                                    value="{{_sort}}">{{_sort}}</option>
                        </select>
                    </div>
                </div>
            </div>


            <hr class="mt-1 mb-3">
            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  mx-auto">
                    <div class="row d-flex flex-wrap justify-content-around mx-auto">
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center ">
                            <label>{{'paperCount'|localize}}</label>
                            <label class=" fsize-2  mt-auto">{{!paperCount ? 0 : ((paperCount|number: '1.') + '').replace(',', '.')}}</label>
                        </div>

                        <div class=" col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'projectCount'|localize}}</label>
                            <label class="mt-auto fsize-2">{{!projectCount ? 0 : ((projectCount|number: '1.') + '').replace(',', '.')}}</label>
                        </div>

                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'totalCitationCount'|localize}}</label>
                            <label class="mt-auto fsize-2">{{ !(citationFromPaperCount + citationFromProjectCount) ? 0 : (((citationFromPaperCount + citationFromProjectCount)|number: '1.') + '').replace(',', '.')}}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'citationFromPaper'|localize}}</label>
                            <label class="mt-auto fsize-2">{{!citationFromPaperCount ? 0 : ((citationFromPaperCount|number: '1.') + '').replace(',', '.')}}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'citationFromProject'|localize}}</label>
                            <label class="mt-auto fsize-2">{{!citationFromProjectCount ? 0 : ((citationFromProjectCount|number: '1.') + '').replace(',', '.')}}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'citationAvg'|localize}}</label>
                            <label class="mt-auto fsize-2">{{citationAvg}}</label>

                        </div>

                    </div>
                </div>

                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  chart mx-auto">
                        <label class=" wrapper chart " role="title">{{"PublicationAndCitationNumbers"|localize}}</label>
                    <div class=" custom-border d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%; height:300px;" echarts *ngIf="chartOptionPublicationAndCitationCountsOfYears && chartOptionPublicationAndCitationCountsOfYears.series && chartOptionPublicationAndCitationCountsOfYears.series[0] && chartOptionPublicationAndCitationCountsOfYears.series[0].data.length>0"
                             [options]="chartOptionPublicationAndCitationCountsOfYears"></div>
                        <div class="label-container" >
                            <label class=" fsize-15  text-muted " *ngIf="chartOptionPublicationAndCitationCountsOfYears && chartOptionPublicationAndCitationCountsOfYears.series && (!chartOptionPublicationAndCitationCountsOfYears.series[0] || chartOptionPublicationAndCitationCountsOfYears.series[0].data.length==0)" >{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
            </div>
            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart   mx-auto">
                    <div class=" wrapper chart">
                        <label class=" " role="title">{{"paperType"|localize}}</label>
                    </div>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts *ngIf="chartOptionPublicationTypeCount && chartOptionPublicationTypeCount.series && chartOptionPublicationTypeCount.series[0] && chartOptionPublicationTypeCount.series[0].data.length>0"
                             [options]="chartOptionPublicationTypeCount"></div>
                        <div class="label-container">
                            <label class=" fsize-15  text-muted" *ngIf="chartOptionPublicationTypeCount && chartOptionPublicationTypeCount.series && (!chartOptionPublicationTypeCount.series[0] || chartOptionPublicationTypeCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart mx-auto">
                        <label class="wrapper chart " role="title">{{"PublicationSubject"|localize}}</label>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionSubjectCount && chartOptionSubjectCount.series && chartOptionSubjectCount.series[0] && chartOptionSubjectCount.series[0].data.length>0"
                             [options]="chartOptionSubjectCount"></div>
                        <div class="label-container">
                            <label class="fsize-15 text-muted" *ngIf="chartOptionSubjectCount && chartOptionSubjectCount.series && (!chartOptionSubjectCount.series[0] || chartOptionSubjectCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
            </div>
            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart   mx-auto">
                    <div class=" wrapper chart">
                        <label class=" " role="title">{{"AccessType"|localize}}</label>
                    </div>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts *ngIf="chartOptionPaperAccessType && chartOptionPaperAccessType.series && chartOptionPaperAccessType.series[0] && chartOptionPaperAccessType.series[0].data.length>0"
                             [options]="chartOptionPaperAccessType"></div>
                        <div class="label-container">
                            <label class=" fsize-15  text-muted" *ngIf="(chartOptionPaperAccessType && chartOptionPaperAccessType.series && (!chartOptionPaperAccessType.series[0] || chartOptionPaperAccessType.series[0].data.length==0)|| !chartOptionPaperAccessType)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart mx-auto">
                    <label class="wrapper chart " role="title">{{"GroupsOfProject"|localize}}</label>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionPublicationProjeGroup && chartOptionPublicationProjeGroup.series && chartOptionPublicationProjeGroup.series[0] && chartOptionPublicationProjeGroup.series[0].data.length>0"
                             [options]="chartOptionPublicationProjeGroup"></div>
                        <div class="label-container">
                            <label class="fsize-15 text-muted" *ngIf="(chartOptionPublicationProjeGroup && chartOptionPublicationProjeGroup.series && (!chartOptionPublicationProjeGroup.series[0] || chartOptionPublicationProjeGroup.series[0].data.length==0) ||!chartOptionPublicationProjeGroup)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
            </div>
            <div class="row mt-3">
                <div style="max-height: 30vh;overflow-y: auto;">
                    <div class=" wrapper chart">
                        <label class=" " role="title">{{"InstitutionCollaboration"|localize}}</label>
                    </div>
                    <table class="table table-striped table-hover">
                        <thead class="mb-2">
                        <tr class="text-center">
                            <th>{{'institution' | localize}}</th>
                            <th>{{'publicationCount' | localize}}</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="institutionCollaborationList.length>0">
                        <tr style="line-height: 14px;" *ngFor="let institution of institutionCollaborationList">
                            <td><small>{{institution.institution}}</small></td>
                            <td class="text-center">
                                <small>{{((institution.count|number: '1.') + '').replace(',', '.')}}</small></td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="institutionCollaborationList.length==0" class="metric-table">
                        <tr>
                            <td colspan="2">
                                {{'NoDataInDate'|localize}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <small class="mx-auto">
                <strong>
                    <ul class="mt-3 nav nav-tabs nav-justified" role="tablist">
                        <li class="nav-item" role="presentation"  *ngIf="selectedInstitution">
                            <a class="tab-btn nav-link" id="justified-tab-4" data-bs-toggle="tab"
                               href="#papersOfTheInstitution-panel" role="tab" aria-controls="papersOfTheInstitution-panel"
                               aria-selected="true">{{"PapersOfTheInstitution"|localize}} </a>
                        </li>
                    </ul>
                </strong>
            </small>
            <div class="tab-content mt-3 mx-auto" id="tab-content">
                <div class="tab-pane active" id="papersOfTheInstitution-panel" role="tabpanel"
                     aria-labelledby="justified-tab-0">
                        <app-publication-list [publicationList]="papersOfTheInstitutionList"></app-publication-list>
                            <div class="w-100 row  mt-2">
                                <div class="col-12 col-md-9">
                                    <trd-paginater [pageList]="[20,50,100]" [pageModel]="publicationInstitutionPageModel"
                                                   (pageChangeEvent)="fillinstitutionCodePublicationsById($event)">
                                    </trd-paginater>
                                </div>

                            <div *ngIf="papersOfTheInstitutionList && papersOfTheInstitutionList.length>0"
                                 class="col-12 col-md-3 float-right text-right">
                                <export-component [fieldSelect]="false" [documentType]="'publication'"
                                                  [documentList]="papersOfTheInstitutionList"
                                ></export-component>
                            </div>

                        </div>
                </div>
            </div>

        </div>
    </div>
</div>
