import {BaseDTO} from "../../core/BaseDTO";
import {PublicationInstitutionDTO} from "./PublicationInstitutionDTO";

export class PublicationAuthorDTO extends BaseDTO {
    public relationId: string;
    public name: string;
    public authorId: string;
    public institutionName: string[];
    public institution: PublicationInstitutionDTO;
    public order: number;
    public isVerified: boolean;
    public duty: string;
    public userId: string;
    public orcid: string;


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.relationId = obj.relationId;
        this.name = obj.name;
        this.authorId = obj.authorId;
        this.institutionName = obj.institutionName;

        this.order = obj.order;
        this.isVerified = obj.isVerified;
        this.duty = obj.duty;
        this.userId = obj.userId;
        this.orcid = obj.orcid;


        if (obj.institution) {
            this.institution = new PublicationInstitutionDTO();
            this.institution = obj.institution;
        }
    }

}
