import { Injectable } from '@angular/core';
import { AuthorizationService } from 'src/app/Authorization/AuthorizationService';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private authService: AuthorizationService) { }

  hasPermission(permissons:string[] | string): boolean{
    permissons = typeof permissons === 'string' ? [permissons] : permissons;
    return this.authService.hasPermission(permissons);
  }

  hasPermissionAll(permissons:string[] | string): boolean{
    permissons = typeof permissons === 'string' ? [permissons] : permissons;
    return this.authService.hasPermissionAll(permissons);
  }
}
