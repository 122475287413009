import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";

export class PublicationIssueDTO extends SearchAbstractDTO {
    public id: string;
    public volume: string;
    public number: string;
    public isSpecial: boolean;
    public publishDate: string;
    public year: string;
    public month: string[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.volume = obj.volume;
        this.number = obj.number;
        this.isSpecial = obj.isSpecial;
        this.publishDate = obj.publishDate;
        this.year = obj.year;
        this.month = obj.month;

    }


}
