

export class SavedSearchDTO {
    id: string;
    created_date:Date;
    created_by:string;
    search_title:string;
    search_text:string;
    url:string;
    total_count:number;
    do_notification:boolean;
    type:string;

    bindData(obj: any): void {
        if (obj == null){
            return
        }

        this.id = obj.id;
        this.created_date = obj.created_date;
        this.created_by = obj.created_by;
        this.search_title = obj.search_title;
        this.search_text = obj.search_text;
        this.url = obj.url;
        this.total_count = obj.total_count;
        this.do_notification = obj.do_notification;
        this.type = obj.type;
    }

    setGmt3CreatedConvert(date: string): void {
        this.created_date = new Date(date);
    }

}