import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {CookieService} from "./CookieService";

@Injectable({ providedIn: 'root' })
export class ConfigurationService{


  constructor(protected http: HttpClient,private cookieService:CookieService) {
  }

  isRequestOfPublicScreen():boolean{
    return window.location.href.startsWith(environment.domain+"public/") && !this.cookieService.getCookie("TRDIZIN_TOKEN");
  }

  initConfig(){

    return new Promise( (resolve, reject) => {
      resolve(true);
        // this.http.get(environment.configurationUrl).subscribe((res: any) => {
        //   if(res){
        //     resolve(true);
        //
        //   }else{
        //     resolve(false);
        //   }
        // }, err=> {
        //   resolve(false);
        // });
     });
    }







}
