import {BaseDTO} from "../../core/BaseDTO";

export class FacetBucketDTO extends BaseDTO {
    public key: string;
    public doc_count: number;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.key = obj.key;
        this.doc_count = obj.doc_count;

    }


}
