import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    private env;

    setEnvVariables(env): void {
        Object.keys(environment).forEach(key => {
                if (env[key]) {
                    environment[key] = env[key];
                }
            }
        );

    }

}

@Injectable({
    providedIn: 'root'
})
export class EnvServiceLoader {

    UNEDITABLE_PROPERTIES = ['production'];

    constructor(@Inject('environment_prod') private environment, private httpClient: HttpClient) {
    }

    async loadEnvVariables(): Promise<any> {
        return await this.getJsonConfig();

    };

    private getJsonConfig(): Promise<any> {
        return this.httpClient.get('./assets/env.json').toPromise();
    }


}
