import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PublicationDTO} from "../publication/PublicationDTO";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import slugify from "slugify";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {SearchService} from "../search.service";
import {LoadingService} from "../../../trdcore/service/LoadingService";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {getCurrentLanguage} from "../../app.module";
import {AuthorizationService} from "../../Authorization/AuthorizationService";
import {ToastrService} from "ngx-toastr";
import {LibraryService} from "../Mylibrary/library.service";
import {MathJaxService} from "../../mathjax.service";
import {AppComponent} from "../../app.component";

@Component({
    selector: 'app-publication-list',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        TrdCoreModule,
        NgClass
    ],
    templateUrl: './publication-list.component.html',
    styleUrl: './publication-list.component.scss'
})
export class PublicationListComponent implements AfterViewInit, OnDestroy {
    @Input() publicationList: Array<PublicationDTO>;
    @Input() libraryView: Boolean;
    @Input() trashButton = false;
    @Input() libraryId: number;
    @Output() reloadRequest = new EventEmitter();
    @Output() onDeletePublication = new EventEmitter();
    private observer: MutationObserver;

    constructor(private searchService: SearchService, private loadingService: LoadingService, public localizationService: LocalizationService,
                private authorizationService: AuthorizationService, private toastrService: ToastrService, private libraryService: LibraryService,
                private mathJaxService: MathJaxService, private appComponent: AppComponent,) {
    }

    getPdf(selectedPublication: PublicationDTO) {
        if (selectedPublication.pdf) {
            this.searchService.getPdfFile(selectedPublication.pdf).subscribe(res => {
                if (res) {
                    window.open(res.toString(), '_blank', 'noreferrer');
                }
                this.loadingService.hideLoader();
            })
        }
    }

    urlFormat(url) {
        return slugify(url, {
            lower: true,
            remove: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
            strict: true,
            trim: true
        });
    }

    DeleteLibraryDocument(publication) {
        let activeUser = this.authorizationService.getActiveUser();
        if (publication && publication.id && activeUser.userId) {
            this.libraryService.deleteLibraryPublicationDocumentByIdAndUserId(publication.id, activeUser.userId, this.libraryId).subscribe(res => {
                this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessDelete'),
                    this.localizationService.getLocalizationValue('Success'));
                const removeIndex = this.publicationList.findIndex(item => item.id === publication.id);
                if (removeIndex !== -1) {
                    this.publicationList.splice(removeIndex, 1);
                }
                this.reloadRequest.emit(this.publicationList);
            });
        }
    }

    deletePublicationOnList(publication: PublicationDTO) {
        this.publicationList = this.publicationList.filter(p => {
            return p.id != publication.id;
        });
        this.onDeletePublication.emit(this.publicationList);
    }

    protected readonly getCurrentLanguage = getCurrentLanguage;

    ngAfterViewInit(): void {
        this.mathJaxService.getMathJaxLoadedPromise().then(() => {
            this.mathJaxService.render();
        });
    }

    render(publication) {
        if (publication) {
            publication.showAbstractCollapse = !publication.showAbstractCollapse
        }
        const config = {attributes: true, childList: true, subtree: true};
        const targetNodes = document.querySelectorAll('.latex');
        this.observer = new MutationObserver((mutationsList, observer) => {
            this.observer.disconnect();
            this.mathJaxService.render();
        });
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            if (targetNode) {
                this.observer.observe(targetNode, config);
            }
        }
    }
    addAnalysisList(publication: PublicationDTO) {
        this.appComponent.addAnalysisList(publication);
    }
    isExistAnalysisList(publication: PublicationDTO):boolean {
        return this.appComponent.isExistAnalysisList(publication);
    }
    removeAnalysisList(publication: PublicationDTO) {
        this.appComponent.removeAnalysisList(publication);
        if(this.trashButton){
            const removeIndex = this.publicationList.findIndex(item => item.id === publication.id);
            if (removeIndex !== -1) {
                this.publicationList.splice(removeIndex, 1);
            }
            this.reloadRequest.emit(this.publicationList);
        }
    }
    ngOnDestroy(): void {
        if(this.observer){
            this.observer.disconnect();
        }
    }
}
