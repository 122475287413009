import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const lang = next.params['lang'];
        this.router.navigate([`${lang}/yayin/ara`], {
            queryParams: {
                q: '',
                order: 'publicationYear-DESC',
                page: 1,
                limit: 20,
                'facet-documentType': 'PROJECT'
            }
        });
        return false;
    }
}
