import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'utcToLocalTime'
})
export class UtcToLocalTimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    var format = args[0];
    let date: Date;
    let utcDate: Date;
    if (!value || value=='null' || value==null){
      return "";
    }
    if(value && value instanceof Date){
      date = (value as Date);
      utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
      date = utcDate;
    } else if(value) {
      date = moment.parseZone(value,'YYYY-MM-DDTHH:mm:SS').toDate();
      date = date;
    }
    if(format){
      return moment(date).format(format.toString());
    }
    return moment(date).format('DD.MM.YYYY HH:mm:SS');
  }

}
