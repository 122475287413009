<div class="container mx-auto" *ngIf="selectedJournal">
    <div class="row d-flex col-12 col-md-11 justify-content-around mx-auto">
        <div class="col-12  d-flex justify-content-between">
            <h3 class="title light-blue">{{selectedJournal.title}}</h3>
<!--            <a id="followCitation" class="not-printed text-decoration-none">-->
<!--                <i class="fas fa-rss fsize-10" aria-hidden="true"-->
<!--                    (click)="followCitationChange(selectedJournal,this.publicationFields)"-->
<!--                    title="{{'FollowCHANGE'|localize}}"><label class="pointer ms-1">{{this.isSaveFound==true?('StopFollow'|localize):this.isSaveFound==false?('Follow'|localize):''}}</label>-->
<!--            </i></a>-->
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">

            <div class="d-flex justify-content-start">
                       <div class="row d-flex flex-wrap justify-content-around mx-auto">
                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center ">
                               <label>{{ 'paperCount'| localize }}</label>
                               <label class=" fsize-2  mt-auto"
                                      *ngIf="publicationFields">{{publicationFields.length}}</label>
                           </div>

                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                               <label>{{ 'citationCount'| localize }}</label>
                               <label class="mt-auto fsize-2 "
                                      *ngIf="publicationFields">{{getJournalTotalCitationCountByFields(publicationFields)}}</label>
                           </div>

                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                               <label>{{ 'numberSelfReferences'| localize }}</label>
                               <label class="mt-auto fsize-2 "
                                      *ngIf="publicationFields">{{getJournalTotalSelfCitationCountByFields(publicationFields)}}</label>
                           </div>
                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                               <label>{{ 'citedPaperCount'| localize }}</label>
                               <label class="mt-auto fsize-2 "
                                      *ngIf="publicationFields">{{getJournalTotalPublicationOfCited(publicationFields)}}</label>
                           </div>
                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                               <label>{{ 'citationAvg'| localize }}</label>
                               <label class="mt-auto fsize-2 "
                                      *ngIf="publicationFields">{{getCitationAvg(publicationFields)}}</label>
                           </div>
                           <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                               <label>{{ 'selfReferenceRate'| localize }}</label>
                               <label class="mt-auto fsize-2 "
                                      *ngIf="publicationFields">%{{getSelfCitationRatio(publicationFields)}}</label>

                           </div>
                       </div>
            </div>

            <div class="cards mt-4 d-flex flex-wrap justify-content-start fsize-09"
                 *ngIf="this.getMaxJournalYear() && this.getMaxJournalYear().subjects && this.getMaxJournalYear().subjects.length>0">
                <label class="f-bold me-2">{{'subjectCategory'|localize}}:<i class="fa fa-info-circle" title="{{'TheLastAcceptedYearsInfo'|localize}}{{'('+getMaxYearObject(selectedJournal)+')'}}:">
                </i></label>
                <button class="card-button zoom-05 me-2 mb-1 mt-1" *ngFor="let root of getSubjectCategory();let isLast=last">
                    <p class="tip">{{root}}</p>
                </button>
                <hr class="text-muted mt-1 w-100">
            </div>


            <div class="cards mt-4 d-flex flex-wrap justify-content-start fsize-09"
                 *ngIf="this.getMaxJournalYear() && this.getMaxJournalYear().subjects && this.getMaxJournalYear().subjects.length>0">
                <label class="f-bold me-2">{{'SubjectFields'|localize}}:<i class="fa fa-info-circle" title="{{'TheLastAcceptedYearsInfo'|localize}}{{'('+getMaxYearObject(selectedJournal)+')'}}"></i></label>
                <button class="card-button zoom-05 me-2 mb-1 mt-1" *ngFor="let root of getSubjectFields();let isLast=last">
                    <p class="tip">{{root}}</p>
                </button>
                <hr class="text-muted mt-1 w-100">
            </div>


<!--            <div class=" bg-card px-3 py-2 mt-2">-->
<!--                <label class="f-bold  w-100 fsize-09">{{'SubjectFields'|localize}}:</label>-->
<!--                <div class="fsize-09 d-flex flex-wrap align-content-between" style="text-align: justify;">-->
<!--                    <a class="no-pointer" *ngFor="let root of getSubjectFields();let isLast=last">-->
<!--                        <div class="vr"></div>-->
<!--                        <label class="ms-2 me-2">{{root}}</label>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->

        </div>

        <div class="sticky-info float-right  col-12 col-sm-12 col-md-12 col-lg-4  col-xl-4  col-xxl-4  mx-auto fsize-09">

            <div class="mb-4 ">
                        <div class="w-100 cover  text-center d-flex justify-content-center " >
                            <img *ngIf="selectedJournal.coverPath else journalAnonimCover"
                                 style="height: 27vh; "
                                 class="cover-img no-pointer"
                                 src="{{'https://download.trdizin.gov.tr/'+selectedJournal.coverPath+'?contentDisposition=inline'}}" alt="">
                            <ng-template #journalAnonimCover>
                                <img src="https://static.trdizin.gov.tr/images/svg/journal.svg"
                                     class="no-pointer"
                                     alt="" style="height: 10vh">
                            </ng-template>
                        </div>
            </div>

            <div class=" bg-card px-3 py-2 mt-1">
                <div *ngIf="selectedJournal.eissn|| selectedJournal.issn" class=" d-flex justify-content-between  ">
                    <div class="me-2" *ngIf="selectedJournal.issn">
                        <strong class="dark-blue">ISSN: </strong><a class="light-blue no-pointer">{{selectedJournal.issn}}</a>
                    </div>
                    <div class="me-2"  *ngIf="selectedJournal.eissn">
                        <strong class="dark-blue">EISSN: </strong><a class="light-blue no-pointer">{{selectedJournal.eissn}}</a>
                    </div>
                </div>
            </div>


            <div class=" bg-card px-3 py-2 mt-1">
                <div class=" d-flex flex-column ">
                    <div>
                        <strong class="dark-blue">{{'firstPublishYear' |localize}}: </strong>
                        <a class="ms-2 no-pointer light-blue">{{selectedJournal.firstPublishYear ? selectedJournal.firstPublishYear : '-'}}</a>
                    </div>

                    <div class="mt-1 d-flex justify-content-start" *ngIf="getJournalYearRange(selectedJournal)">
                        <div class="me-2">
                            <strong class="dark-blue">{{'indexedYears' |localize}}: </strong>
                        </div>
                        <div class="d-flex justify-content-start ">
                            <a class="me-3 no-pointer light-blue"
                               *ngFor="let indexedYears of getJournalYearRange(selectedJournal)">
                                {{ (indexedYears[indexedYears.length - 1] == indexedYears[0]) ? indexedYears[0] : indexedYears[0] + "-" + indexedYears[indexedYears.length - 1]}}
                                {{"(" + Array.from(getYearObjectsByYearValue(selectedJournal.journalYear, indexedYears[0])).join(', ') + ")"}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class=" bg-card px-3 py-2 mt-1 d-flex justify-content-start">
                <label class="f-bold  ">{{'publishPeriods'|localize}}:<i class="fa fa-info-circle" title="{{'TheLastAcceptedYearsInfo'|localize}}{{'('+getMaxYearObject(selectedJournal)+')'}}"></i></label>
                <div class="  ms-2 d-flex flex-wrap align-content-between" style="text-align: justify;">
                    <a class="me-1 no-pointer light-blue" *ngFor="let period of  getPeriodsByMaxYear();let isLast=last">
                        {{('month'+period) | localize}}{{!isLast ? ',' : ''}}
                    </a>
                </div>
            </div>


            <div class=" bg-card px-3 py-1 mt-1">
                <div class=" d-flex justify-content-start  ">
                    <div class="me-2">
                        <strong class="dark-blue">{{'publishFormat' |localize}}: </strong>
                    </div>
                    <a class="no-pointer light-blue">{{selectedJournal.publicationFormats |localize}}</a>
                </div>
            </div>

            <div class=" bg-card px-3 py-2 mt-1 d-flex justify-content-start"
                 *ngIf="selectedJournal.publicationLanguage">
                <label class="f-bold  ">{{'publishLanguage'|localize}}:</label>
                <div class=" ms-2  d-flex flex-wrap align-content-between" style="text-align: justify;">
                    <a class="no-pointer light-blue" *ngFor="let language of  selectedJournal.publicationLanguage;let isLast=last">
                        {{language | localize}}
                        <div *ngIf="!isLast" class="mx-2  vr"></div>
                    </a>
                </div>
            </div>


            <div class=" bg-card px-3 py-1 mt-1 d-flex justify-content-start"
                 *ngIf="selectedJournal.person && getPersonByType(selectedJournal.person,'EDITOR')">
                <label class="f-bold ">{{'editor'|localize}}:</label>
                <div class=" ms-1 d-flex flex-wrap align-content-between" style="text-align: justify;">
                    <a class="no-pointer light-blue"
                       *ngFor="let editor of  getPersonByType(selectedJournal.person,'EDITOR');let isLast=last">
                        {{editor.name}}
                        <div *ngIf="!isLast" class="mx-2  vr"></div>
                    </a>
                </div>
            </div>

            <div class=" bg-card px-3 py-1 mt-1 d-flex justify-content-start"
                 *ngIf="selectedJournal.person && getPersonByType(selectedJournal.person,'PUBLISHER')">
                <label class="f-bold  ">{{'publisher'|localize}}:</label>
                <div class=" ms-2 d-flex flex-wrap align-content-between" style="text-align: justify;">
                    <a class="no-pointer light-blue"
                       *ngFor="let publisher of  getPersonByType(selectedJournal.person,'PUBLISHER');let isLast=last">
                        {{publisher.name}}
                        <div *ngIf="!isLast" class="mx-2  vr"></div>
                    </a>

                </div>
            </div>

            <div class=" bg-card px-3 py-2 mt-1" *ngIf="selectedJournal.webAddress">
                <div class=" d-flex flex-column  ">
                    <strong class="dark-blue">{{'webAddress'|localize}}: </strong>
                    <a target="_blank" rel="nofollow" class="light-blue"
                       href="{{selectedJournal.webAddress}}">{{selectedJournal.webAddress}}</a>
                </div>
            </div>
        </div>

<!--        charts-->
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 mt-1 ">
            <div class="wrapper chart">
                <label class=" " role="title">{{"PaperAndReferenceNumber"|localize}}</label>
            </div>
            <div class="mt-1" style="width: 100%; height:300px;" echarts
                 [options]="chartOptionPublicationAndCitationCountsOfYears"></div>
            <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}" ©{{getCurrentYear()}} - TR
                Dizin </a></div>
            <div class="wrapper chart">
                <label class=" " role="title">{{"PaperTypeGraph"|localize}}</label>
            </div>
            <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  mx-auto mt-3" style="width: 100%; height:400px;"
                 echarts [options]="chartOptionPaperType"></div>
            <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"©{{getCurrentYear()}} - TR
                Dizin </a></div>
            <small class="mx-auto">
                <strong>
                    <ul class="mt-3 nav nav-tabs nav-justified" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="tab-btn nav-link active" id="justified-tab-0" data-bs-toggle="tab"
                               href="#publication-panel" role="tab" aria-controls="publication-panel"
                               aria-selected="true" (click)="fillPublicationIdList()">{{"PaperOfJournal"|localize}} </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="tab-btn nav-link" id="justified-tab-1" data-bs-toggle="tab"
                               href="#journal-citation-panel"
                               (click)="fillJournalCitationIdList(this.publicationFields)"
                               role="tab" aria-controls="citation-panel"
                               aria-selected="false"> {{"ReferencesOfJournal"|localize}} </a>
                        </li>
                    </ul>
                </strong>
            </small>
            <div class="tab-content mt-3 mx-auto" id="tab-content">
                <div class="tab-pane active mx-auto" id="publication-panel" role="tabpanel"
                     aria-labelledby="justified-tab-1">
                    <app-publication-list [publicationList]="publicationList"></app-publication-list>
                    <div class="w-100 row  mt-2">
                        <div class="col-12 col-md-9">
                            <trd-paginater [pageList]="[20,50,100]" [pageModel]="publicationPageModel"
                                           (pageChangeEvent)="changePublicationResultPage($event)">
                            </trd-paginater>
                        </div>
                        <div *ngIf="publicationList && publicationList.length>0"  class="col-12 col-md-3 float-right text-right">
                            <export-component [fieldSelect]="false" [documentType]="'publication'"
                                              [documentList]="publicationList"
                            ></export-component>
                        </div>
                    </div>
                </div>
                <div class="tab-pane  mx-auto" id="journal-citation-panel" role="tabpanel"
                     aria-labelledby="justified-tab-1">
                    <app-publication-list [publicationList]="journalCitationList"></app-publication-list>
                    <div class="w-100 row  mt-2">
                        <div class="col-12 col-md-9">
                            <trd-paginater [pageList]="[20,50,100]" [pageModel]="journalCitationPageModel"
                                           (pageChangeEvent)="changeJournalCitationResultPage($event)">
                            </trd-paginater>
                        </div>
                        <div *ngIf="journalCitationList && journalCitationList.length>0" class="col-12 col-md-3 float-right text-right">
                            <export-component [fieldSelect]="false" [documentType]="'publication'"
                                              [documentList]="journalCitationList"
                            ></export-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="float-right ps-0 col-12 col-sm-12 col-md-12 col-lg-4  col-xl-4  col-xxl-3  mx-auto">

        </div>

    </div>
</div>
