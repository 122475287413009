import {BaseDTO} from "../../core/BaseDTO";
import { environment } from 'src/environments/environment';
import {getCurrentLanguage} from "../../app.module";
export class AuthorDTO extends BaseDTO {
    public id: string;
    public indexedBy: string;
    public firstIndexDate: string;
    public indexedDate: string;
    public fullName: string;
    public otherNames: string[];
    public orcid: string;
    public userId: string;
    public status: string;
    public hindex: string;


    //transient
    public citationCount = 0;
    public publicationCount = 0;
    public citationAvg: string ;
    public trdizinAddress: string;

    public highlight:any;
    public orcidHighLight: string;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.indexedDate = obj.indexedDate;
        this.indexedBy = obj.indexedBy;
        this.firstIndexDate = obj.firstIndexDate;
        this.fullName = obj.fullName;
        this.otherNames = obj.otherNames;
        this.orcid = obj.orcid;
        this.orcidHighLight = this.orcid;
        this.userId = obj.userId;
        this.status = obj.status;
        this.hindex = obj.hindex;


        if (obj.highlight) {
            this.highlight = obj.highlight;
        }
        if (this.highlight) {
            let _highlight = this.highlight;
            if (_highlight["searchField"]) {
                let _searchField = _highlight["searchField"];
                _searchField.forEach(sf => {
                    let cleanedHighLight = sf.replaceAll("<strong class=\"highlight\">", "");
                    cleanedHighLight = cleanedHighLight.replaceAll("</strong>", "");
                    if (this.fullName == cleanedHighLight) {
                        this.fullName = sf;
                    }else if (this.orcid == cleanedHighLight) {
                        this.orcidHighLight = sf;
                    }
                });
            }
        }

    }

    fillExportData(): void {
        if (!this.citationAvg) {
            if (!this.publicationCount || this.publicationCount == 0 || this.citationCount == 0) {
                this.citationAvg = "0,0";
            } else {
                this.citationAvg = parseFloat(parseFloat(("" + (this.citationCount / this.publicationCount))).toFixed(2)).toLocaleString('tr-TR');
            }
        }
        if (!this.trdizinAddress) {
               this.trdizinAddress=  environment.domain+getCurrentLanguage()+"/yazar/detay/"+this.id;
        }
    }
}
