<div class="modal fade mx-auto " id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header header-background">
                <h5 class="modal-title"
                    id="staticBackdropLabel">{{localizationService.getLocalizationValue('exportFile')}}</h5>
                <i class="fas fa-times pointer fsize-13" style="color: white" data-bs-dismiss="modal"></i>
            </div>
            <div class="modal-body">
                <div class="d-flex">
                    <div class="input-group mb-3 me-2">
                        <span class="input-group-text"
                              id="basic-addon1">{{localizationService.getLocalizationValue('startNumber')}}:</span>
                        <input type="number" min="1" class="form-control" [(ngModel)]="startNumber">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text"
                              id="basic-addon2">{{localizationService.getLocalizationValue('endNumber')}}:</span>
                        <input type="number" min="1" class="form-control" [(ngModel)]="endNumber">
                    </div>
                </div>

                <div class="d-flex justify-content-start flex-wrap">
                    <div class="export-fields mb-1" *ngFor="let _field of headers;index as i;">
                        <label class="fsize-1 me-2 float-left pointer"
                               for="{{'fieldSelect'+i}}">
                            <input class="mt-1 form-check-input" type="checkbox"
                                   (click)="exportFieldSelect(_field)"
                                   [attr.data-value]="_field"
                                   [checked]="isFieldSelected(_field)"
                                   id="{{'fieldSelect'+i}}">
                            {{localizationService.getLocalizationValue(_field)}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="ms-3">
                <a class="me-3" style="float:left !important" (click)="selectAllFields()">{{selectedHeaderList.length==headers.length?(localizationService.getLocalizationValue('cleanAll')):(localizationService.getLocalizationValue('selectAll'))}}</a>
                <label style="float:right !important" class="blink info fsize-08 me-3">Tek seferde en fazla 1000 kayıt çıktısı alabilirsiniz...</label>

            </div>
            <div class="modal-footer">
                <img class="img-btn me-1 pointer wsize-1" (click)="export('XLS')" src="/assets/images/xls.png">
                <img class="img-btn me-1 pointer wsize-1" (click)="export('CSV')" src="/assets/images/csv.png">
<!--                <img class="img-btn pointer wsize-1" (click)="export('ODS')" src="/assets/images/ods.png">-->
            </div>
        </div>
    </div>
</div>


<div *ngIf="fieldSelect==false">
    <img class="img-btn me-1 pointer wsize-1" (click)="exportDocumentList('XLS')" src="/assets/images/xls.png">
    <img class="img-btn me-1 pointer wsize-1" (click)="exportDocumentList('CSV')" src="/assets/images/csv.png">
<!--    <img class="img-btn pointer wsize-1" (click)="exportDocumentList('ODS')" src="/assets/images/ods.png">-->
</div>

<div *ngIf="fieldSelect==true">
    <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" (click)="initFields()">
        <img class="img-btn me-1 pointer" src="/assets/images/export.png">
    </a>
</div>
