import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TrdCoreModule} from '../trdcore/trdcore.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestInterceptor} from "../trdcore/interceptor/interceptor";
import {FormsModule} from "@angular/forms";
import {ToastrModule} from 'ngx-toastr';
import {LocalizationService} from "../trdcore/service/localizationService";

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


import {CookieService} from "../trdcore/service/CookieService";
import {ConfigurationService} from "../trdcore/service/ConfigurationService";

import {SafeHtmlPipe} from '../trdcore/pipes/safe-html.pipe';
import {SearchComponent} from "./search/search.component";
import {FacetComponent} from "./search/facet/facet.component";
import {LoadingComponent} from "../trdcore/component/LoadingComponent";
import {NgxEchartsDirective} from "ngx-echarts";
import {AuthInterceptor} from "../trdcore/interceptor/authInterceptor";
import {EnvironmentService, EnvServiceLoader} from "./EnvServiceLoader";
import {environment} from "../environments/environment";
import {DecimalPipe} from "@angular/common";
import {IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {MylibraryDetailComponent} from "./search/Mylibrary/mylibrary-detail.component";
import {TagifyModule} from "ngx-tagify";
import {SavedSearchListComponent} from "./search/saved-search-list/saved-search-list.component";
import {PublicationAnalysisComponent} from "./publication-analysis/publication-analysis.component";
import {MatTreeSelectInputModule} from "mat-tree-select-input";
import {RedirectGuard} from "./RedirectGuard.module";


export function init_app_configuration(configurationService: ConfigurationService) {
    return () => configurationService.initConfig();
}

export function init_app_localization(localizationService: LocalizationService) {
    return () => localizationService.initConfig();
}

function initEnvironmentServiceFactory(
    envServiceLoader: EnvServiceLoader,
    environmentService: EnvironmentService
) {
    return async () => {
        const envs = await envServiceLoader.loadEnvVariables();
        environmentService.setEnvVariables(envs);
    };
}

export function getCurrentYear() {
    return (new Date()).getFullYear();
}

export function getCurrentDomain() {
    return environment.domain;
}

export function getCurrentLanguage(): string {
    let _language = sessionStorage.getItem('activeLanguage')
    return _language ? _language : "tr";
}

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
        validation: false,
    };
};


@NgModule({
    declarations: [
        AppComponent,
        SafeHtmlPipe,
        LoadingComponent,
    ],
    imports: [
        // TagInputModule,
        MatTreeSelectInputModule,
        TagifyModule,
        SearchComponent,
        BrowserModule,
        AppRoutingModule,
        TrdCoreModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({timeOut: 5000, closeButton: true, progressBar: true,}),
        NgxMaskDirective, NgxMaskPipe,
        FacetComponent,
        NgxEchartsDirective,
        MylibraryDetailComponent,
        SavedSearchListComponent,
        PublicationAnalysisComponent,
    ],
    providers: [
        AppComponent,
        [RedirectGuard],
        provideNgxMask(maskConfigFunction),
        DecimalPipe,
        {provide: 'environment_prod', useValue: environment},
        {
            provide: APP_INITIALIZER,
            useFactory: initEnvironmentServiceFactory,
            deps: [EnvServiceLoader, EnvironmentService],
            multi: true
        },
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app_configuration,
            deps: [ConfigurationService],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: init_app_localization,
            deps: [LocalizationService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        RequestInterceptor
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
