import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {AuthorDTO} from "./search/author-detail/AuthorDTO";
import {PublicationAuthorDTO} from "./search/publication/PublicationAuthorDTO";
import {PublicationDTO} from "./search/publication/PublicationDTO";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class PageMetaService {
    constructor(private meta: Meta,private router:Router) {}

    setPublicationMetaTags(selectedPublication:PublicationDTO) {
        let _publication= new PublicationDTO();
        _publication.bindData(selectedPublication);
        this.meta.updateTag({ name: 'citation_publication_date', content: _publication.publicationYear?_publication.publicationYear:""});
        this.meta.updateTag({ name: 'citation_volume', content: _publication.issue.volume?_publication.issue.volume:"" });
        this.meta.updateTag({ name: 'citation_issue', content: _publication.issue.number?_publication.issue.number:"" });
        this.meta.updateTag({ name: 'citation_firstpage', content: _publication.startPage?_publication.startPage:"" });
        this.meta.updateTag({ name: 'citation_lastpage', content: _publication.endPage?_publication.endPage:"" });
        this.meta.updateTag({ name: 'citation_abstract_html_url', content: this.router.url });
        this.meta.updateTag({ property: 'citation_doi', content: _publication.doi?_publication.doi:""});
        if (_publication.authors.length > 0) {
            _publication.authors.forEach((a, index) => {
                const nameParts = a.name.split(/\s+/);
                const firstName = nameParts.shift();
                const updatedName = `${firstName}${nameParts.length > 0 ? ', ' + nameParts.join(' ') : ''}`;
                this.meta.updateTag({name: 'DC.Creator.PersonalName', content: updatedName});
            });
        }
        this.meta.updateTag({ name: 'DC.Title', content: _publication.abstracts[0].title?_publication.abstracts[0].title:""});
        this.meta.updateTag({ name: 'DC.Date', content: _publication.publicationYear?_publication.publicationYear:"" });
        this.meta.updateTag({ name: 'DC.Source.Volume', content: _publication.issue.volume?_publication.issue.volume:"" });
        this.meta.updateTag({ name: 'DC.Source.Issue', content: _publication.issue.number?_publication.issue.number:""  });
        this.meta.updateTag({ name: 'DC.Source.URI', content: this.router.url });
        this.meta.updateTag({ name: 'DC.Type.articleType', content: _publication.docType?_publication.docType:"" });
        this.meta.updateTag({ name: 'DC.Identifier', content: _publication.id?_publication.id:""});
        this.meta.updateTag({ name: 'DC.Identifier.pageNumber', content: _publication.startPage?_publication.startPage:"0"+"-"+_publication.endPage?_publication.endPage:""  });
        this.meta.updateTag({ name: 'DC.Identifier.URI', content: this.router.url });
    }
}
