// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment = {
  production: false,
  activeLanguage: '',
  apiUrl : 'http://localhost:8080',
  managementApiUrl : 'https://yonetim-test.trdizin.gov.tr/api/v1',
  // apiUrl:'https://yonetim-test.trdizin.gov.tr/api/v1',
  redirectNotFound:"https://static.trdizin.gov.tr/error/trd404.html",
  authCookieName : 'TRDIZIN_TOKEN',
  profile:"TEST",
  domain:"http://local-search.trdizin.gov.tr:4200/",
  versionChangeUrl: "https://static.trdizin.gov.tr/page/versions.json",
  searchUrl: 'https://osearch.trdizin.gov.tr/'
}
