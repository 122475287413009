import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PublicationDTO} from "../search/publication/PublicationDTO";
import {LocalizationService} from "../../trdcore/service/localizationService";
import {ToastrService} from "ngx-toastr";
import {NgForOf, NgIf} from "@angular/common";
import {NgxMaskDirective} from "ngx-mask";
import {ReactiveFormsModule} from "@angular/forms";
import {TagifyModule} from "ngx-tagify";
import {TrdCoreModule} from "../../trdcore/trdcore.module";
import {PublicationListComponent} from "../search/publication-list/publication-list.component";
import {ActivatedRoute, Router} from "@angular/router";
import {getCurrentLanguage} from "../app.module";
import {MylibraryDetailComponent} from "../search/Mylibrary/mylibrary-detail.component";
import {AuthInfoDTO} from "../../trdcore/dto/AuthInfoDTO";
import {AuthorizationService} from "../Authorization/AuthorizationService";

@Component({
    selector: 'app-publication-analysis',
    standalone: true,
    imports: [
        NgIf,
        NgForOf,
        NgxMaskDirective,
        ReactiveFormsModule,
        TagifyModule,
        TrdCoreModule,
        PublicationListComponent,
        MylibraryDetailComponent
    ],
    templateUrl: './publication-analysis.component.html',
    styleUrl: './publication-analysis.component.scss'
})
export class PublicationAnalysisComponent implements OnInit {

    publicationList: PublicationDTO[] = [];
    @ViewChild("closeModalAnalysis") closeModalAnalysis: ElementRef;
    @ViewChild("LibraryAnalysis") libraryComponent: MylibraryDetailComponent;
    activeUser :AuthInfoDTO


    constructor(public localizationService: LocalizationService, private toastrService: ToastrService, private router: Router, private activatedRoute: ActivatedRoute,private authorizationService: AuthorizationService) {
    }

    ngOnInit(): void {
        this.activeUser=this.authorizationService.getActiveUser();
    }

    getList(): any[] {
        let list = localStorage.getItem("PublicationAnalysisList");
        if (list) {
            return JSON.parse(list);
        }
        return [];
    }

    getListPublicationFormat() {
        let publicationList: PublicationDTO[] = [];
        let listJson = this.getList();
        listJson.forEach(p => {
            let _publication = new PublicationDTO();
            _publication.bindData(p);
            publicationList.push(_publication);
        });
        this.publicationList = publicationList;
    }

    writeList(list: any[]) {
        localStorage.setItem("PublicationAnalysisList", JSON.stringify(list));
    }

    public isExistAnalysisList(publication: PublicationDTO) {
        let list = this.getList();
        if (list) {
            let exist = list.find(l => {
                if (l["id"] == publication.id) {
                    return true;
                }
            });
            if (!exist) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    public addAnalysisList(publication: PublicationDTO) {
        let _publication = new PublicationDTO();
        _publication.bindData(publication);
        _publication.citedReferences = null;
        _publication.references = null;
        _publication.subjects = null;
        _publication.viewCount = null;
        _publication.publicationType = null;
        _publication.otherCitations = null;
        _publication.indexDate = null;
        _publication.firstIndexDate = null;
        _publication.indexedBy = null;
        _publication.downloadCount = null;
        _publication.databases = null;
        _publication.highlight = null;
        _publication.cleanHighLight();

        let list = this.getList();
        if (list) {
            if (list.length >= 50) {
                this.toastrService.warning(this.localizationService.getLocalizationValue('listIsFull'));
                return list;
            } else {
                if (!this.isExistAnalysisList(publication)) {
                    list.push(_publication);
                    this.writeList(list);
                    return list;
                }
            }
        }
        return [];
    }

    public addAllAnalysisList(publicationList: PublicationDTO[]) {
        for (let i = 0; i < publicationList.length; i++) {
            let p = publicationList[i];
            let list = this.addAnalysisList(p);
            if (list.length >= 50) {
                i = publicationList.length + 1;
            }
        }
    }

    public removeAnalysisList(publication: PublicationDTO) {
        let list = this.getList();
        if (list) {
            list = list.filter(l => {
                return l["id"] != publication.id;
            });
            localStorage.setItem("PublicationAnalysisList", JSON.stringify(list));
        }
    }

    cleanAll() {
        localStorage.setItem("PublicationAnalysisList", JSON.stringify(new Array<PublicationDTO>));
        this.getListPublicationFormat();
    }

    updateList(list: PublicationDTO[]) {
        localStorage.setItem("PublicationAnalysisList", JSON.stringify(list));
        this.getListPublicationFormat();
    }

    doAnalysis() {
        window.open(getCurrentLanguage() + "/analiz?q=id:(" + this.publicationList.map(p => {
            return p.id
        }).join(" ") + ")", '_blank', 'noreferrer');
    }
    closeModal(){
        this.closeModalAnalysis.nativeElement.click();
    }
    doLibrary(publicationList:PublicationDTO[]){
        if(this.libraryComponent){
            this.libraryComponent.doLibrary(publicationList);
        }

    }

    protected readonly close = close;
    protected readonly event = event;
}
