import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";
import {PublicationDTO} from "./PublicationDTO";

export class PublicationSearchResult extends SearchAbstractDTO {
    public publications: PublicationDTO[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        if (obj.publications) {
            this.publications = [];
            for (let i = 0; i < obj.publications.length; i++) {
                let _publication = new PublicationDTO();
                _publication.bindData(obj.publications[i]);
                this.publications.push(_publication);
            }
        }
    }


}
