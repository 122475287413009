
import {Component, ElementRef, Input, OnInit, ViewChild, Renderer2} from "@angular/core";
import {MylibraryDTO} from "./MylibraryDTO";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {SearchService} from "../search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {FormsModule} from "@angular/forms";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";
import {PublicationListComponent} from "../publication-list/publication-list.component";
import {MylibraryRelationDTO} from "./MylibraryRelationDTO";
import {PublicationDTO} from "../publication/PublicationDTO";
import {LibraryService} from "./library.service";
import {ModalAlertService} from "../../../trdcore/service/ModalAlertService";
import {AuthInfoDTO} from "../../../trdcore/dto/AuthInfoDTO";
import {AuthorizationService} from "../../Authorization/AuthorizationService";
import * as moment from "moment/moment";

@Component({
    selector: 'app-my-library',
    standalone: true,
    imports: [
        NgIf,
        TrdCoreModule,
        NgForOf,
        FormsModule,
        PublicationListComponent,
        NgClass
    ],
    templateUrl: './mylibrary-detail.component.html',
    styleUrl: './mylibrary-detail.component.scss'
})
export class MylibraryDetailComponent implements OnInit{
    @ViewChild("CreatedModal")  libraryCreatedModal:ElementRef
    @ViewChild("libraryModal")  libraryModal:ElementRef
    @Input() addFavorite;
    @Input() documentId;
    @Input() documentType;
    @Input() publicationListAnalysis:PublicationDTO[];
    @Input() id = "library-component";
    selectedLibrary:MylibraryDTO=new MylibraryDTO();
    libraryList:Array<MylibraryDTO>=[];
    libraryRelationList:Array<MylibraryRelationDTO>=[];
    selectedLibraryRelationList:Array<MylibraryRelationDTO>=[];
    selectedLibraryRelation:MylibraryRelationDTO;
    editModeLibrary=false;
    libraryPublicationList: Array<PublicationDTO>;
    libraryPublicationPageModel = new PageModel(10);
    libraryPageModel=new PageModel(10);
    libraryPublicationIdList: Array<string>;
    activeUser :AuthInfoDTO
    publicationLibraryId;
    constructor(
        private localizationService: LocalizationService,
        private renderer: Renderer2,
        private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router,private toastrService: ToastrService, private libraryService:LibraryService,
        private modalService: ModalAlertService,private authorizationService: AuthorizationService) {
    }
    ngOnInit() {
        this.selectedLibrary.created_date=new Date();
        this.activeUser=this.authorizationService.getActiveUser();
        if((!this.selectedLibraryRelation ||  !this.selectedLibraryRelation.library_id) && this.documentId && this.activeUser){
            this.libraryService.getLibraryPublicationDocumentByIdAndUserId(this.documentId,this.activeUser.userId).subscribe(res=>{
                if(res["data"]){
                    for(let i=0;i<res["data"].length;i++){
                        let _selectedLibraryRelation=new MylibraryRelationDTO();
                        _selectedLibraryRelation.bindData(res["data"][i]);
                        this.selectedLibraryRelationList.push(_selectedLibraryRelation);
                    }
                    this.selectedLibraryRelation=new MylibraryRelationDTO();
                    this.selectedLibraryRelation.bindData(res["data"][0]);
                }
            })
        }

    }

    setLibrary(library?){
        if(library){
            this.editModeLibrary=true;
            this.selectedLibrary.bindData(library);
            this.selectedLibrary.updated_date=new Date();
            let DateUpdatedDateFormat=this.transform(this.selectedLibrary.updated_date).toString();
            this.selectedLibrary.setGmt3UpdatedConvert(DateUpdatedDateFormat);
        }else{
            this.selectedLibrary=new MylibraryDTO();
            this.selectedLibrary.user_id=this.activeUser.userId;
            this.selectedLibrary.updated_by=this.activeUser.userId;
            this.selectedLibrary.created_date=new Date();
            let DateCreatedDateFormat=this.transform(this.selectedLibrary.created_date).toString();
            this.selectedLibrary.setGmt3CreatedConvert(DateCreatedDateFormat);
            this.selectedLibrary.updated_date=new Date();
            let DateUpdatedDateFormat=this.transform(this.selectedLibrary.updated_date).toString();
            this.selectedLibrary.setGmt3UpdatedConvert(DateUpdatedDateFormat);
        }
    }
    save(){
        let existLibrary=false;
        if(this.editModeLibrary){
            if(this.selectedLibrary && this.selectedLibrary.title && this.selectedLibrary.title.trim()!="") {
                this.libraryService.updateLibrary(this.selectedLibrary.id, this.selectedLibrary.title).subscribe(res => {
                    this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessSave'),
                        this.localizationService.getLocalizationValue('Success'));
                    this.libraryCreatedModal.nativeElement.click();
                    this.editModeLibrary = false;
                    this.getLibraryPage();
                })
            }
            else{
                this.toastrService.error(
                    this.localizationService.getLocalizationValue('LibraryNameCannotBeEmpty'),
                    this.localizationService.getLocalizationValue('Error'));
                return;
            }
        }
        else{
            if(this.selectedLibrary && this.selectedLibrary.title && this.selectedLibrary.title.trim()!=""){
                if(this.libraryList.length>0){
                    this.libraryList.forEach(l=>{
                        if(l.title==this.selectedLibrary.title.trim()){
                            existLibrary=true;
                            return this.toastrService.error(
                                this.localizationService.getLocalizationValue('ExistLibrary'),
                                this.localizationService.getLocalizationValue('Error'));
                        }
                    })
                }
                if(!existLibrary){
                    if(!this.selectedLibrary.user_id){
                        this.selectedLibrary.user_id=this.activeUser.userId;
                    }
                    this.libraryService.AddLibrary(this.selectedLibrary).subscribe(res=>{
                        this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessSave'),
                            this.localizationService.getLocalizationValue('Success'));
                        this.selectedLibrary=new MylibraryDTO();
                        this.libraryCreatedModal.nativeElement.click();
                        this.getLibraryPage();
                    })
                }
            }
            else{
                this.toastrService.error(
                    this.localizationService.getLocalizationValue('LibraryNameCannotBeEmpty'),
                    this.localizationService.getLocalizationValue('Error'));
                return;
            }
        }
    }
    DeleteLibraryDocument(libraryDocument:MylibraryRelationDTO,libraryId) {
        if (this.selectedLibraryRelation && this.documentId && this.activeUser.userId) {
            this.libraryService.deleteLibraryPublicationDocumentByIdAndUserId(this.documentId, this.activeUser.userId,libraryId).subscribe(res => {
                this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessDelete'),
                this.localizationService.getLocalizationValue('Success'));
            })
        }
    }
    clearModal(){
        this.selectedLibrary=new MylibraryDTO();
        this.selectedLibraryRelation=new MylibraryRelationDTO();
        this.selectedLibraryRelationList=[];
        this.selectedLibrary.created_date=new Date();
        this.ngOnInit();
    }
    clearRelationModal(){
        this.libraryRelationList=[];
        this.libraryPublicationList = new Array<PublicationDTO>();
        this.libraryPublicationIdList=new Array<string>();
        this.libraryPublicationPageModel=new PageModel(10);
    }

    getLibraryPage(event?,libraryId?){
        if(event){
            this.libraryPageModel.currentPage = event.currentPage;
            if (this.libraryPageModel.size != event.size) {
                this.libraryPageModel.currentPage = 1;
            }
            if (event.size) {
                this.libraryPageModel.size = event.size;
            }
        }
        if(this.activeUser.userId){
                this.libraryService.getLibraryUserById(this.activeUser.userId,this.libraryPageModel.currentPage,this.libraryPageModel.size).subscribe(res=>{
                    if(res["data"]){
                        this.libraryList=[];
                        for(let i=0;i<res["data"].length;i++){
                            let _library=new MylibraryDTO();
                            _library.bindData(res["data"][i]);
                            this.libraryList.push(_library);
                        }
                        this.libraryPageModel.totalCount=res["totalCount"];
                        this.libraryPageModel.totalPages = Math.ceil(this.libraryPageModel.totalCount / this.libraryPageModel.size);
                    }else{
                        this.libraryList=[];
                        this.libraryPageModel.totalCount=res["totalCount"];
                        this.libraryPageModel.totalPages = Math.ceil(this.libraryPageModel.totalCount / this.libraryPageModel.size);
                    }
                })
            }
    }
    changeLibraryResultPage(event){
        this.libraryPublicationPageModel.currentPage = event.currentPage;

        if (this.libraryPublicationPageModel.size != event.size) {
            this.libraryPublicationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.libraryPublicationPageModel.size = event.size;
        }

        this.libraryPublicationPageModel.totalPages = Math.ceil(this.libraryPublicationPageModel.totalCount / this.libraryPublicationPageModel.size)
        let _array = this.libraryPublicationIdList.slice((this.libraryPublicationPageModel.currentPage - 1) * this.libraryPublicationPageModel.size, this.libraryPublicationPageModel.size * this.libraryPublicationPageModel.currentPage);
        this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
            next: (res) => {
                this.libraryPublicationList = new Array<PublicationDTO>();
                if (res["hits"]["hits"]) {
                    for (let i = 0; i < res["hits"]["hits"].length; i++) {
                        let _publication = new PublicationDTO();
                        _publication.bindData(res["hits"]["hits"][i]["_source"]);
                        this.libraryPublicationList.push(_publication);
                    }
                }
            },
            error: (e) => console.error(e)
        });
    }
    fillLibraryIdList(libraryId){
        if(!libraryId){
            libraryId=this.publicationLibraryId
        }
        if(libraryId){
            this.publicationLibraryId=libraryId;
            this.libraryService.getLibraryPublicationLibraryById(libraryId).subscribe(res=>{
                this.libraryRelationList = [];
                if(res["data"]) {
                    for (let i = 0; i < res["data"].length; i++) {
                        let _libraryRelation = new MylibraryRelationDTO();
                        _libraryRelation.bindData(res["data"][i]);
                        this.libraryRelationList.push(_libraryRelation);
                    }
                    if(this.libraryRelationList.length>0){
                        this.libraryPublicationIdList=new Array<string>();
                        this.libraryRelationList.forEach(r => {
                            if (r.document_id) {
                                this.libraryPublicationIdList.push(r.document_id.toString());
                            }
                        });
                    }
                    this.libraryPublicationIdList.sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    });
                    this.libraryPublicationPageModel.totalCount = this.libraryPublicationIdList.length;
                    this.libraryPublicationPageModel.totalPages = Math.ceil(this.libraryPublicationPageModel.totalCount / this.libraryPublicationPageModel.size)
                    this.changeLibraryResultPage(this.libraryPublicationPageModel.currentPage);
                }else{
                    this.libraryPublicationPageModel.totalCount=0;
                }
            })
        }
    }
    deleteLibraryConfirmend(id){
        this.modalService.confirmBox(
            this.localizationService.getLocalizationValue('Confirmation'),
            this.localizationService.getLocalizationValue('AreYouSureDelete'),
            this.deleteLibrary.bind(this,id),null);
    }
    deleteLibrary(id){
        this.libraryService.deleteLibrary(id).subscribe(res=>{
            this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessDelete'), this.localizationService.getLocalizationValue('Success'));
            this.getLibraryPage();
        })
    }

    setLibraryRelation(library: MylibraryDTO) {
        if (library && this.addFavorite) {
            const existingItem = this.selectedLibraryRelationList.find(
                (item: MylibraryRelationDTO) => item.library_id === library.id
            );
            const existingIndex = this.selectedLibraryRelationList.findIndex(
                (item: MylibraryRelationDTO) => item.library_id === library.id
            );
            if (existingIndex !== -1) {
                if(existingItem){
                    this.selectedLibraryRelationList.splice(existingIndex, 1);
                    this.DeleteLibraryDocument(existingItem,library.id);
                }
            } else {
                this.selectedLibraryRelation = new MylibraryRelationDTO();
                this.selectedLibraryRelation.document_id = this.documentId;
                this.selectedLibraryRelation.library_id = library.id;
                this.selectedLibraryRelation.document_type = this.documentType;
                const parsedUrl = new URL(window.location.href);
                let baseUrl = parsedUrl.origin;
                this.selectedLibraryRelation.url = baseUrl + '/#' + this.router.url;
                this.selectedLibraryRelation.created_date = new Date();
                this.selectedLibraryRelation.updated_date = new Date();
                this.selectedLibraryRelation.updated_by = this.activeUser.userId;
                this.selectedLibraryRelation.libraryName = library.title;
                this.selectedLibraryRelationList.push(this.selectedLibraryRelation);
                this.libraryDocumentAdd(this.selectedLibraryRelation);
            }
        }
        else if(library && this.publicationListAnalysis && this.publicationListAnalysis.length>0 && this.addFavorite==undefined){
            this.selectedLibraryRelationList=new Array<MylibraryRelationDTO>();
            this.publicationListAnalysis.forEach(p=>{
               let _libraryRelation=new MylibraryRelationDTO();
                const parsedUrl = new URL(window.location.href);
                let baseUrl = parsedUrl.origin;
               _libraryRelation.url=baseUrl + '/#' + this.router.url;
               _libraryRelation.library_id=library.id;
               _libraryRelation.libraryName=library.title;
               _libraryRelation.document_id=parseInt(p.id);
               if(this.documentType){
                   _libraryRelation.document_type=this.documentType;
               }else{
                   _libraryRelation.document_type=null;
               }
               _libraryRelation.updated_by=this.activeUser.userId;
               _libraryRelation.created_date=new Date();
               _libraryRelation.updated_date=new Date();
               this.selectedLibraryRelationList.push(_libraryRelation);
            })
            this.libraryDocumentListAdd(this.selectedLibraryRelationList);
            this.libraryModal.nativeElement.click();
        }
    }
    libraryDocumentAdd(selectedRelation:MylibraryRelationDTO){
        if(selectedRelation){
            this.libraryService.LibraryDocumentAdd(selectedRelation).subscribe(res=>{
                this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessSave'), this.localizationService.getLocalizationValue('Success'));
                this.selectedLibraryRelation=new MylibraryRelationDTO();
                this.getLibraryPage();
            })
        }
    }
    libraryDocumentListAdd(selectedRelationList:MylibraryRelationDTO[]){
        if(selectedRelationList){
            this.libraryService.LibraryDocumentListAdd(selectedRelationList).subscribe(res=>{
                this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessSave'), this.localizationService.getLocalizationValue('Success'));
                this.selectedLibraryRelation=new MylibraryRelationDTO();
                this.getLibraryPage();
            })
        }
    }
    SelectedLibraryListIncluded(library:MylibraryDTO): boolean {
        if(this.addFavorite){
            return this.selectedLibraryRelationList.some((item) => item.library_id === library.id);
        }else if(this.addFavorite==undefined && this.publicationListAnalysis && this.publicationListAnalysis.length>0){
            return this.selectedLibraryRelationList.some((item) => item.library_id === library.id);
        }
        else{
            return  false;
        }
    }
    transform(value: unknown, ...args: unknown[]): unknown {
        let date: Date;
        let utcDate: Date;
        if (!value || value=='null' || value==null){
            return "";
        }
        if(value && value instanceof Date){
            date = (value as Date);
            utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            date = utcDate;
        } else if(value) {
            date = moment.parseZone(value,'YYYY-MM-DDTHH:mm:SS').toDate();
            date = date;
        }
        return moment(date).format('YYYY-MM-DDTHH:mm');
    }
    doLibrary(publicationList:PublicationDTO[]){
            if(!this.activeUser){
                this.activeUser=this.authorizationService.getActiveUser();
            }
            this.getLibraryPage();
    }
    getDynamicId(documentId: string, publicationListAnalysis: any[], id: string): string {
        if (this.addFavorite && this.publicationListAnalysis==undefined ) {
            return `library-modal-publication-${documentId}`;
        } else if (this.addFavorite==undefined && this.publicationListAnalysis && this.publicationListAnalysis.length > 0) {
            return `library-modal-analysis-${publicationListAnalysis[0].id}`;
        } else if(!this.addFavorite && this.publicationListAnalysis==undefined) {
            return `library-modal-${id}`;
        }
    }

    getCreatedModalId(documentId: string, publicationListAnalysis: any[], id: string): string {
        if (this.addFavorite && this.publicationListAnalysis==undefined) {
            return `libraryCreatedModal-publication-${documentId}`;
        } else if (this.addFavorite==undefined && this.publicationListAnalysis && this.publicationListAnalysis.length > 0) {
            return `libraryCreatedAnalysisModal-${publicationListAnalysis[0].id}`;
        } else if(!this.addFavorite && this.publicationListAnalysis==undefined){
            return `libraryCreatedModal-${id}`;
        }
    }

    getDetailModalId(documentId: string, publicationListAnalysis: any[], id: string): string {
        if (this.addFavorite && this.publicationListAnalysis==undefined ) {
            return `libraryDetailModal-publication-${documentId}`;
        } else if (this.addFavorite==undefined && this.publicationListAnalysis && this.publicationListAnalysis.length > 0) {
            return `libraryDetailAnalysisModal-${publicationListAnalysis[0].id}`;
        } else if(!this.addFavorite && this.publicationListAnalysis==undefined){
            return `libraryDetailModal-${id}`;
        }
    }

}