import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService{
  isActive = true;

  requetList: String[] = [];

  show(requestUrl){
    this.requetList.push(requestUrl);
    if(this.requetList.length > 0){
      this.isActive = true;
    }
  }

  hide(requestUrl){
    let index = this.requetList.indexOf(requestUrl)
    if(index > -1){
      this.requetList.splice(index,1);
    }

    if(this.requetList.length == 0){
      this.isActive = false;
    }
  }

  showLoader(timeout?){
  //   this.isActive = true;
  //  if(timeout){
  //    setTimeout(() => {this.isActive = false;}, timeout);
  //  }
  }
  showLoaderNew(timeout?){
    this.isActive = true;
   if(timeout){
     setTimeout(() => {this.isActive = false;}, timeout);
   }
  }

  hideLoader(){
    // this.isActive = false;
  }
  hideLoaderNew(){
    this.isActive = false;
  }

}
