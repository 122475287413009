import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class LocalizationService{
  private wordUrl = 'assets/localizationWords.json';
  private sentenceUrl = 'assets/localizationSentences.json';
  wordDictionary = {};
  sentenceDictionary = {};
  activeLanguage = sessionStorage.getItem("activeLanguage");

  protected constructor(protected http: HttpClient) {
  };

  initConfig() {
    return new Promise((resolve, reject) => {
      if(!sessionStorage.getItem("activeLanguage")) {
        sessionStorage.setItem('activeLanguage','tr');
        location.reload();
      }

      this.getWordJSON().subscribe(data => {
        this.wordDictionary = data;
        resolve(true);

      });
    });
    this.getSentenceJSON();
  }

  getWordJSON(): Observable<any> {
    return this.http.get(this.wordUrl);
  }

  getSentenceJSON(): Observable<any> {
    return this.http.get(this.sentenceUrl);
  }

  public getLocalizationValue(val,markNotFound?: boolean) {

    if(this.wordDictionary[val]) {
      if(this.wordDictionary[val][this.activeLanguage]) {
        return this.wordDictionary[val][this.activeLanguage];
      } else if (markNotFound==false) {
        return "[" + val + "]";
      }
      return val;
    }else {
      val = val.charAt(0).toUpperCase() + val.slice(1);
      if(this.wordDictionary[val] && this.wordDictionary[val][this.activeLanguage]) {
        return this.wordDictionary[val][this.activeLanguage];
      } else if (markNotFound==false) {
        return "[" + val + "]";
      }
      return val;
    }

  }

  getLocalizationSentence(val) {
    if(this.sentenceDictionary[val][this.activeLanguage]) {
      return this.sentenceDictionary[val][this.activeLanguage];
    } else {
      return "[" + val + "]";
    }
  }

}
