import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchComponent} from "./search/search.component";
import {PublicationDetailComponent} from "./search/publication-detail/publication-detail.component";
import {JournalDetailComponent} from "./search/journal-detail/journal-detail.component";
import {AuthorDetailComponent} from "./search/author-detail/author-detail.component";
import {InstitutionDetailComponent} from "./search/institution-detail/institution-detail.component";
import {PublicationAnalysisDetailComponent} from "./publication-analysis-detail/publication-analysis-detail.component";
import {JournalSuggestionComponent} from "./journal-suggestion/journal-suggestion.component";
import {RedirectGuard} from "./RedirectGuard.module";


const routes: Routes = [
    {path: ':lang/analiz', component: PublicationAnalysisDetailComponent},
    {path: ':lang/dergionerisi', component: JournalSuggestionComponent},
    {path: 'search/:lang/:object/:query', component: SearchComponent},
    {path: ':lang/proje/ara', component: SearchComponent,canActivate: [RedirectGuard]},
    {path: ':lang/:object/ara', component: SearchComponent},
    {path: ':lang/yayin/detay/:id/:q', component: PublicationDetailComponent},
    {path: ':lang/yayin/detay/:id', component: PublicationDetailComponent},
    {path: ':lang/dergi/detay/:id/:q', component: JournalDetailComponent},
    {path: ':lang/dergi/detay/:id', component: JournalDetailComponent},
    {path: ':lang/yazar/detay/:id/:q', component: AuthorDetailComponent},
    {path: ':lang/yazar/detay/:id', component: AuthorDetailComponent},
    {path: ':lang/kurum/detay/:id/:q', component: InstitutionDetailComponent},
    {path: ':lang/kurum/detay/:id', component: InstitutionDetailComponent},
    {path: '**', redirectTo: 'tr/yayin/ara'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
