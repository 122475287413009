export abstract class BaseDTO {

    constructor() {
    }

    bindData(obj: any, level?): void {
        if (obj == null) {
            return;
        }
    }

    fillExportData(): void {
    }
}
