import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";
import {AuthorDTO} from "./AuthorDTO";


export class AuthorSearchResult extends SearchAbstractDTO {
    public authors: AuthorDTO[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        if (obj.authors) {
            this.authors = [];
            for (let i = 0; i < obj.authors.length; i++) {
                let _author = new AuthorDTO();
                _author.bindData(obj.authors[i]);
                this.authors.push(_author);
            }
        }
    }


}
