import {BaseDTO} from "../../core/BaseDTO";
import {JournalYearSubjectDTO} from "./JournalYearSubjectDTO";
import {JournalPersonDTO} from "./JournalPersonDTO";

export class JournalYearDTO extends BaseDTO {
    public year: number;
    public missingRefereeReportCount: number;
    public missingIssueCount: number;
    public expertWarningCount: number;
    public subjects: JournalYearSubjectDTO[]=[];
    public databases: string[];
    public periods: string[];

    public isJournalYearSubjectCollapseOpen = false;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.year = obj.year;
        this.missingRefereeReportCount = obj.missingRefereeReportCount;
        this.missingIssueCount = obj.missingIssueCount;
        this.expertWarningCount = obj.expertWarningCount;
        this.databases = obj.databases;
        this.periods = obj.periods;

        if (obj.subjects) {
            this.subjects = [];
            if (obj.subjects && obj.subjects.length > 0) {
                for (let i = 0; i < obj.subjects.length; i++) {
                    let _subject = new JournalYearSubjectDTO();
                    _subject.bindData(obj.subjects[i]);
                    this.subjects.push(_subject);
                }
            }
        }

    }

    isGrayStatus() {
        if (this.expertWarningCount > 0 || this.missingIssueCount >= 3 || this.missingRefereeReportCount >= 3) {
            return true;
        }
        return false;
    }


}
