import {Pipe, PipeTransform} from '@angular/core';
import {LocalizationService} from "../service/localizationService";
import slugify from "slugify";

@Pipe({name: 'urlSlugify'})
export class UrlSlugifyPipe implements PipeTransform {

    constructor() {
    }

    transform(url: string): string {
        return slugify(url, {
            lower: true,
            remove: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
            strict: true,
            trim: true
        });
    }

}
