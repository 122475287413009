import { AfterViewChecked, Directive, ElementRef } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Directive({
  selector: '[appExternalLink]'
})
export class ExternalLinkDirective implements AfterViewChecked {
  constructor(private el: ElementRef,private sanitizer: DomSanitizer) { }

  ngAfterViewChecked() {
    Array.from(this.el.nativeElement.querySelectorAll('a'))
      .forEach((el: any) => {
        el.setAttribute('target', '_blank');
        if(!el.getAttribute("href").startsWith("http")){
          el.setAttribute('href',  "http://"+el.getAttribute("href"));
        }
      });
  }
}
