import {BaseDTO} from "../../core/BaseDTO";
import {InstitutionChildDTO} from "./InstitutionChildDTO";
import {PublicationAbstractDTO} from "../publication/PublicationAbstractDTO";

export class InstitutionDTO extends BaseDTO {
    public id: string;
    public indexedBy: string;
    public indexDate: string;
    public name: string;
    public status: string;
    public type: string;
    public labels: string[];
    public codes: string[];
    public childs: InstitutionChildDTO[];

    public country: string;
    public city: string;

    public highlight:any;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.indexDate = obj.indexDate;
        this.indexedBy = obj.indexedBy;
        this.name = obj.name;
        this.labels = obj.labels;
        this.codes = obj.codes;
        this.status = obj.status;
        this.type = obj.type;
        this.country = obj.country;
        this.city = obj.city;
        if (obj.childs) {
            this.childs = [];
            for (let i = 0; i < obj.childs.length; i++) {
                let _child = new InstitutionChildDTO();
                _child.bindData(obj.childs[i]);
                this.childs.push(_child);
            }
        }


        if (obj.highlight) {
            this.highlight = obj.highlight;
        }
        if (this.highlight) {
            let _highlight = this.highlight;
            if (_highlight["searchField"]) {
                let _searchField = _highlight["searchField"];
                _searchField.forEach(sf => {
                    let cleanedHighLight = sf.replaceAll("<strong class=\"highlight\">", "");
                    cleanedHighLight = cleanedHighLight.replaceAll("</strong>", "");
                    if (this.name == cleanedHighLight) {
                        this.name = sf;
                    }
                });
            }
        }

    }

}
