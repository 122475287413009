<div class="container mx-auto">

    <div class=" mx-auto">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-12 mx-auto  ">

            <div class="px-5 pt-5 pb-3 body">
                <div class="">
                    <div class="d-flex align-items-stretch ">
                        <img style="height: 2.5em;"
                             src="https://trdizin.gov.tr/wp-content/uploads/2022/04/TRDizinlogo_live-e1586763957746.png"
                             data-retina="" alt="TRDizin" data-no-retina="">
                        <h1 class="ms-3" style=" font-weight: bold;">{{'journalSuggestion' | localize}}</h1>
                    </div>
                    <p class=""> {{'journalSuggestionQuestion' | localize}} <br>
                        <small>{{'journalSuggestionInfo' | localize}}</small></p>
                    <div>
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="publicationTitle"
                                   [(ngModel)]="publicationTitle"
                                   placeholder="name@example.com">
                            <label class="f-size-1" for="publicationTitle">{{'publicationTitle' | localize}}<span
                                    style="color:red"> *</span></label>
                        </div>

                        <div class="form-floating mt-2 mb-3">
                                <textarea class="f-size-1 form-control"
                                          [(ngModel)]="publicationAbstract"
                                          style="height: 10em; resize: none;"
                                          placeholder=""
                                          id="publicationAbstract" rows="7"
                                          cols="33"></textarea>
                            <label class="f-size-1" for="publicationAbstract">{{'abstract' | localize}}<span
                                    style="color:red"> *</span></label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <tagify
                                [(ngModel)]="keywords"
                                inputClass="form-control"
                                [settings]="getSettingsTagify('keywords')"
                                [readonly]="false"
                                [disabled]="false"
                        ></tagify>
                    </div>

                </div>
            </div>
            <div class="mt-2 d-flex justify-content-between">
                <ngx-mat-tree-select-input-ngmodel
                        (selectionChanged)="onSelectionChanged()"
                        [(select)]="subjectSearchValue"
                        [multiple]= "true"
                        [canSelectParentNode]="true"
                        [options]="subjectOptions"
                        [placeholder]="'SubjectFields'|localize">
                </ngx-mat-tree-select-input-ngmodel>
                <button class="button-30  float-right py-1 px-5" role="button"
                        (click)="searchJournal()"
                >{{'search' | localize}}</button>
            </div>


            <div *ngIf="journalList" class="mt-5 container  col-12">

                <div class=" mb-3 pt-4 row  {{i%2==0 ? 'even':'odd'}}"
                     style="min-height: 13.5vh"
                     *ngFor="let journal of journalList;index as i">

                    <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 text-center">
                        <div class="w-100 p-0   d-flex justify-content-start">
                            <small class="mt-1 me-2">{{i + 1}}.</small>
                            <img src="https://static.trdizin.gov.tr/images/svg/journal.svg"
                                 class="text-center w-100 h-100 mx-3" alt="">
                        </div>
                    </div>

                    <div class="col-8 col-sm-9 col-md-10 col-lg-5 col-xl-5 d-flex align-items-start flex-column">
                        <div class="ms-1 w-100">
                            <label class="light-blue f-bold">
                                <a target="_blank" class="light-blue"
                                   [href]="getCurrentLanguage()+'/dergi/detay/'+journal.id">{{journal.title}}</a>
                            </label>
                            <div>
                                <label><label class="f-bold">ISSN:</label> {{journal.issn ? journal.issn : '-'}}
                                </label>
                                <label><label
                                        class="f-bold ms-3">EISSN:</label> {{journal.eissn ? journal.eissn : '-'}}
                                </label>
                            </div>
                            <a target="_blank" rel="nofollow" href="{{journal.webAddress}}"><i
                                    class="fas fa-external-link-alt me-1 fsize-08"></i>Web</a>

                            <div class="mt-1 mb-4 d-flex justify-content-center w-75">
                                <label class="mx-auto w-25">{{'ilgililik oranı'}}</label>
                                <div class="progress ms-1 w-75 box-shadow">
                                    <div class="progress-bar progress-bar-striped gray-bar" role="progressbar"
                                         style="{{'width:'+journal['width']+'%'}}"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6  fsize-09  "
                         style=" vertical-align: top;!important;">
                        <div class="mb-4 w-100 d-flex align-items-start justify-content-start">
                            <div class="w-100 d-flex align-items-start flex-column">

                                <div class="w-100 d-flex justify-content-between">
                                    <label><label class="f-bold">Yayın Sayısı:</label> {{journal.publicationCount}}
                                    </label>
                                    <label><label class="f-bold">Atıf Sayısı:</label> {{journal.citationCount}}</label>
                                    <label><label class="f-bold">Atıf Alan Yayın
                                        Sayısı:</label> {{journal.citedPublicationCount}}</label>
                                </div>

                                <div class="w-100 mt-1 d-flex align-items-start flex-column">
                                    <label><label class="f-bold me-1">Yayın Periyodu: </label> <label
                                            *ngFor="let period of journal.journalYear[0].periods;let isLast=last"> {{('month' + period)|localize}}{{isLast == false ? ',&nbsp;' : ''}}</label>
                                    </label>

                                    <label class="mt-1"><label class="f-bold">Son Yıl Konu Alanları: </label>

                                        <span>
                                        <span class="ms-1 badge rounded-pill subjects "
                                              *ngFor="let subject of journal.journalYear[0].subjects.slice(0,5)">{{(subject.name)}}
                                        </span>
                                        <span class="collapse" id="{{'subjectCollapse'+i}}">
                                                <span class="ms-1 badge rounded-pill subjects"
                                                      *ngFor="let subject of journal.journalYear[0].subjects.slice(5,journal.journalYear[0].subjects.length)">{{(subject.name)}}
                                                </span>
                                        </span>
                                        <i id="{{'subjectCollapseButtonDown'+i}}"
                                           (click)="subjectCollapse(journal)"
                                           *ngIf="journal.journalYear[0].subjects.length>5"
                                           class="ms-1 mt-1 fsize-08 fas {{ journal['subjectCollapsed'] && journal['subjectCollapsed']==true ?' fa-caret-square-up ':'fa-caret-square-down'}} pointer"
                                           data-bs-toggle="collapse" aria-expanded="false"
                                           [attr.aria-controls]="'subjectCollapse'+i"
                                           [attr.data-bs-target]="'#subjectCollapse'+i" style="color:#2e6984;"
                                           aria-hidden="true"></i>
                                    </span>

                                    </label>

                                    <a class="w-100 mt-2 text-right dark-blue fsize-08 pointer "
                                       data-bs-toggle="collapse" aria-expanded="false"
                                       [attr.aria-controls]="'publicationCollapse'+i"
                                       [attr.data-bs-target]="'#publicationCollapse'+i">
                                        <span >
                                        ilgili yayınlar
                                         <i id="{{'publicationCollapseButtonDown'+i}}"
                                            (click)="publicationCollapse(journal)"
                                            class="ms-1 mt-1 fas {{ journal['publicationCollapsed'] && journal['publicationCollapsed']==true ?' fa-caret-square-up ':'fa-caret-square-down'}} float-right"
                                             style="color:#2e6984;"
                                            aria-hidden="true"></i>
                                        </span>
                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="col-12">
                        <div class="ms-4 collapse row" id="{{'publicationCollapse'+i}}">
                                    <a class="w-100 fsize-09"
                                       [href]="getCurrentLanguage()+'/yayin/detay/'+publication.id+'/'+urlFormat(publication.abstracts[0].title)"
                                       target="_blank"
                                          *ngFor="let publication of getMyPublication(journal).slice(0,10)">
                                        <ng-container *ngIf="publication">
                                            {{ publication.getApaStyleForAuthorsName() }}
                                            ({{ publication.publicationYear }}).
                                            {{ publication.abstracts[0].title }}.
                                            <em *ngIf="publication.journal">{{ publication.journal.name }},</em>
                                            {{ publication.issue.volume }}({{ publication.issue.number }}),
                                            {{ publication.startPage }} - {{ publication.endPage }}.
                                            <ng-container *ngIf="publication.doi">
                                                doi.org/{{ publication.doi }}
                                            </ng-container>
                                        </ng-container>
                                    </a>
                               </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
