<a id="subscribeButton" class="mb-0 fsize-1 light-blue" style=""
   data-bs-toggle="modal" data-bs-target="#saveSearchModal">{{'SaveSearch' | localize}}</a>


<div class="modal fade" id="saveSearchModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="saveSearchModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="width:95vw;">
        <div class="modal-content" style="min-height: auto;">
            <div class="modal-header header-background">
                <h6 class="light-blue modal-title text-white" >
                    {{'SaveSearch' | localize}}</h6>
                <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close" (click)="clearModalContent()" #searchModal>
                    <i class="fa fa-times text-white"></i>
                </button>
            </div>
            <div  class="modal-body">
                <div class="row">
                    <div class="col">
                        <label class="form-label" title="{{'EnterSearchTitle' | localize}} " id="modalNameField">
                            {{ 'SearchTitle' | localize }}
                        </label>
                        <input type="text" class="form-control" name="searchName" id="searchName" [(ngModel)]="selectedSearchName" (keyup.enter)="onEnterKey()">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <label>{{'Notification' | localize}}</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" title="{{'OpenNotifications' | localize}}" (change)="onNotificationToggle($event)">
                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                </div>
                <button (click)="onEnterKey()" type="button" id="searchSaveModalSaveButton" class="btn btn-primary" role="saveSearchButton"
                        [disabled]="!isSaveButtonEnabled()">
                    {{ 'Save' | localize }}
                </button>
                <button type="button" class="btn btn-default" data-bs-dismiss="modal" (click)="clearModalContent()" #searchModal>
                    {{'Close' | localize}}
                </button>
            </div>
        </div>
    </div>
</div>
