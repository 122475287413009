import {BaseDTO} from "../../core/BaseDTO";
import {KeyCloackUserDTO} from "../../../trdcore/dto/KeyCloackUserDTO";

export enum NotificationSortField{
  SEND_DATE="send_date",
  READ_DATE="read_date",
  NOTIFICATION_TYPE="notification_type"
}
export enum EnumNotificationType {
  NOTIFICATION = "Notification",
  POPUP = "Pop-up"
}


export class NotificationDto extends BaseDTO{
  id:number;
  notification_body:string;
  notification_status:string;
  notification_subject:string;
  notification_to:string;
  notification_type:string;
  notificationUser:KeyCloackUserDTO;
  send_date:Date;
  read_date:Date;
  bindData(obj: any): void {
    if (obj == null) {
      return;
    }
    super.bindData(obj);

    this.id=obj.id;
    this.notification_body=obj.notification_body;
    this.notification_status=obj.notification_status;
    this.notification_subject = obj.notification_subject;
    this.notification_to = obj.notification_to;
    this.notification_type = obj.notification_type;
  this.send_date = obj.send_date;
    this.read_date = obj.read_date;
    if (obj.notificationUser){
      this.notificationUser = new KeyCloackUserDTO();
      this.notificationUser.bindData(obj.notificationUser);
    }


  }

}
