import {Component, Input} from '@angular/core';
import {saveAs} from 'file-saver';
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {NgForOf, NgIf} from "@angular/common";
import {__disposeResources} from "tslib";
import {FormsModule} from "@angular/forms";
import {ExportService} from "./export.service";
import {ToastrService} from "ngx-toastr";
import {getCurrentDomain, getCurrentLanguage} from "../../app.module";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import {BaseDTO} from "../../core/BaseDTO";
import {UtcToLocalTimePipe} from "../../../trdcore/pipes/utc-to-local-time.pipe";
import * as moment from "moment/moment";
import { v4 as uuidv4 } from 'uuid';


@Component({
    selector: 'export-component',
    standalone: true,
    imports: [
        NgIf,
        NgForOf,
        FormsModule
    ],
    templateUrl: './export.component.html',
    styleUrl: './export.component.scss'
})
export class ExportComponent {
    constructor(public localizationService: LocalizationService, private exportService: ExportService, private toastrService: ToastrService) {
    }

    private documentTypeList = ["publication", "journal", "author", "institution"];
    private publicationHeaders = ["abstracts.title", "abstracts.abstract","docType", "publicationType", "authors.institution.rootTitle", "authors.name",
        "journal.name", "subjects.name", "firstIndexDate", "citedReferences.sourceId", "references.context", "journal.issn", "journal.eissn", "startPage", "endPage",
        "language", "doi", "issue.year", "issue.volume", "issue.number", "projectGroup", "projectnumber", "endDate"];

    private selectedPublicationHeaders = ["abstracts.title", "docType", "publicationType", "journal.name", "journal.issn", "journal.eissn", "issue.year", "issue.volume", "issue.number", "doi", "projectnumber", "projectGroup", "abstracts.abstract",
        "authors.name", "authors.institution.rootTitle","language", "subjects.name", "abstracts.keywords","firstIndexDate", "startPage", "endPage",
        "endDate"];


    private journalHeaders = ["title", "issn", "eissn", "citationCount", "citationFromPaper", "citationFromProject",
        "publicationCount", "citedPublicationCount", "citationAvg", "trdizinAddress", "firstPublishYear", "webAddress", "registrationDate"];

    private authorHeaders = ["fullName", "citationCount", "orcid", "citationFromPaper", "citationFromProject",
        "paperCount", "projectCount", "citedPublicationCount", "citationAvg", "hindex", "trdizinAddress"];

    private institutionHeaders = ["name", "status", "type", "country", "city",
        "paperCount", "projectCount", "citationFromPaper", "citationFromProject", "citationAvg", "trdizinAddress"];


    @Input() searchText: string;
    @Input() searchUrlParams: string;
    @Input() documentList: any[];


    @Input() documentType: string;
    @Input() fieldSelect: boolean;
    headers: string[] = [];
    selectedHeaderList: string[] = [];

    endNumber = 100;
    startNumber = 1;
    isDocument = false;

    initFields() {
        if (this.documentType == "publication") {
            this.headers = this.publicationHeaders;
            if (this.isDocument == true) {
                this.selectedHeaderList = this.selectedPublicationHeaders;
            }
        } else if (this.documentType == "author") {
            this.headers = this.authorHeaders;
        } else if (this.documentType == "journal") {
            this.headers = this.journalHeaders;
        } else if (this.documentType == "institution") {
            this.headers = this.institutionHeaders;
        }
    }

    exportDocumentList(type: string) {
        this.isDocument = this.documentList && this.documentList.length > 0;

        this.initFields();

        if (this.isDocument) {
            this.fillFile(type, this.documentList);
            return;
        }
    }

    export(type: string) {
        if (!this.selectedHeaderList || this.selectedHeaderList.length == 0) {
            return this.toastrService.warning(this.localizationService.getLocalizationValue('selectLeastOne'));
        }

        if (this.endNumber < this.startNumber) {
            let tmp = this.endNumber;
            this.endNumber = this.startNumber;
            this.startNumber = tmp;
        }

        if (this.startNumber < 1) {
            this.startNumber = 1;
        }
        if (this.endNumber < 1) {
            this.endNumber = 100;
        }

        if ((this.endNumber - this.startNumber) > 999) {
            return this.toastrService.error(this.localizationService.getLocalizationValue("ExportSizeError"));
        }


        let params = this.searchUrlParams;
        params += "&start=" + (this.startNumber - 1);
        if(this.endNumber-this.startNumber<1){
           return this.toastrService.error(this.localizationService.getLocalizationValue("countErrorExport"));
        }
        params += "&size=" + (this.endNumber-(this.startNumber-1));

        params += "&fields=" + "\"" + this.selectedHeaderList.join("\",\"") + "\"";

        if (this.documentType == "publication") {

            this.exportService.getPublication(params).subscribe(res => {
                if (res["hits"]["hits"]) {
                    let _data = res["hits"]["hits"].map(d => {
                        if (this.selectedHeaderList.includes("citedReferences.sourceId")) {
                            if (!d["fields"]["citedReferences.sourceId"]) {
                                d["fields"]["citedReferences.sourceId"] = 0;
                            } else {
                                d["fields"]["citedReferences.sourceId"] = d["fields"]["citedReferences.sourceId"].length;
                            }
                        }
                        if (d["fields"] && d["fields"]["abstracts.title"] && d["fields"]["abstracts.title"].length > 0) {
                            d["fields"]["abstracts.title"] = d["fields"]["abstracts.title"][0];
                        }
                        if (d["fields"] && d["fields"]["abstracts.abstract"] && d["fields"]["abstracts.abstract"].length > 0) {
                            d["fields"]["abstracts.abstract"] = d["fields"]["abstracts.abstract"][0];
                        }

                        if (d["fields"] && d["fields"]["docType"]) {
                            d["fields"]["docType"] = this.localizationService.getLocalizationValue(d["fields"]["docType"]);
                        }

                        if (d["fields"] && d["fields"]["language"]) {
                            d["fields"]["language"] = this.localizationService.getLocalizationValue(d["fields"]["language"]);
                        }
                        if (d["fields"] && d["fields"]["publicationType"]) {
                            d["fields"]["publicationType"] = this.localizationService.getLocalizationValue(d["fields"]["publicationType"]);
                        }

                        return d["fields"];
                    });
                    this.fillFile(type, _data);
                }
            });
        } else if (this.documentType == "journal") {
            this.exportService.getJournal(params).subscribe(res => {
                let idList = [];
                if (res["hits"]["hits"]) {
                    let _data = res["hits"]["hits"].map(d => {
                        idList.push(d["_id"]);
                        d["fields"]["_id"] = d["_id"];
                        return d["fields"];
                    });

                    this.exportService.getJournalCitationListByIdList(idList.reduce((prev, curr) => prev + " " + curr)).subscribe({
                        next: (res) => {
                            if (res["aggregations"] && res["aggregations"]["journals_citation_count"] && res["aggregations"]["journals_citation_count"]["yayin"] && res["aggregations"]["journals_citation_count"]["yayin"]["buckets"]) {
                                let _res = res["aggregations"]["journals_citation_count"]["yayin"]["buckets"];
                                if (_res) {
                                    _res.forEach(r => {
                                        let journalId = r["key"];
                                        let makale_sayisi = 0;
                                        if (r["doc_count"]) {
                                            makale_sayisi = r["doc_count"];
                                        }
                                        let atif_alan_makale_sayisi = 0;
                                        let makaleden_alinan_atif = 0;
                                        let projeden_alinan_atif = 0;
                                        let atif_sayisi = 0;
                                        if (r["yayin_sayisi"] && r["yayin_sayisi"]["atif_sayisi"]) {
                                            if (r["yayin_sayisi"]["atif_sayisi"]["doc_count"]) {
                                                atif_sayisi = r["yayin_sayisi"]["atif_sayisi"]["doc_count"]
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]) {
                                                atif_alan_makale_sayisi = r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]) {
                                                makaleden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]) {
                                                projeden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]["value"];
                                            }
                                        }
                                        let atif_ortalamasi = "0,0";
                                        if (makale_sayisi > 0 && atif_sayisi > 0) {
                                            atif_ortalamasi = parseFloat(parseFloat(("" + (atif_sayisi / makale_sayisi))).toFixed(2)).toLocaleString('tr-TR');
                                        }

                                        _data.forEach(d => {
                                            if (d["_id"] == journalId) {
                                                d["citationCount"] = atif_sayisi;
                                                d["citationFromPaper"] = makaleden_alinan_atif;
                                                d["citationFromProject"] = projeden_alinan_atif;
                                                d["publicationCount"] = makale_sayisi;
                                                d["citationAvg"] = atif_ortalamasi;
                                                d["citedPublicationCount"] = atif_alan_makale_sayisi;
                                            }

                                            return d;
                                        });

                                    });
                                }
                            }
                            _data.forEach(d => {
                                d["trdizinAddress"] = getCurrentDomain() + getCurrentLanguage() + "/dergi/detay/" + d["_id"];
                                if (!d["citationCount"]) {
                                    d["citationCount"] = 0;
                                }
                                if (!d["citationFromPaper"]) {
                                    d["citationFromPaper"] = 0;
                                }
                                if (!d["citationFromProject"]) {
                                    d["citationFromProject"] = 0;
                                }

                                if (!d["publicationCount"]) {
                                    d["publicationCount"] = 0;
                                }

                                if (!d["citationAvg"]) {
                                    d["citationAvg"] = 0;
                                }

                                if (!d["citedPublicationCount"]) {
                                    d["citedPublicationCount"] = 0;
                                }


                                return d;
                            });
                            this.fillFile(type, _data);
                        },
                        error: (e) => console.error(e)
                    });

                }
            });
        } else if (this.documentType == "author") {
            this.exportService.getAuthor(params).subscribe(res => {
                let idList = [];
                if (res["hits"]["hits"]) {
                    let _data = res["hits"]["hits"].map(d => {
                        idList.push(d["_id"]);
                        d["fields"]["_id"] = d["_id"];
                        return d["fields"];
                    });

                    this.exportService.getAuthorCitationListByIdList(idList.reduce((prev, curr) => prev + " " + curr)).subscribe({
                        next: (res) => {
                            if (res["aggregations"] && res["aggregations"]["journals_citation_count"] && res["aggregations"]["journals_citation_count"]["yayin"] && res["aggregations"]["journals_citation_count"]["yayin"]["buckets"]) {
                                let _res = res["aggregations"]["journals_citation_count"]["yayin"]["buckets"];
                                if (_res) {
                                    _res.forEach(r => {

                                        // ["citationFromPaper", "citationFromProject",
                                        //     "paperCount", "projectCount", "citedPublicationCount", "citationAvg", "trdizinAddress"];

                                        let authorId = r["key"];
                                        let makale_sayisi = 0;
                                        let proje_sayisi = 0;

                                        // xxxxxxxxxxxx
                                        if (r["doc_count"]) {
                                            makale_sayisi = r["doc_count"];
                                        }

                                        let atif_alan_makale_sayisi = 0;
                                        let makaleden_alinan_atif = 0;
                                        let projeden_alinan_atif = 0;
                                        let atif_sayisi = 0;
                                        if (r["yayin_sayisi"] && r["yayin_sayisi"]["atif_sayisi"]) {
                                            if (r["yayin_sayisi"]["atif_sayisi"]["doc_count"]) {
                                                atif_sayisi = r["yayin_sayisi"]["atif_sayisi"]["doc_count"]
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]) {
                                                atif_alan_makale_sayisi = r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]) {
                                                makaleden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]) {
                                                projeden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]["value"];
                                            }
                                        }
                                        if (r["yayin_sayisi"] && r["yayin_sayisi"]["yayin_turu"] && r["yayin_sayisi"]["yayin_turu"]["buckets"]) {
                                            r["yayin_sayisi"]["yayin_turu"]["buckets"].forEach(b => {
                                                if (b["key"] == 'PAPER') {
                                                    makale_sayisi = b["doc_count"];
                                                } else if (b["key"] == 'PROJECT') {
                                                    proje_sayisi = b["doc_count"];
                                                }
                                            });
                                        }

                                        let atif_ortalamasi = "0,0";
                                        if ((makale_sayisi + proje_sayisi) > 0 && atif_sayisi > 0) {
                                            atif_ortalamasi = parseFloat(parseFloat(("" + (atif_sayisi / (makale_sayisi + proje_sayisi)))).toFixed(2)).toLocaleString('tr-TR');
                                        }

                                        _data.forEach(d => {
                                            if (d["_id"] == authorId) {
                                                d["citationCount"] = atif_sayisi;
                                                d["citationFromPaper"] = makaleden_alinan_atif;
                                                d["citationFromProject"] = projeden_alinan_atif;
                                                d["paperCount"] = makale_sayisi;
                                                d["projectCount"] = proje_sayisi;
                                                d["citationAvg"] = atif_ortalamasi;
                                                d["citedPublicationCount"] = atif_alan_makale_sayisi;
                                            }

                                            return d;
                                        });

                                    });
                                }
                            }
                            _data.forEach(d => {
                                d["trdizinAddress"] = getCurrentDomain() + getCurrentLanguage() + "/yazar/detay/" + d["_id"];
                                if (!d["citationCount"]) {
                                    d["citationCount"] = 0;
                                }
                                if (!d["projectCount"]) {
                                    d["projectCount"] = 0;
                                }

                                if (!d["paperCount"]) {
                                    d["paperCount"] = 0;
                                }

                                if (!d["citationFromPaper"]) {
                                    d["citationFromPaper"] = 0;
                                }
                                if (!d["citationFromProject"]) {
                                    d["citationFromProject"] = 0;
                                }

                                if (!d["publicationCount"]) {
                                    d["publicationCount"] = 0;
                                }

                                if (!d["citationAvg"]) {
                                    d["citationAvg"] = 0;
                                }

                                if (!d["citedPublicationCount"]) {
                                    d["citedPublicationCount"] = 0;
                                }


                                return d;
                            });
                            this.fillFile(type, _data);
                        },
                        error: (e) => console.error(e)
                    });

                }
            });
        } else if (this.documentType == "institution") {
            this.exportService.getInstitution(params).subscribe(res => {
                let idList = [];
                let codeList = [];
                if (res["hits"]["hits"]) {
                    let _data = res["hits"]["hits"].map(d => {
                        idList.push(d["_id"]);
                        d["fields"]["_id"] = d["_id"];
                        if (d["fields"]["codes"]) {
                            d["fields"]["code"] = d["fields"]["codes"][0];
                            d["fields"]["codes"].forEach(c => {
                                codeList.push(c);
                            });
                        }

                        return d["fields"];
                    });

                    this.exportService.getInstitutionCitationListByIdList(codeList.reduce((prev, curr) => prev + " " + curr)).subscribe({
                        next: (res) => {
                            if (res["aggregations"] && res["aggregations"]["institutions_citation_count"] && res["aggregations"]["institutions_citation_count"]["yayin"] && res["aggregations"]["institutions_citation_count"]["yayin"]["buckets"]) {
                                let _res = res["aggregations"]["institutions_citation_count"]["yayin"]["buckets"];
                                if (_res) {
                                    _res.forEach(r => {

                                        let code = r["key"];
                                        let makale_sayisi = 0;
                                        let proje_sayisi = 0;

                                        // xxxxxxxxxxxx
                                        if (r["doc_count"]) {
                                            makale_sayisi = r["doc_count"];
                                        }
// xxxxxxxxx
                                        let atif_alan_makale_sayisi = 0;
                                        let makaleden_alinan_atif = 0;
                                        let projeden_alinan_atif = 0;
                                        let atif_sayisi = 0;


                                        if (r["yayin_sayisi"] && r["yayin_sayisi"]["atif_sayisi"]) {
                                            if (r["yayin_sayisi"]["atif_sayisi"]["doc_count"]) {
                                                atif_sayisi = r["yayin_sayisi"]["atif_sayisi"]["doc_count"]
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]) {
                                                atif_alan_makale_sayisi = r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]) {
                                                makaleden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]["value"];
                                            }
                                            if (r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]) {
                                                projeden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]["value"];
                                            }
                                        }
                                        if (r["yayin_sayisi"] && r["yayin_sayisi"]["yayin_turu"] && r["yayin_sayisi"]["yayin_turu"]["buckets"]) {
                                            r["yayin_sayisi"]["yayin_turu"]["buckets"].forEach(b => {
                                                if (b["key"] == 'PAPER') {
                                                    makale_sayisi = b["doc_count"];
                                                } else if (b["key"] == 'PROJECT') {
                                                    proje_sayisi = b["doc_count"];
                                                }
                                            });
                                        }

                                        let atif_ortalamasi = "0,0";
                                        if ((makale_sayisi + proje_sayisi) > 0 && atif_sayisi > 0) {
                                            atif_ortalamasi = parseFloat(parseFloat(("" + (atif_sayisi / (makale_sayisi + proje_sayisi)))).toFixed(2)).toLocaleString('tr-TR');
                                        }

                                        _data.forEach(d => {
                                            if (d["code"] == code) {
                                                d["citationCount"] = atif_sayisi;
                                                d["citationFromPaper"] = makaleden_alinan_atif;
                                                d["citationFromProject"] = projeden_alinan_atif;
                                                d["paperCount"] = makale_sayisi;
                                                d["projectCount"] = proje_sayisi;
                                                d["citationAvg"] = atif_ortalamasi;
                                                d["citedPublicationCount"] = atif_alan_makale_sayisi;
                                            }

                                            return d;
                                        });
                                    });
                                }
                            }
                            _data.forEach(d => {
                                d["trdizinAddress"] = getCurrentDomain() + getCurrentLanguage() + "/kurum/detay/" + d["code"];
                                if (!d["citationCount"]) {
                                    d["citationCount"] = 0;
                                }
                                if (!d["projectCount"]) {
                                    d["projectCount"] = 0;
                                }

                                if (!d["paperCount"]) {
                                    d["paperCount"] = 0;
                                }

                                if (!d["citationFromPaper"]) {
                                    d["citationFromPaper"] = 0;
                                }
                                if (!d["citationFromProject"]) {
                                    d["citationFromProject"] = 0;
                                }

                                if (!d["publicationCount"]) {
                                    d["publicationCount"] = 0;
                                }

                                if (!d["citationAvg"]) {
                                    d["citationAvg"] = 0;
                                }

                                if (!d["citedPublicationCount"]) {
                                    d["citedPublicationCount"] = 0;
                                }


                                return d;
                            });
                            this.fillFile(type, _data);
                        },
                        error: (e) => console.error(e)
                    });

                }
            });
        }
    }

    fillFile(type: string, data) {
        if (type == "CSV") {
            this.exportCSV(data)
        } else if (type == "XLS") {
            this.exportXLS(data)
        } else if (type == "ODS") {
            this.exportODS(data).then(r => {
                return r;
            });
        }
    }


    exportCSV(data) {
        if (data && data.length>0 &&  this.selectedHeaderList) {
            data.forEach(c=>{
                if(c && c.endDate && c.endDate!=null){
                    if(c.endDate.length>2){
                        c.endDate=this.transform(c.endDate,'DD.MM.YYYY').toString();
                    }
                }
               else if(c && c.registrationDate && c.registrationDate!=null){
                        c.registrationDate=this.transform(c.registrationDate,'DD.MM.YYYY').toString();
                }
                else if(c && c.firstIndexDate && c.firstIndexDate!=null){
                    if(c.firstIndexDate.length>2){
                        c.firstIndexDate=this.transform(c.firstIndexDate,'DD.MM.YYYY').toString();
                    }
                }
            })
            const replacer = (key, value) => value === null ? '' : value;
            let csv = data.map(row => this.selectedHeaderList.map(fieldName => JSON.stringify(this.getFieldValue(row, fieldName), replacer)).join(','));
            let titles = this.selectedHeaderList.map(h => {
                return this.localizationService.getLocalizationValue(h);
            });
            csv.unshift(titles.join(','));
            let csvArray = csv.join('\r\n');
            var blob = new Blob([csvArray], {type: 'text/csv'})
            const uniqueName = `${uuidv4()}.csv`;
            saveAs(blob, uniqueName);
        }
    }


    exportXLS(data) {
        if (data && this.selectedHeaderList) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            // Add headers
            const headers = this.selectedHeaderList
            let _headers = [];
            this.selectedHeaderList.forEach(h => {
                _headers.push(this.localizationService.getLocalizationValue(h));
            });
            worksheet.addRow(_headers);
            new Promise(resolve => {
                data.forEach((item) => {
                    const row = [];
                    const replacer = (key, value) => value === null ? '' : value;
                    new Promise(resolve => {
                        let w=0;
                        for(let i=0;i<headers.length;i++){
                            w++;
                            let header=headers[i];
                            let value = JSON.stringify(this.getFieldValue(item, header), replacer);
                            if (header == 'endDate' || header == 'firstIndexDate' || header == 'registrationDate') {
                                if (value && value.trim() != "" && value.length > 2) {
                                    value = this.transform(value, 'DD.MM.YYYY').toString();
                                }
                            }

                            if (value) {
                                value = value.replace(/^(")/, "");
                                value = value.replace(/"$/, "");
                            } else {
                                value = '';
                            }

                            row.push(value);

                            if(i==headers.length-1){
                                resolve(true);
                            }
                        }
                    }).then(r => {
                        worksheet.addRow(row);
                    });
                });
                resolve(true);
            }).then(r=>{
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const uniqueName = `${uuidv4()}.xlsx`;
                    saveAs(blob, uniqueName);
                });
            });

        }
    }


    public async exportODS(data) {
//         if (data && this.selectedHeaderList) {
//             let workbook = new ExcelJS.Workbook();
//             let worksheet = workbook.addWorksheet('Sheet 1');
//             // Add headers
//             const headers = this.selectedHeaderList
//             let _headers = [];
//             this.selectedHeaderList.forEach(h => {
//                 _headers.push(this.localizationService.getLocalizationValue(h));
//             });
//             worksheet.addRow(_headers);
//             // Add data
//             data.forEach((item) => {
//                 const row = [];
//                 const replacer = (key, value) => value === null ? '' : value;
//                 headers.forEach((header) => {
//                     let value = JSON.stringify(this.getFieldValue(item, header), replacer);
//                     if (value) {
//                         value = value.replace(/^(")/, "");
//                         value = value.replace(/"$/, "");
//                     } else {
//                         value = '';
//                     }
//                     row.push(value);
//                 });
//                 worksheet.addRow(row);
//             });
//             console.log(worksheet);
//              this.workbookToOds(workbook).then(res=>{
//                  console.log(res.byteLength);
//                 const blob = new Blob([res], {type: 'application/vnd.oasis.opendocument.spreadsheet'});
//                 if(blob){
//                     saveAs(blob, 'data.ods');
//                 }
//             });
//
//         }
    }

//
//     private async workbookToOds(workbook: ExcelJS.Workbook): Promise<Uint8Array>  {
//         const zip = new JSZip();
//
//         // Add mimetype file
//         zip.file('mimetype', 'application/vnd.oasis.opendocument.spreadsheet');
//
//         // Add content.xml
//         const contentXml = this.createContentXml(workbook);
//         zip.file('content.xml', contentXml);
//
//         // Add other necessary files (styles.xml, meta.xml, etc.)
//         zip.file('META-INF/manifest.xml', this.createManifestXml());
//         zip.file('styles.xml', this.createStylesXml());
//         zip.file('meta.xml', this.createMetaXml());
//         console.log(zip);
//         return await zip.generateAsync({ type: 'uint8array' });
//     }
//
//     private createContentXml(workbook: ExcelJS.Workbook): string {
//         let contentXml = `<?xml version="1.0" encoding="UTF-8"?>\n<office:document-content xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0">\n`;
//         contentXml += this.getSheetsXml(workbook);
//         contentXml += `</office:document-content>`;
//         // console.log(contentXml);
//
//         return contentXml;
//     }
//
//     private getSheetsXml(workbook: ExcelJS.Workbook): string {
//         let sheetsXml = '';
//         workbook.eachSheet((worksheet, sheetId) => {
//             const sheetXml = this.sheetToXml(worksheet);
//             sheetsXml += `<table:table table:name="${worksheet.name}">${sheetXml}</table:table>`;
//         });
//         return sheetsXml;
//     }
//
//     private sheetToXml(worksheet: ExcelJS.Worksheet): string {
//         let xml = '';
//         worksheet.eachRow((row, rowNumber) => {
//             xml += '<table:table-row>';
//             row.eachCell((cell, colNumber) => {
//                 xml += `<table:table-cell office:value-type="string"><text:p>${cell.value}</text:p></table:table-cell>`;
//             });
//             xml += '</table:table-row>';
//         });
//         return xml;
//     }
//
//     private createManifestXml(): string {
//         return `<?xml version="1.0" encoding="UTF-8"?>
// <manifest:manifest xmlns:manifest="urn:oasis:names:tc:opendocument:xmlns:manifest:1.0" manifest:version="1.2">
//   <manifest:file-entry manifest:full-path="/" manifest:media-type="application/vnd.oasis.opendocument.spreadsheet"/>
//   <manifest:file-entry manifest:full-path="content.xml" manifest:media-type="text/xml"/>
//   <manifest:file-entry manifest:full-path="styles.xml" manifest:media-type="text/xml"/>
//   <manifest:file-entry manifest:full-path="meta.xml" manifest:media-type="text/xml"/>
// </manifest:manifest>`;
//     }
//
//     private createStylesXml(): string {
//         return `<?xml version="1.0" encoding="UTF-8"?>
// <office:document-styles xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0"
//     xmlns:style="urn:oasis:names:tc:opendocument:xmlns:style:1.0"
//     xmlns:text="urn:oasis:names:tc:opendocument:xmlns:text:1.0"
//     xmlns:table="urn:oasis:names:tc:opendocument:xmlns:table:1.0">
//   <office:styles>
//     <style:default-style style:family="table-cell">
//       <style:table-cell-properties fo:padding="0.0976in"
//           fo:border="0.0028in solid #000000"/>
//     </style:default-style>
//   </office:styles>
// </office:document-styles>`;
//     }
//
//     private createMetaXml(): string {
//         return `<?xml version="1.0" encoding="UTF-8"?>
// <office:document-meta xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0"
//     xmlns:xlink="http://www.w3.org/1999/xlink"
//     xmlns:dc="http://purl.org/dc/elements/1.1/"
//     xmlns:meta="urn:oasis:names:tc:opendocument:xmlns:meta:1.0">
//   <office:meta>
//     <meta:generator>ODS Exporter</meta:generator>
//     <meta:initial-creator>Your Name</meta:initial-creator>
//     <meta:creation-date>${new Date().toISOString()}</meta:creation-date>
//     <dc:creator>Your Name</dc:creator>
//     <dc:date>${new Date().toISOString()}</dc:date>
//     <meta:document-statistic meta:table-count="1" meta:cell-count="100"
//         meta:object-count="0"/>
//   </office:meta>
// </office:document-meta>`;
//     }

    // async exportODS(data) : Promise<void>{
    //     if (data && this.selectedHeaderList) {
    //         const headers = this.selectedHeaderList
    //         let _headers = [];
    //         this.selectedHeaderList.forEach(h => {
    //             _headers.push(this.localizationService.getLocalizationValue(h));
    //         });
    //     let _data=[];
    //         data.forEach((item) => {
    //             let _row={};
    //             const replacer = (key, value) => value === null ? '' : value;
    //             headers.forEach((header) => {
    //                 let value=JSON.stringify(this.getFieldValue(item, header), replacer);
    //                 if(value) {
    //                     value = value.replace(/^(")/, "");
    //                     value = value.replace(/"$/, "");
    //                 }else{
    //                     value='';
    //                 }
    //                 _row[this.localizationService.getLocalizationValue(header)]=value
    //             });
    //             _data.push(_row);
    //         });
    //         console.log(_data);
    //         console.log(headers);
    //         const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(_data, { header: _headers });
    //         const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    //
    //         const odsData = await this.workbookToOds(wb);
    //         const blob = new Blob([odsData], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
    //         saveAs(blob, 'xxxxx.ods');
    //     }
    // }
    //
    // private async workbookToOds(wb: XLSX.WorkBook): Promise<Uint8Array> {
    //     const zip = new JSZip();
    //
    //     // Add mimetype file
    //     zip.file('mimetype', 'application/vnd.oasis.opendocument.spreadsheet');
    //
    //     // Add content.xml
    //     const contentXml = this.createContentXml(wb);
    //     zip.file('content.xml', contentXml);
    //
    //     // Add other necessary files (styles.xml, meta.xml, etc.)
    //     zip.file('META-INF/manifest.xml', this.createManifestXml());
    //     zip.file('styles.xml', this.createStylesXml());
    //     zip.file('meta.xml', this.createMetaXml());
    //
    //     return await zip.generateAsync({ type: 'uint8array' });
    // }
    //
    // private createContentXml(wb: XLSX.WorkBook): string {
    //     let contentXml = `<?xml version="1.0" encoding="UTF-8"?>\n<office:document-content xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0">\n`;
    //     contentXml += this.getSheetsXml(wb);
    //     contentXml += `</office:document-content>`;
    //     return contentXml;
    // }
    //
    // private getSheetsXml(wb: XLSX.WorkBook): string {
    //     let sheetsXml = '';
    //     wb.SheetNames.forEach((sheetName) => {
    //         const ws = wb.Sheets[sheetName];
    //         const sheetXml = this.sheetToXml(ws);
    //         sheetsXml += `<table:table table:name="${sheetName}">${sheetXml}</table:table>`;
    //     });
    //     return sheetsXml;
    // }
    //
    // private sheetToXml(ws: XLSX.WorkSheet): string {
    //     const range = XLSX.utils.decode_range(ws['!ref'] as string);
    //     let xml = '';
    //
    //     for (let R = range.s.r; R <= range.e.r; ++R) {
    //         xml += '<table:table-row>';
    //         for (let C = range.s.c; C <= range.e.c; ++C) {
    //             const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
    //             const cell = ws[cellAddress];
    //             const cellValue = cell ? cell.v : '';
    //             xml += `<table:table-cell office:value-type="string"><text:p>${cellValue}</text:p></table:table-cell>`;
    //         }
    //         xml += '</table:table-row>';
    //     }
    //     return xml;
    // }
    //
    // private createManifestXml(): string {
    //     return `<?xml version="1.0" encoding="UTF-8"?>\n<manifest:manifest xmlns:manifest="urn:oasis:names:tc:opendocument:xmlns:manifest:1.0" manifest:version="1.2">\n<manifest:file-entry manifest:full-path="/" manifest:media-type="application/vnd.oasis.opendocument.spreadsheet"/>\n<manifest:file-entry manifest:full-path="content.xml" manifest:media-type="text/xml"/>\n</manifest:manifest>`;
    // }
    //
    // private createStylesXml(): string {
    //     return `<?xml version="1.0" encoding="UTF-8"?>\n<office:document-styles xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0">\n</office:document-styles>`;
    // }
    //
    // private createMetaXml(): string {
    //     return `<?xml version="1.0" encoding="UTF-8"?>\n<office:document-meta xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0">\n</office:document-meta>`;
    // }

    getFieldValue(obj: any, fieldName: string) {

        if (!obj || !fieldName) {
            return null;
        }

        if (obj instanceof BaseDTO) {
            obj.fillExportData();
        }


        let _obj = obj[fieldName];

        if (_obj == null) {
            fieldName = fieldName.replaceAll(".", "");
            _obj = obj[fieldName];
        }
        if (_obj != null) {
            if (_obj.constructor.name == "Array") {
                _obj = _obj.toString();
                _obj = _obj.replace(/[\u{FFF0}-\u{FFFF}]/gu,"");//clean unvalidated char
                return _obj
            }
        }
        if (_obj && _obj.constructor.name != "Number") {
            _obj = _obj.replace(/[\u{FFF0}-\u{FFFF}]/gu,"");//clean unvalidated char
            _obj = this.localizationService.getLocalizationValue(_obj, true);
        }

        return _obj;

    }

    exportFieldSelect(_field) {
        if (this.headers) {
            if (this.selectedHeaderList.includes(_field)) {
                const index = this.selectedHeaderList.indexOf(_field, 0);
                this.selectedHeaderList.splice(index, 1);
            } else {
                this.selectedHeaderList.push(_field);
            }
        }
    }

    isFieldSelected(_field)
        :
        boolean {
        if (!this.selectedHeaderList || this.selectedHeaderList.length == 0) {
            return false;
        }
        return this.selectedHeaderList.indexOf(_field, 0) > -1;
    }

    selectAllFields() {
        if (this.selectedHeaderList.length == this.headers.length) {
            this.selectedHeaderList = [];
        } else {
            this.selectedHeaderList = [];
            this.selectedHeaderList = this.selectedHeaderList.concat(this.headers);
        }
    }
    transform(value: unknown, ...args: unknown[]): unknown {
        var format = args[0];
        let date: Date;
        let utcDate: Date;
        if (!value || value=='null' || value==null){
            return "";
        }
        if(value && value instanceof Date){
            date = (value as Date);
            utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            date = utcDate;
        } else if(value) {
            date = moment.parseZone(value,'YYYY-MM-DDTHH:mm:SS').toDate();
            date = date;
        }
        if(format){
            return moment(date).format(format.toString());
        }
        return moment(date).format('DD.MM.YYYY HH:mm:SS');
    }

    protected readonly
    __disposeResources = __disposeResources;
}
