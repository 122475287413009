<div  class="modal fade" aria-hidden="true"  id="saveSearchListModal" data-bs-backdrop="false"
     aria-labelledby="saved-search-modal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
<!--                text dark verilmesinin sebebi offcanvas'tan beyaz gelmesidir.-->
                <h5 class="modal-title text-dark">{{ 'SavedSearchs' | localize }}</h5>
                <button type="button"
                        class="btn-close" data-bs-dismiss="modal" (click)="clearModal()"
                        aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-striped mt-3">
                    <thead>
                    <tr>
                        <th class="text-left">{{ 'title'|localize }}</th>
                        <th>{{ 'SearchType'|localize }}</th>
                        <th>{{ 'CreatedDate'|localize }}</th>
<!--                        <th>{{ 'Notification'|localize }}</th>-->
                        <th>{{ 'SearchForSave'|localize }}</th>
                        <th class="text-center" >{{ 'SearchResultCount'|localize }}</th>
                        <th>{{ 'Url'|localize }}</th>
                        <th class="text-center">#</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="table-primary" *ngFor="let savedSearchDTO of savedSearchList; let i = index;">
                        <td class="text-left">{{ savedSearchDTO.search_title }}</td>
                        <td>{{ savedSearchDTO.type | localize}}</td>
                        <td>{{ savedSearchDTO.created_date | utcToLocalTime }}</td>
<!--                        <td>-->
<!--                            <ng-container>-->
<!--                                <div class="form-check form-switch d-inline-block">-->
<!--                                    <input-->
<!--                                            class="form-check-input"-->
<!--                                            type="checkbox"-->
<!--                                            id="flexSwitchCheckDefault"-->
<!--                                            [checked]="savedSearchDTO.do_notification"-->
<!--                                            (change)="savedSearchDTO.do_notification = !savedSearchDTO.do_notification; changeActive(savedSearchDTO.id, savedSearchDTO.do_notification)"-->
<!--                                            title="{{ savedSearchDTO.do_notification ? ('CloseNotifications' | localize) : ('OpenNotifications' | localize) }}">-->
<!--                                </div>-->
<!--                            </ng-container>-->
<!--                        </td>-->
                        <td>{{savedSearchDTO.search_text}}</td>
                        <td  class="text-center">{{savedSearchDTO.total_count}}</td>
                        <td>
                            <a [href]="savedSearchDTO.url" target="_blank" title="{{'OpenSearchinNewTab' | localize}}">
                                <i class="fas fa-external-link-alt"></i>
                            </a>
                        </td>
                        <td class="text-center">
                            <a class="text-center expandcolor me-3 fsize-1 pointer" title="{{ 'Delete' | localize }}" (click)="deleteSavedSearch(savedSearchDTO.id)">
                                <i class="fas fa-trash fsize-3 text-danger"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <trd-paginater [pageList]="[10,20,50]" [pageModel]="savedSearchPageModel" (pageChangeEvent)="getSavedSearchPage($event)"></trd-paginater>

            </div>
        </div>
    </div>
</div>