<i *ngIf="addFavorite && publicationListAnalysis==undefined && this.activeUser"
   (click)="getLibraryPage($event)"
   [ngClass]="this.selectedLibraryRelation&&selectedLibraryRelation.library_id ? 'pointer fa-star fsize-12 fas' : 'img-btn far fa-star pointer fsize-12'"
   [attr.data-bs-target]="'#' + getDynamicId(documentId, publicationListAnalysis, id)"
   data-bs-toggle="modal"></i>
<div class="modal fade" [id]="getDynamicId(documentId, publicationListAnalysis, id)" aria-hidden="true" data-bs-backdrop="false"
     aria-labelledby="library-modal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" >{{'MyLibrary' | localize}}</h5>
                <button  type="button"
                         class="btn-close" data-bs-dismiss="modal" #libraryModal (click)="clearModal()"  aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <a [attr.data-bs-target]="'#'+getCreatedModalId(documentId, publicationListAnalysis, id)"  data-bs-toggle="modal"
                   class=" pointer text-dark ms-2 float-right " role="button" (click)="setLibrary()" >
                    <i class=" fas fa-plus text-dark "></i>
                    {{'AddToLibrary'|localize}}
                </a>
                <table class="table table-striped mt-3">
                    <thead>
                    <tr>
                        <th class="text-left" >{{'LibraryTitle'|localize}}</th>
                        <th>{{'CreatedDate'|localize}}</th>
                        <th style="width: 10%" class="text-center">#</th>
                    </tr>
                    </thead>
                    <tbody >
                    <tr class="table-primary" *ngFor="let library of this.libraryList; let i = index;">
                        <td class="text-left" (click)="setLibraryRelation(library)"> <img src="/assets/images/library.svg" class="me-3" style="width: 30px;" />{{library.title}}</td>
                        <td (click)="setLibraryRelation(library)" >{{library.created_date|utcToLocalTime}}</td>
                        <td class="text-right">
                            <i *ngIf="SelectedLibraryListIncluded(library)" class="fa fa-check me-3" aria-hidden="true"  ></i>
                            <a class=" text-center me-3 pointer" style="text-decoration: none" [attr.data-bs-target]="'#'+getDetailModalId(documentId, publicationListAnalysis, id)"  (click)="fillLibraryIdList(library.id)"  data-bs-toggle="modal"
                               title="{{'Detail' | localize}}">
                                <i class="fas fa-search fsize-3 expandcolor"></i>
                            </a>
                            <a  class="me-3 fsize-1 pointer"   title="{{'Edit' | localize}}" [attr.data-bs-target]="'#'+getCreatedModalId(documentId, publicationListAnalysis, id)"  data-bs-toggle="modal">
                                <i class="fas fa-pen fsize-3 expandcolor" (click)="setLibrary(library)"></i>
                            </a>
                            <a class=" text-center expandcolor me-3 fsize-1 pointer" title="{{'Delete' | localize}}" (click)="deleteLibraryConfirmend(library.id)">
                                <i class="fas fa-trash fsize-3 text-danger "></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                                <trd-paginater [pageList]="[10,20,50]"  [pageModel]="libraryPageModel" (pageChangeEvent)="getLibraryPage($event)">
                                </trd-paginater>
            </div>
        </div>
    </div>
</div>




<div class="modal fade" [id]="getCreatedModalId(documentId, publicationListAnalysis, id)" aria-hidden="true" data-bs-backdrop="false" aria-labelledby="libraryCreatedModal" tabindex="-1" >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" >{{'MyLibrary' | localize}}</h5>
                <button  type="button"
                         class="btn-close" data-bs-dismiss="modal" #CreatedModal [attr.data-bs-target]="'#'+getDynamicId(documentId, publicationListAnalysis, id)"  data-bs-toggle="modal"  aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group mt-3">
                    <label>{{'LibraryTitle' |localize}}<i class="text-danger">*</i></label>
                    <input type="text" class="form-control" [(ngModel)]="selectedLibrary.title">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary mx-2" (click)="save()" >
                    {{'Save'|localize}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [id]="getDetailModalId(documentId, publicationListAnalysis, id)" aria-hidden="true" data-bs-backdrop="false" aria-labelledby="libraryCreatedModal" tabindex="-1" >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" >{{'Publications' | localize}}</h5>
                <button  type="button"
                         class="btn-close" data-bs-dismiss="modal"  [attr.data-bs-target]="'#'+getDynamicId(documentId, publicationListAnalysis, id)" (click)="clearRelationModal()"  data-bs-toggle="modal"  aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div >
                        <small class="text-muted libraryPublication">
                            <app-publication-list [libraryView]="true" [libraryId]="publicationLibraryId" [publicationList]="libraryPublicationList" (reloadRequest)="fillLibraryIdList(this.publicationLibraryId)"></app-publication-list>
                            <trd-paginater [pageList]="[10,20,50]" [pageModel]="libraryPublicationPageModel"
                                           (pageChangeEvent)="changeLibraryResultPage($event)">
                            </trd-paginater>
                        </small>
                </div>
            </div>
        </div>
    </div>
</div>
<a *ngIf="!addFavorite && publicationListAnalysis==undefined"
   class="text-white pointer"
   data-bs-toggle="modal"
   [attr.data-bs-target]="'#' + getDynamicId(documentId, publicationListAnalysis, id)"
   role="button"
   (click)="getLibraryPage()"
   title="{{'MyLibrary'|localize}}">
    <i class="fa fa-book fsize-10"></i>
    <span class="ms-2">{{'MyLibrarys'|localize}}</span>
</a>