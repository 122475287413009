import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {BaseDTO} from "../../app/core/BaseDTO";

@Injectable({ providedIn: 'root' })
export abstract class AbstractService<DTO extends BaseDTO> {
  protected constructor(protected http: HttpClient) {}

  getApiURL() {
  }

  public create(dto: DTO) {
    return this.http.post(`${environment.apiUrl}${this.getApiURL()}/`, dto, { observe: 'response' }).pipe(
      filter((res) => res.ok),
      map((res) => res.body)
    );
  }

  public update(dto: DTO,id:any) {
    return this.http.patch(`${environment.apiUrl}${this.getApiURL()}/${id}`, dto, { observe: 'response' , headers: {"Content-Type": "application/json-patch+json"}}).pipe(
      filter((res) => res.ok),
      map((res) => res.body["data"])
    );
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}${this.getApiURL()}/${id}`, { observe: 'response' }).pipe(
      filter((res) => res.ok),
      map((res) => res.body)
    );
  }

  public findById(id: number) {
    return this.http.get(`${environment.apiUrl}${this.getApiURL()}/${id}`, { observe: 'response' }).pipe(
      filter((res) => res.ok),
      map((res) => res.body["data"])
    );
  }

  public getpage(size:number, page:number, sortField: string, sortType: string, data: any) {
    return this.http.post(`${environment.apiUrl}${this.getApiURL()}/filter?size=${size}&page=${page}&sortField=${sortField}&sortType=${sortType}`, data, { observe: 'response' }).pipe(
        filter((res) => res.ok),
        map((res) => res.body)
      );
  }
}
