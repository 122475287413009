import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageModel} from "./PageModel";

@Component({
  selector: 'trd-paginater',
  templateUrl: './paginater.component.html',
  styleUrls: ['./paginater.component.scss']
})
export class PaginaterComponent implements OnInit {

  @Input()  pageList: Array<number>;
  @Input() hideSizeCombo: boolean;
  @Input() hideTotalCount=false;
  @Input() pageModel: PageModel;
  @Output() pageChangeEvent = new EventEmitter();

  // export
  @Input() exportDataList: any[];


  constructor() {
    this.hideSizeCombo = false;
  }

  ngOnInit(): void {
  }


  changePage(_page: number) {
    this.pageModel.currentPage = _page;
    this.pageChangeEvent.emit(this.pageModel);
  }

  changeBySelectedPage(event) {
    if(event && event.target.value) {
      if (event.target.value < 1) {
        event.target.value = 1;
      }
      if (event.target.value > this.pageModel.totalPages) {
        event.target.value = this.pageModel.totalPages;
      }

      this.changePage(event.target.value);
    }
  }
  onInputBlur(event) {
    // Enter tuşuna basılmamışsa ve input alanından çıkılmışsa
    if (!event || event.key !== 'Enter') {
      this.changeBySelectedPage(event);
    }
  }

  changeSize() {
    this.changePage(1);
  }


  protected readonly Number = Number;
}
