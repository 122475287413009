export class MylibraryRelationDTO{
    id: string;
    created_date:Date;
    updated_date:Date;
    updated_by:string;
    document_id:number;
    document_type:string;
    library_id:string;
    url:string;

    //transient
    libraryName:string

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        this.id=obj.id;
        this.created_date=obj.created_date;
        this.updated_date=obj.updated_date;
        this.document_id=obj.document_id;
        this.document_type=obj.document_type;
        this.library_id=obj.library_id;
        this.updated_by=obj.updated_by;
        this.url=obj.url;
    }
}