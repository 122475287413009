import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";
import {JournalDTO} from "./JournalDTO";


export class JournalSearchResult extends SearchAbstractDTO {
    public journals: JournalDTO[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        if (obj.journals) {
            this.journals = [];
            for (let i = 0; i < obj.journals.length; i++) {
                let _journal = new JournalDTO();
                _journal.bindData(obj.journals[i]);
                this.journals.push(_journal);
            }
        }
    }


}
