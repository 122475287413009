

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'loading-component',
  template: `
    <div style="position: fixed; top: 0; left: 0; z-index: 999998; background-color: #333; opacity: .7"
         class="w-100 h-100"></div>
    <div [@openClose] style="position:fixed; z-index: 1000000; top: calc(50% - 100px); left: calc(50% - 50px); transform: scale(1.7)">
      <div class="loader book">
        <figure class="page"></figure>
        <figure class="page"></figure>
        <figure class="page"></figure>
        <figure class="page"></figure>
        <figure class="page"></figure>
      </div>
      <div style="text-align: center; position: relative; top: -100px;">
        <span class="let1">T</span>
        <span class="let2">R</span>
        <span class="let3"> </span>
        <span class="let4">D</span>
        <span class="let5">İ</span>
        <span class="let6">Z</span>
        <span class="let7">İ</span>
        <span class="let8">N</span>
      </div>
    </div>
  `,
  animations: [trigger('openClose', [
    state('*', style({ opacity: 1 })),
    state('void', style({ opacity: 0 })),
    transition('void => *', animate('0.25s ease-in-out')),
  ])]
})

export class LoadingComponent {


  constructor() {
  }

  ngOnInit() {

  }



}
