<div class="container mx-auto" *ngIf="selectedAuthor">
    <div class="row d-flex justify-content-around mx-auto">
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11  col-11 mx-auto">
            <div class="w-100 d-flex ">

                <div class="float-left flex-fill  d-flex  justify-content-start flex-wrap">
                    <div class="d-flex">
                        <img *ngIf="selectedAuthor.status=='REAL'" class="author-img"
                             src="/assets/images/author-verified.svg">
                        <img *ngIf="selectedAuthor.status=='POOL'" class="author-img"
                             src="/assets/images/author.svg">
                        <h1 class="title light-blue fsize-18 mt-auto ms-1 mb-0 ">{{selectedAuthor.fullName}}</h1>
                    </div>
                    <small *ngIf="selectedAuthor.orcid" class="mt-auto mb-1 ms-1">
                        <label><img class="me-1" src="/assets/images/orcid_16x16.webp">
                            <a class="mt-1" target="_blank"
                               href="https://orcid.org/{{selectedAuthor.orcid}}">{{selectedAuthor.orcid}}</a></label>
                    </small>
                </div>

<!--                <div class="float-left flex-fill">-->
<!--                    <a id="followCitation" class="ms-2 float-right float-end mt-auto not-printed text-decoration-none">-->
<!--                        <i-->
<!--                                class="fas fa-rss fsize-10" aria-hidden="true"><label class="pointer ms-1"></label> </i></a>-->
<!--                </div>-->
            </div>

            <hr class="mt-1 mb-3">
            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6  mx-auto">
                    <div class="row d-flex flex-wrap justify-content-around mx-auto">
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center ">
                            <label>{{'paperCount'|localize}}</label>
                            <label class=" fsize-2  mt-auto">{{getPaperCountByDocType('PAPER')}}</label>
                        </div>

                        <div class=" col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'projectCount'|localize}}</label>
                            <label class="mt-auto fsize-2">{{getPaperCountByDocType('PROJECT')}}</label>
                        </div>

                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'hindex'|localize}}</label>
                            <label class="mt-auto fsize-2">{{selectedAuthor.hindex
                                }}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'totalCitationCount'| localize}} <i class="fa fa-info-circle d-inline"
                                                                         title="{{'AuthorCitationInfo'|localize}}"></i></label>
                            <label class="mt-auto fsize-2">{{getTotalCitationCountByFields()}}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'citationAvg'|localize}}</label>
                            <label class="mt-auto fsize-2">{{getCitationAvg()}}</label>
                        </div>
                        <div class="col-3 citation-card d-flex flex-column me-2 mb-3  px-3 py-4 text-center">
                            <label>{{'numberSelfReferences'|localize}}</label>
                            <label class="mt-auto fsize-2">{{getTotalSelfCitationCountByFields() + ' (%' + getSelfCitationRatio() + ")"}}</label>

                        </div>

                    </div>
                </div>

                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart ">
                    <label class=" chart " role="title">{{"PublicationAndCitationNumbers"|localize}}</label>
                    <div class="custom-border  d-flex justify-content-center align-items-center w-100">
                        <div class=" mt-1" style="width: 100%; height:300px;" echarts
                             *ngIf="chartOptionPublicationAndCitationCountsOfYears && chartOptionPublicationAndCitationCountsOfYears.series && chartOptionPublicationAndCitationCountsOfYears.series[0] && chartOptionPublicationAndCitationCountsOfYears.series[0].data.length>0"
                             [options]="chartOptionPublicationAndCitationCountsOfYears"></div>
                        <div *ngIf="chartOptionPublicationAndCitationCountsOfYears && chartOptionPublicationAndCitationCountsOfYears.series && (!chartOptionPublicationAndCitationCountsOfYears.series[0] || chartOptionPublicationAndCitationCountsOfYears.series[0].data.length==0)"
                             class=" ">
                            <label class=" fsize-15  text-muted ">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"
                        ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
            </div>

            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart mx-auto">
                    <label class="wrapper chart" role="title">{{"AuthorInstitutionAndNUmber"|localize}}</label>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionInstitutionPublicationCount && chartOptionInstitutionPublicationCount.series && chartOptionInstitutionPublicationCount.series[0] && chartOptionInstitutionPublicationCount.series[0].data.length>0"
                             [options]="chartOptionInstitutionPublicationCount"></div>
                        <div>
                            <label class=" fsize-15  text-muted"
                                   *ngIf="chartOptionInstitutionPublicationCount && chartOptionInstitutionPublicationCount.series && (!chartOptionInstitutionPublicationCount.series[0] || chartOptionInstitutionPublicationCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>

                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"
                        ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>

                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart  mx-auto">
                    <label class="" role="title">{{"InstitutionCollaborationAndNUmber"|localize}}</label>
                    <div class="custom-border  d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionInstitutionCollaborationCount && chartOptionInstitutionCollaborationCount.series && chartOptionInstitutionCollaborationCount.series[0] && chartOptionInstitutionCollaborationCount.series[0].data.length>0"
                             [options]="chartOptionInstitutionCollaborationCount"></div>
                        <div>
                            <label class="fsize-15 text-muted"
                                   *ngIf="chartOptionInstitutionCollaborationCount && chartOptionInstitutionCollaborationCount.series && (!chartOptionInstitutionCollaborationCount.series[0] || chartOptionInstitutionCollaborationCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"
                        ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>

            </div>


            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart  mx-auto">
                    <label class="" role="title">{{"PublicationSubject"|localize}}</label>
                    <div class=" custom-border d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionPublicationSubjectCount && chartOptionPublicationSubjectCount.series && chartOptionPublicationSubjectCount.series[0] && chartOptionPublicationSubjectCount.series[0].data.length>0"
                             [options]="chartOptionPublicationSubjectCount"></div>
                        <div>
                            <label class=" fsize-15  text-muted"
                                   *ngIf="chartOptionPublicationSubjectCount && chartOptionPublicationSubjectCount.series && (!chartOptionPublicationSubjectCount.series[0] || chartOptionPublicationSubjectCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>

                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"
                        ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>

                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 chart  mx-auto">
                    <label class="" role="title">{{"AuthorCollaboration"|localize}}</label>
                    <div class="custom-border d-flex justify-content-center align-items-center">
                        <div class="mt-1" style="width: 100%;" echarts
                             *ngIf="chartOptionAuthorCollaborationCount && chartOptionAuthorCollaborationCount.series && chartOptionAuthorCollaborationCount.series[0] && chartOptionAuthorCollaborationCount.series[0].data.length>0"
                             [options]="chartOptionAuthorCollaborationCount"></div>
                        <div>
                            <label class="fsize-15 text-muted"
                                   *ngIf="chartOptionAuthorCollaborationCount && chartOptionAuthorCollaborationCount.series && (!chartOptionAuthorCollaborationCount.series[0] || chartOptionAuthorCollaborationCount.series[0].data.length==0)">{{'NoData'|localize}}</label>
                        </div>
                    </div>
                    <div class="filigran-brand text-right"><a href="https://trdizin.gov.tr">"{{'chartData' | localize}}"
                        ©{{getCurrentYear()}} - TR
                        Dizin </a></div>
                </div>
            </div>

            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 mx-auto">
                    <div class="wrapper chart">
                        <label class="" role="title">{{"IncomingCitationGraph"|localize}} <i
                                class="fa fa-info-circle d-inline" title="{{('IncomingCitationGraphInfo')|localize}}"
                                aria-hidden="true"></i></label>
                    </div>
                    <div id="inComingCitationNetworkId" #inComingCitationNetwork
                         style="  width: 100%; height: 600px; border: 1px solid lightgray;"></div>
                </div>
            </div>

            <div class="row">
                <div class="wrapper chart">
                    <label class="" role="title">{{"OutgoingCitationGraph"|localize}} <i
                            class="fa fa-info-circle d-inline" title="{{('OutgoingCitationGraphInfo')|localize}}"
                            aria-hidden="true"></i></label>
                </div>

                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 mx-auto">
                    <div id="outGoingCitationNetworkId" #outGoingCitationNetwork
                         style="  width: 100%; height: 600px; border: 1px solid lightgray;"></div>
                </div>
            </div>

            <div class="row">
                <div class=" col-12 col-xs-12 col-sm-12 col-md-12 mx-auto">
                    <div class="wrapper chart">
                        <label class=" " role="title">{{"AuthorCollaborationGraph"|localize}} <i
                                class="fa fa-info-circle d-inline" title="{{('AuthorCollaborationGraphInfo')|localize}}"
                                aria-hidden="true"></i></label>
                    </div>
                    <div id="collaborationNetworkId" #collaborationNetwork
                         style="  width: 100%; height: 600px; border: 1px solid lightgray;"></div>
                </div>
            </div>
            <div class="row">
                <small class="mx-auto">
                    <strong>
                        <ul class="mt-3 nav nav-tabs nav-justified" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link active" id="justified-tab-0" data-bs-toggle="tab"
                                   href="#publication-panel" role="tab" aria-controls="publication-panel"
                                   aria-selected="true"
                                   (click)="fillPublicationIdList()">{{"PublicationsOfAuthor"|localize}} </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link " id="justified-tab-1" data-bs-toggle="tab"
                                   href="#journal-citation-panel"
                                   (click)="fillAuthorCitationIdList(this.publicationFields)"
                                   role="tab" aria-controls="citation-panel"
                                   aria-selected="false"> {{"ReferencesOfAuthor"|localize}}<i
                                        class="ms-1 fa fa-info-circle"
                                        title="{{'CitingPublicationsInfoTitle'|localize}}"></i> </a>
                            </li>
                        </ul>
                    </strong>
                </small>
                <div class="tab-content mt-3 mx-auto" id="tab-content">
                    <div class="tab-pane active mx-auto" id="publication-panel" role="tabpanel"
                         aria-labelledby="justified-tab-1">
                        <app-publication-list [publicationList]="publicationList"></app-publication-list>
                        <div class="w-100 row  mt-2">
                            <div class="col-12 col-md-9">
                                <trd-paginater [pageList]="[20,50,100]" [pageModel]="publicationPageModel"
                                               (pageChangeEvent)="changePublicationResultPage($event)">
                                </trd-paginater>
                            </div>
                            <div *ngIf="publicationList && publicationList.length>0"
                                 class="col-12 col-md-3 float-right text-right">
                                <export-component [fieldSelect]="false" [documentType]="'publication'"
                                                  [documentList]="publicationList"
                                ></export-component>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane  mx-auto" id="journal-citation-panel" role="tabpanel"
                         aria-labelledby="justified-tab-1">
                        <app-publication-list [publicationList]="authorCitationList"></app-publication-list>
                        <div class="w-100 row  mt-2">
                            <div class="col-12 col-md-9">
                                <trd-paginater [pageList]="[20,50,100]" [pageModel]="authorCitationPageModel"
                                               (pageChangeEvent)="changeAuthorCitationResultPage($event)">
                                </trd-paginater>
                            </div>
                            <div *ngIf="authorCitationList && authorCitationList.length>0"
                                 class="col-12 col-md-3 float-right text-right">
                                <export-component [fieldSelect]="false" [documentType]="'publication'"
                                                  [documentList]="authorCitationList"
                                ></export-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
