import {Component, Input, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {SavedSearchDTO} from "../save-search/SavedSearchDTO";
import {SaveSearchService} from "../save-search/save-search.service";
import {AuthorizationService} from "../../Authorization/AuthorizationService";
import {ToastrService} from "ngx-toastr";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {AuthInfoDTO} from "../../../trdcore/dto/AuthInfoDTO";
import {ModalAlertService} from "../../../trdcore/service/ModalAlertService";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";

@Component({
  selector: 'app-saved-search-list',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    TrdCoreModule
  ],
  templateUrl: './saved-search-list.component.html',
  styleUrl: './saved-search-list.component.scss'
})
export class SavedSearchListComponent implements OnInit{
  @Input() savedSearchList: SavedSearchDTO[] = [];
  @Input() savedSearchPageModel=new PageModel(10);

  clearModal() {
    this.selectedSavedSearch = new SavedSearchDTO();
    this.ngOnInit();
  }


  selectedSavedSearch: SavedSearchDTO=new SavedSearchDTO();
  activeUser :AuthInfoDTO

  constructor(private saveSearchService: SaveSearchService,
              private authorizationService: AuthorizationService,
              private toastrService: ToastrService,
              private localizationService: LocalizationService,
              private modalService: ModalAlertService) {
  }

  ngOnInit(): void {
    this.activeUser=this.authorizationService.getActiveUser();
  }

  changeActive(id: string, doNotification: boolean){
    this.saveSearchService.changeActive(id, doNotification).subscribe(res => {
      if (res != null){
        this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessUpdate'), this.localizationService.getLocalizationValue('Success'));
      }
    });
  }

  deleteSavedSearch(id: string) {
    this.modalService.confirmBox(
        this.localizationService.getLocalizationValue('Confirmation'),
        this.localizationService.getLocalizationValue('AreYouSureDelete'),
        this.deleteSaveSearch.bind(this,id),null);
  }

  deleteSaveSearch(id){
    this.saveSearchService.deleteSavedSearch(id).subscribe(res=>{
      this.toastrService.success(this.localizationService.getLocalizationValue('GeneralSuccessDelete'), this.localizationService.getLocalizationValue('Success'));
      this.getSavedSearchPage();
    })
  }

  getSavedSearchPage(event?){
    if(event){
      this.savedSearchPageModel.currentPage = event.currentPage;
      if (this.savedSearchPageModel.size != event.size) {
        this.savedSearchPageModel.currentPage = 1;
      }
      if (event.size) {
        this.savedSearchPageModel.size = event.size;
      }
    }
    if(this.activeUser.userId){
      this.saveSearchService.getSavedSearchUserById(this.activeUser.userId,this.savedSearchPageModel.currentPage,this.savedSearchPageModel.size).subscribe(res=>{
        if(res["data"]){
          this.savedSearchList=[];
          for(let i=0;i<res["data"].length;i++){
            let _savedSearch=new SavedSearchDTO();
            _savedSearch.bindData(res["data"][i]);
            this.savedSearchList.push(_savedSearch);
          }
          this.savedSearchPageModel.totalCount=res["totalCount"];
          this.savedSearchPageModel.totalPages = Math.ceil(this.savedSearchPageModel.totalCount / this.savedSearchPageModel.size);
        }else{
          this.savedSearchList=[];
          this.savedSearchPageModel.totalCount=res["totalCount"];
          this.savedSearchPageModel.totalPages = Math.ceil(this.savedSearchPageModel.totalCount / this.savedSearchPageModel.size);
        }
      })
    }
  }
}
