import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {filter, map} from "rxjs/operators";
import {MylibraryDTO} from "./MylibraryDTO";
import {MylibraryRelationDTO} from "./MylibraryRelationDTO";


@Injectable({providedIn: 'root'})
export abstract class LibraryService {
    protected constructor(protected http: HttpClient) {
    }

    getLibraryUserById(userId,page: number, pageSize: number) {
        let params = new HttpParams();
        params = params.append('page', String(page));
        params = params.append('pageSize', String(pageSize));
        return this.http.get(environment.apiUrl + "/api/library/libraryUserById/" + userId,{params:params});
    }
    getLibraryPublicationLibraryById(libraryId){
        return this.http.get(environment.apiUrl + "/api/library/libraryDocumentLibraryById/" + libraryId);
}
    getLibraryPublicationDocumentByIdAndUserId(documentId,userId){
        return this.http.get(environment.apiUrl + "/api/library/libraryDocumentByDocumentId/" + documentId+"/user/"+userId);
    }
    deleteLibraryPublicationDocumentByIdAndUserId(documentId,userId,libraryId){
        return this.http.delete(environment.apiUrl + "/api/library/libraryDocumentByDocumentId/" + documentId+"/user/"+userId+"/libraryId/"+libraryId);
    }
    AddLibrary(dto:MylibraryDTO){
        return this.http.post(environment.apiUrl+"/api/library/add/", dto, { observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
    deleteLibrary(id){
        return this.http.delete(environment.apiUrl+"/api/library/delete/"+id,{ observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
    updateLibrary(id,title){
        return this.http.put(environment.apiUrl+"/api/library/update/"+id+`?title=${title}`,null,{ observe: 'response' });
    }
    LibraryDocumentAdd(libraryRelation:MylibraryRelationDTO){
        return this.http.post(environment.apiUrl+"/api/library/add/DocumentRelation/", libraryRelation, { observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
    LibraryDocumentListAdd(libraryRelationList:MylibraryRelationDTO[]){
        return this.http.post(environment.apiUrl+"/api/library/add/DocumentRelations/", libraryRelationList, { observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
}