import {BaseDTO} from "../../core/BaseDTO";

export class PublicationAbstractDTO extends BaseDTO {
    public id: string;
    public title: string;
    public abstract: string;
    public language: string;
    public keywords: string[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.id = obj.id;
        this.title = obj.title;
        this.abstract = obj.abstract;
        this.language = obj.language;
        this.keywords=obj.keywords;
    }


}
