import {BaseDTO} from "../../core/BaseDTO";


export class MylibraryDTO {
     id: string;
     created_date:Date
     updated_date:Date
     updated_by:string
     title:string;
     user_id:string;
     bindData(obj: any): void {
          if (obj == null) {
               return;
          }
          this.id=obj.id;
          this.created_date=obj.created_date;
          this.updated_date=obj.updated_date;
          this.title=obj.title;
          this.user_id=obj.user_id;
          this.updated_by=obj.updated_by;
     }
     setGmt3CreatedConvert(date: string): void {
               let convertedDate = new Date(date);
               this.created_date = convertedDate;
     }
     setGmt3UpdatedConvert(date: string): void {
          let convertedDate = new Date(date);
          this.updated_date = convertedDate;
     }

}