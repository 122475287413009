import {BaseDTO} from "../../core/BaseDTO";

export class SearchAbstractDTO extends BaseDTO {
    public took: number;
    public totalCount: number;
    public maxScore: number;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.took = obj.took;
        this.totalCount = obj.totalCount;
        this.maxScore = obj.maxScore;
    }


}
