import {SearchAbstractDTO} from "./Abstract/SearchAbstractDTO";
import {getCurrentYear} from "../app.module";

export class AdvanceSearchDTO extends SearchAbstractDTO {

    private languageMap =
        new Map([
            ["Türkçe", "TUR"],
            ["Turkish", "TUR"],
            ["İngilizce", "ENG"],
            ["English", "ENG"],
            ["İspanyolca", "SPA"],
            ["Spanish", "SPA"],
            ["İtalyanca", "ITA"],
            ["Italian", "ITA"],
            ["Almanca", "GER"],
            ["German", "GER"],
            ["Rusça", "RUS"],
            ["Russian", "RUS"],
            ["Arapça", "ARA"],
            ["Arabic", "ARA"],
            ["Diğer", "OTH"],
            ["Other", "OTH"],
            ["Kazakça", "KAZ"],
            ["Kazakh", "KAZ"],
            ["Kürtçe", "KUR"],
            ["Kurdish", "KUR"],
            ["Azerice", "AZE"],
            ["Azerbaijani", "AZE"],
            ["Fransızca", "FRE"],
            ["French", "FRE"],
            ["Hırvatça", "HRV"],
            ["Croatian", "HRV"],
            ["Lehçe", "POL"],
            ["Polish", "POL"],
        ]);

    public field: string;
    public advanceSearchContainsAllWord: string[] = [];
    public advanceSearchContainsAnyWord: string[] = [];
    public advanceSearchContainsAsIs: string[] = [];
    public advanceSearchNotContainsAsIs: string[] = [];
    public advanceSearchFromYear: string = '';
    public advanceSearchToYear: string = '';
    public advanceSearchYearNotContains: string[] = [];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.field = obj.field;
        this.advanceSearchContainsAllWord = obj.advanceSearchContainsAllWord;
        this.advanceSearchContainsAnyWord = obj.advanceSearchContainsAnyWord;
        this.advanceSearchContainsAsIs = obj.advanceSearchContainsAsIs;
        this.advanceSearchNotContainsAsIs = obj.advanceSearchNotContainsAsIs;
        this.advanceSearchFromYear = obj.advanceSearchFromYear;
        this.advanceSearchToYear = obj.advanceSearchToYear;
        this.advanceSearchYearNotContains = obj.advanceSearchYearNotContains;
    }

    getQuery(): string {
        let queryList: string[] = [];
        let query = this.field + " : ";
        query += "(";

        if (this.advanceSearchContainsAllWord) {
            let _query = '';
            for (let i = 0; i < this.advanceSearchContainsAllWord.length; i++) {
                let word = this.advanceSearchContainsAllWord[i]
                if (word && word.trim() != '') {
                    word =this.replaceColons(word);
                    if (i > 0) {
                        _query += " AND "
                    }
                    _query += " \"" + word + "\" ";
                }
            }
            if (_query && _query.trim() != '') {
                queryList.push(_query);
            }
        }

        if (this.advanceSearchContainsAnyWord) {
            let _query = '';
            for (let i = 0; i < this.advanceSearchContainsAnyWord.length; i++) {
                let word = this.advanceSearchContainsAnyWord[i]
                if (word && word.trim() != '') {
                    word =this.replaceColons(word);
                    if (i > 0) {
                        _query += " OR "
                    }
                    _query += "\"" + word + "\"";
                }
            }
            if (_query && _query.trim() != '') {
                queryList.push("(" + _query + ")");
            }
        }

        if (this.advanceSearchContainsAsIs) {
            let _query = '';
            for (let i = 0; i < this.advanceSearchContainsAsIs.length; i++) {
                let word = this.advanceSearchContainsAsIs[i]
                if (word && word.trim() != '') {
                    word =this.replaceColons(word);
                    if (i > 0 && this.field == 'language') {
                        _query += " OR "
                    }else if (i>0){
                        _query += " AND "
                    }
                    if (this.field == 'language') {
                        word = this.languageMap.get(word);
                    }
                    _query += " \"" + word + "\" ";
                }
            }
            if (_query && _query.trim() != '') {
                queryList.push(_query);
            }
        }


        if (this.advanceSearchNotContainsAsIs) {
            let _query = '';
            for (let i = 0; i < this.advanceSearchNotContainsAsIs.length; i++) {
                let word = this.advanceSearchNotContainsAsIs[i]
                if (word && word.trim() != '') {
                    word =this.replaceColons(word);
                    if (i > 0) {
                        _query += " AND "
                    }
                    _query += "-(\"" + word + "\")";
                }
            }
            if (_query && _query.trim() != '') {
                queryList.push(_query);
            }
        }


        if (this.advanceSearchFromYear && this.advanceSearchToYear) {
            queryList.push("[" + this.advanceSearchFromYear + " TO " + this.advanceSearchToYear + "]");
        } else if (this.advanceSearchFromYear) {
            queryList.push("[" + this.advanceSearchFromYear + " TO " + (getCurrentYear() + 1) + "]");
        } else if (this.advanceSearchToYear) {
            queryList.push("[1900 TO " + this.advanceSearchToYear + "]");
        }


        if (this.advanceSearchYearNotContains) {
            let _query = '';
            for (let i = 0; i < this.advanceSearchYearNotContains.length; i++) {
                let word = this.advanceSearchYearNotContains[i]
                if (word && word.trim() != '') {
                    word =this.replaceColons(word);
                    _query += "-" + word + " ";
                }

            }
            if (_query && _query.trim() != '') {
                queryList.push(_query);
            }
        }

        for (let i = 0; i < queryList.length; i++) {
            let word = queryList[i]
            if (word && word.trim() != '') {
                if (i > 0) {
                    query += " AND "
                }
                query += word;
            }
        }


        query += ")";


        return query;
    }

    replaceColons(input: string): string {
        return input.replace(/:/g, '/:');
    }
}
