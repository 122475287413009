import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";

export class JournalYearSubjectDTO extends SearchAbstractDTO {
    public id: string;
    public rootId: string;
    public name: string;
    public fullName: string;
    public rootName: string;
    public autoLabel: string;


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.rootId = obj.rootId;
        this.name = obj.name;
        this.fullName = obj.fullName;
        this.rootName = obj.rootName;
        this.autoLabel = obj.autoLabel;
    }


}
