import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";
import {InstitutionDTO} from "./InstitutionDTO";


export class InstitutionSearchResult extends SearchAbstractDTO {
    public institutions: InstitutionDTO[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        if (obj.institutions) {
            this.institutions = [];
            for (let i = 0; i < obj.institutions.length; i++) {
                let _institution = new InstitutionDTO();
                _institution.bindData(obj.institutions[i]);
                this.institutions.push(_institution);
            }
        }
    }


}
