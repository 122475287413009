import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthInfoDTO } from 'src/trdcore/dto/AuthInfoDTO';
import {AuthorizationService} from "./AuthorizationService";


@Directive({
  selector: '[hasAuth]'
})

export class HasAuthDirective{
  private authorities: string[];

  constructor(
    private authorizationService: AuthorizationService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set hasAuth(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    if(this.authorizationService.getActiveUser()){
      this.updateView(this.authorizationService.getActiveUser());
    } else{
      this.updateView(null);
    }
    // Get notified each time authentication state changes.
    this.authorizationService.activeUser.subscribe(u => this.updateView(u));
  }

  private updateView(activeUser?: AuthInfoDTO): void {
    let hasAnyAuthority = false;
    if(activeUser){
      hasAnyAuthority = this.hasAnyAuthority(activeUser.authorities);
    } else{
    }

    this.viewContainerRef.clear();

    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private hasAnyAuthority(userAuthorities?: string[]): boolean {
    let hasAuthorized = false;
    if(userAuthorities){
      this.authorities.forEach(auth => {
        if(userAuthorities.indexOf(auth) > -1){
          hasAuthorized = true;
        }
      });
    }
    return hasAuthorized;
  }
}
