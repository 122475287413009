import {PublicationAbstractDTO} from "./PublicationAbstractDTO";
import {PublicationAuthorDTO} from "./PublicationAuthorDTO";
import {PublicationIssueDTO} from "./PublicationIssueDTO";
import {PublicationReferenceDTO} from "./PublicationReferenceDTO";
import {PublicationJournalDTO} from "./PublicationJournalDTO";
import {PublicationSubjectDTO} from "./PublicationSubjectDTO";
import {BaseDTO} from "../../core/BaseDTO";
import {PublicationProductDTO} from "./PublicationProductDTO";

export class PublicationDTO extends BaseDTO {


    public id: string;
    public indexDate: string;
    public indexedBy: string;
    public createdBy: string;
    public publicationYear: string;
    public docType: string;
    public publicationType: string;
    public abstracts: PublicationAbstractDTO[];
    public authors: PublicationAuthorDTO[];
    public issue: PublicationIssueDTO;
    public references: PublicationReferenceDTO[];
    public publicationProduct: PublicationProductDTO[];
    public citedReferences: PublicationReferenceDTO[];
    public journal: PublicationJournalDTO;
    public startPage: string;
    public endPage: string;
    public accessType: string;
    public language: string;
    public pdf: string;
    public databases: string[];
    public attachments: string[];
    public firstIndexDate: string;
    public doi: string;
    public otherCitations: { origin: string, count: number }[] = [];
    public projectnumber: string;
    public projectGroup: string;
    public startedDate: string;
    public endDate: string;
    public viewCount: string;
    public downloadCount: string;
    public retreat: string;
    public apa: string;
    public subjects: PublicationSubjectDTO[];

    //transient
    showAuthorCollapse = false;
    showAbstractCollapse = false;

    //export
    abstractstitle: string;
    abstractsabstract: string;
    abstractskeywords: string;
    journalname: string;
    journalissn: string;
    journaleissn: string;
    issuevolume: string;
    issuenumber: string;
    issueyear: string;
    publicationLanguage: string;
    abstractContent: string;
    authorsname: string[];
    authorsinstitutionrootTitle: string[];
    subjectsname: string[];

    public highlight: any;
    score = 0.0;

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.indexDate = obj.indexDate;
        this.indexedBy = obj.indexedBy;
        this.createdBy = obj.createdBy;
        this.publicationYear = obj.publicationYear;
        this.docType = obj.docType;
        this.publicationType = obj.publicationType;
        this.startPage = obj.startPage;
        this.endPage = obj.endPage;
        this.accessType = obj.accessType;
        this.language = obj.language;
        this.pdf = obj.pdf;
        this.databases = obj.databases;
        this.attachments = obj.attachments;
        this.otherCitations = obj.otherCitations;
        this.firstIndexDate = obj.firstIndexDate;
        this.doi = obj.doi;
        this.projectnumber = obj.projectnumber;
        this.projectGroup = obj.projectGroup;
        this.startedDate = obj.startedDate;
        this.endDate = obj.endDate;
        this.viewCount = obj.viewCount;
        this.downloadCount = obj.downloadCount;
        this.retreat = obj.retreat;
        this.apa = obj.apa;


        if (obj.issue) {
            this.issue = new PublicationIssueDTO();
            this.issue.bindData(obj.issue);
        }

        if (obj.journal) {
            this.journal = new PublicationJournalDTO();
            this.journal.bindData(obj.journal);
        }

        if (obj.abstracts) {
            this.abstracts = [];
            for (let i = 0; i < obj.abstracts.length; i++) {
                let _abstracts = new PublicationAbstractDTO();
                _abstracts.bindData(obj.abstracts[i]);
                this.abstracts.push(_abstracts);
            }
            // this.abstracts = this.abstracts.sort((p1, p2) => (p2.language == 'TUR' && p2.title && p2.title.length > 0) ? 1 : -1);
            if (this.abstracts.length > 1 && this.language) {
                if (this.abstracts[0].language == this.language && this.abstracts[0].title && this.abstracts[0].title.length > 0) {
                    // do nothing
                } else if(this.language){
                    this.abstracts = this.abstracts.sort((a, b) =>
                        a.language == this.language ? -1 : 1);
                }
            }
        }
        if (obj.authors) {
            this.authors = [];
            for (let i = 0; i < obj.authors.length; i++) {
                let _author = new PublicationAuthorDTO();
                _author.bindData(obj.authors[i]);
                this.authors.push(_author);
            }
            if (this.authors.length > 0) {
                this.authors =
                    this.authors.slice()
                        .sort((a, b) =>
                            a.order - b.order);
            }
        }

        if (obj.references) {
            this.references = [];
            for (let i = 0; i < obj.references.length; i++) {
                let _reference = new PublicationReferenceDTO();
                _reference.bindData(obj.references[i]);
                this.references.push(_reference);
            }
        }
        if (obj.publicationProduct) {
            this.publicationProduct = [];
            for (let i = 0; i < obj.publicationProduct.length; i++) {
                let _publicationProduct = new PublicationProductDTO();
                _publicationProduct.bindData(obj.publicationProduct[i]);
                this.publicationProduct.push(_publicationProduct);
            }
        }
        if (obj.citedReferences) {
            this.citedReferences = [];
            for (let i = 0; i < obj.citedReferences.length; i++) {
                let _reference = new PublicationReferenceDTO();
                _reference.bindData(obj.citedReferences[i]);
                this.citedReferences.push(_reference);
            }
        }
        if (obj.subjects) {
            this.subjects = [];
            for (let i = 0; i < obj.subjects.length; i++) {
                let _subject = new PublicationSubjectDTO();
                _subject.bindData(obj.subjects[i]);
                this.subjects.push(_subject);
            }
        }


        if (obj.highlight) {
            this.highlight = obj.highlight;
        }
        if (this.highlight) {
            let _highlight = this.highlight;
            if (_highlight["searchField"]) {
                let _searchField = _highlight["searchField"];
                _searchField.forEach(sf => {
                    let cleanedHighLight = sf.replaceAll("<strong class=\"highlight\">", "");
                    cleanedHighLight = cleanedHighLight.replaceAll("</strong>", "");
                    if (this.abstracts && this.abstracts.length > 0) {
                        this.abstracts.forEach(a => {
                            if (a.title == cleanedHighLight) {
                                a.title = sf;
                            } else if (a.abstract == cleanedHighLight) {
                                a.abstract = sf;
                            }
                        });
                    }
                    if (this.authors && this.authors.length > 0) {
                        this.authors.forEach(a => {
                            if (a.name == cleanedHighLight) {
                                a.name = sf;
                            }
                        });
                    }

                });
            }
        }

    }

    getCitationCount() {
        if (this.citedReferences && this.citedReferences.length > 0) {
            let _set = new Set();
            this.citedReferences.forEach(r => {
                _set.add(r.sourceId);
            });
            return _set.size;
        }
        return 0;
    }


    fillExportData(): void {
        if (!this.abstractstitle) {
            if (this.abstracts && this.abstracts.length > 0) {
                this.abstractstitle = this.abstracts[0].title;
            }
        }
        if (!this.abstractsabstract) {
            if (this.abstracts && this.abstracts.length > 0) {
                this.abstractsabstract = this.abstracts[0].abstract;
            }
        }
        if (!this.abstractskeywords) {
            if (this.abstracts && this.abstracts.length > 0 && this.abstracts[0].keywords && this.abstracts[0].keywords.length > 0) {
                this.abstractskeywords = this.abstracts[0].keywords.join(", ");
            }
        }

        if (!this.journalname && this.journal) {
            this.journalname = this.journal.name;
        }

        if (!this.journalissn && this.journal) {
            this.journalissn = this.journal.issn;
        }

        if (!this.journaleissn && this.journal) {
            this.journaleissn = this.journal.eissn;
        }

        if (!this.issuevolume && this.issue) {
            this.issuevolume = this.issue.volume;
        }

        if (!this.issuenumber && this.issue) {
            this.issuenumber = this.issue.number;
        }

        if (!this.issueyear) {
            this.issueyear = this.publicationYear;
        }

        if (this.language) {
            this.publicationLanguage = this.language;
        }
        if (!this.abstractContent && this.abstracts && this.abstracts.length > 0) {
            this.abstractContent = this.abstracts[0].abstract;
        }

        if (this.authors) {
            this.authorsname = [];
            this.authorsinstitutionrootTitle = [];
            this.authors.forEach(a => {
                this.authorsname.push(a.name);
                this.authorsinstitutionrootTitle.push(a.institutionName.toString().replace('/,/', ' || '));
            });
        }
        if (this.subjects) {
            this.subjectsname = [];
            this.subjects.forEach(s => {
                this.subjectsname.push(s.name);
            });
        }

    }

    cleanHighLight() {
        if (this.abstracts) {
            this.abstracts.forEach(a => {
                a.title = a.title.replaceAll("<strong class=\"highlight\">", "");
                a.title = a.title.replaceAll("</strong>", "");

                a.abstract = a.abstract.replaceAll("<strong class=\"highlight\">", "");
                a.abstract = a.abstract.replaceAll("</strong>", "");
            })
        }

        if (this.authors) {
            this.authors.forEach(a => {
                a.name = a.name.replaceAll("<strong class=\"highlight\">", "");
                a.name = a.name.replaceAll("</strong>", "");
            });
        }
    }

    public getApaStyleForAuthorsName() {
        if (this.authors && this.authors.length > 0) {


            const formattedAuthors = this.authors.map(author => {
                const name = author.name;
                const parts = name.split(' ');

                const lastName = parts[parts.length - 1].toUpperCase();
                const firstNames = parts.slice(0, parts.length - 1)
                    .map(part => part.charAt(0).toUpperCase() + '.')
                    .join(' ');

                return lastName + ', ' + firstNames;
            });

            const formattedNames = formattedAuthors.join(', ');
            return formattedNames + ' ';
        } else {
            return "";
        }
    }


}
