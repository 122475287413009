<div class="facet fade-in" style="border-radius: 10px;">
    <div class="form-floating mb-2">
        <input class="form-control" id="floatingInput" placeholder="ankara"
               style="background-color: whitesmoke; border-top: none; border-left: none; border-right: none; border-radius: 0"
               [(ngModel)]="facetSearchText"
               (ngModelChange)="searchByFacetKey($event)">
        <label for="floatingInput">{{'SearchForFilters' | localize}}</label>
    </div>

    <div *ngIf="getFacetFilterList() && getFacetFilterList().length > 0"
         class="facetList pt-0 pb-2"
         style="max-height: 20vh;">
        <div class="mx-auto sticky-filter-clear float-right mt-0 fsize-09">
            <a class="" (click)="clearFilters()">{{'clearAllFilter' | localize}}</a>
        </div>
        <br>
        <small class="dark-blue d-flex justify-content-between pb-0"
               *ngFor="let facet of getFacetFilterList()">
            <div class="mb-0 fsize-11">{{facet.key | localize}}:</div>
            <div class="pointer light-blue d-flex justify-content-end" style="overflow: hidden;"
                 (click)="removeFacetFilterByKeyValue(facet.key, facet.value)">
                <a class="mb-0 facet-value light-blue" style="max-width: 90%"
                   title="{{(facet.value | localize:true)}}">{{facet.value | localize}}
                </a>
                <i (click)="removeFacetFilterByKeyValue(facet.key, facet.value)"
                   class="fsize-08 ms-1 pointer fas fa-times pe-1"></i>
            </div>

        </small>
    </div>

    <div class="pt-0 pb-1" *ngFor="let facet of facetList">
        <div *ngIf="facet.valueList && facet.valueList.length > 0">
            <a class="card-title"
               data-bs-toggle="collapse" href="{{'#collapseFacetCard' + facet.order}}" role="button"
               aria-expanded="false">
                {{facet.key | localize:true}} ({{facet.valueList.length}})
            </a>
            <hr class="mt-0 text-muted">
            <div class="text-break collapsed collapse show facetList mt-1" id="{{'collapseFacetCard' + facet.order}}">
                <ul class="ms-0 ps-2 pt-1 facet-ul" *ngIf="facet.key != 'facet-subject'">
                    <li class="pb-0" *ngFor="let facetValue of facet.valueList; index as i">
                        <div class="d-flex justify-content-between">
                            <a *ngIf="facetValue.key && facetValue.key.length > 0" class="facet-button" title="{{(facetValue.key | localize:true)}}"
                               style="display: block; width: calc(100% - 55px);">
                                <label class="fs-5 facet-value" *ngIf="facet.key != 'facet-subject'" (click)="facetChangeClickLabel(facet.key, facetValue.key)"
                                       for="{{'collapseFacetValue' + facet.order + 'facetIndex' + i}}">
                                    <input class="me-1 mt-0 mb-1 p-2 form-check-input" type="checkbox"
                                           [attr.data-key]="facet.key"
                                           [checked]="isFacetSelected(facet.key, facetValue.key)"
                                           [attr.data-value]="facetValue.key"
                                           id="{{'collapseFacetValue' + facet.order + 'facetIndex' + i}}">
                                    {{facetValue.key | localize:true}}
                                </label>
                            </a>
                            <small *ngIf="facetValue.key && facetValue.key.length > 0" class="float-right fsize-1">
                                {{((facetValue.doc_count | number: '1.') + '').replace(',', '.')}}
                            </small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="text-break collapsed collapse show facetList" *ngIf="(facetSearchText == '' || (facetSearchText && facetSearchText.trim().length == 0))">
        <ul class="ms-0 ps-2 facet-ul">
            <li class="pb-0" *ngFor="let subject of facetSubjectList; index as i">
                <div>
                    <div>
                        <label (click)="toggleChildSubjects(subject)">{{subject.name | localize:true}}</label>
                    </div>
                    <ul *ngIf="subject.showChildren">
                        <li *ngFor="let child of subject.childrens">
                            <div>
                                <div>
                                    <label (click)="toggleChildSubjects(child)">
                                        {{child.name | localize:true}}
                                    </label>
                                </div>
                                <ul *ngIf="child.showChildren">
                                    <li *ngFor="let grandChild of child.childrens">
                                        <a class="facet-button">
                                            <label>
                                                <input class="me-1 mt-0 mb-1 p-2 form-check-input" type="checkbox"
                                                       [attr.data-key]="'facet-subject'"
                                                       (click)="facetChangeClickLabel('facet-subject', grandChild.hierarchySubject)"
                                                       [checked]="isFacetSelected('facet-subject', grandChild.hierarchySubject)"
                                                       [attr.data-value]="grandChild.hierarchySubject"
                                                       id="{{'collapseFacetValue' + 13 + 'facetIndex' + i}}">
                                                {{grandChild.name | localize:true}}
                                            </label>
                                            <small *ngIf="grandChild.doc_count" class="float-right me-2 fsize-1">
                                                {{'(' + ((grandChild.doc_count | number: '1.') + '').replace(',', '.') + ')'}}
                                            </small>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
    <div class="mx-auto sticky-filter text-center mt-3" *ngIf="newFacetSelected == true">
        <button class="mx-auto btn btn-primary filter-button"
                (click)="submitFilters()">{{'applyFilter' | localize}}</button>
    </div>
</div>
