import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from "rxjs/operators";
import {CookieService} from "../service/CookieService";
import {LoadingService} from "../service/LoadingService";
import {LocalizationService} from '../service/localizationService';
import {Router} from '@angular/router';
import {ConfigurationService} from "../service/ConfigurationService";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  exceptedUrlList: String[]=[
    '/widget/',
    '/account',
    '?loading=true',
    '&loading=true',
    '/notification/filter',
    '/correspondence/waiting-correspondence',
    '/ask-orcid',
    'notes/search-count'
  ];
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private localizationService: LocalizationService,
    private configurationService:ConfigurationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.checkUrlContainExceptedUrl(request.url)){
      this.loadingService.show(request.url);
    }

    return next.handle(request).pipe(tap(res => {

    }, err => {
      if(!this.checkUrlContainExceptedUrl(request.url)){
        this.loadingService.hide(request.url);
      } else {
      }


    }, () => {
      if(!this.checkUrlContainExceptedUrl(request.url)){
        this.loadingService.hide(request.url);
      } else {
      }
    }));
  }

  // logout() {
  //   this.authorizationService.resetActiveUser();
  //   localStorage.removeItem("Authorization");
  //   this.cookieService.deleteCookie("Authorization");
  //   window.location.href = environment.apiUrl + "/login?redirectUrl=" + window.location.href.replace("#", "%23");
  // }
  blockedUrlForError:Array<string> = ["esign.do"];

  setBlockUrl(request:HttpRequest<any>){

    if (this.blockedUrlForError && this.blockedUrlForError.length>0){
      let _blockUrl = this.blockedUrlForError.find(s=>{
        return request.url.includes(s);
      });
      if (_blockUrl){
        return false;
      }else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkUrlContainExceptedUrl(url:String){
    let result = false;
    this.exceptedUrlList.forEach(exceptedUrl =>{
      if(url.toLowerCase().indexOf(exceptedUrl.toLowerCase()) > -1){
        result = true;
      }
    });

    return result;
  }
}
