import {Component, OnInit} from '@angular/core';
import {SearchService} from "../search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {JournalDTO} from "../journal/JournalDTO";
import {MoreLessComponent} from "../../../trdcore/component/more-less/more-less.component";
import {NgForOf, NgIf} from "@angular/common";
import {NgxEchartsDirective} from "ngx-echarts";
import {PublicationListComponent} from "../publication-list/publication-list.component";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {JournalPersonDTO} from "../journal/JournalPersonDTO";
import {JournalYearDTO} from "../journal/JournalYearDTO";
import {JournalYearSubjectDTO} from "../journal/JournalYearSubjectDTO";
import {JournalPublicationFieldsDTO} from "../journal/JournalPublicationFieldsDTO";
import {EChartsOption} from "echarts";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {PublicationDTO} from "../publication/PublicationDTO";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";
import {getCurrentYear} from "../../app.module";
import {ExportComponent} from "../export/export.component";
import {MylibraryDetailComponent} from "../Mylibrary/mylibrary-detail.component";

@Component({
    selector: 'app-journal-detail',
    standalone: true,
    imports: [
        MoreLessComponent,
        NgForOf,
        NgIf,
        NgxEchartsDirective,
        PublicationListComponent,
        TrdCoreModule,
        ExportComponent,
        MylibraryDetailComponent
    ],
    templateUrl: './journal-detail.component.html',
    styleUrl: './journal-detail.component.scss'
})
export class JournalDetailComponent implements OnInit {
    selectedJournalId: string;
    selectedJournal: JournalDTO;
    publicationFields: JournalPublicationFieldsDTO[];
    publicationList: Array<PublicationDTO>;
    publicationIdList: Array<string>;
    journalCitationIdList: Array<string>;
    journalCitationList: Array<PublicationDTO>;
    chartOptionPublicationAndCitationCountsOfYears: EChartsOption
    chartOptionPaperType: EChartsOption
    publicationPageModel: PageModel = new PageModel(10);
    journalCitationPageModel: PageModel = new PageModel(10);
    isSaveFound:Boolean;
    constructor(
        private localizationService: LocalizationService,
        private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router,) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
    }

    ngOnInit(): void {
        this.selectedJournalId = this.activatedRoute.snapshot.paramMap.get('id');

        this.searchService.getJournalById(this.selectedJournalId).subscribe({
            next: (res) => {
                if (res["hits"]["hits"] && res["hits"]["hits"].length>0) {
                    this.selectedJournal = new JournalDTO();
                    this.selectedJournal.bindData(res["hits"]["hits"][0]["_source"]);


                    this.searchService.getJournalPublicationFieldById(this.selectedJournalId).subscribe({
                        next: (res) => {
                            if (res["hits"]["hits"]) {
                                this.publicationFields = new Array<JournalPublicationFieldsDTO>;
                                if (res["hits"] && res["hits"]["hits"]) {
                                    this.publicationFields = [];
                                    for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                        let _publicationFields = new JournalPublicationFieldsDTO();
                                        _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                        this.publicationFields.push(_publicationFields);
                                    }
                                    this.getCitationChartOptions();
                                    this.getPaperTypeChartOptions();
                                    this.fillPublicationIdList();
                                }

                                // this.selectedJournal.bindData(res["hits"]["hits"][0]["_source"]);
                            }
                        },
                        error: (e) => console.error(e)
                    });


                }else{
                    window.location.href="https://static.trdizin.gov.tr/404";
                }
            },
            error: (e) => console.error(e)
        });
    }

    getPaperTypeChartOptions() {
        let _paperType = new Map<string, number>();
        this.publicationFields.forEach(f => {
            if (_paperType.get(f.publicationType)) {
                _paperType.set(f.publicationType, _paperType.get(f.publicationType) + 1);
            } else {
                _paperType.set(f.publicationType, 1);
            }
        });
        let _data = [];
        if (_paperType) {
            _paperType.forEach((v, k) => {
                if (k) {
                    _data.push({value: v, name: this.localizationService.getLocalizationValue(k)});
                }
            });
        }


        this.chartOptionPaperType = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                left: 'center'
            },
            series: [
                {
                    name: this.localizationService.getLocalizationValue("paperType"),
                    type: 'pie',
                    radius: ['40%', '70%'],
                    center: ['50%', '70%'],
                    // adjust the start and end angle
                    startAngle: 180,
                    endAngle: 360,
                    data: [...new Set(_data)]
                }
            ]
        };
    }

    getCitationChartOptions() {
        let yearsPapers = new Map<string, number>();
        let yearsCitations = new Map<string, number>();
        this.publicationFields.forEach(f => {
            if (yearsPapers.get(f.issueYear)) {
                yearsPapers.set(f.issueYear, yearsPapers.get(f.issueYear) + 1);
            } else {
                yearsPapers.set(f.issueYear, 1);
            }

            if (!yearsCitations.get(f.issueYear)) {
                let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                yearsCitations.set(f.issueYear, uniqueSourceCount.size);
            } else {
                let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                yearsCitations.set(f.issueYear, yearsCitations.get(f.issueYear) + uniqueSourceCount.size);
            }


        });


        let _years = [...yearsPapers.keys(), ...yearsCitations.keys()];
        _years = _years.sort((a, b) => Number(a) - Number(b));
        let citationYear = new Set(_years);

        let citationCount: number[] = [];
        let paperCount: number[] = [];
        citationYear.forEach(cy => {
            let _citationCountOfYear = yearsCitations.get(cy)
            if (_citationCountOfYear) {
                citationCount.push(_citationCountOfYear);
            } else {
                citationCount.push(0);
            }

            let _paperCountOfYear = yearsPapers.get(cy)
            if (_paperCountOfYear) {
                paperCount.push(_paperCountOfYear);
            } else {
                paperCount.push(0);
            }

        });

        this.chartOptionPublicationAndCitationCountsOfYears = {
            legend: {},
            grid: {
                top: 35,
                bottom: 30,
                right: 10,
                left: 40
            },
            tooltip: {
                show: true,
            },
            yAxis: {
                nameTextStyle: {
                    fontSize: 0.1,
                },
                type: 'value',
                splitLine: {
                    show: true
                },
            },
            xAxis: {
                type: 'category',
                data: [...new Set(citationYear)],
                splitLine: {
                    show: true
                },
                axisLabel: {
                    inside: false,
                    fontSize: 10,
                },
                axisLine: {}
            },
            series: [
                {
                    data: citationCount,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('citationCount')),
                    // barWidth: '25%'
                },
                {
                    data: paperCount,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('paperCount')),
                    // barWidth: '25%'
                }
            ],
        };
    }


    getPersonByType(personList: JournalPersonDTO[], type: string) {
        if (personList && personList.length > 0) {
            let _list = new Array<JournalPersonDTO>;
            personList.forEach(p => {
                if (p.type == type) {
                    _list.push(p);
                }
            });
            return _list;
        }
        return new Array<JournalPersonDTO>;
    }


    getJournalYearRange(journal: JournalDTO) {
        let years = journal.journalYear.map(jy => jy.year);
        years = years.sort((a, b) => a - b);
        let indexs = new Array<number>;
        let ranges = new Array<string>;
        for (let i = 0; i < years.length; i++) {
            if (i + 1 < years.length) {
                let firstYear = journal.journalYear.find(jy => jy.year == years[i]);
                let nextYear = journal.journalYear.find(jy => jy.year == years[i + 1]);
                let firstSubjectRoots = this.getRootNameBySubjectList(firstYear.subjects);
                let nextSubjectRoots = this.getRootNameBySubjectList(nextYear.subjects);
                if (years[i] + 1 != years[i + 1] || (firstSubjectRoots.size != nextSubjectRoots.size || (firstSubjectRoots.size == 0 && firstSubjectRoots.size == nextSubjectRoots.size && firstSubjectRoots[0] != nextSubjectRoots[0]))) {
                    indexs.push(i);
                }
            }
        }

        if (!indexs.includes(years.length)) {
            indexs.push(years.length);
        }

        let chunks = years.reduceRight((result, value, index) => {
            result[0] = result[0] || [];
            if (indexs.includes(index)) {
                result.unshift([value]);
            } else {
                result[0].unshift(value);
            }
            return result;
        }, []);

        return chunks;
    }

    getRootNameBySubjectList(subjectList: JournalYearSubjectDTO[]) {
        const roots = new Set<string>();
        if (subjectList && subjectList.length > 0) {
            subjectList.forEach(s => {
                roots.add(s.rootName);
            });
        }
        return roots;
    }

    getYearObjectsByYearValue(yearList: JournalYearDTO[], year: number) {
        if (yearList && yearList.length > 0 && year) {
            return this.getRootNameBySubjectList(yearList.find(jy => jy.year === year).subjects);
        }
        return null;
    }
    getMaxYearObject(journal:JournalDTO){
      return  Math.max(...journal.journalYear.map(y => y.year));
    }


    getMaxJournalYear() {
        if (this.selectedJournal.journalYear) {
            let years = this.selectedJournal.journalYear;
            years = years.sort((a, b) => b.year - a.year);
            return years[0];
        }
    }

    getPeriodsByMaxYear() {
        let year = this.getMaxJournalYear()
        if (year && year.periods) {
            return year.periods.sort((a, b) => Number(a) - Number(b));

        }
        return new Array<number>;
    }

    getSubjectCategory() {
        let year = this.getMaxJournalYear();
        const roots = new Set<string>();
        if (year && year.subjects && year.subjects.length > 0) {
            year.subjects.forEach(s => {
                roots.add(this.spliceSubjectByCategory(s));
            });
        }
        return roots;
    }

    spliceSubjectByCategory(subject: JournalYearSubjectDTO) {
        let _array = subject.fullName.split(">");
        return _array[0].trim() + " > " + _array[1].trim();
    }


    getSubjectFields() {
        let year = this.getMaxJournalYear();
        const roots = new Set<string>();
        if (year && year.subjects && year.subjects.length > 0) {
            year.subjects.forEach(s => {
                roots.add(s.name);
            });
        }
        return roots;
    }

    getJournalTotalCitationCountByFields(fields: JournalPublicationFieldsDTO[]) {
        let count = 0;
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesSourceIds && f.citedReferencesSourceIds.length > 0) {
                    let uniqueSourceCount = new Set(f.citedReferencesSourceIds);
                    count += uniqueSourceCount.size;
                }
            });
        }
        return count;
    }

    getJournalTotalSelfCitationCountByFields(fields: JournalPublicationFieldsDTO[]) {
        let count = 0;
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesJournalCodes && f.citedReferencesJournalCodes.length > 0) {
                    f.citedReferencesJournalCodes.forEach(j => {
                        if (j.toString() === this.selectedJournalId) {
                            count += 1;
                        }
                    });
                }
            });
        }
        return count;
    }


    getJournalTotalPublicationOfCited(fields: JournalPublicationFieldsDTO[]) {
        let count = 0;
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesIds && f.citedReferencesIds.length > 0) {
                    count += 1;
                }
            });
        }
        return count;
    }

    getCitationAvg(fields: JournalPublicationFieldsDTO[]) {
        let _citationCount = this.getJournalTotalCitationCountByFields(fields);
        if (!this.publicationFields || this.publicationFields.length == 0 || _citationCount == 0) {
            return "0,0";
        }
        return parseFloat(parseFloat(("" + (_citationCount / this.publicationFields.length))).toFixed(2)).toLocaleString('tr-TR');
    }

    getSelfCitationRatio(fields: JournalPublicationFieldsDTO[]) {
        let _selfCitationCount = this.getJournalTotalSelfCitationCountByFields(fields);
        let _citationCount = this.getJournalTotalCitationCountByFields(fields);

        if (!this.publicationFields || this.publicationFields.length == 0 || _selfCitationCount == 0) {
            return "0,0";
        }
        return parseFloat(parseFloat(("" + (_selfCitationCount / _citationCount) * 100)).toFixed(2)).toLocaleString('tr-TR');
    }

    changePublicationResultPage(event) {
        this.publicationPageModel.currentPage = event.currentPage;
        if (this.publicationPageModel.size != event.size) {
            this.publicationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.publicationPageModel.size = event.size;
        }
        this.publicationPageModel.totalCount = this.publicationIdList.length;
        this.publicationPageModel.totalPages = Math.ceil(this.publicationPageModel.totalCount / this.publicationPageModel.size)
        let _array = this.publicationIdList.slice(
            (this.publicationPageModel.currentPage - 1) * this.publicationPageModel.size, this.publicationPageModel.size * this.publicationPageModel.currentPage);
        if (_array && _array.length > 0) {
            this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
                next: (res) => {
                    this.publicationList = new Array<PublicationDTO>();
                    if (res["hits"]["hits"]) {
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.publicationList.push(_publication);
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }

    }

    fillPublicationIdList() {
        this.publicationIdList = new Array<string>();
        if (this.publicationFields.length > 0) {
            this.publicationFields.forEach(p => {
                if (p.id) {
                    this.publicationIdList.push(p.id);
                }
            });
        }

        this.publicationIdList.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        this.publicationPageModel.totalCount = this.publicationIdList.length;
        this.publicationPageModel.totalPages = Math.ceil(this.publicationPageModel.totalCount / this.publicationPageModel.size)
        this.changePublicationResultPage(this.publicationPageModel.currentPage);
    }

    changeJournalCitationResultPage(event) {
        this.journalCitationPageModel.currentPage = event.currentPage;

        if (this.journalCitationPageModel.size != event.size) {
            this.journalCitationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.journalCitationPageModel.size = event.size;
        }

        this.journalCitationPageModel.totalPages = Math.ceil(this.journalCitationPageModel.totalCount / this.journalCitationPageModel.size)
        let _array = this.journalCitationIdList.slice((this.journalCitationPageModel.currentPage - 1) * this.journalCitationPageModel.size, this.journalCitationPageModel.size * this.journalCitationPageModel.currentPage);
        this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
            next: (res) => {
                this.journalCitationList = new Array<PublicationDTO>();
                if (res["hits"]["hits"]) {
                    for (let i = 0; i < res["hits"]["hits"].length; i++) {
                        let _publication = new PublicationDTO();
                        _publication.bindData(res["hits"]["hits"][i]["_source"]);
                        this.journalCitationList.push(_publication);
                    }
                }
            },
            error: (e) => console.error(e)
        });
    }

    fillJournalCitationIdList(fields: JournalPublicationFieldsDTO[]) {

        this.journalCitationIdList = new Array<string>();
        let _journalCitationIdList = new Array<number>();
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesSourceIds && f.citedReferencesSourceIds.length > 0) {
                    _journalCitationIdList = _journalCitationIdList.concat([...new Set(f.citedReferencesSourceIds)])
                }
            });
        }

        _journalCitationIdList.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        let stringList: string[] = _journalCitationIdList.map(num => num.toString())
        this.journalCitationIdList = stringList;

        this.journalCitationPageModel.totalCount = this.journalCitationIdList.length;
        this.journalCitationPageModel.totalPages = Math.ceil(this.journalCitationPageModel.totalCount / this.journalCitationPageModel.size)
        this.changeJournalCitationResultPage(this.journalCitationPageModel.currentPage);
    }
    followCitationChange(selectedJournal,fields:JournalPublicationFieldsDTO[]){
        let _selectedJournal=new JournalDTO()
        _selectedJournal.bindData(selectedJournal);
        let _journalCitationIdList = new Array<number>();
       let _citionCount:number;
        if (fields && fields.length > 0) {
            fields.forEach(f => {
                if (f.citedReferencesSourceIds && f.citedReferencesSourceIds.length > 0) {
                    _journalCitationIdList = _journalCitationIdList.concat([...new Set(f.citedReferencesSourceIds)])
                }
            });
        }
        _citionCount=_journalCitationIdList.length;
        let searchNotificationDTO = {"title": _selectedJournal.title,"value": _selectedJournal.id,"subscriptionField": "JOURNAL","oldCount": _citionCount}
        let changedId;
        this.searchService.followCitationChange(parseInt(_selectedJournal.id)).subscribe(res=>{
            if(res["data"]!=null){
                this.isSaveFound=true;
                changedId=res["data"].id;
            }else{
                this.isSaveFound=false;
            }
            if(this.isSaveFound==false){
                this.searchService.followCitationCrate(searchNotificationDTO).subscribe(res=>{
                    if(res){
                        this.isSaveFound=true
                    }
                })
            }else{
                this.searchService.changeActive(parseInt(changedId)).subscribe(res=>{
                    this.isSaveFound=res["data"].active;
                })
            }
        });
    }
    protected readonly Array = Array;
    protected readonly getCurrentYear = getCurrentYear;
}
