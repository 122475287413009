import {BaseDTO} from "../../core/BaseDTO";

export class JournalPersonDTO extends BaseDTO {
    public type: string;
    public name: string;


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.type = obj.type;
        this.name = obj.name;
    }


}
