import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {filter, map} from "rxjs/operators";
import {SavedSearchDTO} from "./SavedSearchDTO";

@Injectable({providedIn: 'root'})

export abstract class SaveSearchService {

    protected constructor(protected http: HttpClient) {
    }

    addSavedSearch(dto: SavedSearchDTO) {
        return this.http.post(environment.apiUrl + "/api/saveSearch/add/", dto, {observe: 'response'}).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }

    findSavedSearchListToUserId(userId: string) {
        return this.http.get(environment.apiUrl + "/api/saveSearch/savedSearchListByUserId/" + userId);
    }

    deleteSavedSearch(id){
        return this.http.delete(environment.apiUrl+"/api/saveSearch/delete/"+id,{ observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }

    changeActive(id, do_notification) {
        return this.http.put(environment.apiUrl+"/api/saveSearch/update/"+id+`?do_notification=${do_notification}`,null,{ observe: 'response' });
    }
    checkUniqueSearchToSearchTextAndUrl(dto: SavedSearchDTO) {
        return this.http.post(environment.apiUrl + "/api/saveSearch/checkUniqueSearch/", dto, {observe: 'response'}).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
    getSavedSearchCountByUserId(userId: string) {
        return this.http.get(environment.apiUrl + "/api/saveSearch/count/" + userId);
    }
    getSavedSearchUserById(userId,page: number, pageSize: number) {
        let params = new HttpParams();
        params = params.append('page', String(page));
        params = params.append('pageSize', String(pageSize));
        return this.http.get(environment.apiUrl + "/api/saveSearch/savedSearchListByUserIdPageable/" + userId,{params:params});
    }


}