import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export abstract class ExportService {
    protected constructor(protected http: HttpClient) {
    }

    getPublication(searchUrlParams?) {
        return this.http.get(environment.apiUrl + "/api/export/publication/" + searchUrlParams.toString());
    }
    getJournal(searchUrlParams?) {
        return this.http.get(environment.apiUrl + "/api/export/journal/" + searchUrlParams.toString());
    }

    getAuthor(searchUrlParams?) {
        return this.http.get(environment.apiUrl + "/api/export/author/" + searchUrlParams.toString());
    }
    getInstitution(searchUrlParams?) {
        return this.http.get(environment.apiUrl + "/api/export/institution/" + searchUrlParams.toString());
    }

    getJournalCitationListByIdList(publicationIdList) {
        return this.http.post(environment.apiUrl + "/api/export/journal/citation" ,{ ids: publicationIdList });
    }
    getAuthorCitationListByIdList(publicationIdList) {
        return this.http.post(environment.apiUrl + "/api/export/author/citation",{ ids: publicationIdList });
    }
    getInstitutionCitationListByIdList(publicationIdList) {
        return this.http.post(environment.apiUrl + "/api/export/institution/citation" ,{ ids: publicationIdList });
    }

}
