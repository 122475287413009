import {SearchAbstractDTO} from "../Abstract/SearchAbstractDTO";
import {PublicationAuthorDTO} from "./PublicationAuthorDTO";

export class PublicationReferenceDTO extends SearchAbstractDTO {
    public id: string;
    public context: string;
    public order: number;
    public targetPublication: number;

    public sourceId: number;
    public docType: string;
    public year: string;
    public journalCode: string;
    public authors: PublicationAuthorDTO[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.sourceId = obj.sourceId;
        this.context = obj.context;
        this.order = obj.order;
        this.targetPublication = obj.targetPublication;

        this.docType = obj.docType;
        this.year = obj.year;
        this.journalCode = obj.journalCode;
        if (obj.authors) {
            this.authors = [];
            for (let i = 0; i < obj.authors.length; i++) {
                let _author = new PublicationAuthorDTO();
                _author.bindData(obj.authors[i]);
                this.authors.push(_author);
            }
        }
    }


}
