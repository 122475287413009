import { Injectable } from '@angular/core';
import {AbstractService} from "../trdcore/service/AbstractService";
import {NotificationDto} from "./search/notification/NotificationDto";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {filter, map} from "rxjs/operators";
import {SendNotificationDTO} from "./search/notification/SendNotificationDTO";
import {PageModel} from "../trdcore/component/paginater/PageModel";
import {NotificationInfoDTO} from "./search/notification/NotificationInfoDTO";
import {Filter} from "../trdcore/component/paginater/Filter";


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService<NotificationDto>{

  protected constructor(protected http: HttpClient) {
    super(http);
  }
  getApiURL() {
    return "/notification"
  }

  getNotificationsByUserId(size: any, page: any, sortField: any, sortType: any) {
    return this.http.get
    (`${environment.managementApiUrl}${this.getApiURL()}/filter?sort_field=${sortField}&sort_order=${sortType}&page=${page}&size=${size}`,
        {observe: 'response'}).pipe(
        filter((res) => res.ok),
        map((res) => res.body)
    );

  }

  markAsRead(notification:NotificationDto){
    return this.http.get(`${environment.managementApiUrl}${this.getApiURL()}/mark-read/${notification.id}`)
  }

  sendNotification(notification:SendNotificationDTO,isSketch:boolean){
    return this.http.post(`${environment.managementApiUrl}${this.getApiURL()}/send-notification?sketch=${isSketch}`,notification,{observe: 'response'}).pipe(
        filter((res) => res.ok),

        map((res) => res.body)
    );
  }


  getAllSendNotifications(filter: Filter, page: PageModel) {
    return this.http.post(`${environment.managementApiUrl}${this.getApiURL()}/send-notification-filter?page=${page.currentPage}&size=${page.size}&sortField=${page.sortField}&sortType=${page.sortType}`, filter.getFilterAsJson());
  }

  getUserByNotificationId(notification:NotificationInfoDTO, page: PageModel,id:number){
    return this.http.post(`${environment.managementApiUrl}${this.getApiURL()}/get-notification-info/${id}?page=${page.currentPage}&size=${page.size}`,notification,{observe: 'response'}).pipe(
        filter((res) => res.ok),
        map((res) => res.body)
    );
  }
  getNotificationBySubjectOrSubjectAndPersonId(page: PageModel,notificationInfo:NotificationInfoDTO){
    return this.http.post(`${environment.managementApiUrl}${this.getApiURL()}/filter/subject/?page=${page.currentPage}&size=${page.size}`,notificationInfo,
        {observe: 'response'}).pipe(
        filter((res) => res.ok),
        map((res) => res.body)
    );
  }

  deleteSketchNotification(_notification:SendNotificationDTO){
    return this.http.post(`${environment.managementApiUrl}${this.getApiURL()}/delete-sketch-notification`,_notification,{observe: 'response'}).pipe(
        filter((res) => res.ok),
        map((res) => res.body)
    );
  }
}
