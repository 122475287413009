import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FacetComponent} from "./facet/facet.component";
import {SearchService} from "./search.service";
import {FormsModule} from "@angular/forms";
import {PublicationSearchResult} from "./publication/PublicationSearchResult";
import {PublicationDTO} from "./publication/PublicationDTO";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {TrdCoreModule} from "../../trdcore/trdcore.module";
import {PageModel} from "../../trdcore/component/paginater/PageModel";
import {ActivatedRoute, Router} from "@angular/router";
import slugify from "slugify";
import {JournalSearchResult} from "./journal/JournalSearchResult";
import {JournalDTO} from "./journal/JournalDTO";
import {AuthorSearchResult} from "./author-detail/AuthorSearchResult";
import {AuthorDTO} from "./author-detail/AuthorDTO";
import {AuthorPublicationFieldsDTO} from "./author-detail/AuthorPublicationFieldsDTO";
import {InstitutionSearchResult} from "./institution-detail/InstitutionSearchResult";
import {InstitutionDTO} from "./institution-detail/InstitutionDTO";
import {LoadingService} from "../../trdcore/service/LoadingService";
import {LocalizationService} from "../../trdcore/service/localizationService";
import {getCurrentLanguage, getCurrentYear} from "../app.module";
import {ExportComponent} from "./export/export.component";
import {AdvanceSearchDTO} from "./AdvanceSearchDTO";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {TagData, TagifyModule, TagifySettings} from "ngx-tagify";
import {SaveSearchComponent} from "./save-search/save-search.component";
import {environment} from "../../environments/environment";
import {ToastrService} from "ngx-toastr";
import {MathJaxService} from "../mathjax.service";
import {AuthInfoDTO} from "../../trdcore/dto/AuthInfoDTO";
import {KeyCloackUserDTO} from "../../trdcore/dto/KeyCloackUserDTO";
import {CookieService} from "../../trdcore/service/CookieService";
import {AuthorizationService} from "../Authorization/AuthorizationService";
import {ConfigurationService} from "../../trdcore/service/ConfigurationService";
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-search',
    standalone: true,
    providers: [
        provideNgxMask(),
    ],
    imports: [
        FacetComponent,
        FormsModule,
        NgForOf,
        NgIf,
        TrdCoreModule,
        DecimalPipe,
        ExportComponent,
        NgxMaskDirective,
        TagifyModule,
        SaveSearchComponent
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {

    public model: { user, name, surname, email } | undefined;
    loggedUser: AuthInfoDTO;
    loginSuccess = false;
    public activeUser: AuthInfoDTO;

    constructor(
        private appComponent: AppComponent,
        private searchService: SearchService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        public localizationService: LocalizationService,
        private toasterService: ToastrService,
        private cookieService: CookieService,
        private authorizationService: AuthorizationService,
        private configurationService: ConfigurationService,
        private mathJaxService: MathJaxService, private cdr: ChangeDetectorRef
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        this.model = {user: undefined, name: undefined, surname: undefined, email: undefined};

        let cookie = this.cookieService.getCookie(this.cookieService.cookieName);
        if (cookie && cookie != "") {
            let authInfo: AuthInfoDTO = this.cookieService.getUserInfo(this.cookieService.cookieName);
            if (authInfo) {
                this.loginSuccess = true;
                this.loggedUser = authInfo;
                this.cookieService.headerToken = cookie;
                if (!this.authorizationService.getActiveUser()) {
                    this.authorizationService.setActiveUser(this.loggedUser);
                }
                this.activeUser = this.authorizationService.getActiveUser();
                if (!this.activeUser.accountInfo && !configurationService.isRequestOfPublicScreen()) {
                    this.authorizationService.getAccount().subscribe(res => {
                        let accountInfo = new KeyCloackUserDTO();
                        accountInfo.bindData(res);
                        this.loggedUser.accountInfo = accountInfo;
                        // if(!this.activeUser.accountInfo){
                        //   this.authorizationService.getAccount().subscribe(res => {
                        //     let accountInfo = new KeyCloackUserDTO();
                        //     accountInfo.bindData(res);
                        //     this.activeUser.accountInfo = accountInfo;

                        this.authorizationService.setActiveUser(this.loggedUser);
                        this.activeUser = this.authorizationService.getActiveUser();
                    });
                }
            }
        }
    }


    getSettingsTagify(_placeholder): TagifySettings {
        return {
            templates: {
                dropdownItemNoMatch: function (data) {
                    return `<div class='${this.settings.classNames.dropdownItem} ' value="noMatch" tabindex="0" >
                   Ekle: <strong>${data.value}</strong>
                </div>`
                }
            },
            editTags: {
                clicks: 2,
                keepInvalid: true
            },
            delimiters: ",",
            keepInvalidTags: false,
            backspace: true,
            trim: true,
            duplicates: false,
            maxTags: 15,
            placeholder: this.localizationService.getLocalizationValue(_placeholder),
            dropdown: {
                enabled: 1,
            }
        };
    }

    getSettingsTagifyForSuggestLanguage(): TagifySettings {
        return {
            editTags: {
                clicks: 2,
                keepInvalid: true
            },
            delimiters: ",",
            keepInvalidTags: false,
            backspace: true,
            trim: true,
            duplicates: false,
            maxTags: 15,
            placeholder: this.localizationService.getLocalizationValue('languageContainsAny'),
            whitelist: [this.localizationService.getLocalizationValue('TUR'), this.localizationService.getLocalizationValue('ENG'), this.localizationService.getLocalizationValue('GER'),
                this.localizationService.getLocalizationValue('ARA'), this.localizationService.getLocalizationValue('AZE')
                , this.localizationService.getLocalizationValue('FRE'), this.localizationService.getLocalizationValue('SPA'), this.localizationService.getLocalizationValue('ITA')
                , this.localizationService.getLocalizationValue('KAZ'), this.localizationService.getLocalizationValue('KUR'), this.localizationService.getLocalizationValue('RUS')
                , this.localizationService.getLocalizationValue('OTH')
            ],
            dropdown: {
                maxItems: Infinity,
                classname: 'tags-look', // <- custom classname for this dropdown, so it could be targeted
                enabled: 0,             // <- show suggestions on focus
                closeOnSelect: false
            },
            templates: {
                dropdownItemNoMatch() {
                    return `Nothing Found`;
                }
            },
            enforceWhitelist: true
        };
    }


    public publicationSearchResult = new PublicationSearchResult();
    public journalSearchResult = new JournalSearchResult();
    public authorSearchResult = new AuthorSearchResult();
    public institutionSearchResult = new InstitutionSearchResult();

    searchText: string = "";
    @ViewChild('publicationFacetComponent') public facetComponent: FacetComponent;
    @ViewChild('publicationFacetComponentMobil') public facetComponentMobil: FacetComponent;
    pageModel: PageModel = new PageModel(20);
    searchUrlParams: string;
    hasSearchQuery: boolean;
    selectedDocumentType: string;
    documentTypes: Map<string, string> = new Map([["yayin", "publication"],
        ["publication", "publication"],
        ["dergi", "journal"],
        ["journal", "journal"],
        ["yazar", "author"],
        ["author", "author"],
        ["kurum", "institution"],
        ["institution", "institution"]]);


    sortTypes: string[];
    selectedSortType = "relevance-DESC";
    defaultSortTypeTitleAscending = "title-ASC";
    defaultSortTypeForPublication = "publicationYear-DESC";

    advanceSearchFields = ["title", "abstract", "year", "author", "orcid", "issn", "eissn", "journalName", "publicationNumber", "language", 'institution'];
    advanceSearchFromYear: string;
    advanceSearchToYear: string;
    advanceSearchContainsAllWord: string;
    advanceSearchContainsAnyWord: string;
    advanceSearchNotContainsAsIsTags: TagData[] = [];
    advanceSearchYearNotContain: TagData[] = [];
    advanceSearchContainsAsIsTags: TagData[] = [];
    advanceSearchList: AdvanceSearchDTO[] = [];
    selectedAdvanceSearchField = '';

    exportDataList = [];
    exportSelectedHeaderList = [];
    searchUrl: string;
    searchTotalCount: number;
    private observer: MutationObserver;
    searchObserver: MutationObserver

    isAdvanceSearch() {
        if (this.searchText && this.searchText.startsWith("(") && this.searchText.endsWith(")")) {
            return true;
        }
        return false;
    }

    @ViewChild("advanceSearchModalCloseButton") advanceSearchModalCloseButton: ElementRef;

    searchAdvance() {
        if (this.advanceSearchList && this.advanceSearchList.length > 0) {
            let query = "(";

            if (this.advanceSearchList.length == 1) {
                query += this.advanceSearchList[0].getQuery();
            } else {
                for (let i = 0; i < this.advanceSearchList.length; i++) {
                    let advanceSearch = this.advanceSearchList[i]

                    if (i > 0) {
                        query += " | "
                    }
                    query += advanceSearch.getQuery();
                }
            }

            query += ")";
            this.searchText = query;
            if (this.advanceSearchModalCloseButton) {
                this.advanceSearchModalCloseButton.nativeElement.click();
                this.publicationSearch(true);
            }

        }
    }


    advanceSearchFieldIsDisabled(_field: string): boolean {
        if (this.advanceSearchList && this.advanceSearchList.length > 0) {
            if (this.advanceSearchList.find(f => {
                return f.field == _field;
            })) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }

    addAdvanceQuery() {
        let query = new AdvanceSearchDTO();
        query.field = this.selectedAdvanceSearchField;

        if (this.selectedAdvanceSearchField == 'year') {
            if (this.advanceSearchFromYear) {
                query.advanceSearchFromYear = this.advanceSearchFromYear;
            }

            if (this.advanceSearchToYear) {
                query.advanceSearchToYear = this.advanceSearchToYear;
            }
            if (this.advanceSearchYearNotContain) {
                query.advanceSearchYearNotContains = this.advanceSearchYearNotContain.map(v => {
                    return v.value
                });
            }
        }

        if (this.advanceSearchContainsAllWord) {
            query.advanceSearchContainsAllWord = [];
            this.advanceSearchContainsAllWord.split(" ").forEach(w => {
                query.advanceSearchContainsAllWord.push(w);
            });
        }

        if (this.advanceSearchContainsAnyWord) {
            query.advanceSearchContainsAnyWord = [];
            this.advanceSearchContainsAnyWord.split(" ").forEach(w => {
                query.advanceSearchContainsAnyWord.push(w);
            });
        }
        query.advanceSearchContainsAsIs = this.advanceSearchContainsAsIsTags.map(v => {
            return v.value
        });

        query.advanceSearchNotContainsAsIs = this.advanceSearchNotContainsAsIsTags.map(v => {
            return v.value
        });

        if (!this.advanceSearchList) {
            this.advanceSearchList = [];
        }
        if (query.advanceSearchContainsAllWord.length === 0 && query.advanceSearchContainsAnyWord.length === 0 && query.advanceSearchToYear.trim() === "" && query.advanceSearchFromYear.trim() === "" && query.advanceSearchContainsAsIs.length === 0 && query.advanceSearchNotContainsAsIs.length == 0) {
            this.toasterService.error(
                this.localizationService.getLocalizationValue('addToQueryNotEmpty'),
                this.localizationService.getLocalizationValue('Error'));
            return;
        }
        this.advanceSearchList.push(query);
        this.clearAdvanceSearchWordBoxes();

    }

    editAdvanceSearchList(index) {
        let query = this.advanceSearchList[index];
        this.selectedAdvanceSearchField = query.field;
        this.advanceSearchContainsAllWord = query.advanceSearchContainsAllWord.join(" ");
        this.advanceSearchContainsAnyWord = query.advanceSearchContainsAnyWord.join(" ");
        this.advanceSearchContainsAsIsTags = query.advanceSearchContainsAsIs.map(v => {
            return {value: v}
        });
        this.advanceSearchNotContainsAsIsTags = query.advanceSearchNotContainsAsIs.map(v => {
            return {value: v}
        });
        this.advanceSearchFromYear = query.advanceSearchFromYear;
        this.advanceSearchToYear = query.advanceSearchToYear;
        this.advanceSearchYearNotContain = query.advanceSearchYearNotContains.map(v => {
            return {value: v}
        });


        this.removeAdvanceSearchByIndex(index);
    }

    clearAdvanceSearchWordBoxes() {
        this.advanceSearchContainsAllWord = '';
        this.advanceSearchContainsAnyWord = '';
        this.advanceSearchContainsAsIsTags = [];
        this.advanceSearchNotContainsAsIsTags = [];
        this.selectedAdvanceSearchField = '';
        this.advanceSearchYearNotContain = [];
        this.advanceSearchFromYear = '';
        this.advanceSearchToYear = '';
        this.advanceSearchContainsAllWord = ''
    }

    removeAdvanceSearchByIndex(index) {
        this.advanceSearchList.splice(index, 1);
    }

    ngAfterViewInit(): void {
        const config = {attributes: true, childList: true, subtree: true};
        const targetNodes = document.querySelectorAll('.latex');
        this.observer = new MutationObserver((mutationsList, observer) => {
            this.observer.disconnect();
            this.mathJaxService.render();
        });
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            if (targetNode) {
                this.observer.observe(targetNode, config);
            }
        }
    }

    ngOnInit(): void {
        this.selectedDocumentType = this.activatedRoute.snapshot.paramMap.get('object');
        if (this.selectedDocumentType) {
            this.selectedDocumentType = this.documentTypes.get(this.selectedDocumentType);
            if (this.selectedDocumentType == 'publication') {
                this.sortTypes = [];
                this.sortTypes.push('orderCitationCount-DESC');
                this.sortTypes.push('orderCitationCount-ASC');
                this.sortTypes.push('publicationYear-DESC');
                this.sortTypes.push('publicationYear-ASC');
                this.sortTypes.push('title-DESC');
                this.sortTypes.push('title-ASC');
                this.sortTypes.push('relevance-DESC')
            } else if (this.selectedDocumentType == 'journal') {
                this.sortTypes = [];
                this.sortTypes.push('title-DESC');
                this.sortTypes.push('title-ASC');
                this.sortTypes.push('relevance-DESC')
            } else if (this.selectedDocumentType == 'author') {
                this.sortTypes = [];
                this.sortTypes.push('title-DESC');
                this.sortTypes.push('title-ASC');

                this.sortTypes.push('orderCitationCount-DESC');
                this.sortTypes.push('orderCitationCount-ASC');

                this.sortTypes.push('orderPublicationCount-DESC');
                this.sortTypes.push('orderPublicationCount-ASC');
                this.sortTypes.push('relevance-DESC')

            } else if (this.selectedDocumentType == 'institution') {
                this.sortTypes = [];
                this.sortTypes.push('title-DESC');
                this.sortTypes.push('title-ASC');
                this.sortTypes.push('relevance-DESC')
            }
        }
        let _params;
        this.activatedRoute.queryParams.subscribe(params => {

            _params = params;
            this.searchUrlParams = "?";
            let i = 0;
            for (let key in params) {
                if (i > 0) {
                    this.searchUrlParams += "&";
                }


                if (key.startsWith("facet-") && params[key] && params[key].constructor.name == "Array") {
                    let paramIndex=0;
                    for (let p of params[key]) {
                        if (paramIndex > 0) {
                            this.searchUrlParams += "&";
                        }
                        this.searchUrlParams += key + "=" + p;
                        paramIndex++;
                    }
                }else{
                    this.searchUrlParams += key + "=" + params[key];
                }


                if (key.startsWith("facet-") && this.facetComponent) {
                    this.facetComponent.selectedFacetMap.set(key.replace("facet-", ""), params[key]);
                }
                i++;
            }
            if (params["q"]) {
                this.searchText = params["q"];
            }
            if (params["page"]) {
                this.pageModel.currentPage = params["page"];
            }
            if (params["order"] && this.sortTypes && this.sortTypes.includes(params["order"])) {
                this.selectedSortType = params["order"];
            } else {
                if (this.selectedDocumentType == "journal") {
                    this.selectedSortType = this.defaultSortTypeTitleAscending;
                } else if (this.selectedDocumentType == "publication") {
                    this.selectedSortType = this.defaultSortTypeForPublication;
                } else if (this.selectedDocumentType == "institution") {
                    this.selectedSortType = this.defaultSortTypeTitleAscending;
                }
            }
            if (params["limit"]) {
                this.pageModel.size = params["limit"];
                if (this.pageModel.size > 100) {
                    this.pageModel.size = 20;
                }
            }
        });
        if (_params["q"] == undefined || !_params["page"] || !_params["order"] || !_params["limit"]) {
            this.generateSearchParams();
        }

        this.hasSearchText(this.searchUrlParams);
        this.publicationSearch()
    }


    onSortTypeChanged(newSortType) {
        this.publicationSearch(true);
    }


    getCurrentUrl() {
        let url = this.activatedRoute.snapshot.url[0] + "/" + this.activatedRoute.snapshot.url[1] + "/" + this.activatedRoute.snapshot.url[2]
        return url;
    }

    generateSearchParams(isNewSearch?) {
        const parsedUrl = new URL(window.location.href);
        let baseUrl = parsedUrl.origin;

        let url = this.getCurrentUrl();

        this.searchUrlParams = "?q=" + encodeURIComponent(this.searchText);
        this.searchUrlParams += "&order=" + this.selectedSortType;

        this.searchUrlParams += "&page=" + ((isNewSearch && isNewSearch == true) ? 1 : this.pageModel.currentPage);
        this.searchUrlParams += "&limit=" + this.pageModel.size;

        this.searchUrl = url + this.searchUrlParams;
        if (this.facetComponent && this.facetComponent.selectedFacetMap) {
            for (let key of Array.from(this.facetComponent.selectedFacetMap.keys())) {
                this.facetComponent.selectedFacetMap.get(key).forEach(v => {
                    this.searchUrlParams += "&" + key + "=" + v;
                });
            }
        }


        this.router.navigateByUrl(url + this.searchUrlParams)
            .then(nav => {
                // console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
    }

    changeSearchResultPage(event) {
        this.pageModel.bindData(event);
        this.pageModel.currentPage = event.currentPage;
        if (this.pageModel.size != event.size) {
            this.pageModel.currentPage = 1;
        }
        this.pageModel.size = event.size;
        this.generateSearchParams();
        this.publicationSearch();
    }

    mobilFacetFilter() {
        this.facetComponent.selectedFacetMap = this.facetComponentMobil.selectedFacetMap;
        this.publicationSearch(true);
    }

    publicationSearch(newSearch?) {
        if (newSearch == true) {
            this.generateSearchParams(true);
        }

        if (this.selectedDocumentType == "publication") {
            let url = environment.searchUrl + 'tr/yayin/ara'
            this.searchUrl = url + this.searchUrlParams;
            this.searchService.defaultSearch(this.selectedDocumentType, this.pageModel.currentPage, this.pageModel.size, this.searchText, this.searchUrlParams).subscribe({
                next: (res) => {
                    if (res["hits"] && res["hits"]["total"]) {
                        this.pageModel.totalCount = res["hits"]["total"]["value"];
                        this.searchTotalCount = this.pageModel.totalCount;
                        this.pageModel.totalPages = Math.ceil(this.pageModel.totalCount / this.pageModel.size);
                    }
                    if (res && res["aggregations"] && this.facetComponent) {
                        this.facetComponent.clearFacets();
                        this.facetComponent.parseFacetList(res["aggregations"]);
                    }
                    if (res && res["aggregations"] && this.facetComponentMobil) {
                        this.facetComponentMobil.clearFacets();
                        this.facetComponentMobil.parseFacetList(res["aggregations"]);
                    }
                    this.publicationSearchResult = new PublicationSearchResult();
                    if (res["hits"] && res["hits"]["hits"]) {
                        this.publicationSearchResult.publications = [];
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.highlight = res["hits"]["hits"][i]["highlight"];
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.publicationSearchResult.publications.push(_publication);
                        }
                        this.exportDataList = this.publicationSearchResult.publications;
                        this.exportSelectedHeaderList = [];
                    }
                    if (res["hits"] && res["hits"]["total"]) {
                        this.publicationSearchResult.totalCount = res["hits"]["total"]["value"];
                        this.publicationSearchResult.maxScore = res["hits"]["max_score"];
                    }
                },
                error: (e) => console.error(e)
            });
        } else if (this.selectedDocumentType == "journal") {
            let url = environment.searchUrl + 'tr/dergi/ara';
            this.searchUrl = url + this.searchUrlParams;

            this.searchService.defaultSearch(this.selectedDocumentType, this.pageModel.currentPage, this.pageModel.size, this.searchText, this.searchUrlParams).subscribe({
                next: (res) => {
                    if (res["hits"] && res["hits"]["total"]) {
                        this.pageModel.totalCount = res["hits"]["total"]["value"];
                        this.searchTotalCount = this.pageModel.totalCount;
                        this.pageModel.totalPages = Math.ceil(this.pageModel.totalCount / this.pageModel.size);
                    }
                    if (res && res["aggregations"] && this.facetComponent) {
                        this.facetComponent.clearFacets();
                        this.facetComponent.parseFacetList(res["aggregations"]);
                    }
                    if (res && res["aggregations"] && this.facetComponentMobil) {
                        this.facetComponentMobil.clearFacets();
                        this.facetComponentMobil.parseFacetList(res["aggregations"]);
                    }
                    this.journalSearchResult = new JournalSearchResult();
                    if (res["hits"] && res["hits"]["hits"]) {
                        this.journalSearchResult.journals = [];
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _journal = new JournalDTO();
                            _journal.highlight = res["hits"]["hits"][i]["highlight"];
                            _journal.bindData(res["hits"]["hits"][i]["_source"]);
                            this.journalSearchResult.journals.push(_journal);
                        }
                    }
                    if (res["hits"]) {
                        if (res["hits"]["total"] && res["hits"]["total"]["value"]) {
                            this.journalSearchResult.totalCount = res["hits"]["total"]["value"];
                        }
                        if (res["hits"]["max_score"]) {
                            this.journalSearchResult.maxScore = res["hits"]["max_score"];
                        }
                    }


                },
                error: (e) => console.error(e)
            });
        } else if (this.selectedDocumentType == "author") {
            let url = environment.searchUrl + 'tr/yazar/ara';
            this.searchUrl = url + this.searchUrlParams;
            this.searchService.defaultSearch(this.selectedDocumentType, this.pageModel.currentPage, this.pageModel.size, this.searchText, this.searchUrlParams).subscribe({
                next: (res) => {
                    if (res["hits"] && res["hits"]["total"]) {
                        this.pageModel.totalCount = res["hits"]["total"]["value"];
                        this.searchTotalCount = this.pageModel.totalCount;
                        this.pageModel.totalPages = Math.ceil(this.pageModel.totalCount / this.pageModel.size);
                    }
                    if (res && res["aggregations"] && this.facetComponent) {
                        this.facetComponent.clearFacets();
                        this.facetComponent.parseFacetList(res["aggregations"]);
                    }
                    if (res && res["aggregations"] && this.facetComponentMobil) {
                        this.facetComponentMobil.clearFacets();
                        this.facetComponentMobil.parseFacetList(res["aggregations"]);
                    }
                    this.authorSearchResult = new AuthorSearchResult();
                    if (res["hits"] && res["hits"]["hits"]) {
                        this.authorSearchResult.authors = [];
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _author = new AuthorDTO();
                            _author.highlight = res["hits"]["hits"][i]["highlight"];
                            _author.bindData(res["hits"]["hits"][i]["_source"]);
                            this.authorSearchResult.authors.push(_author);
                        }
                        this.exportDataList = this.authorSearchResult.authors;
                        this.exportSelectedHeaderList = ["fullName", "orcid", "citationCount", "publicationCount", "citationAvg", "otherNames", "trdizinAddress"];
                    }

                    this.constructAuthorCitationList();
                    this.authorSearchResult.totalCount = res["hits"]["total"]["value"];
                    this.authorSearchResult.maxScore = res["hits"]["max_score"];
                },
                error: (e) => console.error(e)
            });
        } else if (this.selectedDocumentType == "institution") {
            let url = environment.searchUrl + 'tr/kurum/ara';
            this.searchUrl = url + this.searchUrlParams;
            this.searchService.defaultSearch(this.selectedDocumentType, this.pageModel.currentPage, this.pageModel.size, this.searchText, this.searchUrlParams).subscribe({
                next: (res) => {
                    if (res["hits"] && res["hits"]["total"]) {
                        this.pageModel.totalCount = res["hits"]["total"]["value"];
                        this.searchTotalCount = this.pageModel.totalCount;
                        this.pageModel.totalPages = Math.ceil(this.pageModel.totalCount / this.pageModel.size);
                    }
                    if (res && res["aggregations"] && this.facetComponent) {
                        this.facetComponent.clearFacets();
                        this.facetComponent.parseFacetList(res["aggregations"]);
                    }
                    if (res && res["aggregations"] && this.facetComponentMobil) {
                        this.facetComponentMobil.clearFacets();
                        this.facetComponentMobil.parseFacetList(res["aggregations"]);
                    }
                    //
                    this.institutionSearchResult = new InstitutionSearchResult();
                    if (res["hits"] && res["hits"]["hits"]) {
                        this.institutionSearchResult.institutions = [];
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _institution = new InstitutionDTO();
                            _institution.highlight = res["hits"]["hits"][i]["highlight"];
                            _institution.bindData(res["hits"]["hits"][i]["_source"]);
                            this.institutionSearchResult.institutions.push(_institution);
                        }
                    }
                    // this.authorSearchResult.totalCount = res["hits"]["total"]["value"];
                    // this.authorSearchResult.maxScore = res["hits"]["max_score"];
                },
                error: (e) => console.error(e)
            });
        }
        const config = {attributes: true, childList: true, subtree: true};
        const targetNodes = document.querySelectorAll('.latex');
        this.searchObserver = new MutationObserver((mutationsList, observer) => {
            this.mathJaxService.render();
        });
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            if (targetNode) {
                this.searchObserver.observe(targetNode, config);
            }
        }
    }

    getPdf(selectedPublication: PublicationDTO) {
        if (selectedPublication.pdf) {
            this.searchService.getPdfFile(selectedPublication.pdf).subscribe(res => {
                if (res) {
                    window.open(res.toString(), '_blank', 'noreferrer');
                }
                this.loadingService.hideLoader();
            })
        }
    }

    urlFormat(url) {
        if (!url) {
            return url;
        }
        url = url.replaceAll("<strong class=\"highlight\">", "");
        url = url.replaceAll("</strong>", "");
        return slugify(url, {
            lower: true,
            remove: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
            strict: true,
            trim: true
        });
    }


    constructAuthorCitationList() {

        if (this.authorSearchResult && this.authorSearchResult.authors && this.authorSearchResult.authors.length > 0) {
            let authorIdList = "";
            for (let i = 0; i < this.authorSearchResult.authors.length; i++) {
                let _author = this.authorSearchResult.authors[i];

                authorIdList += "\"" + _author.id + "\"";
                if (i < this.authorSearchResult.authors.length - 1) {
                    authorIdList += ", ";
                }
            }

            authorIdList = authorIdList.trim();


            this.searchService.getAuthorCitationsByIdList(authorIdList).subscribe({
                next: (res) => {
                    let authorPublicationCount = new Map<number, number>;
                    let authorCitationCount = new Map<number, number>;

                    if (res["hits"] && res["hits"]["hits"]) {
                        // this.publicationFields = new Array<AuthorPublicationFieldsDTO>;
                        if (res["hits"] && res["hits"]["hits"]) {
                            for (let i = 0; i < res["hits"]["hits"].length; i++) {
                                let _publicationFields = new AuthorPublicationFieldsDTO();
                                _publicationFields.bindData(res["hits"]["hits"][i]["fields"]);
                                let _citationCountOfPublication = 0;
                                if (_publicationFields.citedReferencesSourceIds) {
                                    _citationCountOfPublication = new Set(_publicationFields.citedReferencesSourceIds).size;
                                }


                                this.authorSearchResult.authors.forEach(_author => {
                                    if (_publicationFields.authorId.find(aId => {
                                        return aId == Number(_author.id);
                                    })) {
                                        if (!authorPublicationCount.get(Number(_author.id))) {
                                            authorPublicationCount.set(Number(_author.id), 1);
                                        } else {
                                            authorPublicationCount.set(Number(_author.id), authorPublicationCount.get(Number(_author.id)) + 1);
                                        }

                                        if (!authorCitationCount.get(Number(_author.id))) {
                                            authorCitationCount.set(Number(_author.id), _citationCountOfPublication);
                                        } else {
                                            authorCitationCount.set(Number(_author.id), authorCitationCount.get(Number(_author.id)) + _citationCountOfPublication);
                                        }

                                        let _citationCount = authorCitationCount.get(Number(_author.id));
                                        let _publicationCount = authorPublicationCount.get(Number(_author.id));
                                        _author.citationCount = _citationCount ? _citationCount : 0;
                                        _author.publicationCount = _publicationCount ? _publicationCount : 0;

                                    }
                                });


                            }
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }
    }

    clearModal() {
        this.selectedAdvanceSearchField = '';
        this.advanceSearchFromYear = '';
        this.advanceSearchToYear = '';
        this.advanceSearchContainsAllWord = '';
        this.advanceSearchContainsAnyWord = '';
        this.advanceSearchNotContainsAsIsTags = [];
        this.advanceSearchYearNotContain = [];
        this.advanceSearchContainsAsIsTags = [];
        this.advanceSearchList = [];

    }

    render(publication?) {
        if (publication) {
            publication.showAbstractCollapse = !publication.showAbstractCollapse
        }
        const config = {attributes: true, childList: true, subtree: true};
        const targetNodes = document.querySelectorAll('.latex');
        this.observer = new MutationObserver((mutationsList, observer) => {
            this.observer.disconnect();
            this.mathJaxService.render();
        });
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            if (targetNode) {
                this.observer.observe(targetNode, config);
            }
        }
    }

    protected readonly getCurrentLanguage = getCurrentLanguage;
    protected readonly getCurrentYear = getCurrentYear;
    protected readonly Number = Number;

    ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
        if (this.searchObserver) {
            this.searchObserver.disconnect();
        }
    }

    addAnalysisList(publication: PublicationDTO) {
        this.appComponent.addAnalysisList(publication);
    }

    addAllAnalysisList() {
        this.appComponent.addAllAnalysisList(this.publicationSearchResult.publications);
    }

    removeAnalysisList(publication: PublicationDTO) {
        this.appComponent.removeAnalysisList(publication);
    }

    isExistAnalysisList(publication: PublicationDTO): boolean {
        return this.appComponent.isExistAnalysisList(publication);
    }

    hasSearchText(searchUrlParams: string): void {
        this.hasSearchQuery = !searchUrlParams.startsWith('?q=&');
    }
}
