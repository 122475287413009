<div class="modal fade" id="advanceSearchModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header header-background">
                <h5 class="modal-title"
                    id="staticBackdropLabel">{{localizationService.getLocalizationValue('gelişmiş arama')}}</h5>
                <i #advanceSearchModalCloseButton class="fas fa-times pointer fsize-13 img-btn" style="color: white" (click)="clearModal()"
                   data-bs-dismiss="modal"></i>
            </div>
            <div class="modal-body mx-2">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                            (ngModelChange)="clearAdvanceSearchWordBoxes()"
                            [(ngModel)]="selectedAdvanceSearchField">
                        <option value=''>{{'pleaseSelect'|localize}}</option>
                        <option [disabled]="advanceSearchFieldIsDisabled(field)" value="{{field}}"
                                *ngFor="let field of advanceSearchFields" [value]="field">{{field|localize}}</option>
                    </select>
                    <label style="font-size: 1em !important;" for="floatingSelect">{{'searchField' | localize}}</label>
                </div>


                <div class="d-flex justify-content-start mt-4" *ngIf="selectedAdvanceSearchField=='year'">
                    <div class="form-floating w-50 me-1 pb-3 ">
                        <input [disabled]="selectedAdvanceSearchField==''" [min]="1900" [max]="getCurrentYear()+1"
                               mask="9999"
                               [(ngModel)]="advanceSearchFromYear"
                               type="text" class="form-control" id="fromYearAdvanceSearch">
                        <label for="fromYearAdvanceSearch">{{'fromYear'|localize}}</label>
                    </div>
                    <div class="form-floating w-50 ms-1">
                        <input [disabled]="selectedAdvanceSearchField==''" [min]="1900" [max]="getCurrentYear()+1"
                               mask="9999"
                               [(ngModel)]="advanceSearchToYear"
                               type="text" class="form-control" id="toYearAdvanceSearch">
                        <label for="toYearAdvanceSearch">{{'toYear'|localize}}</label>
                    </div>

                </div>

                <div class="w-100 mt-3" *ngIf="selectedAdvanceSearchField=='year'">
                    <tagify
                            [(ngModel)]="advanceSearchYearNotContain"
                            inputClass="form-control"
                            [settings]="getSettingsTagify('doesNotContainYears')"
                            [readonly]="false"
                            [disabled]="false"
                    ></tagify>


                </div>

                <div class="form-floating mt-3"
                     *ngIf="selectedAdvanceSearchField!='year' && selectedAdvanceSearchField!='language'  && selectedAdvanceSearchField!=''">
                    <input [(ngModel)]="advanceSearchContainsAllWord"
                           type="text" class="form-control pb-4" placeholder="xxxxxxx" id="floatingInputContains">
                    <label class="text-muted" style="font-size: 1em !important;"
                           for="floatingInputContains">{{'wordContainsAll' | localize}}</label>
                    <small class="text-muted float-right fsize-08 mb-2"><i>{{'ex'| localize}}
                        : {{'wordContainsAllExample' |localize}}</i></small>

                </div>

                <div class="form-floating mt-5"
                     *ngIf="selectedAdvanceSearchField!='year' && selectedAdvanceSearchField!='language'  && selectedAdvanceSearchField!=''">
                    <input [(ngModel)]="advanceSearchContainsAnyWord"
                           type="text" class="form-control pb-4" placeholder="xxxxxxx"
                           id="floatingInputWordContainsAny">
                    <label class="text-muted" style="font-size: 1em !important;"
                           for="floatingInputWordContainsAny">{{'wordContainsAny' | localize}}</label>
                    <small class="text-muted float-right fsize-08 mb-2"><i>{{'ex'| localize}}
                        : {{'wordContainsAnyExample' |localize}}</i></small>
                </div>

                <div class=" mt-4"
                     *ngIf="selectedAdvanceSearchField!='year' && selectedAdvanceSearchField!='language'  && selectedAdvanceSearchField!=''">

                    <tagify
                            [(ngModel)]="advanceSearchContainsAsIsTags"
                            [name]="'containPhrases'"
                            inputClass="form-control"
                            [settings]="getSettingsTagify('containPhrases')"
                            [readonly]="false"
                            [disabled]="false"
                    ></tagify>
                    <small class="text-muted float-right fsize-08 mb-2"><i>{{'ex'| localize}}
                        : {{'wordOrPhrasesContainAsIsExample' |localize}}</i></small>
                </div>

                <div class=" mt-4"
                     *ngIf="selectedAdvanceSearchField=='language'">
                    <tagify
                            [(ngModel)]="advanceSearchContainsAsIsTags"
                            [name]="'containPhrases'"
                            inputClass="form-control"
                            [settings]="getSettingsTagifyForSuggestLanguage()"
                            [readonly]="false"
                            [disabled]="false"
                    ></tagify>
                </div>

                <div class=" mt-4"
                     *ngIf="selectedAdvanceSearchField!='year' && selectedAdvanceSearchField!='language'  && selectedAdvanceSearchField!=''">

                    <tagify
                            [(ngModel)]="advanceSearchNotContainsAsIsTags"
                            inputClass="form-control"
                            [settings]="getSettingsTagify('notContainPhrases')"
                            [readonly]="false"
                            [disabled]="false"
                    ></tagify>


                    <small class="text-muted float-right fsize-08 mb-2"><i>{{'ex'| localize}}
                        : {{'wordOrPhrasesNotContainAsIsExample' |localize}}</i></small>
                </div>


                <div class=" mt-5 d-flex justify-content-end">
                    <button
                            [disabled]="selectedAdvanceSearchField==''"
                            (click)="addAdvanceQuery()"
                            class="btn btn-light-blue float-right"
                            type="button" id="addSearchBtn">{{'addToQuery'|localize}}</button>
                </div>


                <div class=" mt-3">
                    <small class="text-muted float-left fsize-08"><i>{{'searchQuery'| localize}}</i></small>
                    <hr class="light-blue mt-0  w-100">
                    <div *ngFor="let _query of advanceSearchList;index as i" class="mb-1">
                        <div class="d-flex justify-content-between">
                            <div style="text-align: justify;" class="mt-0 w-100">
                                <label class="light-blue me-1">{{_query.field|localize}}:</label> {{_query.getQuery()}}
                            </div>
                            <i title="{{'edit'|localize}}" (click)="editAdvanceSearchList(i)"
                               class="img-btn light-blue pointer fas fa-pen ms-1  me-2 " aria-hidden="true"></i>
                            <i title="{{'delete'|localize}}" (click)="removeAdvanceSearchByIndex(i)"
                               class="img-btn light-blue pointer fas fa-trash" aria-hidden="true"></i>
                        </div>
                        <hr class="light-blue mt-0  w-100">
                    </div>

                </div>


            </div>
            <div class="modal-footer">
                <button class="btn float-right" style="background-color: #13859a !important; color: white;"
                        (click)="searchAdvance()"
                        type="button" id="advanceSearchBtn">{{'search'|localize}}</button>
            </div>
        </div>
    </div>
</div>


<div class="offcanvas offcanvas-start  " tabindex="-1" id="offcanvasLeftFacet"
     aria-labelledby="offcanvasLeftFacetLabel">
    <div class="offcanvas-header p-1 bg-trdizin-dark">
        <h5 id="offcanvasLeftFacetLabel" class="mt-2 ms-2">
            <span class=" text-white fsize-1">{{'filters' |localize}}</span>
        </h5>
        <a type="button" #closeHamburgerMenuModalButton class="m-0 btn-close text-reset text-white"
           style="background-color: white;" data-bs-dismiss="offcanvas" aria-label="Close"></a>
    </div>
    <div class="offcanvas-body  ">
        <facet-component #publicationFacetComponentMobil
                         (facetChangeSearch)="mobilFacetFilter()"></facet-component>
    </div>
</div>
<div class="row mx-auto">


    <div class="col-sm-10 mx-auto ">
        <div class="row mx-auto ">

            <div
                    class=" col-sm-9 col-md-10 col-lg-12  col-xxl-9 col-11  d-flex flex-column align-items-center align-self-center">
                <div class="col-sm-10 col-md-10 col-lg-9  align-self-end">
                    <strong>
                        <ul class="w-auto nav nav-tabs nav-justified" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link {{selectedDocumentType=='publication'?'active':''}}"
                                   id="justified-tab-0" [href]="getCurrentLanguage()+'/yayin/ara?q='+searchText"
                                   aria-selected="true"><img class="mt-0" style="  width: 1.8em;"
                                                             src="/assets/images/assignment.png"><br/>{{"publication"|localize}}
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link {{selectedDocumentType=='journal'?'active':''}}"
                                   [href]="getCurrentLanguage()+'/dergi/ara?q='+searchText"><img class="mt-0"
                                                                                        style="  width: 1.8em;"
                                                                                        src="/assets/images/open-magazine.png"><br/>{{"journal"|localize}}
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link {{selectedDocumentType=='author'?'active':''}}"
                                   id="justified-tab-2"
                                   [href]="getCurrentLanguage()+'/yazar/ara?q='+searchText">
                                    <img class="mt-0" style="  width: 1.8em;"
                                         src="/assets/images/user.png"><br/>{{"author" |localize}}
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="tab-btn nav-link {{selectedDocumentType=='institution'?'active':''}}"
                                   id="justified-tab-3"
                                   [href]="getCurrentLanguage()+'/kurum/ara?q='+searchText"><img class="mt-0"
                                                                                        style="  width: 1.8em;"
                                                                                        src="/assets/images/government.png"><br/>{{"institution" |localize}}
                                </a>
                            </li>
                        </ul>
                    </strong>

                </div>
                <div class=" col-sm-10 col-md-10 col-lg-9  d-flex align-self-end mt-2">
                    <a class="d-block d-sm-block d-md-block d-lg-none align-self-center fsize-1 pointer canvas-button"
                       data-bs-toggle="offcanvas"
                       data-bs-target="#offcanvasLeftFacet"
                       aria-controls="offcanvasLeftFacet" style="position: absolute; margin-left: -5.5vw;">
                        <i class="fsize-15 fas fa-filter"></i>
                    </a>

                    <div class="input-group">
                        <span class=" input-group-text"
                              style="{{isAdvanceSearch()==true?' color:green; ':''}}  background-color: transparent; border: none; position: absolute; margin-left: 0; z-index: 10;  height: 90%; "
                              id="basic-addon1"><i class="fa fa-spa" aria-hidden="true"></i></span>
                        <input [(ngModel)]="searchText" type="text" class="form-control search-box form-control"
                               [placeholder]="'Searching' |localize"  style="padding-left: 35px;"
                               (keyup.enter)="publicationSearch(true)">


                    </div>
                    <span style="background: #13859a; color: white" class="pointer input-group-text"
                          (click)="publicationSearch(true)"
                          id="searchButton"><i class="fas fa-search"></i></span>
                </div>
                <div class="align-self-end fsize-08 fst-italic font-Quaternary mt-2"
                     *ngIf="selectedDocumentType == 'journal'"
                     data-bs-toggle="modal"
                     data-bs-target="#infoModal"
                     style="cursor: pointer;"
                     title="Daha fazla bilgi için tıklayın">
                    <i class="fa fa-exclamation-circle font-l text-danger ml-2"></i>
                    {{'üakInfo' | localize}}
                </div>
                <!-- The Modal -->
                <div class="modal fade" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header header-background">
                                <h5 class="modal-title" id="infoModalLabel">
                                    {{ 'TRDizinJournalUpdates' | localize }}
                                </h5>
                                <i class="fas fa-times pointer fsize-13" style="color: white" data-bs-dismiss="modal"></i>
                            </div>
                            <div class="modal-body fsize-09">
                                <p style="margin-top: 0; margin-bottom: 1em;">
                                    ÜAK Doçentlik başvurularında yer alan "Ulusal Hakemli Dergi" tanımında yer alan ULAKBİM TR Dizin dergi listesidir.
                                </p>
                                <ul>
                                    <li>{{'JournalListsFor2023and2024ContinuetoBeUpdated' | localize}}</li>
                                    <li>{{'AftertheFirstIssueofEachYearisSubmittedtoTRIndex' | localize}}</li>
                                    <li>{{'UpdateFrequencyoftheListItisUpdatedInstantly' | localize}}</li>
                                    <li>{{'TheNewYearsListisPublishedattheEndofMarch' | localize}}</li>
                                </ul>
                                <p class="f-bold" style="margin-top: 1em; margin-bottom: 0;">
                                    <span>{{'YearsWithGreenorGreenGreyIntheListMeanThatTheyareIndexedinTRIndex' | localize}}</span>
                                    {{'ForDetailedInformation' | localize}}
                                    <a href="https://trdizin.gov.tr/yardim/" class="font-Quaternary" style="text-decoration: underline;">{{'clickHere' | localize}}</a>
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'Close' | localize}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="align-self-end" *ngIf="this.selectedDocumentType=='publication'">
                    <a class="fsize-08 fst-italic" data-bs-toggle="modal" data-bs-target="#advanceSearchModal">
                        {{'advanceSearch' | localize}}
                    </a>
                </div>

            </div>


            <div class="d-flex justify-content-between flex-nowrap mt-4 pe-0 mx-auto col-sm-12 col-md-12  col-12  ">
                <div class="align-self-end me-3">
                    <i class=" fsize-1">{{('findedTotalResult'|localize) + ' : ' }}<strong
                            class="light-blue">{{((pageModel.totalCount|number: '1.') + '').replace(',', '.')}}</strong></i>
                </div>


                <div class="d-flex justify-content-end ms-3">
                        <div class="flex-column">
                            <div class=" d-flex mb-0 justify-content-end ms-auto align-items-center" *ngIf="searchText && loginSuccess && hasSearchQuery === true" >
                                <app-save-search  [searchText]="searchText" [searchUrl]="searchUrl" [searchTotalCount]="searchTotalCount"></app-save-search>
                            </div>
                            <div *ngIf="this.selectedDocumentType=='journal'" class="d-flex mb-0 justify-content-end ms-auto align-items-center">
                                <a class="fsize-1 light-blue" [href]="getCurrentLanguage()+'/dergionerisi'">{{'JournalFinderButtonText'|localize}}</a>
                            </div>

                            <select class="w-100 form-select mb-0 mt-3 pb-0 fsize-1" style="border: none; background-color: transparent;"
                                    [(ngModel)]="selectedSortType" (change)="onSortTypeChanged($event.target.value)">
                                <option class="fsize-09" *ngFor="let _sort of sortTypes"
                                        value="{{_sort}}">{{_sort|localize}}</option>
                            </select>
                        </div>
                </div>
            </div>

            <hr class="mt-0 text-muted">


            <!--            <hr class="mt-5 mb-3" style="margin: 0;">-->


            <div class="row mx-auto for-footer-height">
                <div class="ps-0 col-sm-3 col-md-3 col-lg-3  col-xl-3  col-xxl-3 d-none d-lg-block ">
                    <facet-component #publicationFacetComponent
                                     (facetChangeSearch)="publicationSearch(true)"></facet-component>
                </div>

                <div class="pe-0 mx-auto col-sm-12 col-md-12 col-lg-9  col-12 ">
                    <div *ngIf="selectedDocumentType=='publication'">
                        <small class="ms-5 mt-1 text-muted dark-blue float-left  pointer fsize-07 pointer img-btn"
                               *ngIf="publicationSearchResult && publicationSearchResult.publications && publicationSearchResult.publications.length>0"
                               (click)="addAllAnalysisList(null)">
                            <i class="far fa-plus-square me-1 text-muted"></i>
                            <label class="pointer text-muted">{{'addAllAnalysisList'|localize}} </label>
                        </small>
                        <div class="latex mb-2 ps-1 pe-4 pt-4 pb-4 search-result"
                             *ngFor="let publication of publicationSearchResult.publications; index as i">
                            <div class="d-flex ">
                                <div class="d-flex flex-column  col-md-1">
                                    <h6 class="text-center   light-blue">{{((pageModel.currentPage - 1) * pageModel.size) + i + 1}}
                                        .</h6>
                                    <div class="mx-auto">
                                        <img *ngIf="publication.docType=='PAPER'"
                                             src="https://static.trdizin.gov.tr/images/svg/paper.svg" class="result-img"
                                             style="float: right; width: 44px;height: 75px;">
                                        <img *ngIf="publication.docType=='PROJECT'"
                                             src="https://static.trdizin.gov.tr/images/svg/project.svg"
                                             class="result-img"
                                             style="float: right; width: 44px;height: 75px;">
                                    </div>
                                </div>
                                <div class="col-md-10 col-9">
                                    <div class="d-flex justify-content-between ">
                                        <div class="col-md-11 " style="text-align: justify; width: 100%; ">
                                            <a class="light-blue font-noto-regular" target="_blank"
                                               [href]="getCurrentLanguage()+'/yayin/detay/'+publication.id+'/'+urlFormat(publication.abstracts && publication.abstracts.length>0 ?publication.abstracts[0].title :'-')" [innerHTML]="publication.abstracts && publication.abstracts.length>0 ?publication.abstracts[0].title :'-'">{{publication.abstracts && publication.abstracts.length>0 ?publication.abstracts[0].title :'-'}}</a>
                                        </div>

                                    </div>
                                    <!--                                    <hr class="mt-0 text-muted">-->
                                    <div class="d-flex justify-content-start flex-wrap"
                                         *ngIf="  publication.authors && publication.showAuthorCollapse==false; else authorMore">
                                        <section class="me-2 light-blue"
                                                 *ngFor="let author of publication.authors.length>7 ? publication.authors.slice(0,7):publication.authors; let isLast=last">
                                            <small>
                                                <a target="_blank" [href]="getCurrentLanguage()+'/yazar/detay/'+author.authorId+'/'+urlFormat(author.name)">
                                                    <i *ngIf="author.isVerified" class="fa fa-check fsize-09 me-1"></i>
                                                    <span class="author-name" [innerHTML]="author.name"></span>
                                                    <span class="author-comma">{{(publication.showAuthorCollapse == true) || !isLast ? ',' : ''}}</span>
                                                </a>
                                            </small>

                                            <small>
                                                <a class="ms-2"
                                                   (click)="publication.showAuthorCollapse = !publication.showAuthorCollapse"
                                                   *ngIf="isLast">
                                                    <i *ngIf="publication.authors.length>7 && publication.showAuthorCollapse==false"
                                                       class="fas fa-caret-square-down pointer"></i>
                                                </a>
                                            </small>
                                        </section>
                                    </div>

                                    <ng-template #authorMore>
                                        <div class="d-flex justify-content-start flex-wrap"
                                             *ngIf=" publication.authors && publication.showAuthorCollapse==true">
                                            <section class="me-2 light-blue"
                                                     *ngFor="let author of  publication.authors; index as authorIndex;let isLast=last">
                                                <small>
                                                    <a target="_blank" [href]="getCurrentLanguage()+'/yazar/detay/'+author.authorId+'/'+urlFormat(author.name)">
                                                        <i *ngIf="author.isVerified" class="fa fa-check fsize-09 me-1"></i>
                                                        <span class="author-name" [innerHTML]="author.name"></span>
                                                        <span class="author-comma">{{!isLast ? ',' : ''}}</span>
                                                    </a>
                                                </small>
                                                <small>
                                                    <a class="ms-2"
                                                       (click)="publication.showAuthorCollapse = !publication.showAuthorCollapse"
                                                       *ngIf="isLast">
                                                        <i class="fas fa-caret-square-up pointer"></i>
                                                    </a>
                                                </small>
                                            </section>
                                        </div>
                                    </ng-template>
                                    <small *ngIf="publication.journal"><label
                                            class="medium-text"><a target="_blank"
                                                                   [href]="getCurrentLanguage()+'/dergi/detay/'+publication.journal.id">{{publication.journal.name}}</a></label></small>
                                    <div>
                                        <div *ngIf="publication.docType=='PAPER'">
                                            <small *ngIf="publication.publicationYear"
                                                   class="text-muted meta-info me-2">{{'year' | localize}}:
                                                <strong>{{publication.publicationYear}}</strong></small>
                                            <small *ngIf="publication.issue.volume" class="text-muted meta-info me-2 ">{{'volume' | localize}}:
                                                <strong>{{publication.issue.volume}}</strong></small>
                                            <small *ngIf="publication.issue.number" class="text-muted meta-info me-2">{{'number' | localize}}:
                                                <strong>{{publication.issue.number}}</strong></small>
                                            <small *ngIf="publication.startPage ||publication.endPage "
                                                   class="text-muted meta-info">{{'page' | localize}}:
                                                <strong>{{(publication.startPage + ' - ' + publication.endPage)}}</strong></small>
                                        </div>
                                        <div *ngIf="publication.docType=='PROJECT'">
                                            <small *ngIf="publication.publicationYear"
                                                   class="text-muted meta-info me-2">{{"ProjectDeadline"|localize}}:
                                                <strong>{{publication.endDate|utcToLocalTime:'DD.MM.YYYY'}}</strong></small>
                                        </div>
                                        <small class="text-muted dark-blue float-left  pointer fsize-07 pointer img-btn"
                                               *ngIf="isExistAnalysisList(publication)==false"
                                               (click)="addAnalysisList(publication)">
                                            <i class="far fa-plus-square me-1 text-muted"></i>
                                            <label class="pointer text-muted">{{'addAnalysisList'|localize}} </label>
                                        </small>

                                        <small class="text-muted dark-blue float-left  pointer fsize-07 pointer img-btn"
                                               *ngIf="isExistAnalysisList(publication)==true"
                                               (click)="removeAnalysisList(publication)">
                                            <i class="far fa-minus-square me-1 text-muted"></i>
                                            <label class="pointer text-muted">{{'removeAnalysisList'|localize}} </label>
                                        </small>

                                        <small class="dark-blue float-right  pointer"
                                               (click)="render(publication)">
                                            <i *ngIf="publication.showAbstractCollapse==false"
                                               class="fas fa-angle-double-down"
                                               style="font-size: 1.2em; line-height: unset;"></i>
                                            <i *ngIf="publication.showAbstractCollapse==true"
                                               class="fas fa-angle-double-up"
                                               style="font-size: 1.2em; line-height: unset;"></i>
                                            {{'abstractContent'|localize}}
                                        </small>
                                        <small class="dark-blue float-right  pointer me-2">
                                            <i *ngIf="!publication.pdf || publication.accessType=='CLOSED'" class="fas fa-lock"
                                               title="{{'FullTextAccessDenied' |localize}}"
                                               style="font-size: 1.2em; line-height: unset;"></i>
                                            <i *ngIf="publication.pdf &&  publication.accessType=='OPEN'" class="far fa-file-pdf pointer"
                                               (click)="getPdf(publication)"
                                               title="{{'FullText' |localize}}"
                                               style="font-size: 1.2em; line-height: unset;"></i>
                                        </small>
                                    </div>
                                </div>

                                <div class="light-blue col-md-1 col-2 text-center d-flex flex-column" *ngIf="publication.getCitationCount() >0">
                                    <h1 style="font-size: 2.5em !important;
                                     text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;background-color: transparent!important;">{{publication.getCitationCount()}}</h1>
                                    <small>{{'Reference'|localize}}</small>
                                </div>
                            </div>
                            <div class="ps-5 pt-2 pe-1 dark-blue "
                                 style="width:100%; text-align: justify; max-height: 25vh;  overflow-y: auto "
                                 *ngIf=" publication.showAbstractCollapse==true">
                                <p class="text-break font-noto-light" [innerHTML]="publication.abstracts[0].abstract">{{publication.abstracts[0].abstract}}</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedDocumentType=='journal'">
                        <div class=" mb-3 ps-2 pe-2 pt-2 pb-2 search-result" style="min-height: 15vh!important;"
                             *ngFor="let journal of journalSearchResult.journals; index as i">
                            <div class="d-flex mt-1   justify-content-start w-100">
                                <div class="d-flex mt-1 ms-3 justify-content-start  ">
                                    <img *ngIf="!journal.coverPath"
                                         src="https://static.trdizin.gov.tr/images/svg/journal.svg"
                                         class=" ms-0 mt-2"
                                         style="min-width:70px; height: 60px;">
                                    <img *ngIf="journal.coverPath"
                                         src="{{'https://download.trdizin.gov.tr/'+journal.coverPath+'?contentDisposition=inline'}}"
                                         class="cover-img-static "
                                         style=" height: 100px;min-width:80px;  width: 4vw">
                                </div>

                                <div class="ms-5 mt-2 d-flex flex-column  ">
                                    <div class=" col-md-12 d-flex flex-wrap" style="text-align: justify; width: 100%; ">
<!--                                        number of journal-->
<!--                                        <h6 class="  light-blue">{{(((pageModel.currentPage - 1) * pageModel.size) + i + 1) + '.'}}</h6>-->
                                        <strong><a class="light-blue fsize-12" target="_blank"
                                                   [href]="getCurrentLanguage()+'/dergi/detay/'+journal.id+'/'+urlFormat(journal.title)"
                                        [innerHTML]="journal.title"
                                        >{{journal.title}}</a>
                                        </strong>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-start flex-wrap fsize-09">
                                        <strong *ngIf="journal.issn" class="green-blue me-3">ISSN:
                                            <strong class="dark-blue">{{journal.issn}}</strong>
                                        </strong>

                                        <strong *ngIf="journal.eissn" class="green-blue">EISSN:
                                            <strong class="dark-blue">{{journal.eissn}}</strong>
                                        </strong>

                                    </div>

                                    <div class="d-flex justify-content-start fsize-09">
                                        <strong *ngIf="journal.getEditor()" class="flex-fill  green-blue">{{'Editor' | localize}}
                                            <strong class="dark-blue">{{journal.getEditor().name}}</strong>
                                        </strong>
                                    </div>

                                    <div class="mt-1 d-flex flex-wrap fsize-09">
                                        <label (click)="_year.isJournalYearSubjectCollapseOpen=!_year.isJournalYearSubjectCollapseOpen;journal.selectedJournalYear=_year;"
                                               *ngFor="let _year of journal.journalYear.slice(0,4)">
                                            <a>
                                                <i class="fas fa-circle me-1"
                                                   style="{{_year.isGrayStatus()==true?'color:gray':'color:green'}}"></i>
                                                <label class="me-2 pointer">{{_year.year}}</label>
                                            </a>
                                        </label>
                                        <a class="me-3 light-blue fsize-09 pointer" *ngIf="journal.journalYear.length>4"
                                           target="_blank"
                                           [href]="getCurrentLanguage()+'/dergi/detay/'+journal.id+'/'+urlFormat(journal.title)"
                                           style="font-style: italic;">{{'allTheYears' | localize }}</a>
                                        <strong>
                                            <a class="dark-blue fsize-09 pointer" *ngIf="journal.webAddress"
                                               target="_blank" rel="nofollow"
                                               href="{{journal.webAddress}}">
                                                <label class="me-1 pointer">{{ 'WebAddress' | localize }}</label>
                                                <small><i
                                                        class="fas fa-external-link-alt"></i>
                                                </small></a>
                                        </strong>
                                    </div>
                                    <div class="d-flex flex-wrap mt-2 ms-3"
                                         *ngIf="journal.selectedJournalYear && journal.selectedJournalYear.isJournalYearSubjectCollapseOpen">
                                            <span *ngFor="let _subject of journal.selectedJournalYear.subjects"
                                                  style=" text-decoration: underline;"
                                                  class="fsize-08 me-3 mb-2">{{_subject.fullName}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedDocumentType=='author'" class="d-flex justify-content-start flex-wrap">
                        <div class="ps-4 pe-4 pt-4 pb-4 search-result col-12 col-sm-12 col-md-6"
                             *ngFor="let author of authorSearchResult.authors; index as i">
                            <div class="p-4 d-flex justify-content-start" style="border: 1px solid; min-height: 15vh;">
                                <div class="d-flex justify-content-start w-50">
                                    <h6 class="text-center me-1  light-blue number-format">{{(((pageModel.currentPage - 1) * pageModel.size) + i + 1) + '.'}}</h6>
                                    <div class="w-50">
                                        <img *ngIf="author.status=='REAL'"
                                             src="/assets/images/author-verified.svg"
                                             class="result-img ms-1 w-100">
                                        <img *ngIf="author.status=='POOL'"
                                             src="/assets/images/author.svg"
                                             class="result-img ms-1 w-100">
                                    </div>
                                </div>
                                <div class="ms-0 d-flex flex-column  w-100">
                                    <div class=" col-md-12 " style="text-align: justify; width: 100%; ">
                                        <strong><a class="light-blue fsize-12" target="_blank"
                                                   [innerHTML]="author.fullName"
                                                   [href]="getCurrentLanguage()+'/yazar/detay/'+author.id+'/'+urlFormat(author.fullName)">{{author.fullName}}</a>
                                        </strong>
                                    </div>

                                    <div *ngIf="author.orcid" class="d-flex justify-content-start flex-wrap fsize-1">
                                        <small *ngIf="author.orcid" class="mt-auto mb-1">
                                            <label><img class="me-1" src="/assets/images/orcid_16x16.webp">
                                                <a class="mt-1" target="_blank"
                                                   [innerHTML]="author.orcidHighLight"
                                                   href="https://orcid.org/{{author.orcid}}">{{author.orcidHighLight}}</a></label>
                                        </small>
                                    </div>


                                    <div class="d-flex justify-content-start fsize-09 flex-wrap">
                                        <div class="me-3">
                                            <label class="">{{('publicationCount'|localize) + ': '}}
                                                <strong>{{author.publicationCount}}</strong></label>
                                        </div>
                                        <div class="">
                                            <label class="">{{('citationCount'|localize) + ': '}}
                                                <strong>{{author.citationCount}}</strong></label>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>


                    <div *ngIf="selectedDocumentType=='institution'" class="d-flex justify-content-start flex-wrap">
                        <div class="ps-4 pe-4 pt-4 pb-4 search-result col-12 col-sm-12 col-md-12"
                             *ngFor="let institution of institutionSearchResult.institutions; index as i">
                            <div class="mx-auto d-flex justify-content-start" style="min-height:110px; height: 12vh;">
                                <div class="d-flex justify-content-start  ">
                                    <h6 class="text-center me-1  light-blue">{{(((pageModel.currentPage - 1) * pageModel.size) + i + 1) + '.'}}</h6>
                                </div>
                                <div class="ms-3 d-flex flex-column  w-100">
                                    <div class=" col-md-12 " style=" width: 100%; ">
                                        <strong><a class="light-blue fsize-12" target="_blank"
                                                   [innerHTML]="institution.name"
                                                   [href]="getCurrentLanguage()+'/kurum/detay/'+institution.codes[0]+'/'+urlFormat(institution.name)">{{institution.name}}</a>
                                        </strong>
                                    </div>

                                    <div class="d-flex justify-content-start flex-wrap mt-1 ms-1 col-12 col-sm-12 col-md-9 col-lg-12 col-xl-6 col-xxl-6">
                                        <div class="d-flex justify-content-start col-12">
                                            <label class="col-4 dark-blue fsize-09">
                                                {{'facet-status'|localize}}:
                                            </label>
                                            <strong class="col-8 fsize-09 light-blue">{{institution.status ? (institution.status|localize) : '-'}}</strong>
                                        </div>

                                        <div class="d-flex justify-content-start col-12">
                                            <label class="col-4 dark-blue fsize-09">
                                                {{'facet-type'|localize}}:
                                            </label>
                                            <strong class="col-8 fsize-09 light-blue">{{institution.type ? (institution.type|localize) : '-'}}</strong>
                                        </div>

                                        <div class="d-flex justify-content-start col-12">
                                            <label class="col-4 dark-blue fsize-09">
                                                {{'facet-country'|localize}}:
                                            </label>
                                            <strong class="col-8 fsize-09 light-blue">{{institution.country ? institution.country : '-'}}</strong>
                                        </div>

                                        <div class="d-flex justify-content-start col-12">
                                            <label class="col-4 dark-blue fsize-09">
                                                {{'facet-city'|localize}}:
                                            </label>
                                            <strong class="col-8 fsize-09  light-blue">{{institution.city ? institution.city : '-'}}</strong>

                                        </div>

                                    </div>

                                    <!--                                    <div class="d-flex justify-content-start flex-wrap ms-1 col-12 col-sm-12 col-md-9 col-lg-12 col-xl-6 col-xxl-6">-->
                                    <!--                                -->

                                    <!--                                    </div>-->


                                </div>


                            </div>
                        </div>
                    </div>

                    <hr class="mb-2 text-muted">

                    <div class="w-100 row">
                        <div class="col-12 col-md-10 mt-2">
                            <trd-paginater *ngIf="selectedDocumentType" [pageList]="[20,50,100]" [pageModel]="pageModel"
                                           (pageChangeEvent)="changeSearchResultPage($event)"
                                           [exportDataList]="exportDataList">
                            </trd-paginater>
                        </div>
                        <div class="col-12 col-md-2">
                            <span class="float-right">
                                <export-component [fieldSelect]="true" [documentType]="selectedDocumentType"
                                                  [searchText]="searchText" [searchUrlParams]="searchUrlParams">
                            </export-component>
                            </span>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</div>
