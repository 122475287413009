import {BaseDTO} from "../../core/BaseDTO";

export class JournalPublicationFieldsDTO extends BaseDTO {
    public id: string;
    public publicationType: string;
    public issueYear: string;
    public citedReferencesIds: number[];
    public citedReferencesJournalCodes: number[];
    public citedReferencesSourceIds: number[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);


        if (obj.id && obj.id.length > 0) {
            this.id = obj.id[0];
        } else if (obj.id) {
            this.id = obj.id;
        }


        if (obj.publicationType && obj.publicationType.length > 0) {
            this.publicationType = obj.publicationType[0];
        } else if (obj.docType) {
            this.publicationType = obj.publicationType;
        }



        if (obj["issue.year"] && obj["issue.year"].length>0) {
            this.issueYear = obj["issue.year"][0];
        }

        if (obj["citedReferences.id"]) {
            this.citedReferencesIds = obj["citedReferences.id"];
        }


        if (obj["citedReferences.journalCode"]) {
            this.citedReferencesJournalCodes = obj["citedReferences.journalCode"];
        }


        if (obj["citedReferences.sourceId"]) {
            this.citedReferencesSourceIds = obj["citedReferences.sourceId"];
        }


    }


}
