import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FacetDTO} from "./FacetDTO";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {FacetBucketDTO} from "./FacetBucketDTO";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {ActivatedRoute, Router} from "@angular/router";
import {FacetSubjectDTO} from "./FacetSubjectDTO.TS";
import {Subject} from "rxjs";
import {FormsModule} from "@angular/forms";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {LocalizationPipe} from "../../../trdcore/pipes/localization-pipe";

@Component({
    selector: 'facet-component',
    standalone: true,
    imports: [
        NgForOf,
        TrdCoreModule,
        NgIf,
        DecimalPipe,
        FormsModule
    ],
    templateUrl: './facet.component.html',
    styleUrl: './facet.component.scss'
})
export class FacetComponent implements OnInit {
    @Output() facetChangeSearch = new EventEmitter();
    newFacetSelected = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router,private localizationService: LocalizationService) {
    }

    facetMap = new Map();
    public facetList: FacetDTO[];
    public facetSubjectList: FacetSubjectDTO[]=[];
    public facetSearchText:string='';
    public originalFacetList: FacetDTO[] = [];
    public selectedFacetMap = new Map<string, string[]>();

    submitFilters() {
        this.newFacetSelected = false;
        this.facetSearchText="";
        this.facetChangeSearch.emit()
    }

    clearFilters() {
        this.selectedFacetMap.clear();
        this.submitFilters();
    }

    getFacetFilterList() {
        let _filter = [];
        if (this.selectedFacetMap && this.selectedFacetMap.keys()) {
            for (let key of Array.from(this.selectedFacetMap.keys())) {
                this.selectedFacetMap.get(key).forEach(v => {
                    let obj = {};
                    obj["value"] = v;
                    obj["key"] = key;
                    _filter.push(obj);
                });
            }
        }
        return _filter;
    }

    isFacetSelected(key, value) {
        if (!this.selectedFacetMap.get(key)) {
            return false;
        }
        let _values = this.selectedFacetMap.get(key).filter(v => {
            return v == value;
        });

        if (_values && _values.length > 0) {
            return true;
        }
        return false;
    }


    removeFacetFilter(event) {
        let key = event.target.dataset.key;
        let value = event.target.dataset.value;
        this.removeFacetFilterByKeyValue(key, value);
    }

    removeFacetFilterByKeyValue(key: string, value: string) {
        let _values = this.selectedFacetMap.get(key).filter(v => {
            return v != value;
        });
        if (!_values || _values.length == 0) {
            this.selectedFacetMap.delete(key);
        } else {
            this.selectedFacetMap.set(key, _values);
        }
        // this.facetChangeSearch.emit();
        this.newFacetSelected = true;
    }
    // multiSubjectSelect(subject:FacetSubjectDTO){
    //     if(subject.childrens.length>0){
    //         console.log("aaaa");
    //         subject.childrens.forEach(c=>{
    //             if(c.childrens.length>0){
    //                 c.childrens.forEach(cc=>{
    //                     this.facetChangeClickLabel('facet-subject',cc.hierarchySubject);
    //                 })
    //             }else{
    //                 this.facetChangeClickLabel('facet-subject',c.hierarchySubject);
    //             }
    //         })
    //     }else{
    //         this.facetChangeClickLabel('facet-subject',subject.hierarchySubject);
    //     }
    // }
    facetChangeClickLabel(key, value) {
        if (!this.selectedFacetMap.get(key)) {
            this.selectedFacetMap.set(key, [value]);
        } else if (!this.selectedFacetMap.get(key).find(v => {
            return v == value;
        })) {
            this.selectedFacetMap.get(key).push(value);
        } else {
            let _values = this.selectedFacetMap.get(key).filter(v => {
                return v != value;
            });
            if (!_values || _values.length == 0) {
                this.selectedFacetMap.delete(key);
            } else {
                this.selectedFacetMap.set(key, _values);
            }
        }
        this.newFacetSelected = true;
        // this.facetChangeSearch.emit();
    }

    facetChange(event) {
        let key = event.target.dataset.key;
        let value = event.target.dataset.value;
        this.facetChangeClickLabel(key, value);
    }


    public selectFacet(key: string, value: string) {
        if (!this.selectedFacetMap.get(key)) {
            this.selectedFacetMap.set(key, [value]);
        } else if (!this.selectedFacetMap.get(key).find(v => {
            return v == value;
        })) {
            this.selectedFacetMap.get(key).push(value);
        }
    }


    clearFacets() {
        this.facetList = [];
    }

    parseFacetList(facet) {
        this.facetMap.forEach((value, key) => {
            if (facet[key]) {
                let _facet = new FacetDTO();
                _facet.order = value;
                _facet.key = key;
                _facet.valueList = new Array();
                if (facet[key]["values"]) {
                    for (let i = 0; i < facet[key]["values"]["buckets"].length; i++) {
                        let _bucket = new FacetBucketDTO();
                        _bucket.bindData(facet[key]["values"]["buckets"][i]);
                        _facet.valueList.push(_bucket);
                    }
                } else if (facet[key]["buckets"]) {
                    for (let i = 0; i < facet[key]["buckets"].length; i++) {
                        let _bucket = new FacetBucketDTO();
                        _bucket.bindData(facet[key]["buckets"][i]);
                        _facet.valueList.push(_bucket);
                    }
                } else if (facet[key][key] && facet[key][key]["buckets"]) {
                    for (let i = 0; i < facet[key][key]["buckets"].length; i++) {
                        let _bucket = new FacetBucketDTO();
                        _bucket.key = facet[key][key]["buckets"][i]["key"];
                        if (facet[key][key]["buckets"][i]["unique"]) {
                            _bucket.doc_count = facet[key][key]["buckets"][i]["unique"]["value"];
                        } else {
                            _bucket = facet[key][key]["buckets"][i]["doc_count"];
                        }
                        _facet.valueList.push(_bucket);
                    }
                }
                if ((key == "facet-year" || key == "facet-publication_year") && _facet.valueList) {
                    _facet.valueList = _facet.valueList.sort((a, b) => Number(b.key) - Number(a.key));
                }
                if (key == "facet-subject" && _facet.valueList) {
                    if (_facet.valueList.length > 0) {
                      this.facetSubjectList=this.createHierarchy(_facet.valueList);
                    }
                }
                this.addFacet(_facet);
                this.originalFacetList = this.facetList;
            }
        });
    }
    toggleChildSubjects(subject: FacetSubjectDTO) {
        subject.showChildren = !subject.showChildren;
    }
    createHierarchy(subjects) {
        const root = [];
        subjects.forEach(subject => {
            const levels = subject.key.split('>');
            let currentLevel = root;

            levels.forEach((level, index) => {
                let existingNode = currentLevel.find(node => node.name === level);
                if (!existingNode) {
                    existingNode = {
                        name: level,
                        childrens: [],
                        hierarchySubject:subject.key,
                        doc_count:subject.doc_count
                    };
                    currentLevel.push(existingNode);
                }
                currentLevel = existingNode.childrens;
            });
        });
        return root;
    }
    toTurkishLowerCase(text: string): string {
        const turkishUpperToLower: { [key: string]: string } = {
            'Ç': 'ç',
            'Ğ': 'ğ',
            'I': 'ı',
            'İ': 'i',
            'Ö': 'ö',
            'Ş': 'ş',
            'Ü': 'ü'
        };

        return text.replace(/[ÇĞIİÖŞÜ]/g, char => turkishUpperToLower[char]).toLowerCase();
    }
    searchByFacetKey($event) {
        let searchTextLower = this.facetSearchText.trim();
        searchTextLower=this.toTurkishLowerCase(searchTextLower);
        if (searchTextLower === "") {
            this.facetList = this.originalFacetList;
        } else {
            this.facetList = this.originalFacetList.map(facet => {
                if (!facet.valueList || facet.key.toLowerCase().includes(searchTextLower)) {
                    return facet;
                }

                const filteredValueList = facet.valueList.filter(item => item.key.toLowerCase().includes(searchTextLower) || this.localizationService.getLocalizationValue(item.key.trim()).toLocaleLowerCase().includes(searchTextLower));

                const filteredFacet: FacetDTO = {
                    key: facet.key,
                    order: facet.order,
                    valueList: filteredValueList,
                    bindData: facet.bindData,
                    fillExportData: facet.fillExportData
                };

                return filteredFacet;
            });
        }
    }

    private addFacet(_facet: FacetDTO) {
        if (!this.facetList) {
            this.facetList = [];
        }
        this.facetList.push(_facet);
    }



    ngOnInit(): void {
        this.facetMap.set("facet-documentType", "1");
        this.facetMap.set("facet-authorStatus", "1");
        this.facetMap.set("facet-database", "2");
        this.facetMap.set("facet-accessType", "3");
        this.facetMap.set("accessType", "4");
        this.facetMap.set("facet-authorName", "5");
        this.facetMap.set("facet-publication_year", "6");
        this.facetMap.set("facet-year", "6");
        this.facetMap.set("facet-publicationLanguage", "7");
        this.facetMap.set("facet-journalName", "8");
        this.facetMap.set("facet-publicationType", "9");
        this.facetMap.set("facet-projectGroup", "10");


        this.facetMap.set("facet-facetAuthorInstitution", "10");
        this.facetMap.set("facet-facetAuthorCountry", "11");
        this.facetMap.set("facet-facetAuthorCity", "12");
        this.facetMap.set("facet-subject", "13");

        //institution
        this.facetMap.set("facet-status", "1");
        this.facetMap.set("facet-type", "2");
        this.facetMap.set("facet-country", "3");
        this.facetMap.set("facet-city", "4");


        this.activatedRoute.queryParams.subscribe(params => {
            for (let key in params) {
                if (key.startsWith("facet-")) {
                    if (Array.isArray(params[key])) {
                        for (let i in params[key]) {
                            this.selectFacet(key, params[key][i]);
                        }
                    } else {
                        this.selectFacet(key, params[key]);
                    }
                }

            }
        });
    }

}
