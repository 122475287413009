import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FacetComponent} from "../facet/facet.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SearchService} from "../search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PublicationDTO} from "../publication/PublicationDTO";
import {NgxEchartsDirective} from "ngx-echarts";
import {EChartsOption} from "echarts";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {PublicationAbstractDTO} from "../publication/PublicationAbstractDTO";
import {MoreLessComponent} from "../../../trdcore/component/more-less/more-less.component";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";
import {PublicationListComponent} from "../publication-list/publication-list.component";
import {saveAs} from 'file-saver';
import {LoadingService} from "../../../trdcore/service/LoadingService";
import {PageMetaService} from "../../PageMetaService";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {getCurrentLanguage} from "../../app.module";
import {MylibraryDetailComponent} from "../Mylibrary/mylibrary-detail.component";
import {MathJaxService} from "../../mathjax.service";
import {ExportComponent} from "../export/export.component";
import {PermissionService} from "../../../trdcore/service/permission.service";


@Component({
    selector: 'app-publication-detail',
    standalone: true,
    imports: [
        FacetComponent,
        ReactiveFormsModule,
        NgxEchartsDirective,
        NgForOf,
        NgIf,
        TrdCoreModule,
        MoreLessComponent,
        PublicationListComponent,
        MylibraryDetailComponent,
        ExportComponent,
        NgClass
    ],
    templateUrl: './publication-detail.component.html',
    styleUrl: './publication-detail.component.scss'
})
export class PublicationDetailComponent implements OnInit , AfterViewInit{
    @ViewChild('referencesMoreLess') public referencesMoreLessComponent: MoreLessComponent
    @ViewChild('publicationProduct') public publicationProductComponent:MoreLessComponent
    public selectedPublication: PublicationDTO;
    public selectedAbstract: PublicationAbstractDTO;
    selectedPublicationId: string;
    chartOption: EChartsOption;
    citationSourcePublicationList: Array<PublicationDTO>;
    citationSourcePublicationIdList: Array<string>;
    citationPageModel: PageModel = new PageModel(10);
    apaCopyScripts: string;
    authors: string[];
    publicationTitle: string;
    journalName: string;
    issueVolume: string;
    issueNumber: string;
    publicationYear: string;
    startPageAndEndPage: string;
    similarArticlesOfThePublicationList:Array<PublicationDTO>;

    constructor(private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router, private pageMetaService: PageMetaService, private loadingService: LoadingService,
                private localizationService: LocalizationService,private mathJaxService: MathJaxService,public permissionService: PermissionService) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
    }


    ngOnInit(): void {
        this.selectedPublicationId = this.activatedRoute.snapshot.paramMap.get('id');
        if (this.selectedPublicationId) {
            this.searchService.getPublicationById(this.selectedPublicationId).subscribe({
                next: (res) => {
                    if (res["hits"]["hits"] && res["hits"]["hits"].length>0) {
                        this.selectedPublication = new PublicationDTO();
                        this.selectedPublication.bindData(res["hits"]["hits"][0]["_source"]);
                        let citationYear = [];
                        let citationYearsPaper = new Map<string, number>();
                        let citationYearsProject = new Map<string, number>();
                        let citationCountPaper: number[] = [];
                        let citationCountProject: number[] = [];
                        if (this.selectedPublication.citedReferences) {
                            this.selectedPublication.citedReferences.forEach(r => {
                                citationYear.push(r.year);
                                if (r.docType == "PAPER") {
                                    if (citationYearsPaper.get(r.year)) {
                                        citationYearsPaper.set(r.year, citationYearsPaper.get(r.year) + 1);
                                    } else {
                                        citationYearsPaper.set(r.year, 1);
                                    }
                                } else if (r.docType == "PROJECT") {
                                    if (citationYearsProject.get(r.year)) {
                                        citationYearsProject.set(r.year, citationYearsProject.get(r.year) + 1);
                                    } else {
                                        citationYearsProject.set(r.year, 1);
                                    }
                                }
                            });
                            // console.log(citationYearsPaper);
                            // console.log([...citationYearsPaper.entries().sort()].values());
                            citationYear.sort();
                            citationYear = [...new Set(citationYear)];
                            citationYear.forEach(y => {
                                if (!citationYearsPaper.get(y)) {
                                    citationYearsPaper.set(y, 0);
                                }
                                if (!citationYearsProject.get(y)) {
                                    citationYearsProject.set(y, 0);
                                }
                            });

                            citationYear.forEach(y => {
                                citationCountPaper.push(citationYearsPaper.get(y))
                                citationCountProject.push(citationYearsProject.get(y))
                            });
                        }
                        let maxValue = Math.max(...citationCountProject);
                        if (Math.max(...citationCountPaper) > maxValue) {
                            maxValue = Math.max(...citationCountPaper);
                        }

                        if(this.selectedPublication.abstracts && this.selectedPublication.abstracts.length>0){
                            this.selectedAbstract=this.selectedPublication.abstracts[0];
                        }

                        this.chartOption = {
                            grid: {
                                top: 0,
                                right: 10,
                                left: 40,
                            },
                            tooltip: {
                                show: true,
                            },
                            xAxis: {
                                nameTextStyle: {
                                    fontSize: 0.1,
                                },
                                type: 'value',
                                interval: 5,
                                max: maxValue,
                                splitLine: {
                                    show: true
                                },
                            },
                            yAxis: {
                                type: 'category',
                                data: [...new Set(citationYear)],
                                splitLine: {
                                    show: true
                                },
                                axisLabel: {
                                    inside: false,
                                    fontSize: 10,
                                },
                                axisLine: {}
                            },
                            series: [
                                {
                                    data: citationCountPaper,
                                    type: 'bar',
                                    stack: 'a',
                                    name: this.localizationService.getLocalizationValue('PAPER'),
                                    // barWidth: '25%'
                                },
                                {
                                    data: citationCountProject,
                                    type: 'bar',
                                    stack: 'a',
                                    name: this.localizationService.getLocalizationValue('PROJECT'),
                                    // barWidth: '25%'
                                }
                            ],
                        };

                    }else{
                        window.location.href="https://static.trdizin.gov.tr/404";
                    }
                    this.pageMetaService.setPublicationMetaTags(this.selectedPublication);
                },
                error: (e) => console.error(e)
            });
        }
    }

    public selectAbstract(_abstract: PublicationAbstractDTO) {
        this.selectedAbstract = new PublicationAbstractDTO();
        this.selectedAbstract.bindData(_abstract);
    }



    public apaScriptForCopy(selectedPublication) {
        let formattedNames = selectedPublication.getApaStyleForAuthorsName();
        let doiText = selectedPublication.doi ? ' doi.org/' + selectedPublication.doi : '';
        this.setIssueInfo(selectedPublication);

        this.apaCopyScripts = formattedNames + ' (' + selectedPublication.publicationYear + '). ' +
            this.publicationTitle + '. ' + this.journalName + ', ' +
            selectedPublication.issue.volume + '(' + selectedPublication.issue.number + '),' +
            selectedPublication.startPage + '-' + selectedPublication.endPage + '. ' +
            doiText;
        return this.apaCopyScripts;
    }

    public chicagoStyle(selectedPublication) {
        let formattedAuthors = selectedPublication.authors.map(author => author.name).join(', ');
        this.setIssueInfo(selectedPublication);
        let doiText = selectedPublication.doi !== null && selectedPublication.doi !== undefined ? selectedPublication.doi : '';
        return formattedAuthors + ' ' + this.publicationTitle + ' ' + this.journalName + ' ' + this.issueVolume + ",no." + this.issueNumber + ' (' + this.publicationYear + '): ' + this.startPageAndEndPage + ' ' + doiText;
    }

    public mlaStyle(selectedPublication) {
        let formattedAuthors = selectedPublication.authors.map(author => {
            const names = author.name.split(' ');
            const lastName = names.pop().toUpperCase();
            const formattedName = names.map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(' ');
            return `${lastName} ${formattedName}`;
        }).join(', ');

        this.setIssueInfo(selectedPublication);
        let doiText = selectedPublication.doi !== null && selectedPublication.doi !== undefined ? selectedPublication.doi : '';
        return formattedAuthors + ' ' + this.publicationTitle + '.' + this.journalName + ',' + 'vol.' + this.issueVolume + ',no.' + this.issueNumber + ','
            + this.publicationYear + ',' + 'ss.' + this.startPageAndEndPage + ' ' + doiText;
    }

    public amaAndVancouverStyle(selectedPublication) {
        let formattedAuthors = selectedPublication.authors.map(author => {
            const names = author.name.split(' ');
            const lastName = names.pop().toUpperCase(); // Soyadı al ve büyük harf yap
            const firstNameInitial = names.shift().charAt(0).toUpperCase(); // İlk ismin ilk harfini al ve büyük harf yap
            return `${lastName} ${firstNameInitial}`;
        }).join(', ');
        this.setIssueInfo(selectedPublication);
        let doiText = selectedPublication.doi !== null && selectedPublication.doi !== undefined ? selectedPublication.doi : '';
        return formattedAuthors + ' ' + this.publicationTitle + '. ' + this.journalName + '. ' + this.publicationYear + '; ' + this.issueVolume + '(' +
            this.issueNumber + '): ' + this.startPageAndEndPage + ' ' + doiText;

    }

    public ieeeStyle(selectedPublication) {
        let formattedAuthors = selectedPublication.authors.map(author => {
            const names = author.name.split(' ');
            const lastName = names.pop().toUpperCase(); // Soyadı al ve büyük harf yap
            const firstNameInitial = names.shift().charAt(0).toUpperCase(); // İlk ismin ilk harfini al ve büyük harf yap
            return `${lastName} ${firstNameInitial}`;
        }).join(', ');
        this.setIssueInfo(selectedPublication);
        let doiText = selectedPublication.doi !== null && selectedPublication.doi !== undefined ? selectedPublication.doi : '';
        return formattedAuthors + ' ' + '"' + this.publicationTitle + '." ' + this.journalName + ',' + this.issueVolume + ',ss.' +
            this.startPageAndEndPage.replace(/\.$/, '') + ',' + this.publicationYear + '. ' + doiText;
    }

    public isnadStyle(selectedPublication) {
        let formattedAuthors = '';
        if (selectedPublication.authors.length > 0) {
            const author = selectedPublication.authors[0];
            const names = author.name.split(' ');
            const lastName = names.pop().toUpperCase(); // Soyadı al ve büyük harf yap
            const firstName = names.join(' '); // İlk isimleri birleştir
            formattedAuthors = `${lastName}, ${firstName}`;
            if (selectedPublication.authors.length > 1) {
                formattedAuthors += ' vd';
            }
        }
        let doiText = selectedPublication.doi ? '  https://doi.org//' + selectedPublication.doi : '';
        this.setIssueInfo(selectedPublication);
        return formattedAuthors + '. ' + '"' + this.publicationTitle + '." ' + (this.journalName ? this.journalName : '') + ' ' +
            this.issueVolume + '/' + this.issueNumber + '(' + this.publicationYear + '),' + this.startPageAndEndPage + doiText;
    }

    private setIssueInfo(selectedPublication): void {
        this.publicationYear = selectedPublication.publicationYear;
        this.publicationTitle = selectedPublication.abstracts[0].title;
        if (selectedPublication.journal) {
            this.journalName = selectedPublication.journal.name;
        }
        this.startPageAndEndPage = selectedPublication.startPage + ' - ' + selectedPublication.endPage + '.';
        this.issueVolume = null;
        this.issueNumber = null;

        if (selectedPublication.issue && selectedPublication.issue.volume) {
            this.issueVolume = selectedPublication.issue.volume;
        }

        if (selectedPublication.issue && selectedPublication.issue.number) {
            this.issueNumber = selectedPublication.issue.number;
        }
    }

    copySelectedField(value, e) {
        e.stopPropagation();
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    fillCitationIdList() {
        this.citationSourcePublicationIdList = new Array<string>();
        if (this.selectedPublication.citedReferences) {
            this.selectedPublication.citedReferences.forEach(r => {
                if (r.sourceId) {
                    this.citationSourcePublicationIdList.push(r.sourceId.toString());
                }
            });
        }

        this.citationSourcePublicationIdList.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        this.citationPageModel.totalCount = this.citationSourcePublicationIdList.length;
        this.citationPageModel.totalPages = Math.ceil(this.citationPageModel.totalCount / this.citationPageModel.size)
        this.changeCitationResultPage(this.citationPageModel.currentPage);
    }

    getBibtext(selectedPublication: PublicationDTO) {
        const authorsList = selectedPublication.authors.map(a => a.name).join(' and ');
        const bibtexOutput = `@article{${(selectedPublication.journal ? this.turkishToEnglish(selectedPublication.journal.name) : "") + "_" + selectedPublication.publicationYear + "_" + selectedPublication.id},
      author = {${authorsList}},
      title = {${selectedPublication.abstracts[0].title}},
      year = {${selectedPublication.publicationYear}},
      journal = {${selectedPublication.journal.name}},
      issn = {${selectedPublication.journal.issn ? selectedPublication.journal.issn : selectedPublication.journal.eissn}},
      volume={${selectedPublication.issue.volume}},
      number = {${selectedPublication.issue.number}},
      url={${"https://search.trdizin.gov.tr" + this.router.url}}
    }`;
        const blob = new Blob([bibtexOutput], {type: 'text/plain'})
        saveAs(blob, `${selectedPublication.abstracts[0].title}.bib`);
    }

    turkishToEnglish(input: string): string {
        const turkishChars = 'çÇğĞıİöÖşŞüÜ';
        const englishChars = 'cCgGiIoOsSuU';
        const charMap: { [key: string]: string } = {};
        for (let i = 0; i < turkishChars.length; i++) {
            charMap[turkishChars[i]] = englishChars[i];
        }

        const convertedString = input.replace(/[çÇğĞıİöÖşŞüÜ]/g, char => charMap[char]);
        return convertedString;
    }

    getRis(selectedPublication: PublicationDTO) {
        let authorsList = selectedPublication.authors.map(author => `AU  - ${author.name}`).join('\n');
        const risOutput = `
TY  - JOUR
TI  - ${selectedPublication.abstracts[0].title}
AB  - ${selectedPublication.abstracts[0].abstract}
    - ${authorsList}
PY  - ${selectedPublication.publicationYear}
JO  - ${selectedPublication.journal.name}
VL  - ${selectedPublication.issue.volume}
IS  - ${selectedPublication.issue.number}
SN  - ${selectedPublication.journal.issn ? selectedPublication.journal.issn : selectedPublication.journal.eissn}
SP  - ${selectedPublication.startPage}
EP  - ${selectedPublication.endPage}
DB  - TRDizin,
UR  -${"https://search.trdizin.gov.tr" + this.router.url}
ER  - `;

        const blob = new Blob([risOutput], {type: 'text/plain'});
        saveAs(blob, `${selectedPublication.abstracts[0].title}.ris`);
    }

    getMendeley() {
        (window as any).APP = (window as any).APP || {};
        (window as any).APP.functions = (window as any).APP.functions || {};

        (window as any).APP.functions.saveMendeley = function () {
            document.getElementsByTagName('body')[0].appendChild(document.createElement('script')).setAttribute('src', 'https://www.mendeley.com/minified/bookmarklet.js');
        };

        (window as any).APP.functions.saveMendeley();
    }

    getZotero() {
        let s = document.createElement('script');
        s.src = 'https://www.zotero.org/bookmarklet/loader.js';
        s.onload = function (e) {
            var cDiv = document.createElement("div");
            cDiv.id = "zoteroclosediv";
            cDiv.style.position = "absolute";
            cDiv.style.right = "16px";
            let close = document.createElement("a");
            close.style.cursor = "pointer";
            close.innerHTML = `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACuSURBVEiJtZUxDoNADAQn+QMo+UEK8v8q5A+h4DmkMRIgDs724uoEYuYOvAbgBXyBJ7pqgA/wBuiBCRiAVgBvjTUZmwb42YWR3Em2rEfpRkRShCskp/CMpBoekbjhHkkYXiNJw48kMvhcy+AMm7UimMB6166d3xPSW+LZVV36ivY+qGx2HXVLWlLTimGJp8/dkkiIqiWZhJ5KFPEvSpSzZVdy+U+/s4VscBmrB7o/8VF4C5HSRGcAAAAASUVORK5CYII=" width="24" height="24" />`;
            close.onclick = function () {
                let containerCount = document.querySelector("#zotero-iframe");
                let containerCount2 = document.querySelector("#zotero-progress-window-frame");
                containerCount.remove();
                containerCount2.remove();
                document.body.lastChild.remove();
            };
            cDiv.appendChild(close);

            const zoteroIframe = document.querySelector("#zotero-iframe") as HTMLIFrameElement;
            zoteroIframe.onload = function () {
                const content = zoteroIframe.contentDocument?.body;
                if (content) {
                    content.appendChild(cDiv);
                }
            };
        };
        document.getElementsByTagName("head")[0].appendChild(s);
        void (0);
    }

    getEndNote(selectedPublication: PublicationDTO) {
        let baseUrl = "https://search.trdizin.gov.tr" + this.router.url
        let endNoteTransferUrl = new URL("https://www.myendnoteweb.com/EndNoteWeb.html");
        endNoteTransferUrl.searchParams.append('func', 'directExport');
        endNoteTransferUrl.searchParams.append('partnerName', 'TRDizin');
        endNoteTransferUrl.searchParams.append('dataIdentifier', '1');
        let risUrl = this.searchService.getRisUrl(selectedPublication.id, baseUrl);
        endNoteTransferUrl.searchParams.append('dataRequestUrl', encodeURIComponent(risUrl));
        window.open(endNoteTransferUrl, '_blank').focus();
    }

    getPdf(selectedPublication: PublicationDTO) {
        if (selectedPublication.pdf) {
            this.searchService.getPdfFile(selectedPublication.pdf).subscribe(res => {
                if (res) {
                    window.open(res.toString(), '_blank', 'noreferrer');
                }
                this.loadingService.hideLoader();
            })
        }
    }

    changeCitationResultPage(event) {
        this.citationPageModel.currentPage = event.currentPage;
        if (this.citationPageModel.size != event.size) {
            this.citationPageModel.currentPage = 1;
        }
        if (event.size) {
            this.citationPageModel.size = event.size;
        }
        // this.currentPage = this.pageModel.currentPage;
        let _array = this.citationSourcePublicationIdList.slice((this.citationPageModel.currentPage - 1) * this.citationPageModel.size, this.citationPageModel.size * this.citationPageModel.currentPage);
        if (_array && _array.length>0) {
            this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
                next: (res) => {
                    this.citationSourcePublicationList = new Array<PublicationDTO>();
                    if (res["hits"]["hits"]) {
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.citationSourcePublicationList.push(_publication);
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }
    }

    getOtherCitations(origin) {
        if (this.selectedPublication && this.selectedPublication.otherCitations && this.selectedPublication.otherCitations.length > 0) {
            const citation = this.selectedPublication.otherCitations.find(o => origin === o.origin);
            if (citation) {
                return citation.count;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }
    getSimilarArticlesOfThePublication(id){
        this.searchService.getSimilarArticlesOfThePublication(id).subscribe(res=>{
            this.similarArticlesOfThePublicationList = new Array<PublicationDTO>();
            if (res["hits"]["hits"]) {
                for (let i = 0; i < res["hits"]["hits"].length; i++) {
                    let _publication = new PublicationDTO();
                    _publication.bindData(res["hits"]["hits"][i]["_source"]);
                    this.similarArticlesOfThePublicationList.push(_publication);
                }
            }
        });
    }
    protected readonly getCurrentLanguage = getCurrentLanguage;

    ngAfterViewInit(): void {
        this.mathJaxService.getMathJaxLoadedPromise().then(() => {
            this.mathJaxService.render();
        });
    }
    filterAuthorTypeAndSort(type: string): any[] {
        return this.selectedPublication.authors
            .filter(author => author.duty === type)
            .sort((a, b) => a.order - b.order);
    }
    filterAuthorType(type:string){
        return this.selectedPublication.authors.filter(x=>x.duty==type).length;
    }
    getAccessType(){
        if(this.selectedPublication.accessType && this.selectedPublication.accessType=='OPEN'){
            return this.localizationService.getLocalizationValue('OpenToAccess');
        }else{
            return this.selectedPublication.accessType;
        }
    }

    protected readonly length = length;
}
