import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthorizationCodeDTO } from "./AuthorizationCodeDTO";

import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import { AuthInfoDTO } from 'src/trdcore/dto/AuthInfoDTO';
import { CookieService } from 'src/trdcore/service/CookieService';
import { KeyCloackUserDTO } from 'src/trdcore/dto/KeyCloackUserDTO';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractService} from "../../trdcore/service/AbstractService";

export enum EnumConditionType {OR,AND }

@Injectable({ providedIn: 'root' })
export abstract class AuthorizationService extends AbstractService<AuthorizationCodeDTO> {

  private _activeUser: BehaviorSubject<AuthInfoDTO> = new BehaviorSubject(null);
  activeUser: Observable<AuthInfoDTO> = this._activeUser.asObservable();

  getApiURL() {
    return "/";
  }

  private userAuthorities: AuthInfoDTO[] = [];

  protected constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    private cookieService: CookieService) {
    super(http);

    // let activeUserStringify = localStorage.getItem('activeUser');
    // if (activeUserStringify) {
    //   let activeUser = JSON.parse(activeUserStringify);
    //   this._activeUser.next(activeUser);

    // }
    // this.userAuthorities = [];
    // let userAuthoritiesStringify = localStorage.getItem('userAuthorities');
    // if (userAuthoritiesStringify) {
    //   let userAuthorityList = JSON.parse(userAuthoritiesStringify);
    //   userAuthorityList.forEach(user => {
    //     this.userAuthorities.push(user);
    //   });
    // }
  };

  initConfig(isLogin?) {
    if(isLogin===false){
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    return new Promise((resolve, reject) => {
      let activeUser = this.getActiveUser();

      if(!activeUser) {
        let loginUser: AuthInfoDTO = null;
        let cookie = this.cookieService.getCookie(this.cookieService.cookieName);
        if (cookie && cookie != "") {

          loginUser = this.cookieService.getUserInfo(this.cookieService.cookieName);
          if (loginUser) {
            this.cookieService.headerToken = cookie;
          }
        }
        if (loginUser) {
          activeUser = loginUser;
        }
      }

      let activeUserStringify = localStorage.getItem('activeUser');
      if (activeUserStringify) {
        activeUser = JSON.parse(activeUserStringify);
      }

      if (activeUser) {
        this._activeUser.next(activeUser);
        if(this.getActiveUser() && this.isImpersonateUser()){
          localStorage.setItem('activeUser', JSON.stringify(this.getActiveUser()));
        }

        let authoritiesSubs = this.getAuthritiesByUserId(activeUser.userId);
        let accountInfoSubs = this.getAccount();
        forkJoin([authoritiesSubs, accountInfoSubs]).subscribe(results => {
          activeUser.authorities = results[0] as string[];

          let accountInfo = new KeyCloackUserDTO();
          accountInfo.bindData(results[1]);
          activeUser.accountInfo = accountInfo;

          this._activeUser.next(activeUser);
          if(this.getActiveUser() && this.isImpersonateUser()){
            localStorage.setItem('activeUser', JSON.stringify(this.getActiveUser()));
          }

          let userIndex = this.userAuthorities.findIndex(u => u.userId == activeUser.userId);
          if(userIndex > -1){
            this.userAuthorities[userIndex] = activeUser;
          } else {
            this.userAuthorities.push(activeUser);
          }

          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  getAccount(){
    // const options = {
    //   headers: new HttpHeaders(),
    //   observe: 'events' as const,
    //   params: new HttpParams(),
    //   reportProgress: false,
    //   responseType: 'json' as const,
    //   withCredentials: false,
    //   timeout: 3000
    // };
    return this.http.get(environment.managementApiUrl + "/account");
  }

  getAuthritiesByUserId(userid) {
    return this.http.get(environment.managementApiUrl + "/get-user-authorities/" + userid);
  }

  getUserByEmail(email: any) {
    return this.http.get(environment.managementApiUrl + "/find-user-by-mail/" + email);
  }

  getUsersByUserId(userId) {
    return this.http.get(environment.managementApiUrl + "/find-user-by-userid/" + userId);
  }


  getUsersByIds(group, userIds) {
    return this.http.post(environment.managementApiUrl + "/find-user-by-id/" + group, userIds);
  }

  getUsersByPaperAssignable(size:any, page:any, sortField: any, sortType: string, data: {}) {
    return this.http.get(`${environment.managementApiUrl}/find-user-by-paper-assignable?size=${size}&page=${page}&sortField=${sortField}&sortType=${sortType}&searchKey=${data}`);
  }

  setActiveUser(activeUser: AuthInfoDTO) {
    let user = this.userAuthorities.find(u => u.userId == activeUser.userId);

    if (user) {
      this._activeUser.next(user);
      if(this.getActiveUser() && this.isImpersonateUser()){
        localStorage.setItem('activeUser', JSON.stringify(this.getActiveUser()));
      } else {
        localStorage.removeItem('activeUser');
      }

      // window.location.reload();
    } else {
      this._activeUser.next(activeUser);
      if(this.getActiveUser() && this.isImpersonateUser()){
        localStorage.setItem('activeUser', JSON.stringify(this.getActiveUser()));
      } else {
        localStorage.removeItem('activeUser');
      }

      let authoritiesSubs = this.getAuthritiesByUserId(activeUser.userId);
      let accountInfoSubs = this.getAccount();
      // accountInfoSubs.subscribe(res=>{
      //   console.log(res);
      // });
      forkJoin([authoritiesSubs, accountInfoSubs]).subscribe(results => {
        activeUser.authorities = results[0] as string[];

        let accountInfo = new KeyCloackUserDTO();
        accountInfo.bindData(results[1]);
        activeUser.accountInfo = accountInfo;

        this._activeUser.next(activeUser);
        if(this.getActiveUser() && this.isImpersonateUser()){
          localStorage.setItem('activeUser', JSON.stringify(this.getActiveUser()));
        } else {
          localStorage.removeItem('activeUser');
        }

        let userIndex = this.userAuthorities.findIndex(u => u.userId == activeUser.userId);
        if(userIndex > -1){
          this.userAuthorities[userIndex] = activeUser;
        } else {
          this.userAuthorities.push(activeUser);
        }

        // window.location.reload();
      });
    }
  }

  getActiveUser() {
    return this._activeUser.getValue();
  }

  getActiveUserBS() {
    return this._activeUser;
  }

  getActiveUserObservable() : Observable<AuthInfoDTO> {
    return this.activeUser;
  }

  resetActiveUser() {
    localStorage.removeItem('activeUser');
    this._activeUser.next(null);
    this.userAuthorities = [];
  }

  hasPermission(permissons?: string[]): boolean {
    let hasPerm: boolean = false;

    if (permissons) {
      permissons = typeof permissons === 'string' ? [permissons] : permissons;
    }

    if (permissons && this.getActiveUser() && this.getActiveUser().authorities) {
      permissons.forEach(perm => {
        if (this.getActiveUser().authorities.includes(perm)) {
          hasPerm = true;
        }
      });
    }

    return hasPerm;
  }

  hasPermissionAll(permissons?: string[]): boolean {
    let hasPerm: boolean = false;

    if (permissons) {
      permissons = typeof permissons === 'string' ? [permissons] : permissons;
    }

    if (permissons && this.getActiveUser() && this.getActiveUser().authorities) {
      hasPerm = permissons.every(e => this.getActiveUser().authorities.indexOf(e) !== -1);
    }

    return hasPerm;
  }


  isImpersonateUser(){
    let isImpersonateUser = false;
    let loginUser: AuthInfoDTO = null;
    let cookie = this.cookieService.getCookie(this.cookieService.cookieName);
    loginUser = this.cookieService.getUserInfo(this.cookieService.cookieName);
    if (loginUser) {
      let activeUser = this.getActiveUser();
      if(activeUser && activeUser.userId != loginUser.userId){
        isImpersonateUser = true;
      }
    }

    return isImpersonateUser;
  }
}
