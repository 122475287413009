
let ItemListInstance = {
  instance: null
};
(function () {
  class ItemList {
    constructor() {
      this.opt = {
        container: ".apps-wrapper",
        token: "TRDIZIN_TOKEN",
        requestUrl: null,
        button:null,
        classes: {
          item: "item",
          list: "wrapper"
        },
        template: {
          item: `
         <a href="{link}" target="_blank"  style="border-radius: 10px;" class='link bg-hover pointer'>
            <div class="app-icons">
                  {html}
            </div>
            <span>{title}</span>
        </a>`,
          list: `
            <div id="menu-container" class='menu-container' data-is-closed="true">
                <div class="icons-container" >
                    {child}
                </div>
            </div>`
        }
      }
      this.state="true";
      this._containerDom = null;
    }

    init(options){
      let _instance = this;
      setTimeout(function (){
        if(document.querySelector(options.container)){
          _instance._init(options);
        }else{
          _instance.init(options);
        }
      },100);
    }

    _init(options) {
      this.opt = Object.assign(this.opt, options);
      if (!this.opt.requestUrl) {
        throw "requestURL is required";
      }
      this.opt.template.list = this.opt.template.list.replace("{classes.list}",
        this.opt.classes.list);
      const _instance = this;
      this.request(function (d){  _instance.templateBinder(d,_instance)});
      const container= this._containerDom;
      container.show=function (e){
        container.setAttribute("data-is-closed","false");
      }
      container.hide=function () {
        container.setAttribute("data-is-closed", "true");
      }
      container.toggle=function (){
        container.setAttribute("data-is-closed",container.getAttribute("data-is-closed")==="false");
      }
      const containerCommand = document.querySelector(this.opt.button);
      window.addEventListener('click', function(e){
        if (container.contains(e.target) || containerCommand.contains(e.target)){
        } else{
          container.hide();
        }
      });
    }
    show(){
      if(this._containerDom)
        this._containerDom.show();
    }
    hide(){
      if(this._containerDom)
        this._containerDom.hide();
    }
    toggle(){
      if(this._containerDom)
        this._containerDom.toggle();
      return this.isOpen();
    }
    isOpen(){
      return this._containerDom.getAttribute("data-is-closed")==='true';
    }
    itemBinder(item) {
      let result = this.opt.template.item;
      result = result.replace("{classes.item}", this.opt.classes.item);
      result = result.replace("{link}", item["url"]);
      result = result.replace("{icon}", item["icon"]);
      result = result.replace("{html}", item["html"]);
      result = result.replace("{title}", item["title"]);
      result = result.replace("{id}", item["id"]);
      return result;
    }
    templateBinder(data, _instance){
      let childString = "";
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          childString += _instance.itemBinder(data[i]);
        }

        document.querySelector(_instance.opt.container).innerHTML =
          _instance.opt.template.list.replace("{child}", childString);
        _instance._containerDom = document.querySelector(_instance.opt.container).children[0];
      }
    }


    request(callback) {
      let match = document.cookie.match(
        new RegExp('(^| )' + this.opt.token + '=([^;]+)'));
      if (match) {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
          if (xmlHttp.status == 200) {
            const result = JSON.parse(xmlHttp.responseText);
            callback(result);
          }
        };
        xmlHttp.open("GET", this.opt.requestUrl, false);
        xmlHttp.setRequestHeader("Authorization", "Bearer " + match[2]);
        xmlHttp.setRequestHeader("Accept-Language",sessionStorage.getItem("activeLanguage"))

        xmlHttp.send(null);
      } else {
      }
    }
  }
  ItemListInstance.instance = new ItemList();
})();
export default ItemListInstance ;
