import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {TagData, TagifyModule, TagifySettings} from "ngx-tagify";
import {LocalizationService} from "../../trdcore/service/localizationService";
import {FormsModule} from "@angular/forms";
import {TrdCoreModule} from "../../trdcore/trdcore.module";
import {SearchService} from "../search/search.service";
import {PublicationDTO} from "../search/publication/PublicationDTO";
import {PublicationJournalDTO} from "../search/publication/PublicationJournalDTO";
import {PublicationAbstractDTO} from "../search/publication/PublicationAbstractDTO";
import {JournalDTO} from "../search/journal/JournalDTO";
import {getCurrentLanguage} from "../app.module";
import {ExportService} from "../search/export/export.service";
import {PublicationAuthorDTO} from "../search/publication/PublicationAuthorDTO";
import {PublicationIssueDTO} from "../search/publication/PublicationIssueDTO";
import slugify from "slugify";
import {MatTreeSelectInputModule, TreeData} from "mat-tree-select-input";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-journal-suggestion',
    standalone: true,
    imports: [
        NgIf,
        TagifyModule,
        FormsModule,
        TrdCoreModule,
        NgForOf,
        MatTreeSelectInputModule,
    ],
    templateUrl: './journal-suggestion.component.html',
    styleUrl: './journal-suggestion.component.scss'
})


export class JournalSuggestionComponent implements OnInit {
    keywords: TagData[] = [];
    publicationTitle: string;
    publicationAbstract: string;
    publicationList = new Array<PublicationDTO>();
    journalList = new Array<JournalDTO>();
    journalScore = new Map<string, number>();
    maxScore = 0;
    subjectSearchValue: any;
    selectedSubjectOptions: String[] = [];

    subjectOptions: TreeData[] = [
        {
            name: 'Fen',
            value: 'Fen',
            children: [
                {
                    name: 'Diş Hekimliği',
                    value: 'Diş Hekimliği',
                    children: [
                        {
                            name: 'Diş Hekimliği',
                            value: 'Fen > Diş Hekimliği > Diş Hekimliği',
                            children: []
                        }
                    ]
                },
                {
                    name: 'Eczacılık',
                    value: 'Eczacılık',
                    children: [
                        {
                            name: "Farmakoloji ve Eczacılık",
                            value: "Fen > Eczacılık > Farmakoloji ve Eczacılık",
                            children: []
                        },
                        {
                            name: "Toksikoloji",
                            value: "Fen > Eczacılık > Toksikoloji",
                            children: []
                        }
                    ]
                },
                {
                    name: 'Mühendislik',
                    value: 'Muhendislik',
                    children: [
                        {
                            name: "Bilgisayar Bilimleri, Bilgi Sistemleri",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Bilgi Sistemleri",
                            children: []
                        },
                        {
                            name: "Bilgisayar Bilimleri, Donanım ve Mimari",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Donanım ve Mimari",
                            children: []
                        },
                        {
                            name: "Bilgisayar Bilimleri, Sibernitik",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Sibernitik",
                            children: []
                        },
                        {
                            name: "Bilgisayar Bilimleri, Teori ve Metotlar",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Teori ve Metotlar",
                            children: []
                        },
                        {
                            name: "Bilgisayar Bilimleri, Yapay Zeka",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Yapay Zeka",
                            children: []
                        },
                        {
                            name: "Bilgisayar Bilimleri, Yazılım Mühendisliği",
                            value: "Fen > Mühendislik > Bilgisayar Bilimleri, Yazılım Mühendisliği",
                            children: []
                        },
                        {name: "Çevre Mühendisliği", value: "Fen > Mühendislik > Çevre Mühendisliği", children: []},
                        {name: "Denizcilik", value: "Fen > Mühendislik > Denizcilik", children: []},
                        {
                            name: "Endüstri Mühendisliği",
                            value: "Fen > Mühendislik > Endüstri Mühendisliği",
                            children: []
                        },
                        {name: "Enerji ve Yakıtlar", value: "Fen > Mühendislik > Enerji ve Yakıtlar", children: []},
                        {
                            name: "Gıda Bilimi ve Teknolojisi",
                            value: "Fen > Mühendislik > Gıda Bilimi ve Teknolojisi",
                            children: []
                        },
                        {
                            name: "Görüntüleme Bilimi ve Fotoğraf Teknolojisi",
                            value: "Fen > Mühendislik > Görüntüleme Bilimi ve Fotoğraf Teknolojisi",
                            children: []
                        },
                        {
                            name: "Hücre ve Doku Mühendisliği",
                            value: "Fen > Mühendislik > Hücre ve Doku Mühendisliği",
                            children: []
                        },
                        {
                            name: "İmalat Mühendisliği",
                            value: "Fen > Mühendislik > İmalat Mühendisliği",
                            children: []
                        },
                        {
                            name: "İnşaat Mühendisliği",
                            value: "Fen > Mühendislik > İnşaat Mühendisliği",
                            children: []
                        },
                        {
                            name: "İnşaat ve Yapı Teknolojisi",
                            value: "Fen > Mühendislik > İnşaat ve Yapı Teknolojisi",
                            children: []
                        },
                        {
                            name: "Jeokimya ve Jeofizik",
                            value: "Fen > Mühendislik > Jeokimya ve Jeofizik",
                            children: []
                        },
                        {name: "Jeoloji", value: "Fen > Mühendislik > Jeoloji", children: []},
                        {
                            name: "Maden İşletme ve Cevher Hazırlama",
                            value: "Fen > Mühendislik > Maden İşletme ve Cevher Hazırlama",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Biyomalzemeler",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Biyomalzemeler",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Kâğıt ve Ahşap",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Kâğıt ve Ahşap",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Kaplamalar ve Filmler",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Kaplamalar ve Filmler",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Kompozitler",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Kompozitler",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Özellik ve Test",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Özellik ve Test",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Seramik",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Seramik",
                            children: []
                        },
                        {
                            name: "Malzeme Bilimleri, Tekstil",
                            value: "Fen > Mühendislik > Malzeme Bilimleri, Tekstil",
                            children: []
                        },
                        {
                            name: "Metalürji Mühendisliği",
                            value: "Fen > Mühendislik > Metalürji Mühendisliği",
                            children: []
                        },
                        {
                            name: "Meteoroloji ve Atmosferik Bilimler",
                            value: "Fen > Mühendislik > Meteoroloji ve Atmosferik Bilimler",
                            children: []
                        },
                        {name: "Mimarlık", value: "Fen > Mühendislik > Mimarlık", children: []},
                        {
                            name: "Mühendislik, Biyotıp",
                            value: "Fen > Mühendislik > Mühendislik, Biyotıp",
                            children: []
                        },
                        {name: "Mühendislik, Deniz", value: "Fen > Mühendislik > Mühendislik, Deniz", children: []},
                        {
                            name: "Mühendislik, Elektrik ve Elektronik",
                            value: "Fen > Mühendislik > Mühendislik, Elektrik ve Elektronik",
                            children: []
                        },
                        {
                            name: "Mühendislik, Hava ve Uzay",
                            value: "Fen > Mühendislik > Mühendislik, Hava ve Uzay",
                            children: []
                        },
                        {
                            name: "Mühendislik, Jeoloji",
                            value: "Fen > Mühendislik > Mühendislik, Jeoloji",
                            children: []
                        },
                        {name: "Mühendislik, Kimya", value: "Fen > Mühendislik > Mühendislik, Kimya", children: []},
                        {
                            name: "Mühendislik, Makine",
                            value: "Fen > Mühendislik > Mühendislik, Makine",
                            children: []
                        },
                        {
                            name: "Mühendislik, Petrol",
                            value: "Fen > Mühendislik > Mühendislik, Petrol",
                            children: []
                        },
                        {
                            name: "Nanobilim ve Nanoteknoloji",
                            value: "Fen > Mühendislik > Nanobilim ve Nanoteknoloji",
                            children: []
                        },
                        {
                            name: "Nükleer Bilim ve Teknolojisi",
                            value: "Fen > Mühendislik > Nükleer Bilim ve Teknolojisi",
                            children: []
                        },
                        {name: "Orman Mühendisliği", value: "Fen > Mühendislik > Orman Mühendisliği", children: []},
                        {name: "Polimer Bilimi", value: "Fen > Mühendislik > Polimer Bilimi", children: []},
                        {name: "Robotik", value: "Fen > Mühendislik > Robotik", children: []},
                        {name: "Savunma Bilimleri", value: "Fen > Mühendislik > Savunma Bilimleri", children: []},
                        {
                            name: "Taşınım Bilimi ve Teknolojisi",
                            value: "Fen > Mühendislik > Taşınım Bilimi ve Teknolojisi",
                            children: []
                        },
                        {name: "Telekomünikasyon", value: "Fen > Mühendislik > Telekomünikasyon", children: []},
                        {
                            name: "Yeşil, Sürdürülebilir Bilim ve Teknoloji",
                            value: "Fen > Mühendislik > Yeşil, Sürdürülebilir Bilim ve Teknoloji",
                            children: []
                        }
                    ]
                },
                {
                    name: 'Temel Bilimler',
                    value: 'Temel Bilimler',
                    children: [
                        {name: "Akustik", value: "Fen > Temel Bilimler > Akustik", children: []},
                        {
                            name: "Astronomi ve Astrofizik",
                            value: "Fen > Temel Bilimler > Astronomi ve Astrofizik",
                            children: []
                        },
                        {name: "Balıkçılık", value: "Fen > Temel Bilimler > Balıkçılık", children: []},
                        {name: "Biyoloji", value: "Fen > Temel Bilimler > Biyoloji", children: []},
                        {
                            name: "Biyoloji Çeşitliliğinin Korunması",
                            value: "Fen > Temel Bilimler > Biyoloji Çeşitliliğinin Korunması",
                            children: []
                        },
                        {name: "Çevre Bilimleri", value: "Fen > Temel Bilimler > Çevre Bilimleri", children: []},
                        {
                            name: "Deniz ve Tatlı Su Biyolojisi",
                            value: "Fen > Temel Bilimler > Deniz ve Tatlı Su Biyolojisi",
                            children: []
                        },
                        {name: "Ekoloji", value: "Fen > Temel Bilimler > Ekoloji", children: []},
                        {name: "Entomoloji", value: "Fen > Temel Bilimler > Entomoloji", children: []},
                        {
                            name: "Fizik, Akışkanlar ve Plazma",
                            value: "Fen > Temel Bilimler > Fizik, Akışkanlar ve Plazma",
                            children: []
                        },
                        {
                            name: "Fizik, Atomik ve Moleküler Kimya",
                            value: "Fen > Temel Bilimler > Fizik, Atomik ve Moleküler Kimya",
                            children: []
                        },
                        {name: "Fizik, Katı Hal", value: "Fen > Temel Bilimler > Fizik, Katı Hal", children: []},
                        {name: "Fizik, Matematik", value: "Fen > Temel Bilimler > Fizik, Matematik", children: []},
                        {name: "Fizik, Nükleer", value: "Fen > Temel Bilimler > Fizik, Nükleer", children: []},
                        {name: "Fizikokimya", value: "Fen > Temel Bilimler > Fizikokimya", children: []},
                        {
                            name: "Fizik, Partiküller ve Alanlar",
                            value: "Fen > Temel Bilimler > Fizik, Partiküller ve Alanlar",
                            children: []
                        },
                        {
                            name: "Fizik, Uygulamalı",
                            value: "Fen > Temel Bilimler > Fizik, Uygulamalı",
                            children: []
                        },
                        {
                            name: "Genetik ve Kalıtım",
                            value: "Fen > Temel Bilimler > Genetik ve Kalıtım",
                            children: []
                        },
                        {
                            name: "İstatistik ve Olasılık",
                            value: "Fen > Temel Bilimler > İstatistik ve Olasılık",
                            children: []
                        },
                        {name: "Kimya, Analitik", value: "Fen > Temel Bilimler > Kimya, Analitik", children: []},
                        {
                            name: "Kimya, İnorganik ve Nükleer",
                            value: "Fen > Temel Bilimler > Kimya, İnorganik ve Nükleer",
                            children: []
                        },
                        {name: "Kimya, Organik", value: "Fen > Temel Bilimler > Kimya, Organik", children: []},
                        {name: "Kimya, Tıbbi", value: "Fen > Temel Bilimler > Kimya, Tıbbi", children: []},
                        {
                            name: "Kimya, Uygulamalı",
                            value: "Fen > Temel Bilimler > Kimya, Uygulamalı",
                            children: []
                        },
                        {name: "Kuş Bilimi", value: "Fen > Temel Bilimler > Kuş Bilimi", children: []},
                        {name: "Limnoloji", value: "Fen > Temel Bilimler > Limnoloji", children: []},
                        {name: "Mantar Bilimi", value: "Fen > Temel Bilimler > Mantar Bilimi", children: []},
                        {name: "Matematik", value: "Fen > Temel Bilimler > Matematik", children: []},
                        {name: "Mikroskopi", value: "Fen > Temel Bilimler > Mikroskopi", children: []},
                        {name: "Mineraloji", value: "Fen > Temel Bilimler > Mineraloji", children: []},
                        {name: "Optik", value: "Fen > Temel Bilimler > Optik", children: []},
                        {name: "Oşinografi", value: "Fen > Temel Bilimler > Oşinografi", children: []},
                        {name: "Paleontoloji", value: "Fen > Temel Bilimler > Paleontoloji", children: []},
                        {name: "Parazitoloji", value: "Fen > Temel Bilimler > Parazitoloji", children: []},
                        {name: "Spektroskopi", value: "Fen > Temel Bilimler > Spektroskopi", children: []},
                        {name: "Su Kaynakları", value: "Fen > Temel Bilimler > Su Kaynakları", children: []},
                        {name: "Taşınım", value: "Fen > Temel Bilimler > Taşınım", children: []},
                        {name: "Termodinamik", value: "Fen > Temel Bilimler > Termodinamik", children: []},
                        {name: "Viroloji", value: "Fen > Temel Bilimler > Viroloji", children: []},
                        {name: "Zooloji", value: "Fen > Temel Bilimler > Zooloji", children: []}
                    ]
                },
                {
                    name: 'Tıp',
                    value: 'Tıp',
                    children: [{name: "Acil Tıp", value: "Fen > Tıp > Acil Tıp", children: []},
                        {name: "Adli Tıp", value: "Fen > Tıp > Adli Tıp", children: []},
                        {name: "Alerji", value: "Fen > Tıp > Alerji", children: []},
                        {name: "Anatomi ve Morfoloji", value: "Fen > Tıp > Anatomi ve Morfoloji", children: []},
                        {name: "Androloji", value: "Fen > Tıp > Androloji", children: []},
                        {name: "Anestezi", value: "Fen > Tıp > Anestezi", children: []},
                        {name: "Biyofizik", value: "Fen > Tıp > Biyofizik", children: []},
                        {
                            name: "Biyokimya ve Moleküler Biyoloji",
                            value: "Fen > Tıp > Biyokimya ve Moleküler Biyoloji",
                            children: []
                        },
                        {
                            name: "Biyoteknoloji ve Uygulamalı Mikrobiyoloji",
                            value: "Fen > Tıp > Biyoteknoloji ve Uygulamalı Mikrobiyoloji",
                            children: []
                        },
                        {name: "Cerrahi", value: "Fen > Tıp > Cerrahi", children: []},
                        {name: "Dermatoloji", value: "Fen > Tıp > Dermatoloji", children: []},
                        {
                            name: "Endokrinoloji ve Metabolizma",
                            value: "Fen > Tıp > Endokrinoloji ve Metabolizma",
                            children: []
                        },
                        {
                            name: "Enfeksiyon Hastalıkları",
                            value: "Fen > Tıp > Enfeksiyon Hastalıkları",
                            children: []
                        },
                        {name: "Fizyoloji", value: "Fen > Tıp > Fizyoloji", children: []},
                        {
                            name: "Gastroenteroloji ve Hepatoloji",
                            value: "Fen > Tıp > Gastroenteroloji ve Hepatoloji",
                            children: []
                        },
                        {name: "Genel ve Dahili Tıp", value: "Fen > Tıp > Genel ve Dahili Tıp", children: []},
                        {
                            name: "Geriatri ve Gerontoloji",
                            value: "Fen > Tıp > Geriatri ve Gerontoloji",
                            children: []
                        },
                        {name: "Göz Hastalıkları", value: "Fen > Tıp > Göz Hastalıkları", children: []},
                        {name: "Halk ve Çevre Sağlığı", value: "Fen > Tıp > Halk ve Çevre Sağlığı", children: []},
                        {name: "Hematoloji", value: "Fen > Tıp > Hematoloji", children: []},
                        {name: "Hücre Biyolojisi", value: "Fen > Tıp > Hücre Biyolojisi", children: []},
                        {name: "İmmünoloji", value: "Fen > Tıp > İmmünoloji", children: []},
                        {
                            name: "Kadın Hastalıkları ve Doğum",
                            value: "Fen > Tıp > Kadın Hastalıkları ve Doğum",
                            children: []
                        },
                        {
                            name: "Kalp ve Kalp Damar Sistemi",
                            value: "Fen > Tıp > Kalp ve Kalp Damar Sistemi",
                            children: []
                        },
                        {name: "Klinik Nöroloji", value: "Fen > Tıp > Klinik Nöroloji", children: []},
                        {name: "Kulak, Burun, Boğaz", value: "Fen > Tıp > Kulak, Burun, Boğaz", children: []},
                        {name: "Mikrobiyoloji", value: "Fen > Tıp > Mikrobiyoloji", children: []},
                        {name: "Nörolojik Bilimler", value: "Fen > Tıp > Nörolojik Bilimler", children: []},
                        {
                            name: "Odyoloji ve Konuşma-Dil Patolojisi",
                            value: "Fen > Tıp > Odyoloji ve Konuşma-Dil Patolojisi",
                            children: []
                        },
                        {name: "Onkoloji", value: "Fen > Tıp > Onkoloji", children: []},
                        {name: "Ortopedi", value: "Fen > Tıp > Ortopedi", children: []},
                        {name: "Patoloji", value: "Fen > Tıp > Patoloji", children: []},
                        {name: "Pediatri", value: "Fen > Tıp > Pediatri", children: []},
                        {
                            name: "Periferik Damar Hastalıkları",
                            value: "Fen > Tıp > Periferik Damar Hastalıkları",
                            children: []
                        },
                        {name: "Psikiyatri", value: "Fen > Tıp > Psikiyatri", children: []},
                        {
                            name: "Radyoloji, Nükleer Tıp, Tıbbi Görüntüleme",
                            value: "Fen > Tıp > Radyoloji, Nükleer Tıp, Tıbbi Görüntüleme",
                            children: []
                        },
                        {name: "Rehabilitasyon", value: "Fen > Tıp > Rehabilitasyon", children: []},
                        {name: "Romatoloji", value: "Fen > Tıp > Romatoloji", children: []},
                        {
                            name: "Sağlık Bilimleri ve Hizmetleri",
                            value: "Fen > Tıp > Sağlık Bilimleri ve Hizmetleri",
                            children: []
                        },
                        {name: "Solunum Sistemi", value: "Fen > Tıp > Solunum Sistemi", children: []},
                        {name: "Spor Bilimleri", value: "Fen > Tıp > Spor Bilimleri", children: []},
                        {
                            name: "Tamamlayıcı ve Entegre Tıp",
                            value: "Fen > Tıp > Tamamlayıcı ve Entegre Tıp",
                            children: []
                        },
                        {
                            name: "Temel Sağlık Hizmetleri",
                            value: "Fen > Tıp > Temel Sağlık Hizmetleri",
                            children: []
                        },
                        {
                            name: "Tıbbi Araştırmalar Deneysel",
                            value: "Fen > Tıp > Tıbbi Araştırmalar Deneysel",
                            children: []
                        },
                        {name: "Tıbbi Etik", value: "Fen > Tıp > Tıbbi Etik", children: []},
                        {name: "Tıbbi İnformatik", value: "Fen > Tıp > Tıbbi İnformatik", children: []},
                        {
                            name: "Tıbbi Laboratuar Teknolojisi",
                            value: "Fen > Tıp > Tıbbi Laboratuar Teknolojisi",
                            children: []
                        },
                        {name: "Transplantasyon", value: "Fen > Tıp > Transplantasyon", children: []},
                        {name: "Tropik Tıp", value: "Fen > Tıp > Tropik Tıp", children: []},
                        {name: "Üroloji ve Nefroloji", value: "Fen > Tıp > Üroloji ve Nefroloji", children: []},
                        {name: "Yoğun Bakım, Tıp", value: "Fen > Tıp > Yoğun Bakım, Tıp", children: []}]
                },
                {
                    name: 'Veterinerlik',
                    value: 'Veterinerlik',
                    children: [{name: "Veterinerlik", value: "Fen > Veterinerlik > Veterinerlik", children: []}]
                },
                {
                    name: 'Ziraat',
                    value: 'Ziraat',
                    children: [{name: "Bahçe Bitkileri", value: "Fen > Ziraat > Bahçe Bitkileri", children: []},
                        {name: "Bitki Bilimleri", value: "Fen > Ziraat > Bitki Bilimleri", children: []},
                        {
                            name: "Tarımsal Ekonomi ve Politika",
                            value: "Fen > Ziraat > Tarımsal Ekonomi ve Politika",
                            children: []
                        },
                        {name: "Ziraat Mühendisliği", value: "Fen > Ziraat > Ziraat Mühendisliği", children: []},
                        {name: "Ziraat, Toprak Bilimi", value: "Fen > Ziraat > Ziraat, Toprak Bilimi", children: []}
                    ]
                }
            ]
        },
        {
            name: 'Sosyal',
            value: 'Sosyal',
            children: [
                {name: "Aile Çalışmaları", value: "Sosyal > Sosyal > Aile Çalışmaları", children: []},
                {name: "Antropoloji", value: "Sosyal > Sosyal > Antropoloji", children: []},
                {name: "Arkeoloji", value: "Sosyal > Sosyal > Arkeoloji", children: []},
                {name: "Asya Çalışmaları", value: "Sosyal > Sosyal > Asya Çalışmaları", children: []},
                {name: "Beslenme ve Diyetetik", value: "Sosyal > Sosyal > Beslenme ve Diyetetik", children: []},
                {name: "Beşeri Bilimler", value: "Sosyal > Sosyal > Beşeri Bilimler", children: []},
                {name: "Bilgi, Belge Yönetimi", value: "Sosyal > Sosyal > Bilgi, Belge Yönetimi", children: []},
                {
                    name: "Bilim Felsefesi ve Tarihi",
                    value: "Sosyal > Sosyal > Bilim Felsefesi ve Tarihi",
                    children: []
                },
                {name: "Coğrafya", value: "Sosyal > Sosyal > Coğrafya", children: []},
                {name: "Çevre Çalışmaları", value: "Sosyal > Sosyal > Çevre Çalışmaları", children: []},
                {name: "Davranış Bilimleri", value: "Sosyal > Sosyal > Davranış Bilimleri", children: []},
                {name: "Dil ve Dil Bilim", value: "Sosyal > Sosyal > Dil ve Dil Bilim", children: []},
                {name: "Din Bilimi", value: "Sosyal > Sosyal > Din Bilimi", children: []},
                {name: "Edebi Teori ve Eleştiri", value: "Sosyal > Sosyal > Edebi Teori ve Eleştiri", children: []},
                {name: "Edebiyat", value: "Sosyal > Sosyal > Edebiyat", children: []},
                {
                    name: "Eğitim, Eğitim Araştırmaları",
                    value: "Sosyal > Sosyal > Eğitim, Eğitim Araştırmaları",
                    children: []
                },
                {name: "Eğitim, Özel", value: "Sosyal > Sosyal > Eğitim, Özel", children: []},
                {name: "Ergonomi", value: "Sosyal > Sosyal > Ergonomi", children: []},
                {name: "Etik", value: "Sosyal > Sosyal > Etik", children: []},
                {name: "Etnik Çalışmalar", value: "Sosyal > Sosyal > Etnik Çalışmalar", children: []},
                {name: "Felsefe", value: "Sosyal > Sosyal > Felsefe", children: []},
                {name: "Film, Radyo, Televizyon", value: "Sosyal > Sosyal > Film, Radyo, Televizyon", children: []},
                {name: "Folklor", value: "Sosyal > Sosyal > Folklor", children: []},
                {name: "Halkla İlişkiler", value: "Sosyal > Sosyal > Halkla İlişkiler", children: []},
                {name: "Hemşirelik", value: "Sosyal > Sosyal > Hemşirelik", children: []},
                {name: "Hukuk", value: "Sosyal > Sosyal > Hukuk", children: []},
                {name: "İktisat", value: "Sosyal > Sosyal > İktisat", children: []},
                {name: "İletişim", value: "Sosyal > Sosyal > İletişim", children: []},
                {name: "İş", value: "Sosyal > Sosyal > İş", children: []},
                {name: "İşletme", value: "Sosyal > Sosyal > İşletme", children: []},
                {name: "İşletme Finans", value: "Sosyal > Sosyal > İşletme Finans", children: []},
                {name: "Kadın Araştırmaları", value: "Sosyal > Sosyal > Kadın Araştırmaları", children: []},
                {name: "Kamu Yönetimi", value: "Sosyal > Sosyal > Kamu Yönetimi", children: []},
                {name: "Kentsel Çalışmalar", value: "Sosyal > Sosyal > Kentsel Çalışmalar", children: []},
                {
                    name: "Kriminoloji ve Ceza Bilimi",
                    value: "Sosyal > Sosyal > Kriminoloji ve Ceza Bilimi",
                    children: []
                },
                {name: "Kültürel Çalışmalar", value: "Sosyal > Sosyal > Kültürel Çalışmalar", children: []},
                {name: "Madde Bağımlılığı", value: "Sosyal > Sosyal > Madde Bağımlılığı", children: []},
                {name: "Mantık", value: "Sosyal > Sosyal > Mantık", children: []},
                {name: "Müzik", value: "Sosyal > Sosyal > Müzik", children: []},
                {
                    name: "Nüfus İstatistikleri Bilimi",
                    value: "Sosyal > Sosyal > Nüfus İstatistikleri Bilimi",
                    children: []
                },
                {
                    name: "Ortaçağ ve Rönesans Çalışmaları",
                    value: "Sosyal > Sosyal > Ortaçağ ve Rönesans Çalışmaları",
                    children: []
                },
                {
                    name: "Otelcilik, Konaklama, Spor ve Turizm",
                    value: "Sosyal > Sosyal > Otelcilik, Konaklama, Spor ve Turizm",
                    children: []
                },
                {name: "Psikoloji", value: "Sosyal > Sosyal > Psikoloji", children: []},
                {
                    name: "Sağlık Politikaları ve Hizmetleri",
                    value: "Sosyal > Sosyal > Sağlık Politikaları ve Hizmetleri",
                    children: []
                },
                {name: "Sanat", value: "Sosyal > Sosyal > Sanat", children: []},
                {name: "Siyasi Bilimler", value: "Sosyal > Sosyal > Siyasi Bilimler", children: []},
                {name: "Sosyal Çalışma", value: "Sosyal > Sosyal > Sosyal Çalışma", children: []},
                {name: "Sosyoloji", value: "Sosyal > Sosyal > Sosyoloji", children: []},
                {name: "Tarih", value: "Sosyal > Sosyal > Tarih", children: []},
                {name: "Tiyatro", value: "Sosyal > Sosyal > Tiyatro", children: []},
                {name: "Uluslararası İlişkiler", value: "Sosyal > Sosyal > Uluslararası İlişkiler", children: []}
            ]
        }
    ];


    constructor(
        public localizationService: LocalizationService, private searchService: SearchService, private exportService: ExportService,
        private toasterService: ToastrService) {
    }

    getSettingsTagify(_placeholder): TagifySettings {
        return {
            templates: {
                dropdownItemNoMatch: function (data) {
                    return `<div class='${this.settings.classNames.dropdownItem} ' value="noMatch" tabindex="0" >
                   Ekle: <strong>${data.value}</strong>
                </div>`
                }
            },
            editTags: {
                clicks: 2,
                keepInvalid: true
            },
            delimiters: ",",
            keepInvalidTags: false,
            backspace: true,
            trim: true,
            duplicates: false,
            maxTags: 15,
            placeholder: this.localizationService.getLocalizationValue(_placeholder),
            dropdown: {
                enabled: 1,
            }
        };
    }


    searchJournal() {

        if(!this.publicationTitle || this.publicationTitle.trim().length<10){
            return this.toasterService.error(this.localizationService.getLocalizationValue("PublicationTitleNotEmpty"));
        }

        if(!this.publicationAbstract || this.publicationAbstract.trim().length<50){
            return this.toasterService.error(this.localizationService.getLocalizationValue("AbstractNotEmpty"));
        }


        this.publicationList = [];
        this.journalList = [];
        this.journalScore = new Map<string, number>();

        let _specialCharacters = [";", "“", "”", "’", "'", "%", "+", "-", "&&", "||", "!", "(", ")", '"', "~", "*", ".", ",", ":", "?", "[", "]", "\n"];
        let stopWords = ["örneği", "II", "III", "V", "IV", "VI", "VII", "ye", "kısa", "yu", "sunulmustur", "sonuçlara", "incelenmiş", "ornegini",
            "incelemesiyle", "el", "dair", "olacaktır", "biçim", "örneğini", "içermektedir", "vermek", "olacaktır", "çalışmalardaki", "bilgiler", "desteklenerek",
            "görüşler", "yillar", "boyunca", "icermektedir", "yapılmamıştır", "yılları", "nedenle", "araştırmasında", "özelliklerini", "yansıtan", "anlaminin",
            "disinda", "anlamina", "yansimistir", "kullanilmis", "unsurlarindan", "sembolu", "bir", "iki", "üç", "dört", "beş", "altı", "yedi", "sekiz", "dokuz",
            "on", "sıfır", "rakam", "dışı", "de", "da", "den", "dan", "and", "gelistirdigi", "konu", "var", "olup", "düşük", "yapar", "oranda", "sınırlı", "açık",
            "ele", "yaş", "kullanılmıştır", "çalışmada,", "şeklinde", "temel", "oranı", "çalışmanın", "vurgu", "çalışmadır", "tarihleri", "ocak", "şubat", "mart",
            "öne", "sıklıkla", "bulgular", "hangi", "etki", "yaygın", "gereken",
            "nisan", "mayıs", "haziran", "temmuz", "ağustos", "eylül", "ekim", "kasım", "aralık", "yapılmış", "yer", "dış", "gücü", "ilk", "yeri", "li", "ağı",
            "gelişmiştir", "artmıştır", "sırada", "nun", "nün", "amacını", "yılından", "yılına", "araştırma", "dönük", "nitel", "nicel", "dönük", "amaç", "amaçla",
            "ilişki", "takip", "durum", "konusu", "almaktadır", "tercih", "kaymıştır", "yılların", "durumu", "yön", "incelemek", "dönemde", "sonrasında", "nin",
            "ortaya", "koymak", "eder", "hızlı", "artık", "edilmesi", "sonucu", "amaçlanmıştır", "değerlendirilmiştir", "kapsamında", "ana", "belirlenen", "bağlamında",
            "bilgi", "si", "toplam", "kanısı", "oluşmuştur", "beklenmektedir", "bulgularının", "bulgulara", "uygulama", "arasında", "açısından", "konuyu", "yönelik",
            "yöneliktir", "birçok", "al", "çok", "önemli", "ifade", "edilen", "yeni", "olmuştur", "başlıca", "sorun", "analiz", "analizi", "eden", "çalışma", "tüm",
            "en", "az", "aza", "enaza", "önlem", "henüz", "adil", "yılında", "arasında,", "ayrıca", "olarak", "benzer", "aynı", "fazla", "karşı", "son", "sebep",
            "aynı", "son", "olmakta", "gerekli", "belli", "eşik", "zaman", "sonuç", "metot", "yapılan", "görülür", "güçlü", "bağlı", "önem", "geniş", "ortak", "söz",
            "bu", "özgün", "sunar", "alan", "beraber", "hale", "yerde", "büyük", "arası", "etmek", "özellikle", "olarak", "amacı", "veri", "pozitif", "yönde",
            "negatif", "tespit", "edilmiştir", "kısmi", "sahip", "olduğu", "belirlenmiştir", "şekilde", "özdgün", "özgü", "çözüm", "yolu", "inceleme", "altına",
            "alınıp", "hızla", "artan", "görev", "konulmuştur", "gittikçe", "bulunmaktadır", "yakın", "çalışılmış", "çıkan", "yönelik", "ise", "da", "yada", "bu",
            "vb", "vd", "inin", "diğer", "nından", "olan", "un", "acaba", "acep", "açıkça", "açıkçası", "adamakıllı", "adeta", "ait", "ama", "amma", "anca", "ancak",
            "bana", "bari", "başkası", "bazen", "bazı", "belki", "ben", "beri", "beriki", "bilcümle", "bile", "binaen", "binaenaleyh", "biraz", "birazdan",
            "birbiri", "birçoğu", "birden", "birden", "birdenbire", "biri", "birice", "birileri", "birisi", "birkaçı", "birlikte", "bitevi", "biteviye", "bittabi",
            "biz", "bizatihi", "bizce", "bizcileyin", "bizden", "bizimki", "bizzat", "boşuna", "böyle", "böylece", "böylecene", "böylelikle", "böylemesine",
            "böylesine", "buna", "bunda", "bundan", "bunlar", "bunu", "bunun", "buracıkta", "burada", "buradan", "burası", "büsbütün", "cuk", "cümlesi", "çabuk",
            "çabukça", "çeşitli", "çoğu", "çoğu", "çoğu", "çoğun", "çoğunca", "çoğunlukla", "çok", "çokça", "çokları", "çoklarınca", "çokluk", "çoklukla", "çünkü",
            "daha", "dahi", "dahil", "dahilen", "daima", "değil", "değin", "dek", "demin", "demincek", "deminden", "denli", "derakap", "derhal", "derken", "diğeri",
            "diye", "doğru", "dolayı", "dolayısıyla", "eğer", "elbet", "elbette", "emme", "enikonu", "epey", "epeyce", "epeyi", "esasen", "esnasında", "etraflı",
            "etraflıca", "evleviyetle", "evvel", "evvela", "evvelce", "evvelden", "evvelemirde", "evveli", "fakat", "filanca", "gah", "gayet", "gayetle", "gayrı",
            "gayri", "geçende", "geçenlerde", "gelgelelim", "gene", "gerçi", "gerek", "gırla", "gibi", "gibi", "gibilerden", "gibisinden", "gine", "göre", "hakeza",
            "halbuki", "halen", "halihazırda", "haliyle", "handiyse", "hangisi", "hani", "hasebiyle", "hasılı", "hatta", "hele", "hem", "hepsi", "hiçbiri", "hoş",
            "hulasaten", "için", "iken", "ila", "ile", "ile", "ile", "ilen", "illa", "illaki", "imdi", "indinde", "inen", "ister", "iş", "itibarıyla", "iyice",
            "iyicene", "kaçı", "kadar", "kaffesi", "kah", "kala", "karşın", "kaynak", "kelli", "kendi", "keşke", "kez", "keza", "kezalik", "kısaca", "kim", "kim",
            "kimi", "kimisi", "kimse", "kimse", "kimsecik", "kimsecikler", "külliyen", "lakin", "Leh", "lütfen", "maada", "madem", "mademki", "mamafih", "mebni",
            "meğer", "meğerki", "meğerse", "nasıl", "nasılsa", "naşi", "nazaran", "neden", "nedeniyle", "nedense", "nerde", "nerden", "nerdeyse", "nere", "nerede",
            "nereden", "neredeyse", "neresi", "nereye", "netekim", "neye", "neyi", "neyse", "nice", "niçin", "nihayet", "nihayetinde", "nitekim", "niye", "oldu",
            "oldukça", "olur", "ona", "onca", "onculayın", "onda", "ondan", "ondan", "onlar", "onu", "onun", "oracık", "oracıkta", "orada", "oradan", "oranca",
            "oranla", "oraya", "oysa", "oysaki", "öbür", "öbürkü", "öbürü", "önce", "önceden", "önceleri", "öncelikle", "öteki", "ötekisi", "öyle", "öylece",
            "öylelikle", "öylemesine", "öz", "pek", "pekala", "pekçe", "peki", "peyderpey", "rağmen", "sadece", "sahi", "sahiden", "sana", "sanki", "sen", "siz",
            "sonra", "sonradan", "sonraları", "sonunda", "şayet", "şöyle", "şuna", "şuncacık", "şunda", "şundan", "şunlar", "şunu", "şunun", "şura", "şuracık",
            "şuracıkta", "şurası", "tabii", "tam", "tamam", "tamamen", "tamamıyla", "tek", "üzere", "vasıtasıyla", "velev", "velhasıl", "velhasılıkelam", "veya",
            "veyahut", "yahut", "yakında", "yakından", "yakınlarda", "yakinen", "yalnız", "yalnız", "yalnızca", "yani", "yeniden", "yenilerde", "yine", "yok", "yok",
            "yoksa", "yoluyla", "yüzünden", "zarfında", "zaten", "zati", "zira", "a", "b", "c", "ç", "d", "e", "f", "g", "ğ", "h", "i", "j", "k", "l", "m", "n",
            "o", "ö", "p", "q", "r", "s", "t", "u", "ü", "v", "w", "x", "y", "z", "İ", "use", "me", "my", "myself", "we", "our", "ours", "ourselves", "you",
            "you're", "you've", "you'll", "you'd", "your", "yours", "yourself", "yourselves", "he", "him", "his", "himself", "she", "she's", "her", "hers",
            "herself", "it", "it's", "its", "itself", "they", "them", "their", "theirs", "themselves", "what", "which", "who", "whom", "this", "that", "that'll",
            "these", "those", "am", "is", "are", "was", "were", "be", "been", "being", "have", "has", "had", "having", "do", "does", "did", "doing", "a", "an", "the",
            "and", "but", "if", "or", "because", "as", "until", "while", "of", "by", "for", "with", "about", "against", "between", "into", "through", "during",
            "before", "after", "above", "below", "to", "from", "up", "down", "in", "out", "on", "off", "over", "under", "again", "further", "then", "once", "here",
            "there", "when", "where", "why", "how", "all", "any", "both", "each", "few", "more", "most", "other", "some", "such", "no", "nor", "not", "only", "own",
            "same", "so", "than", "too", "very", "s", "t", "can", "will", "just", "don", "don't", "should", "should've", "now", "d", "ll", "m", "o", "re", "ve", "y",
            "ain", "aren", "aren't", "couldn", "couldn't", "didn", "didn't", "doesn", "doesn't", "hadn", "hadn't", "hasn", "hasn't", "haven", "haven't", "isn", "isn't",
            "ma", "mightn", "mightn't", "mustn", "mustn't", "needn", "needn't", "shan", "shan't", "shouldn", "shouldn't", "wasn", "wasn't", "weren", "weren't", "won",
            "won't", "wouldn", "wouldn't", "The", "of", "and", "ve", "in", "to", "a", "was", "were", "is", "The", "with", "bir", "that", "ile", "for", "this", "olarak",
            "Bu", "as", "on", "are", "by", "In", "be", "study", "bu", "from", "için", "patients", "which", "it", "olan", "between", "has", "have", "an", "been", "at",
            "de", "daha", "their", "not", "used", "olduğu", "da", "arasında", "en", "This", "who", "göre", "study", "these", "or", "ise", "önemli", "can", "KB", "MB",
            "GB", "uzerinden", "onerilmektedir", "found", "edilen", "also", "different", "It", "had", "using", "data", "group", "ortaya", "results", "significant",
            "farklı", "important", "one", "most", "anlamlı", "çok", "iki", "other", "yüksek", "gibi", "than", "edilmiştir.", "elde", "tespit", "after", "all", "its",
            "about", "more", "research", "determined", "two", "analysis", "they", "Turkish", "yer", "there", "total", "such", "A", "will", "we", "during", "levels",
            "açısından", "our", "yapılan", "both", "her", "treatment", "first", "into", "terms", "ilgili", "çalışmada", "students", "obtained", "çalışma", "social",
            "hem", "no", "some", "determine", "test", "tarafından", "use", "effect", "aim", "üzerinde", "alan", "level", "should", "As", "sahip", "out", "may", "veya",
            "values", "studies", "higher", "mean", "groups", "control", "age", "effects", "yönelik", "high", "method", "time", "according", "arasındaki", "time",
            "according", "arasındaki", "relationship", "sonra", "new", "diğer", "but", "compared", "order", "number", "his", "result", "risk", "significantly",
            "education", "yeni", "through", "based", "tedavi", "birlikte", "observed", "toplam", "şekilde", "çalışmanın", "ilk", "due", "We", "study", "ele", "yaş",
            "kullanılmıştır", "çalışmada", "Amaç", "included", "period", "aimed", "patient", "related", "years", "evaluated", "performed", "There", "kontrol",
            "analiz", "difference", "among", "model", "while", "positive", "rate", "cases", "within", "However", "present", "belirlenmiştir", "büyük", "many",
            "bağlı", "under", "factors", "fazla", "kullanılarak", "increase", "Results", "made", "analizi", "three", "edildi", "only", "kadar", "showed", "ilişkin",
            "temel", "process", "applied", "tüm", "development", "For", "Methods", "bazı", "yapılmıştır", "seen", "ortalama", "üzerine", "sonrası",
            "According", "üzere", "son", "increased", "case", "amacıyla", "conducted", "developed", "findings", "Araştırma", "when", "kullanılan", "carried",
            "common", "life", "Conclusion", "olmak", "each", "methods", "main", "yöntemi", "araştırma", "ait", "various", "samples", "eden", "olduğunu", "bilgi",
            "çeşitli", "those", "üç", "evaluate", "sonucunda", "iyi", "alt", "problems", "içinde", "them", "incelenmiştir", "genel", "uygun", "özellikle", "examined",
            "well", "role", "work", "Ayrıca", "investigate", "value", "diagnosis", "Bulgular", "ya", "nedeniyle", "amacı", "neden", "aynı", "karşı", "analyzed",
            "being", "ilişki", "amacı", "effective", "bulunan", "oranı", "very", "bulunmuştur", "These", "public", "değerleri", "olup", "Sonuç", "purpose", "type",
            "respectively", "kabul", "birçok", "collected", "fark", "olması", "without", "etkisi", "normal", "lower", "literature", "sık", "etkili", "differences",
            "especially", "considered", "how", "Objective", "biri", "konusu", "olarak", "changes", "taken", "over", "content", "addition", "form", "negative",
            "activities", "problem", "aims", "scores", "could", "same", "tek", "patients", "Çalışma", "az", "blood", "grup", "veri", "idi", "parameters", "zaman",
            "içerisinde", "olmayan", "revealed", "production", "against", "pozitif", "field", "görülmüştür", "uygulanan", "özellikleri", "he", "nasıl", "primary",
            "low", "grubunda", "veriler", "where", "second", "given", "before", "importance", "scale", "yılında", "tanısı", "structure", "To", "iş", "investigated",
            "Although", "did", "show", "associated", "üzerindeki", "because", "article", "factor", "approach", "grubu", "Yöntem", "olmuştur", "öncesi", "area",
            "hakkında", "possible", "right", "altında", "oldukça", "düzeyde", "oluşan", "uzun", "examine", "amaçlanmıştır", "general", "detected", "early",
            "conditions", "Araştırmanın", "ne", "dönemde", "working", "place", "current", "dahil", "Ancak", "Çalışmada", "sadece", "change", "knowledge", "söz",
            "concept", "cause", "alınan", "göstermektedir", "bakımından", "rates", "diagnosed", "testi", "gelen", "gün", "düzeyleri", "species", "Group", "including",
            "When", "önce", "presented", "On", "sample", "After", "devam", "saptanmıştır", "care", "average", "farklılık", "provide", "Therefore", "çalışma",
            "konusunda", "up", "regarding", "önem", "increasing", "sonuçları", "ratio", "alınmıştır", "state", "saptandı", "score", "ifade", "group", "end",
            "görülen", "doğru", "highest", "ancak", "similar", "evaluation", "Elde", "sayısı", "While", "göz", "özel", "sonuçlar", "adet", "kendi", "areas",
            "ön", "görülmektedir", "treated", "One", "make", "olumlu", "Her", "need", "Çalışmanın", "takip", "kapsamında", "presence", "local", "known", "yıl",
            "olumsuz", "sırasıyla", "caused", "All", "etkileri", "left", "çıkan", "used", "towards", "süresi", "sonucu", "method", "ilişkili", "etmektedir",
            "weight", "düzeyi", "mm", "Grup", "measured"];
        let numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

        let searchText = " " + this.publicationTitle + " " + this.publicationAbstract + " " + this.keywords.map(t => t.value).join(" ") + " ";

        searchText = this.toEN(searchText).toLowerCase();


        _specialCharacters.forEach(c => {
            searchText = searchText.replaceAll(c, " ");
        });

        stopWords.forEach(c => {
            let rx = new RegExp("(^|\\s)" + this.toEN(c).toLowerCase() + "(?!\\S)", "gi");
            searchText = searchText.replace(rx, "$1" + " ");
        });

        numbers.forEach(c => {
            searchText = searchText.replaceAll(c, " ");
        });
        searchText = searchText.replace(/\s+/g, " ");
        searchText = searchText.trim();

        this.searchService.journalFinder(searchText).subscribe(res => {
            if (res["hits"]["hits"]) {
                for (let i = 0; i < res["hits"]["hits"].length; i++) {
                    let _res = res["hits"]["hits"][i]["fields"];
                    let _publication = new PublicationDTO();

                    _publication.score = res["hits"]["hits"][i]["_score"];

                    _publication.id = _res["id"][0];

                    _publication.journal = new PublicationJournalDTO();
                    _publication.journal.id = _res["journal.id"][0];

                    if (!this.journalScore.get(_publication.journal.id)) {
                        this.journalScore.set(_publication.journal.id, 0);
                    }
                    this.journalScore.set(_publication.journal.id, this.journalScore.get(_publication.journal.id) + _publication.score);

                    if (_res["abstracts.title"] && _res["abstracts.title"].length > 0) {
                        _publication.abstracts = new Array<PublicationAbstractDTO>();
                        _publication.abstracts.push(new PublicationAbstractDTO());
                        _publication.abstracts[0].title = _res["abstracts.title"][0];
                    }

                    if (_res["authors.name"] && _res["authors.name"].length > 0) {
                        _publication.authors = [];
                        for (let i = 0; i < _res["authors.name"].length; i++) {
                            let _author = new PublicationAuthorDTO();
                            _author.name = _res["authors.name"][i];
                            _publication.authors.push(_author);
                        }
                    }

                    if (_res["issue.number"] && _res["issue.number"].length > 0) {
                        _publication.issue = new PublicationIssueDTO();
                        _publication.issue.number = _res["issue.number"][0];
                        _publication.issue.volume = _res["issue.volume"][0];
                    }

                    if (_res["startPage"] && _res["startPage"].length > 0) {
                        _publication.startPage = _res["startPage"][0];
                        _publication.endPage = _res["endPage"][0];
                    }

                    if (_res["doi"] && _res["doi"].length > 0) {
                        _publication.doi = _res["doi"][0];
                    }

                    if (_res["publicationYear"] && _res["publicationYear"].length > 0) {
                        _publication.publicationYear = _res["publicationYear"][0];
                    }


                    this.publicationList.push(_publication);
                }
            }


            let journalScoreArray = Array.from(this.journalScore);
            journalScoreArray.sort((j1, j2) => j2[1] - j1[1]);
            journalScoreArray = journalScoreArray.slice(0, 30);
            let sortedJournalScore = new Map(journalScoreArray);
            journalScoreArray.forEach(s => {
                if (this.maxScore < Math.log1p(sortedJournalScore.get(s[0]))) {
                    this.maxScore = Math.log1p(sortedJournalScore.get(s[0]));
                }
            });


            let idList = Array.from(sortedJournalScore.keys());

            this.searchService.getJournalByIdList(idList.reduce((prev, curr) => prev + " " + curr),this.selectedSubjectOptions).subscribe({
                next: (journalRes) => {
                    this.journalList = new Array<JournalDTO>();
                    if (journalRes["hits"] && journalRes["hits"]["hits"]) {
                        for (let i = 0; i < journalRes["hits"]["hits"].length; i++) {
                            let _journal = new JournalDTO();
                            _journal.bindData(journalRes["hits"]["hits"][i]["_source"]);
                            _journal['score'] = this.journalScore.get(_journal.id.toString());
                            this.journalList.push(_journal);
                        }
                        this.journalList = this.journalList.sort((p1, p2) => p2['score'] - p1['score']);

                    console.log(idList);
                        this.exportService.getJournalCitationListByIdList(idList.reduce((prev, curr) => prev + " " + curr)).subscribe({
                            next: (res) => {
                                if (res["aggregations"] && res["aggregations"]["journals_citation_count"] && res["aggregations"]["journals_citation_count"]["yayin"] && res["aggregations"]["journals_citation_count"]["yayin"]["buckets"]) {
                                    let _res = res["aggregations"]["journals_citation_count"]["yayin"]["buckets"];
                                    if (_res) {
                                        _res.forEach(r => {
                                            let journalId = r["key"];
                                            let makale_sayisi = 0;
                                            if (r["doc_count"]) {
                                                makale_sayisi = r["doc_count"];
                                            }
                                            let atif_alan_makale_sayisi = 0;
                                            let makaleden_alinan_atif = 0;
                                            let projeden_alinan_atif = 0;
                                            let atif_sayisi = 0;
                                            if (r["yayin_sayisi"] && r["yayin_sayisi"]["atif_sayisi"]) {
                                                if (r["yayin_sayisi"]["atif_sayisi"]["doc_count"]) {
                                                    atif_sayisi = r["yayin_sayisi"]["atif_sayisi"]["doc_count"]
                                                }
                                                if (r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]) {
                                                    atif_alan_makale_sayisi = r["yayin_sayisi"]["atif_sayisi"]["atif_alan_makale_sayisi"]["value"];
                                                }
                                                if (r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]) {
                                                    makaleden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]["value"];
                                                }
                                                if (r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]) {
                                                    projeden_alinan_atif = r["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]["value"];
                                                }
                                            }
                                            let atif_ortalamasi = "0,0";
                                            if (makale_sayisi > 0 && atif_sayisi > 0) {
                                                atif_ortalamasi = parseFloat(parseFloat(("" + (atif_sayisi / makale_sayisi))).toFixed(2)).toLocaleString('tr-TR');
                                            }

                                            console.log(this.journalList);
                                            this.journalList.forEach(j => {
                                                console.log(this.calculateMyScore(j['score']))
                                                j['width'] = this.calculateMyScore(j['score']);
                                                if (j.id == journalId) {
                                                    j.citationCount = atif_sayisi;
                                                    j.citationFromPaper = makaleden_alinan_atif;
                                                    j.citationFromProject = projeden_alinan_atif;
                                                    j.publicationCount = makale_sayisi;
                                                    j.citationAvg = atif_ortalamasi;
                                                    j.citedPublicationCount = atif_alan_makale_sayisi;
                                                }

                                            });

                                        });
                                    }
                                }
                            },
                            error: (e) => console.error(e)
                        });

                    }
                },
                error: (e) => console.error(e)
            });

        });
    }

    toEN(text) {
        return text.replaceAll('Ğ', 'g')
            .replaceAll('Ü', 'u')
            .replaceAll('Ş', 's')
            .replaceAll('I', 'i')
            .replaceAll('İ', 'i')
            .replaceAll('Ö', 'o')
            .replaceAll('Ç', 'c')
            .replaceAll('ğ', 'g')
            .replaceAll('Ğ', 'g')
            .replaceAll('ü', 'u')
            .replaceAll('ş', 's')
            .replaceAll('ı', 'i')
            .replaceAll('ö', 'o')
            .replaceAll('ç', 'c');
    }

    calculateMyScore(score) {
        score = Math.log1p(score);
        if (score === this.maxScore) {
            return 100;
        }
        return score * 100 / this.maxScore;
    }

    subjectCollapse(journal: JournalDTO) {
        if (journal['subjectCollapsed']) {
            journal['subjectCollapsed'] = !journal['subjectCollapsed'];
        } else {
            journal['subjectCollapsed'] = true;
        }
    }

    publicationCollapse(journal: JournalDTO) {
        if (journal['publicationCollapsed']) {
            journal['publicationCollapsed'] = !journal['publicationCollapsed'];
        } else {
            journal['publicationCollapsed'] = true;
        }
    }

    getMyPublication(journal: JournalDTO) {
        let _publication = this.publicationList.filter(p => {
            if (p.journal.id == journal.id) {
                return true;
            }
        });

        _publication.sort((p1, p2) => p2.score - p1.score);

        return _publication;
    }


    urlFormat(url) {
        if (!url) {
            return url;
        }
        url = url.replaceAll("<strong class=\"highlight\">", "");
        url = url.replaceAll("</strong>", "");
        return slugify(url, {
            lower: true,
            remove: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
            strict: true,
            trim: true
        });
    }

    getChildValue(t: TreeData) {
        let values: String[] = [];
        if (t && t.children && t.children.length > 0) {
            t.children.forEach(c => {
                if (c.children && c.children.length > 0) {
                    values = values.concat(this.getChildValue(c));
                } else {
                    values.push(c.value);
                }
            });
            return values;
        }
        values.push(t.value);
        return values;
    }

    onSelectionChanged() {
        this.selectedSubjectOptions = [];
        if (this.subjectSearchValue && this.subjectSearchValue.length > 0) {
            this.subjectSearchValue.forEach(t => {
                this.selectedSubjectOptions = this.selectedSubjectOptions.concat(this.getChildValue(t));
            });
        }
    }


    ngOnInit(): void {
    }

    protected readonly getCurrentLanguage = getCurrentLanguage;
}
