import {Component, OnInit} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgxEchartsDirective} from "ngx-echarts";
import {LocalizationService} from "../../../trdcore/service/localizationService";
import {SearchService} from "../search.service";
import {ActivatedRoute, Router} from "@angular/router";
import {InstitutionDTO} from "./InstitutionDTO";
import {TrdCoreModule} from "../../../trdcore/trdcore.module";
import {EChartsOption} from "echarts";
import {ToastrService} from "ngx-toastr";
import {FormsModule} from "@angular/forms";
import {getCurrentLanguage, getCurrentYear} from "../../app.module";
import {PublicationListComponent} from "../publication-list/publication-list.component";
import {PageModel} from "../../../trdcore/component/paginater/PageModel";
import {PublicationDTO} from "../publication/PublicationDTO";
import {ExportComponent} from "../export/export.component";

@Component({
    selector: 'app-institution-detail',
    standalone: true,
    imports: [
        NgIf,
        NgxEchartsDirective,
        TrdCoreModule,
        DecimalPipe,
        NgForOf,
        FormsModule,
        PublicationListComponent,
        ExportComponent
    ],
    templateUrl: './institution-detail.component.html',
    styleUrl: './institution-detail.component.scss'
})
export class InstitutionDetailComponent implements OnInit {
    selectedInstitutionCode: string;
    selectedInstitution: InstitutionDTO;
    paperCount: 0;
    projectCount: 0;
    citationCount: 0;
    citationFromPaperCount: 0;
    citationFromProjectCount: 0;
    citationAvg="0,0";
    fromYear=1900;
    toYear=new Date().getFullYear();
    toDate=new Date().toISOString().slice(0, 10);
    fromDate="1900-01-01";
    YearList=[]
    sortTypes: string[]=[];
    selectYear;
    indexDate;
    chartOptionPublicationAndCitationCountsOfYears: EChartsOption;
    chartOptionPublicationTypeCount: EChartsOption;
    chartOptionSubjectCount: EChartsOption;
    papersOfTheInstitutionIdList: Array<string>;
    publicationInstitutionPageModel:PageModel = new PageModel(20);
    papersOfTheInstitutionList: Array<PublicationDTO>;
    institutionCollaborationList = [];
    chartOptionPaperAccessType: EChartsOption
    chartOptionPublicationProjeGroup: EChartsOption;
    chartOptionPublicationProjeGroupList = [];
    chartOptionPublicationAccessTypeList = [];

    constructor(
        private localizationService: LocalizationService,
        private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router,private toastrService: ToastrService) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
    }
    ngOnInit(): void {
        this.selectedInstitutionCode = this.activatedRoute.snapshot.paramMap.get('id');
         this.indexDate= this.activatedRoute.snapshot.queryParams['indeks_tarihi'];
         if(this.activatedRoute.snapshot.queryParams["yayin_yili"]){
             this.toYear=(this.activatedRoute.snapshot.queryParams["yayin_yili"]);
             this.fromYear=this.activatedRoute.snapshot.queryParams["yayin_yili"];
             this.selectYear=this.activatedRoute.snapshot.queryParams["yayin_yili"];
         }
         else{
             this.selectYear="all";
         }
         if(this.indexDate){
             [this.fromDate, this.toDate] = this.indexDate.split(':');
         }
        this.generateYearList(1900, new Date().getFullYear());
        this.selectedInstitution = new InstitutionDTO();
        this.searchService.getInstitutionByCode(this.selectedInstitutionCode).subscribe({
            next: (res) => {
                if (res["hits"]["hits"] && res["hits"]["hits"][0] && res["hits"]["hits"][0]["_source"]) {
                    this.selectedInstitution = new InstitutionDTO();
                    this.selectedInstitution.bindData(res["hits"]["hits"][0]["_source"]);
                }else{
                    window.location.href="https://static.trdizin.gov.tr/404";
                }
            },
            error: (e) => console.error(e)
        });

        this.searchService.getInstitutionCitationByCode(this.selectedInstitutionCode,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe({
            next: (res) => {
                this.citationFromPaperCount=0;
                this.citationFromProjectCount=0;
                this.paperCount=0;
                this.projectCount=0;

                if (res["aggregations"]) {
                    if (res["aggregations"]["citationFacet"] && res["aggregations"]["citationFacet"]["atif_dagilimi"] && res["aggregations"]["citationFacet"]["atif_dagilimi"]["yayin"]) {
                        res["aggregations"]["citationFacet"]["atif_dagilimi"]["yayin"]["buckets"].forEach(b => {
                            if(b["key"]==this.selectedInstitutionCode){
                                if (b["yayin_sayisi"] && b["yayin_sayisi"]["atif_sayisi"]) {
                                    this.citationFromPaperCount =  b["yayin_sayisi"]["atif_sayisi"]["makaleden_alinan_atif"]["value"]
                                    this.citationFromProjectCount = b["yayin_sayisi"]["atif_sayisi"]["projeden_alinan_atif"]["value"]
                                    return;
                                }
                            }


                        });
                    }
                    if (res["aggregations"]["docTypeFacet"]) {
                        res["aggregations"]["docTypeFacet"]["buckets"].forEach(b => {
                            if (b["key"] == "PAPER") {
                                this.paperCount = b["doc_count"];
                            } else if (b["key"] == "PROJECT") {
                                this.projectCount = b["doc_count"];
                            }
                            this.getCitationAvg();
                        });
                    }
                }
            },
            error: (e) => console.error(e)
        });


        this.searchService.getInstitutionCitationAndDocumentOfYearByCode(this.selectedInstitutionCode,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe({
            next: (res) => {
                if (res["aggregations"] && res["aggregations"]["years"] && res["aggregations"]["years"]["buckets"]) {

                    let yearsPapers = new Map<string, number>();
                    let yearsCitations = new Map<string, number>();


                    res["aggregations"]["years"]["buckets"].forEach(y => {
                        let _year = y["key"];
                        let _docCount = y["doc_count"];
                        let _citationCount = y["cited_references_count"]["count"]["value"];
                        yearsPapers.set(_year, _docCount);
                        yearsCitations.set(_year, _citationCount);
                    });


                    let _years = [...yearsPapers.keys(), ...yearsCitations.keys()];
                    _years = _years.sort((a, b) => Number(a) - Number(b));
                    let citationYear = new Set(_years);

                    let citationCount: number[] = [];
                    let paperCount: number[] = [];
                    citationYear.forEach(cy => {
                        let _citationCountOfYear = yearsCitations.get(cy)
                        if (_citationCountOfYear) {
                            citationCount.push(_citationCountOfYear);
                        } else {
                            citationCount.push(0);
                        }

                        let _paperCountOfYear = yearsPapers.get(cy)
                        if (_paperCountOfYear) {
                            paperCount.push(_paperCountOfYear);
                        } else {
                            paperCount.push(0);
                        }
                    });
                    if(this.YearList.length>0){
                        this.YearList=this.YearList.sort((a, b) => {
                            if (b < a) {
                                return -1;
                            }
                            if (b > a) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    this.chartOptionPublicationAndCitationCountsOfYears = {
                        legend: {},
                        grid: {
                            top: 35,
                            bottom: 30,
                            right: 10,
                            left: 40
                        },
                        tooltip: {
                            show: true,
                        },
                        yAxis: {
                            nameTextStyle: {
                                fontSize: 0.1,
                            },
                            type: 'value',
                            splitLine: {
                                show: true
                            },
                        },
                        xAxis: {
                            type: 'category',
                            data: [...new Set(citationYear)],
                            axisTick: {
                                length: 1,
                                interval: 1
                            },
                            splitLine: {
                                show: true
                            },
                            axisLabel: {
                                inside: false,
                                rotate: 60,
                                fontSize: 10,
                            },
                            axisLine: {}
                        },
                        series: [
                            {
                                data: citationCount,
                                type: 'bar',
                                name: (this.localizationService.getLocalizationValue('citationCount')),
                                // barWidth: '25%'
                            },
                            {
                                data: paperCount,
                                type: 'bar',
                                name: (this.localizationService.getLocalizationValue('publicationCount')),
                            }
                        ],
                    };
                }

                if (res["aggregations"] && res["aggregations"]["publicationType"] && res["aggregations"]["publicationType"]["buckets"]) {
                    let publicationTypeCountMap = new Map<string, number>;
                    res["aggregations"]["publicationType"]["buckets"].forEach(pt => {
                        publicationTypeCountMap.set(this.localizationService.getLocalizationValue(pt["key"]), pt["doc_count"])
                    });
                    let _data = [];
                    for (var _publicationType of [...publicationTypeCountMap.keys()]) {
                        let _count = publicationTypeCountMap.get(_publicationType);
                        _data.push({name: (_publicationType + " (" + _count + ")"), value: _count});
                    }

                    this.chartOptionPublicationTypeCount = {

                        series: [
                            {
                                type: 'treemap',
                                data: _data
                            }
                        ]
                    };
                }


                if (res["aggregations"] && res["aggregations"]["subjects"] && res["aggregations"]["subjects"]["values"] && res["aggregations"]["subjects"]["values"]["buckets"]) {
                    let subjectCountMap = new Map<string, number>;
                    res["aggregations"]["subjects"]["values"]["buckets"].forEach(s => {
                        subjectCountMap.set(s["key"], s["doc_count"])
                    });
                    let _data = [];
                    for (var _subject of [...subjectCountMap.keys()]) {
                        let _count = subjectCountMap.get(_subject);
                        _data.push({name: (_subject + " (" + _count + ")"), value: _count});
                    }

                    this.chartOptionSubjectCount = {

                        series: [
                            {
                                type: 'treemap',
                                data: _data
                            }
                        ]
                    };
                }


                if (res["aggregations"] && res["aggregations"]["institutionFacet"] && res["aggregations"]["institutionFacet"]["kurum_isbirligi"]
                    && res["aggregations"]["institutionFacet"]["kurum_isbirligi"]["buckets"]) {
                    this.institutionCollaborationList = [];
                    res["aggregations"]["institutionFacet"]["kurum_isbirligi"]["buckets"].forEach(s => {
                        if (s["key"] && s["key"] != this.selectedInstitution.name && s["key"].trim()!='') {
                            this.institutionCollaborationList.push({
                                "institution": s["key"],
                                "count": s["unique"]["value"]
                            })
                        }
                    });

                    this.institutionCollaborationList = this.institutionCollaborationList.sort((a, b) => Number(b.count) - Number(a.count));
                }


            },
            error: (e) => console.error(e)
        });
        if(this.selectedInstitutionCode){
            this.searchService.institutionCodePublicationsById(this.selectedInstitutionCode,this.publicationInstitutionPageModel.size,(this.publicationInstitutionPageModel.currentPage - 1) * this.publicationInstitutionPageModel.size,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe(res=>{
              if(res["hits"]["hits"]){
                  this.papersOfTheInstitutionIdList=new Array<string>();
                  for (let i = 0; i < res["hits"]["hits"].length; i++) {
                      this.papersOfTheInstitutionIdList.push(res["hits"]["hits"][i]['fields']['id'].toString());
                  }
                  this.publicationInstitutionPageModel.totalCount=res["hits"]["total"]["value"];
                  this.publicationInstitutionPageModel.totalPages = Math.ceil(this.publicationInstitutionPageModel.totalCount / this.publicationInstitutionPageModel.size);
                  this.changePublicationResultPage(this.publicationInstitutionPageModel.currentPage);
              }
            })
        }
        if(this.selectedInstitutionCode){
            this.searchService.institutionDistinctProjectNumbers(this.selectedInstitutionCode,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe(res=>{
                if (res["aggregations"] && res["aggregations"]["distinct_project_numbers"]
                    && res["aggregations"]["distinct_project_numbers"]["buckets"]) {
                    this.chartOptionPublicationProjeGroupList = [];
                    res["aggregations"]["distinct_project_numbers"]["buckets"].forEach(s => {
                        if (s["key"] && s["key"] != this.selectedInstitution.name && s["key"].trim()!='') {
                            this.chartOptionPublicationProjeGroupList.push({
                                "ProjeGroup": s["key"],
                                "count": s["doc_count"]
                            })
                        }
                    });

                    this.chartOptionPublicationProjeGroupList = this.chartOptionPublicationProjeGroupList.sort((a, b) => Number(b.count) - Number(a.count));
                }
                if(this.chartOptionPublicationProjeGroupList && this.chartOptionPublicationProjeGroupList.length>0){
                    this.getPublicationProjeGroupChartOptions(this.chartOptionPublicationProjeGroupList);
                }
            })
        }
        if(this.selectedInstitutionCode){
            this.searchService.institutionDistinctAccessTypeCount(this.selectedInstitutionCode,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe(res=>{
                if (res["aggregations"] && res["aggregations"]["distinct_accessType_count"]
                    && res["aggregations"]["distinct_accessType_count"]["buckets"]) {
                    this.chartOptionPublicationAccessTypeList = [];
                    res["aggregations"]["distinct_accessType_count"]["buckets"].forEach(s => {
                        if (s["key"] && s["key"] != this.selectedInstitution.name && s["key"].trim()!='') {
                            this.chartOptionPublicationAccessTypeList.push({
                                "accessType": s["key"],
                                "count": s["doc_count"]
                            })
                        }
                    });

                    this.chartOptionPublicationAccessTypeList = this.chartOptionPublicationAccessTypeList.sort((a, b) => Number(b.count) - Number(a.count));
                }
                if(this.chartOptionPublicationAccessTypeList && this.chartOptionPublicationAccessTypeList.length>0){
                    this.getPaperAccessTypeChartOptions(this.chartOptionPublicationAccessTypeList);
                }
            })
        }
    }
    fillinstitutionCodePublicationsById(event) {
        if (event) {
            const from = (this.publicationInstitutionPageModel.currentPage - 1) * this.publicationInstitutionPageModel.size;
            const size = event.size;

            this.searchService.institutionCodePublicationsById(this.selectedInstitutionCode, size, from,this.toYear,this.fromYear,this.toDate,this.fromDate).subscribe(res => {
                if (res["hits"]["hits"]) {
                    this.papersOfTheInstitutionIdList=[];
                    this.papersOfTheInstitutionIdList = res["hits"]["hits"].map(hit => hit['fields']['id'].toString());

                    this.publicationInstitutionPageModel.totalCount = res["hits"]["total"]["value"];
                    this.publicationInstitutionPageModel.totalPages = Math.ceil(this.publicationInstitutionPageModel.totalCount / this.publicationInstitutionPageModel.size);
                }
                this.changePublicationResultPage(event);
            });
        }
    }
    getPaperAccessTypeChartOptions(chartOptionPublicationAccessTypeList) {
        const chartData = chartOptionPublicationAccessTypeList.map(item => ({
            name: this.localizationService.getLocalizationValue(item.accessType),
            value: item.count
        }));
        this.chartOptionPaperAccessType = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                left: 'center'
            },
            series: [
                {
                    name: this.localizationService.getLocalizationValue("AccessType"),
                    type: 'pie',
                    radius: ['40%', '70%'],
                    center: ['50%', '70%'],
                    // adjust the start and end angle
                    startAngle: 180,
                    endAngle: 360,
                    data: [...new Set(chartData)]
                }
            ]
        };
    }
    getPublicationProjeGroupChartOptions(papersOfTheInstitutionList){
        const distinctProjectGroups = papersOfTheInstitutionList.map(paper => paper["ProjeGroup"]);
        const projectGroupCounts = papersOfTheInstitutionList.map(paper => paper.count);
        this.chartOptionPublicationProjeGroup = {
            legend: {},
            grid: {
                top: 35,
                bottom: 30,
                right: 10,
                left: 40
            },
            tooltip: {
                show: true,
            },
            yAxis: {
                nameTextStyle: {
                    fontSize: 0.1,
                    color: '#359753'
                },
                type: 'value',
                splitLine: {
                    show: true
                },
            },
            xAxis: {
                type: 'category',
                data: distinctProjectGroups,
                axisTick: {
                    length: 1,
                    interval: 1
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    inside: false,
                    rotate: 60,
                    fontSize: 10,
                },
                axisLine: {}
            },
            series: [
                {
                    data: projectGroupCounts,
                    type: 'bar',
                    name: this.localizationService.getLocalizationValue('GroupsOfProject'),
                    itemStyle: {
                        color: '#359753'
                    }
                }
            ],
        };
    }

    changePublicationResultPage(event) {
        this.publicationInstitutionPageModel.currentPage = event.currentPage;
        if (this.publicationInstitutionPageModel.size != event.size) {
            this.publicationInstitutionPageModel.currentPage = 1;
        }
        if (event.size) {
            this.publicationInstitutionPageModel.size = event.size;
        }
        this.publicationInstitutionPageModel.totalPages = Math.ceil(this.publicationInstitutionPageModel.totalCount / this.publicationInstitutionPageModel.size)
        let _array = this.papersOfTheInstitutionIdList;
        if (_array && _array.length > 0) {
            this.searchService.getPublicationListByIdList(_array.reduce((prev, curr) => prev + " " + curr)).subscribe({
                next: (res) => {
                    this.papersOfTheInstitutionList = new Array<PublicationDTO>();
                    if (res["hits"]["hits"]) {
                        for (let i = 0; i < res["hits"]["hits"].length; i++) {
                            let _publication = new PublicationDTO();
                            _publication.bindData(res["hits"]["hits"][i]["_source"]);
                            this.papersOfTheInstitutionList.push(_publication);
                        }
                    }
                },
                error: (e) => console.error(e)
            });
        }

    }

    getCitationAvg() {
        if (!this.citationFromPaperCount) {
            this.citationFromPaperCount = 0;
        }
        if (!this.citationFromProjectCount) {
            this.citationFromProjectCount = 0;
        }
        if (!this.paperCount) {
            this.paperCount = 0;
        }
        if (!this.projectCount) {
            this.projectCount = 0;
        }



        if (this.citationFromPaperCount==0 && this.citationFromProjectCount == 0) {
            this.citationAvg= "0,0";
        }else{
            this.citationAvg =parseFloat(parseFloat(("" + ((this.citationFromPaperCount + this.citationFromProjectCount) / (this.paperCount + this.projectCount)))).toFixed(2)).toLocaleString('tr-TR');
        }


    }
    onSortTypeChanged($event) {
       if($event){
           if($event=='All'){
               this.fromYear=1900;
               this.toYear=new Date().getFullYear();
               this.fromDate='1900-01-01';
               this.toDate=new Date().toISOString().slice(0, 10);
               let url = this.getCurrentUrl();
               let params;
               params="";
               this.router.navigateByUrl(url + params)
                   .then(nav => {
                       // console.log(nav); // true if navigation is successful
                   }, err => {
                       console.log(err) // when there's an error
                   });
               this.ngOnInit();
           }else{
               this.toYear=$event;
               this.fromYear=$event;
               let url = this.getCurrentUrl();
               let params;
               params="?yayin_yili="+this.toYear;
               this.router.navigateByUrl(url + params)
                   .then(nav => {
                       // console.log(nav); // true if navigation is successful
                   }, err => {
                       console.log(err) // when there's an error
                   });
           }
       }
    }
    onSortFromDateChanged($event){
        if($event){
            this.fromDate=$event;
            if(this.fromDate>this.toDate){
                return  this.toastrService.error(this.localizationService.getLocalizationValue('FromDateWarning'));
            }
            let url = this.getCurrentUrl();
            let params;
            params="?indeks_tarihi="+this.fromDate+':'+this.toDate;

            this.router.navigateByUrl(url + params)
                .then(nav => {
                    // console.log(nav); // true if navigation is successful
                }, err => {
                    console.log(err) // when there's an error
                });
        }
    }
    onSortTODateChanged($event?){
        if($event){
            this.toDate=$event;
            if(this.fromDate>this.toDate){
                return  this.toastrService.error(this.localizationService.getLocalizationValue('FromDateWarning'));
            }
            if(this.toDate<this.fromDate){
                return  this.toastrService.error(this.localizationService.getLocalizationValue('ToDateWarning'));
            }
            let url = this.getCurrentUrl();
            let params;
            params="?indeks_tarihi="+this.fromDate+':'+this.toDate;

            this.router.navigateByUrl(url + params)
                .then(nav => {
                    // console.log(nav); // true if navigation is successful
                }, err => {
                    console.log(err) // when there's an error
                });
        }else{
            if(this.toDate<this.fromDate){
                return  this.toastrService.error(this.localizationService.getLocalizationValue('ToDateWarning'));
            }
            let url = this.getCurrentUrl();
            let params;
            params="?indeks_tarihi="+this.fromDate+':'+this.toDate;

            this.router.navigateByUrl(url + params)
                .then(nav => {
                    // console.log(nav); // true if navigation is successful
                }, err => {
                    console.log(err) // when there's an error
                });
        }
    }
    getCurrentUrl() {
        let url = this.activatedRoute.snapshot.url[0] + "/" + this.activatedRoute.snapshot.url[1] + "/" + this.activatedRoute.snapshot.url[2]+"/"+this.activatedRoute.snapshot.url[3]+'/'+this.activatedRoute.snapshot.url[4]
        return url;

    }
    generateYearList(startYear: number, endYear: number) {
        this.YearList=[];
        for (let year = startYear; year <= endYear; year++) {
            this.YearList.push(year);
        }
    }
    getPapersOfTheInstitutionList(){

    }

    protected readonly getCurrentLanguage = getCurrentLanguage;
    protected readonly getCurrentYear = getCurrentYear;
}
