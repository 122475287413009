import {Component} from '@angular/core';
import {PublicationDTO} from "../search/publication/PublicationDTO";
import {LocalizationService} from "../../trdcore/service/localizationService";
import {SearchService} from "../search/search.service";
import {ActivatedRoute} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {TrdCoreModule} from "../../trdcore/trdcore.module";
import {getCurrentLanguage, getCurrentYear} from "../app.module";
import slugify from "slugify";
import {NgxEchartsDirective} from "ngx-echarts";
import {EChartsOption} from "echarts";

@Component({
    selector: 'app-publication-analysis-detail',
    standalone: true,
    imports: [
        NgIf,
        NgForOf,
        TrdCoreModule,
        NgxEchartsDirective
    ],
    templateUrl: './publication-analysis-detail.component.html',
    styleUrl: './publication-analysis-detail.component.scss'
})
export class PublicationAnalysisDetailComponent {
    publicationList: PublicationDTO[] = [];
    idList = [];
    years = new Set<number>()
    public citationCountByYear = new Map<string, Map<string, string[]>>();

    chartOptionPublicationCounts: EChartsOption;
    chartOptionPublicationCitationCounts: EChartsOption;

    constructor(private activatedRoute: ActivatedRoute, public localizationService: LocalizationService, private searchService: SearchService) {
    }


    ngOnInit(): void {
        let _params;
        this.activatedRoute.queryParams.subscribe(params => {
            _params = params;
            if (params["q"]) {
                let idParam = params["q"];
                idParam = idParam.replaceAll("id:", "").replaceAll("(", "").replaceAll(")", "");
                this.idList = idParam.split(" ")
                if (this.idList && this.idList.length > 50) {
                    this.idList = this.idList.slice(0, 50);
                }
                this.getPublicationListDetail();
            }

        });
    }


    getPublicationListDetail() {
        this.searchService.getPublicationListByIdList(this.idList.reduce((prev, curr) => prev + " " + curr)).subscribe({
            next: (res) => {
                this.publicationList = new Array<PublicationDTO>();
                if (res["hits"]["hits"]) {
                    for (let i = 0; i < res["hits"]["hits"].length; i++) {
                        let _publication = new PublicationDTO();
                        _publication.bindData(res["hits"]["hits"][i]["_source"]);
                        this.publicationList.push(_publication);
                    }
                    this.publicationList = this.publicationList.sort((p1, p2) => p2.getCitationCount() - p1.getCitationCount());
                    this.getCitationCountsByYear();
                    this.getPublicationCountOptions();
                    this.getPublicationCitationCountOptions();
                }
            },
            error: (e) => console.error(e)
        });
    }

    getCitationCountsByYear() {
        this.citationCountByYear = new Map<string, Map<string, string[]>>();
        if (this.publicationList && this.publicationList.length > 0) {
            this.publicationList.forEach(p => {
                // let citationYearMap = new Map<string, string[]>();
                if (p.citedReferences && p.citedReferences.length > 0) {
                    p.citedReferences.forEach(cr => {
                        if (cr.year) {
                            this.years.add(Number(cr.year));
                        }
                    });
                }
                let _years: number[] = new Array<number>;
                _years = [...new Set(this.years)];
                _years = _years.sort((n1, n2) => n1 - n2);
                this.years = new Set<number>();
                this.years = new Set(_years)
            });
        }
    }


    urlFormat(url) {
        if (!url) {
            return url;
        }
        url = url.replaceAll("<strong class=\"highlight\">", "");
        url = url.replaceAll("</strong>", "");
        return slugify(url, {
            lower: true,
            remove: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
            strict: true,
            trim: true
        });
    }

    getCitationCountByYear(publication: PublicationDTO, year: number) {
        if (publication && publication.citedReferences && publication.citedReferences.length > 0 && year) {
            let _set = new Set();
            publication.citedReferences.forEach(r => {
                if (r.year && r.year == year.toString()) {
                    _set.add(r.sourceId);
                }
            });
            return _set.size;
        }
        return 0;

    }

    getCitationAvg(publication: PublicationDTO) {
        if (publication && publication.citedReferences && publication.citedReferences.length > 0) {
            let _set = new Set();
            let _years = new Set();
            publication.citedReferences.forEach(r => {
                _set.add(r.sourceId);
                _years.add(r.year);
            });
            if (_set.size > 0 && _years.size > 0) {
                return parseFloat(parseFloat(("" + (_set.size / _years.size))).toFixed(2)).toLocaleString('tr-TR');
            }
            return '0,0';
        }
        return '0,0';
    }

    getPublicationCountOptions() {
        let paperCount = new Map<string, number>();
        let projectCount = new Map<string, number>();
        if (this.publicationList && this.publicationList.length > 0) {
            this.publicationList.forEach(p => {
                if (p.docType == 'PAPER') {
                    let _count = paperCount.get(p.publicationYear);
                    if (!_count || _count == 0) {
                        _count = 1
                    } else {
                        _count += 1;
                    }
                    paperCount.set(p.publicationYear, _count);
                } else if (p.docType == 'PROJECT') {
                    let _count = projectCount.get(p.publicationYear);
                    if (!_count || _count == 0) {
                        _count = 1
                    } else {
                        _count += 1;
                    }

                    projectCount.set(p.publicationYear, _count);
                }

            });
        }

        let _years = [...paperCount.keys()];
        _years = _years.concat([...projectCount.keys()]);
        _years.sort((p1, p2) => Number(p1) - Number(p2));

        let paperData = [];
        let projectData = [];
        for (var _year of [...new Set(_years)]) {
            paperData.push(paperCount.get(_year) ? paperCount.get(_year) : 0);
        }
        for (var _year of [...new Set(_years)]) {
            projectData.push(projectCount.get(_year) ? projectCount.get(_year) : 0);
        }


        this.chartOptionPublicationCounts = {
            legend: {
                top: -5
            },
            grid: {
                top: 35,
                bottom: 30,
                right: 10,
                left: 30
            },
            tooltip: {
                show: true,
            },
            yAxis: {
                interval: 1,
                nameTextStyle: {
                    fontSize: 0.1,
                },
                type: 'value',
                splitLine: {
                    show: true
                },
            },
            xAxis: {
                type: 'category',
                data: [...new Set(_years)],
                axisTick: {
                    length: 1,
                    interval: 1
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    inside: false,
                    fontSize: 10,
                },
                axisLine: {}
            },
            series: [
                {
                    data: paperData,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('paperCount')),
                    // barWidth: '25%'
                },
                {
                    data: projectData,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('projectCount')),
                    // barWidth: '25%'
                }
            ],
        };
    }

    getPublicationCitationCountOptions() {
        let paperCitationCount = new Map<string, number>();
        let projectCitationCount = new Map<string, number>();
        if (this.publicationList && this.publicationList.length > 0) {
            this.publicationList.forEach(p => {
                if (p.citedReferences && p.citedReferences.length > 0) {
                    p.citedReferences.forEach(r => {
                        if (p.docType == 'PAPER') {
                            let _count = paperCitationCount.get(r.year);
                            if (!_count || _count == 0) {
                                _count = 0
                            }
                            _count += 1;
                            paperCitationCount.set(r.year, _count);
                        } else if (p.docType == 'PROJECT') {
                            let _count = projectCitationCount.get(r.year);
                            if (!_count || _count == 0) {
                                _count = 0
                            }
                            _count += 1;
                            projectCitationCount.set(r.year, _count);
                        }
                    });
                }
            });
        }


        let _years = [...paperCitationCount.keys()];
        _years = _years.concat([...projectCitationCount.keys()]);
        _years.sort((p1, p2) => Number(p1) - Number(p2));

        let paperData = [];
        let projectData = [];
        for (let _year of [...new Set(_years)]) {
            paperData.push(paperCitationCount.get(_year) ? paperCitationCount.get(_year) : 0);
        }
        for (let _year of [...new Set(_years)]) {
            projectData.push(projectCitationCount.get(_year) ? projectCitationCount.get(_year) : 0);
        }


        this.chartOptionPublicationCitationCounts = {
            legend: {
                top: -5
            },
            grid: {
                top: 35,
                bottom: 30,
                right: 10,
                left: 40
            },
            tooltip: {
                show: true,
            },
            yAxis: {
                nameTextStyle: {
                    fontSize: 0.1,
                },
                type: 'value',
                splitLine: {
                    show: true
                },
            },
            xAxis: {
                type: 'category',
                data: [...new Set(_years)],
                axisTick: {
                    length: 1,
                    interval: 1
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    inside: false,
                    fontSize: 10,
                },
                axisLine: {}
            },
            series: [
                {
                    data: paperData,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('citationFromPaper')),
                    // barWidth: '25%'
                },
                {
                    data: projectData,
                    type: 'bar',
                    name: (this.localizationService.getLocalizationValue('citationFromProject')),
                    // barWidth: '25%'
                }
            ],
        };
    }

    getHindex() {
        let hindex = 0;
        let citationCount = new Map<number, number>();
        let index = 1;
        this.publicationList.forEach(p => {
            let _list = this.publicationList.filter(pc => {
                return pc.getCitationCount() >= index;
            });
            if (_list.length >= index) {
                hindex = index;
                index += 1;
            }
        });
        return hindex;
    }

    getTotalCitationCount() {
        let citationCount = 0;
        this.publicationList.forEach(p => {
            citationCount += p.getCitationCount();
        });
        return citationCount;
    }

    getTotalCitationAvg() {
        let citationCount = this.getTotalCitationCount();
        let publicationCount = this.publicationList.length;
        if (citationCount > 0 && publicationCount > 0) {
            return parseFloat(parseFloat(("" + (citationCount / publicationCount))).toFixed(2)).toLocaleString('tr-TR');
        }
        return '0,0';
    }

    getOutCitedPublicationCount() {
        let outCitedPublicationList = new Set();
        this.publicationList.forEach(p => {
            if (p.citedReferences) {
                p.citedReferences.forEach(pc => {
                    outCitedPublicationList.add(pc.sourceId);
                });
            }
        });
        return outCitedPublicationList.size;
    }


    protected readonly getCurrentLanguage = getCurrentLanguage;
    protected readonly getCurrentYear = getCurrentYear;
}
