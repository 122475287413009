import { KeyCloackUserDTO } from "./KeyCloackUserDTO";

export class AuthInfoDTO {
    public userId?: string;
    public name?: string;
    public surname?: string;
    public email?: string;
    public authorities?: string[];
    public fullName?: string;
    public accountInfo?: KeyCloackUserDTO;

    constructor() {}

    bindData(obj: any): void {
        if (obj == null) {
          return;
        }
        this.userId = obj.userId;
        this.name = obj.name;
        this.surname = obj.surname;
        this.email = obj.email;
        this.authorities = obj.authorities;
        this.fullName = obj.fullName;
        this.accountInfo = obj.accountInfo;
      }
}
