import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {filter, map} from "rxjs/operators";
import {Filter} from "../../trdcore/component/paginater/Filter";
import {PageModel} from "../../trdcore/component/paginater/PageModel";

@Injectable({providedIn: 'root'})
export abstract class SearchService {
    protected constructor(protected http: HttpClient) {
    }

    defaultSearch(schmea:string,page: number, size: number, searchText: string, searchUrlParams?) {
        return this.http.get(environment.apiUrl + "/api/defaultSearch/"+schmea+"/" + searchUrlParams.toString());
    }


    getPublicationById(publicationId) {
        return this.http.get(environment.apiUrl + "/api/publicationById/" + publicationId);
    }

    getJournalById(journalId) {
        return this.http.get(environment.apiUrl + "/api/journalById/" + journalId);
    }

    getAuthorById(authorId) {
        return this.http.get(environment.apiUrl + "/api/authorById/" + authorId);
    }
  getInstitutionByCode(institutionCode) {
        return this.http.get(environment.apiUrl + "/api/institutionByCode/" + institutionCode);
    }
  getInstitutionCitationByCode(institutionCode, toYear: number,fromYear:number,toDate:string,fromDate:string) {
        return this.http.get(environment.apiUrl + "/api/institutionCitationByCode/" + institutionCode+`?toYear=${toYear}&fromYear=${fromYear}&toDate=${toDate}&fromDate=${fromDate}`);
    }
    getInstitutionCitationAndDocumentOfYearByCode(institutionCode: string, toYear: number,fromYear:number,toDate:string,fromDate:string) {
       return  this.http.get(`${environment.apiUrl}/api/institutionCitationAndDocumentOfYearByCode/${institutionCode}?toYear=${toYear}&fromYear=${fromYear}&toDate=${toDate}&fromDate=${fromDate}`);

    }

    getJournalPublicationFieldById(journalId) {
        return this.http.get(environment.apiUrl + "/api/journalPublicationsById/" + journalId);
    }
   getAuthorPublicationFieldById(authorId) {
        return this.http.get(environment.apiUrl + "/api/authorPublicationsById/" + authorId);
    }

    getAuthorCitationsByIdList(authorId:string) {
        return this.http.get(environment.apiUrl + "/api/findAuthorCitationsByIdList/" + authorId);
    }

    getPublicationListByIdList(publicationIdList) {
        return this.http.get(environment.apiUrl + "/api/findPublicationByIdList/" + publicationIdList);
    }
    getPdfFile(pdfFile:string){
        return this.http.get(`${environment.apiUrl}/api/getFile/${pdfFile}?showViewer=true`);
    }

    getRisUrl(publicationId,baseUrl:string){
        return `${environment.apiUrl}/api/getRisUrl/${publicationId}?url=${baseUrl}`;
    }
    followCitationChange(id:number){
        return this.http.get(`${environment.managementApiUrl}/searchnotification/find-value-url?data=`+id);
    }
    changeActive(id:number){
        let data = { };
        return this.http.post(`${environment.managementApiUrl}/searchnotification/change-active?id=`+id, { observe: 'response' , headers: {"Content-Type": "application/json-patch+json"}});
    }
    followCitationCrate(dto){
        return this.http.post(`${environment.managementApiUrl}/searchnotification/`, dto, { observe: 'response' }).pipe(
            filter((res) => res.ok),
            map((res) => res.body)
        );
    }
    getSimilarArticlesOfThePublication(id) {
        return this.http.get(environment.apiUrl + "/api/similarArticlesOfThePublication/" + id);
    }

    journalFinder(query:string) {
        return this.http.post(environment.apiUrl+"/api/journalFinder", ({"query":query}));
    }
    getJournalByIdList(journalIdList,subjects) {
        return this.http.post(`${environment.apiUrl}/api/findJournalByIdList/`+journalIdList, {"subjects":subjects});
    }
    institutionCodePublicationsById(code,size,from,toYear: number,fromYear:number,toDate:string,fromDate:string) {
        return this.http.get(`${environment.apiUrl}/api/institutionCodePublicationsById/${code}?size=${size}&from=${from}&toYear=${toYear}&fromYear=${fromYear}&toDate=${toDate}&fromDate=${fromDate}`);
    }
    institutionDistinctProjectNumbers(code,toYear: number,fromYear:number,toDate:string,fromDate:string) {
        return this.http.get(`${environment.apiUrl}/api/institutionDistinctProjectNumbers/${code}?toYear=${toYear}&fromYear=${fromYear}&toDate=${toDate}&fromDate=${fromDate}`);
    }
    institutionDistinctAccessTypeCount(code,toYear: number,fromYear:number,toDate:string,fromDate:string) {
        return this.http.get(`${environment.apiUrl}/api/institutionDistinctAccessTypeByOfCode/${code}?toYear=${toYear}&fromYear=${fromYear}&toDate=${toDate}&fromDate=${fromDate}`);
    }
}
