import { Injectable } from '@angular/core';
declare global {
    interface Window {
        MathJax: {
            typesetPromise: () => Promise<any>;
            startup: {
                promise: Promise<any>;
            };
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class MathJaxService {
    private mathJaxLoaded: Promise<void>;

    // Configure which MathJax version we want
    private mathJax: any = {
        source: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js',
    }

    constructor() {
        this.mathJaxLoaded = this.loadMathJax()
            .then(() => {
            })
            .catch((err) => {
                console.log('MathJax failed to load', err);
            });
    }
    public getMathJaxLoadedPromise(): Promise<void> {
        return this.mathJaxLoaded;
    }

    private async loadMathJax(): Promise<any> {
        return new Promise((resolve, reject) => {

            const mathjaxConfigScript: HTMLScriptElement = document.createElement('script');
            mathjaxConfigScript.type = 'text/javascript'
            mathjaxConfigScript.innerHTML = 'window.MathJax = {\n' +
                '  tex: {\n' +
                '    inlineMath: [[\'$\', \'$\'], [\'\\\\(\', \'\\\\)\']]\n' +
                '  },\n' +
                '  svg: {\n' +
                '    fontCache: \'global\'\n' +
                '  }\n' +
                '};'
            const script: HTMLScriptElement = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.mathJax.source;
            script.async = true;
            script.onload = () => {
                resolve("MathJax loaded")
            };
            script.onerror = () => {
                reject("Error loading MathJax");
            }
            document.head.appendChild(mathjaxConfigScript)
            document.head.appendChild(script);
        });
    }

    public render() {
        if (window.MathJax && window.MathJax.startup && typeof window.MathJax.typesetPromise === 'function') {
            window.MathJax.startup.promise.then(() => {
                window.MathJax.typesetPromise()
                    .then(() => {
                    })
                    .catch((err) => {
                    });
            }).catch((err) => {
            });
        } else {
        }
    }
}