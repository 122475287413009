<div id="card-block" class="page-container fadeIn" style="background-color: #f5f5f561;min-height: 100vh;">
    <div section="header" class="not-printed header-box sticky-top " id="up">
        <div class="py-2 row header">
            <div class="d-flex justify-content-start col-md-3 col-sm-3 trdizin_logo">
                <a class="ms-4" href="https://trdizin.gov.tr"><img class="logo" src="https://static.trdizin.gov.tr/images/trdizinlogo.svg"
                                              width="120vh"></a>
            </div>
            <div class="d-flex justify-content-end col-md-9 col-sm-9 col-12">


                <div class="mx-2  fsize-13">
                    <app-publication-analysis #analysiscomponent></app-publication-analysis>
                </div>



                <!--                kullanıcı ismi-->
                <div class="">
                    <a *ngIf="loginSuccess" class="text-white fsize-1 "
                       style="text-decoration: none">
                    </a>
                    <div *ngIf="loggedUser && loggedUser.name"
                         class="mx-1 ">
                        <a class="expand text-white" href="https://sso.trdizin.gov.tr/auth/realms/trdizin/account">
                            <i *ngIf="loginSuccess" class="text-white  fas fa-user fsize-10" style="cursor: default"></i>
                            <span class=" expand text-white fsize-1"></span>{{this.loggedUser.name }}
                        </a>
                    </div>
                </div>
                <!--                kullanıcı ismi-->


                <div class="mt-1  counter">
                    <a *hasAuth="'VIEW_NOTIFICATIONS'" data-bs-toggle="modal"
                       href="#notification-modal" role="button" aria-expanded="false"
                       title="Bildirimlerinizi görmek için tıklayınız.">
                        <div>
                            <i class="fas fa-solid fa-bell  fsize-12" style="color: white"></i>
                            <small *ngIf="notificationList && notificationList.length>0"
                                   class="translate-middle badge bg-danger">
                                {{notificationList.length}}
                            </small>

                        </div>
                    </a>
                    <button [disabled]="isUnreadMessage" data-bs-toggle="modal" style="display: none"
                            href="#popup-notification-modal" #popupNotificationModal>
                    </button>
                </div>


                <div class="mx-2  counter">
                    <a *ngIf="loginSuccess" class=" text-white me-1 fsize-1 pointer" (click)="deleteCookie()"
                       style="text-decoration: none">
                        <i class="fas fa-sign-out-alt  fsize-10 "></i>
                        <span class="text-white ms-1 align-top me-2">{{'Logout' | localize}}</span>
                    </a>
                    <a *ngIf="!loginSuccess" class=" text-white me-1 fsize-1 pointer" (click)="logIn()"
                       style="text-decoration: none">
                        <i class="fas fa-sign-out-alt  fsize-10 "></i>
                        <span class="text-white ms-1 align-top me-2">{{'LogIn' | localize}}</span>
                    </a>
                    <a *ngIf="loginSuccess" class="text-white fsize-1 pointer" (click)="appMenuToggle()"
                       style="text-decoration: none" id="appmenu"
                       title="Servis menüsü">
                        <i class="fas fa-th fsize-10"></i>
                    </a>
                    <div role="app-menu" style="border-radius: 5px;">
                    </div>
                </div>


                <!--yardim menusu-->
                <div class="mx-2  helpPage  counter">
                    <a class="text-white fsize-1 pointer" data-bs-toggle="dropdown"
                       title="{{'HelpPage'|localize}}">
                        <i class="fas fa-question-circle fsize-10" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown-menu primary-background" style="z-index: 99999!important;">
                        <li *hasAuth="'VIEW_HELP_MANAGEMENT_PAGE_LINK'"><a target="_blank"
                                                                           href="https://yardim-yonetici.trdizin.gov.tr/"
                                                                           class="text-white dropdown-item">{{'ManagementHelp' | localize}}</a>
                        </li>
                        <li *hasAuth="'VIEW_HELP_EXPERT_PAGE_LINK'"><a target="_blank"
                                                                       href="https://yardim-uzman.trdizin.gov.tr/"
                                                                       class="text-white dropdown-item">{{'ExpertHelp' | localize}}</a>
                        </li>
                        <li *hasAuth="'VIEW_HELP_COMMITTEE_PAGE_LINK'"><a target="_blank"
                                                                          href="https://yardim-komite.trdizin.gov.tr/"
                                                                          class="text-white dropdown-item">{{'CommitteeHelp' | localize}}</a>
                        </li>
                        <li *hasAuth="'VIEW_HELP_SECRETARY_PAGE_LINK'"><a target="_blank"
                                                                          href="https://yardim-sekreter.trdizin.gov.tr/"
                                                                          class="text-white dropdown-item">{{'SecreterHelp' | localize}}</a>
                        </li>
                        <li *hasAuth="'VIEW_HELP_GENERAL_PAGE_LINK'"><a target="_blank"
                                                                        href="https://yardim-genel.trdizin.gov.tr/"
                                                                        class="text-white dropdown-item">{{'GeneralHelp' | localize}}</a>
                        </li>
                        <li><a target="_blank" href="https://yardim-arama.trdizin.gov.tr/"
                               class="text-white dropdown-item">{{'SearchHelp' | localize}}</a></li>

                    </ul>
                </div>
                <!--yardim menusu-->


                <!--    dil secme-->
                <label class="switch mx-2">
                    <input type="checkbox"    [(ngModel)]="activeLanguage" (ngModelChange)="switchLanguage()">
                    <div class="slider">
                        <span>TR</span>
                        <span>EN</span>
                    </div>
                </label>
                <!--    dil secme-->


                <!--                işlem menüsü-->
                <div class="mx-2  counter" *ngIf="loginSuccess">
                    <a *ngIf="loginSuccess" class=" text-white fsize-1 pointer me-2" data-bs-toggle="offcanvas"
                       data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                       title="İşlem menüsü">
                        <i class="fsize-15 fas fa-bars"></i>
                    </a>
                    <a *ngIf="!loginSuccess" class="text-white fsize-1 pointer" data-bs-toggle="dropdown"
                       style="text-decoration: none" title="İşlem menüsü">
                        <i class="fas fa-bars me-2 fsize-1"></i>
                        <span class="expand">Menu</span>
                    </a>
                    <ul class="dropdown-menu primary-background" style="z-index: 99999!important;">
                        <!-- *hasAuth="'UPDATE_JOURNAL_ISSUE'" -->
                        <li *hasAuth="'SHOW_PERSON_DETAIL'"><a routerLink="/person/list">{{'Person' | localize}}</a>
                        </li>
                        <li *hasAuth="'FIND_COMMITTEE'"><a routerLink="/committee/list">{{'Committee' | localize}}</a>
                        </li>
                        <li *hasAuth="'FIND_EXPERT'"><a routerLink="/expert/list">{{'Expert' | localize}}</a></li>
                        <li *hasAuth="'VIEW_AUTHOR_LIST'"><a routerLink="/author/list">{{'Author' | localize}}</a></li>
                        <li *hasAuth="'VIEW_AUTHOR_PROFILE'"><a
                                routerLink="/author/profile">{{'MyAuthorProfile' | localize}}</a></li>
                        <li *hasAuth="['FIND_PERSON','FIND_COMMITTEE','FIND_EXPERT','VIEW_AUTHOR_LIST','VIEW_AUTHOR_PROFILE']">
                            <hr class="dropdown-divider">
                        </li>
                    </ul>
                </div>
                <div class="offcanvas offcanvas-end bg-trdizin-dark  hamburger-menu" tabindex="-1" id="offcanvasRight"
                     aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header bg-trdizin-dark">
                        <h5 id="offcanvasRightLabel" *ngIf="loggedUser && loggedUser.name">
                            <span class="expand text-white fsize-1">{{this.loggedUser.name }} {{loggedUser.surname}}</span>
                        </h5>
                        <a type="button" #closeHamburgerMenuModalButton class="btn-close text-reset text-white"
                           style="background-color: white;" data-bs-dismiss="offcanvas" aria-label="Close"></a>
                    </div>
                    <div class="offcanvas-body  bg-trdizin-dark">
                        <ul style="list-style-type: none;">
                            <hr class="dropdown-divider mt-3">
                            <li>
                                <a class="text-white me-3 fsize-1 pointer" style="text-decoration: none"
                                   data-bs-toggle="modal" data-bs-target="#saveSearchListModal"
                                   (click)="getSavedSearchPage()">
                                    <i class="fas fa-bookmark fa-lg me-2 mt-1"></i>
                                    <span>{{ 'SavedSearchs' | localize }}</span>
                                </a>
                                <app-saved-search-list [savedSearchList]="savedSearchList"
                                                       [savedSearchPageModel]="savedSearchPageModel"></app-saved-search-list>
                            </li>
                            <li>
                                <div class="" *ngIf="loginSuccess">
                                    <ng-container>
                                        <app-my-library [addFavorite]="false" [id]="'app-'+this.activeUser.userId">
                                        </app-my-library>
                                    </ng-container>
                                </div>
                            </li>
                        </ul>
                        <ul style="list-style-type: none;">
                            <hr class="dropdown-divider mt-3">
                            <li *ngIf="loginSuccess">
                                <a class="text-white me-3 fsize-1 pointer" (click)="deleteCookie()"
                                   style="text-decoration: none">
                                    <i class="fas fa-sign-out-alt fa-lg me-2 mt-1"></i>
                                    <span>{{ 'Logout' | localize }}</span>
                                </a>
                            </li>
                        </ul>

                        <div class="mb-3 col-md-3 col-sm-3 trdizin_logo d-flex flex-row justify-content-start off-canvas"
                             style="position:absolute; bottom: 35px; width: 10%; margin-top: -20px; background-color: #2e6984 !important">
                            <a class="ms-4"><img src="https://static.trdizin.gov.tr/images/trdizinlogo.svg" width="120"></a>
                        </div>
                    </div>
                </div>
                <!--                işlem menüsü-->




            </div>
        </div>
    </div>
    <div class="container-fluid mt-5" style="overflow-y: visible;  scrollbar-gutter: stable;  scrollbar-width: thin;">
        <router-outlet></router-outlet>
    </div>

    <loading-component *ngIf="loadingService.isActive"></loading-component>



    <!-- Footer -->
    <footer class="text-center mt-5 pt-2 upper-footer"
            style="
background-color: transparent !important;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"
    >
        <!-- Grid container -->
        <!-- Section: Text -->
        <div class="container-fluid">
            <div class="row" style="margin-right: 0.5em; margin-left: 0.5em;">
                <div class="footer-col-1 col-md-6 text-start mb-1">
                    <p>{{'CONTACT' | localize}}</p>
                    <p>TÜBİTAK ULAKBİM TR Dizin</p>
                    <p>Yüzüncüyıl, İşçi Blokları Mahallesi</p>
                    <p>Muhsin Yazıcıoğlu Caddesi No:51/C</p>
                    <p>06530 Çankaya / ANKARA +90 (312) 298 92 00</p>
                    <p>{{ trDizinEmail }}</p>
                </div>
                <div class="col-md-6 text-end my-1">
                    <div class="mt-1 mb-2 pt-1">
                        <img src="https://static.trdizin.gov.tr/images/sanayi_bakanligi_logo.png" width="125"
                             class="institution-img d-sm-inline-block">
                        <img src="https://static.trdizin.gov.tr/images/ulakbim_logo.png" width="100"
                             class="institution-img">
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- Footer -->
    <footer class=" text-center mx-auto">
        <!-- Grid container -->


        <!-- Grid container -->
        <!-- Copyright -->
        <div class=" text-light mx-auto">

            <div class=" footer fadeIn fixed-bottom pt-2 pb-2 px-2 mx-auto">
                <div class="mx-1">
                    <div id="socialicons" class="float-left ">

<!--                        <a name="Facebook" title="Facebook'da Paylaş"-->
<!--                           class="resp-sharing-button__link ps-3 pe-2 py-2 pointer text-light"><i class="fa fa-facebook"-->
<!--                                                                                                  aria-hidden="true"></i></a>-->
<!--                        <a name="Twitter" title="Twitter'da Paylaş"-->
<!--                           class=" text-light resp-sharing-button__link px-2 py-2 pointer"><i-->
<!--                                class="fa fa-twitter" aria-hidden="true"></i></a>-->
<!--                        <a name="shareLinkBtn" title="Sayfayı Paylaş"-->
<!--                           class="resp-sharing-button__link px-2 py-2 pointer text-light"><i-->
<!--                                class="fa fa-share" aria-hidden="true"></i></a>-->

                        <label class="text-center">TÜBİTAK ULAKBİM Ulusal Akademik Ağ ve Bilgi Merkezi Cahit Arf Bilgi
                            Merkezi ©
                            {{getCurrentYear()}}
                            Tüm Hakları Saklıdır</label>
                    </div>
                    <small>
                        <a class="ms-2 text-light float-right" target="_blank" href="https://trdizin.gov.tr/gizlilik/">Gizlilik
                            Politikası</a>
                        <a class="text-light float-right" target="_blank"
                           href="https://trdizin.gov.tr/kullanim-sartlari/">Kullanım
                            Şartları</a>
                    </small>
                </div>

            </div>
        </div>
        <!-- Copyright -->

    </footer>


    <!-- Footer -->




    <!-- Footer -->
</div>
<div class="modal fade" data-bs-backdrop="static" id="notification-modal" tabindex="-1"
     aria-labelledby="notification-modal" aria-hidden="true">
    <div class="modal-dialog modal-xl  modal-dialog-centered">
        <div class="modal-content" style="max-height: 92vh;">
            <div class="modal-header">
                <h6 class="modal-title">{{'Notifications'|localize}}</h6>

                <button type="button" class="btn-close float-right"
                        data-bs-toggle="modal" data-bs-dismiss="modal"
                        aria-label="Close"></button>

            </div>
            <div class="modal-body">
                <div class="card card-body">
                    <table class="table">
                        <thead>
                        <tr>
                            <th style="width: 5%" scope="col">{{'Order'|localize}}</th>
                            <th style="width: 65%" scope="col">{{'Subject'|localize}}</th>
                            <th style="width: 5%" scope="col">{{'Content'|localize}}</th>
                            <th style="width: 5%" scope="col">{{'IHaveRead'|localize}}</th>
                            <th style="width: 20%" scope="col">{{'SendDate'|localize}}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let notification of notificationList;let index=index">
                            <td>{{index + 1}}</td>
                            <td>{{notification.notification_subject}}</td>

                            <td>
                                <a (click)="getNotificationById(notification,'notification-modal')"
                                   data-bs-toggle="modal" data-bs-target="#notifications-body-modal">
                                    <i class="fas fa-search me-2 fsize-1"></i>

                                </a></td>
                            <td>
                                <a
                                        class="me-1 fsize-1 pointer"
                                        role="button"
                                        (click)="markAsRead(notification)"
                                        title="{{'Approve' | localize}}">

                                    <i class="fas fa-check me-2 fsize-1"></i>
                                </a>
                            </td>

                            <td>{{notification.send_date|utcToLocalTime:'DD.MM.YYYY HH:mm'}}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>


        </div>
    </div>
</div>

<div class="modal fade" data-bs-backdrop="static" id="popup-notification-modal" tabindex="-1"
     data-bs-keyboard="false"
     aria-labelledby="notification-modal" aria-hidden="true">
    <div class="modal-dialog modal-xl  modal-dialog-centered">
        <div class="modal-content" style="max-height: 92vh;">
            <div class="modal-header">
                <h6 class="modal-title">{{'Notifications'|localize}}</h6>

                <button type="button" class="btn-close float-right"

                        data-bs-toggle="modal" data-bs-dismiss="modal"
                        aria-label="Close"></button>

            </div>
            <div class="modal-body">
                <div class="card card-body">
                    <table class="table">
                        <thead>
                        <tr>
                            <th style="width: 10%" scope="col">{{'Order'|localize}}</th>
                            <th style="width: 10%" scope="col">{{'Subject'|localize}}</th>
                            <th style="width: 10%" scope="col">{{'Content'|localize}}</th>
                            <th style="width: 10%" scope="col">{{'IHaveRead'|localize}}</th>
                            <th style="width: 10%" scope="col">{{'SendDate'|localize}}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let notification of popupNotificationList;let index=index">
                            <td>{{index + 1}}</td>
                            <td>{{notification.notification_subject}}</td>
                            <td>
                                <a (click)="getNotificationById(notification,'popup-notification-modal')"
                                   data-bs-toggle="modal" data-bs-target="#notifications-body-modal">
                                    <i class="fas fa-search me-2 fsize-1"></i>

                                </a>

                            </td>
                            <td>
                                <a
                                        class="me-1 fsize-1 pointer"
                                        role="button"
                                        (click)="markAsRead(notification)"
                                        title="{{'Approve' | localize}}">

                                    <i class="fas fa-check me-2 fsize-1"></i>
                                </a>
                            </td>
                            <td>{{notification.send_date|utcToLocalTime:'DD.MM.YYYY HH:mm'}}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>


        </div>
    </div>
</div>
<div class="modal fade" data-bs-backdrop="static" id="notifications-body-modal" tabindex="-1"
     aria-labelledby="notification-send-modal" aria-hidden="true">
    <div class="modal-dialog modal-xl  modal-dialog-centered">
        <div class="modal-content" style="max-height: 92vh;">
            <div class="modal-header">
                <h5 class="modal-title">{{'Notification'|localize}} </h5>


                <button type="button" class="btn-close float-right"
                        [attr.data-bs-target]="'#' +selectedModalBackReference"
                        data-bs-toggle="modal" data-bs-dismiss="modal"
                        aria-label="Close"></button>

            </div>
            <div class="modal-body">
                <div class="card card-body">
                    <br>
                    <h5 *ngIf="selectedNotification && selectedNotification.notification_subject"
                        class="modal-title text-center">{{selectedNotification.notification_subject}} </h5>
                    <div *ngIf="selectedNotification && selectedNotification.notification_body"
                         [innerHTML]="selectedNotification.notification_body"></div>
                </div>
                <h6 class="mt-1 text-right"
                    *ngIf="selectedNotification && selectedNotification.send_date">{{selectedNotification.send_date | utcToLocalTime:'DD.MM.YYYY HH:mm'}}</h6>

            </div>


        </div>
    </div>
</div>


