import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from "../trdcore/service/LoadingService";
import {CookieService} from "../trdcore/service/CookieService";
import {LocalizationService} from 'src/trdcore/service/localizationService';
import {AuthInfoDTO} from 'src/trdcore/dto/AuthInfoDTO';
import ItemListInstance from '../assets/applicationsMenu.js';
import {DOCUMENT} from '@angular/common';
import {PageModel, SortType} from "../trdcore/component/paginater/PageModel";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfigurationService} from "../trdcore/service/ConfigurationService";
import {provideEcharts} from 'ngx-echarts';
import {AuthorizationService} from "./Authorization/AuthorizationService";
import {environment} from "../environments/environment";
import {KeyCloackUserDTO} from "../trdcore/dto/KeyCloackUserDTO";
import {getCurrentLanguage, getCurrentYear} from "./app.module";
import {LibraryService} from "./search/Mylibrary/library.service";
import {SearchService} from "./search/search.service";
import {ToastrService} from "ngx-toastr";
import {ModalAlertService} from "../trdcore/service/ModalAlertService";
import {EnumNotificationType, NotificationDto, NotificationSortField} from "./search/notification/NotificationDto";
import {SendNotificationDTO} from "./search/notification/SendNotificationDTO";
import {NotificationService} from "./notification.service";
import {SaveSearchService} from "./search/save-search/save-search.service";
import {SavedSearchDTO} from "./search/save-search/SavedSearchDTO";
import {PublicationAnalysisComponent} from "./publication-analysis/publication-analysis.component";
import {PublicationDTO} from "./search/publication/PublicationDTO";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        provideEcharts(),
    ]
})


export class AppComponent implements OnInit {
    title = 'management';
    public activeLanguage = sessionStorage.getItem('activeLanguage') !== "tr";
    public model: { user, name, surname, email } | undefined;
    public activeUser: AuthInfoDTO;
    pageModel: PageModel = new PageModel(5);
    currentYear: number;
    loginSuccess = false;
    loggedUser: AuthInfoDTO;
    window = window;
    language = "tr";
    notificationList: Array<NotificationDto> = [];
    isUnreadMessage: boolean = false;
    selectedNotification: NotificationDto;
    selectedModalBackReference: string;
    popupNotificationList: Array<NotificationDto>;
    savedSearchList: SavedSearchDTO[] = [];
    trDizinEmail: string;
    savedSearchPageModel = new PageModel(10);


    @ViewChild('popupNotificationModal') private popupNotificationModal: ElementRef;


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService,
        private router: Router,
        private authorizationService: AuthorizationService,
        public loadingService: LoadingService,
        private activeRoute: ActivatedRoute,
        private localizationService: LocalizationService,
        private notificationService: NotificationService,
        private libraryService: LibraryService,
        private searchService: SearchService,
        private toastrService: ToastrService,
        private modalService: ModalAlertService,
        private configurationService: ConfigurationService,
        private saveSearchService: SaveSearchService) {
        this.trDizinEmail = 'trdizin@tubitak.gov.tr';

        this.model = {user: undefined, name: undefined, surname: undefined, email: undefined};
        let cookie = this.cookieService.getCookie(this.cookieService.cookieName);
        if (cookie && cookie != "") {
            let authInfo: AuthInfoDTO = this.cookieService.getUserInfo(this.cookieService.cookieName);
            if (authInfo) {
                this.loginSuccess = true;
                this.loggedUser = authInfo;
                this.cookieService.headerToken = cookie;
                if (!this.authorizationService.getActiveUser()) {
                    this.authorizationService.setActiveUser(this.loggedUser);
                }
                this.activeUser = this.authorizationService.getActiveUser();
                if (!this.activeUser.accountInfo && !configurationService.isRequestOfPublicScreen()) {
                    this.authorizationService.getAccount().subscribe(res => {
                        let accountInfo = new KeyCloackUserDTO();
                        accountInfo.bindData(res);
                        this.loggedUser.accountInfo = accountInfo;
                        // if(!this.activeUser.accountInfo){
                        //   this.authorizationService.getAccount().subscribe(res => {
                        //     let accountInfo = new KeyCloackUserDTO();
                        //     accountInfo.bindData(res);
                        //     this.activeUser.accountInfo = accountInfo;

                        this.authorizationService.setActiveUser(this.loggedUser);
                        this.activeUser = this.authorizationService.getActiveUser();
                    },error => {
                        let accountInfo = new KeyCloackUserDTO();
                        accountInfo=null
                        this.loggedUser.accountInfo =null;
                        this.authorizationService.setActiveUser(null);
                        this.activeUser = null;
                    });
                }
            }
        }
        // this.authorizationService.activeUser.subscribe(u => this.activeUser = u);
    }

    deleteCookie() {
        this.cookieService.deleteCookie(this.cookieService.cookieName);
        window.location.href = environment.managementApiUrl + "/logout";
    }

    ngOnInit(): void {
        if (this.activeUser) {
            this.getNotificationsByUserId();
        }

        this.document.documentElement.lang = this.localizationService.activeLanguage
        if (!!sessionStorage.getItem("activeLanguage")) {
            if (window.location.href.indexOf("/tr/") !== -1 || window.location.href.indexOf("/en/") !== -1) {
                if (window.location.href.indexOf("/en/") !== -1) {
                    this.language = 'en';
                    sessionStorage.setItem('activeLanguage', 'en');
                } else {
                    this.language = 'tr';
                    sessionStorage.setItem('activeLanguage', 'tr');
                }
            }
        } else {
            if (this.cookieService.getCookie("TRDIZIN_LANGUAGE") == 'tr') {
                this.language = 'tr';
            } else {
                this.language = 'en'
            }
        }
        this.pageModel.sortType = SortType.DESC;
        this.pageModel.currentPage = 1;
        if (!this.configurationService.isRequestOfPublicScreen()) {
            this.initFunction();
        }
        this.currentYear = new Date().getFullYear();
    }

    initFunction() {
        let requestUrl = environment.managementApiUrl + "/widget/pages";
        if (this.authorizationService.getActiveUser() && this.authorizationService.isImpersonateUser()) {
            requestUrl += "?impersonate=" + this.authorizationService.getActiveUser().userId;
        }
        if (this.activeUser) {
            ItemListInstance.instance.init(
                {
                    button: '#appmenu',
                    container: '[role="app-menu"]',
                    requestUrl: requestUrl
                }
            );
        }

    }


    appMenuToggle() {
        let isOpen = ItemListInstance.instance.toggle();
    }

    logIn() {
        this.authorizationService.resetActiveUser();
        window.location.href = environment.managementApiUrl + "/login?redirectUrl=" + window.location.href.replace("#", "%23");
    }

    switchLanguage() {
        let newLanguage="";
        if(!this.language || (this.language && this.language=='tr')){
            newLanguage="en";
        }else {
            newLanguage="tr";
        }

        if (newLanguage == "tr") {
            sessionStorage.setItem('activeLanguage', 'tr');
            this.language = 'tr';
            setTimeout(function(){
                window.location.href = window.location.href.replace("/en/", "/tr/");
                } , 200);

        } else {
            sessionStorage.setItem('activeLanguage', 'en');
            this.language = 'en';
            setTimeout(function(){
                window.location.href = window.location.href.replace("/tr/", "/en/");
            } , 200);
        }
        this.loadingService.showLoader();
        // setTimeout(() => {
        //     window.location.reload();
        // }, 3000);
    }

    getNotificationById(notification: SendNotificationDTO, modal?: string) {

        if (modal) {
            this.selectedModalBackReference = modal;
        }
        this.selectedNotification = new NotificationDto();
        this.selectedNotification.bindData(notification);
    }

    markAsRead(notification: NotificationDto) {

        this.notificationService.markAsRead(notification).subscribe(res => {
            this.getNotificationsByUserId();
        });
    }

    getNotificationsByUserId(pageModel?: PageModel) {

        let sortFields = Object.keys(NotificationSortField)

        if (pageModel) {
            this.pageModel.currentPage = pageModel.currentPage;
        } else {
            this.pageModel.currentPage = 1;
        }
        this.notificationList = []
        this.notificationService.getNotificationsByUserId(100, this.pageModel.currentPage,
            sortFields[0], this.pageModel.sortType).subscribe((res) => {
            for (let index = 0; index < res["data"].length; index++) {
                let _notification = new NotificationDto();
                if (res["data"] && res["data"][index]) {
                    _notification.bindData(res["data"][index]);
                }
                this.notificationList.push(_notification);
            }
            this.getPopUpNotificationsByUserId()
        }, error => {
            let _notification = new NotificationDto();
            _notification.bindData({}); // Boş veri ile doldur
            this.notificationList.push(_notification);
        });

        setTimeout(function () {
            this.getNotificationsByUserId()

        }.bind(this), 60000);
    }

    getPopUpNotificationsByUserId(pageModel?: PageModel) {
        this.popupNotificationList = []
        this.notificationList.forEach(n => {
            if (n.notification_type == EnumNotificationType.POPUP) {
                this.popupNotificationList.push(n)
            }
        })
        if (this.popupNotificationList && this.popupNotificationList.length > 0) {
            this.popupNotificationModal.nativeElement.click();
            this.isRead();

        }
    }

    isRead() {
        this.popupNotificationList.forEach(n => {
            if (n.notification_status == "UNREAD") {
                this.isUnreadMessage = true;
            }
        })
    }

    protected readonly getCurrentYear = getCurrentYear;
    protected readonly getCurrentLanguage = getCurrentLanguage;

    getSavedSearchPage(event?) {
        if (event) {
            this.savedSearchPageModel.currentPage = event.currentPage;
            if (this.savedSearchPageModel.size != event.size) {
                this.savedSearchPageModel.currentPage = 1;
            }
            if (event.size) {
                this.savedSearchPageModel.size = event.size;
            }
        }
        if (this.activeUser.userId) {
            this.saveSearchService.getSavedSearchUserById(this.activeUser.userId, this.savedSearchPageModel.currentPage, this.savedSearchPageModel.size).subscribe(res => {
                if (res["data"]) {
                    this.savedSearchList = [];
                    for (let i = 0; i < res["data"].length; i++) {
                        let _savedSearch = new SavedSearchDTO();
                        _savedSearch.bindData(res["data"][i]);
                        this.savedSearchList.push(_savedSearch);
                    }
                    this.savedSearchPageModel.totalCount = res["totalCount"];
                    this.savedSearchPageModel.totalPages = Math.ceil(this.savedSearchPageModel.totalCount / this.savedSearchPageModel.size);
                } else {
                    this.savedSearchList = [];
                    this.savedSearchPageModel.totalCount = res["totalCount"];
                    this.savedSearchPageModel.totalPages = Math.ceil(this.savedSearchPageModel.totalCount / this.savedSearchPageModel.size);
                }
            })
        }
    }

    @ViewChild('closeHamburgerMenuModalButton') closeHamburgerMenuModalButton: ElementRef

    closeHamburgerMenuModal() {
        if (this.closeHamburgerMenuModalButton) {
            this.closeHamburgerMenuModalButton.nativeElement.click();
        }
    }

    @ViewChild('analysiscomponent') public publicationAnalysisComponent: PublicationAnalysisComponent;

    addAnalysisList(publication: PublicationDTO) {
        if (this.publicationAnalysisComponent) {
            this.publicationAnalysisComponent.addAnalysisList(publication);
        }
    }
    addAllAnalysisList(publicationList: PublicationDTO[]) {
        if (this.publicationAnalysisComponent) {
            this.publicationAnalysisComponent.addAllAnalysisList(publicationList);
        }
    }

    removeAnalysisList(publication: PublicationDTO) {
        if (this.publicationAnalysisComponent) {
            this.publicationAnalysisComponent.removeAnalysisList(publication);
        }
    }

    public isExistAnalysisList(publication: PublicationDTO) {
        if (this.publicationAnalysisComponent) {
            return this.publicationAnalysisComponent.isExistAnalysisList(publication);
        }
        return false;
    }
}
