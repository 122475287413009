import {BaseDTO} from "../../core/BaseDTO";

export class AuthorPublicationFieldsDTO extends BaseDTO {
    public id: string;
    public docType: string;
    public publicationType: string;
    public issueYear: string;

    public authorId: number[];
    public authorName: string[];
    public authorDuty: string[];
    public authorInstitutionCity: string[];
    public authorInstitutionCountry: string[];
    public authorInstitutionCode: string[];
    public authorInstitutionRootCode: string[];
    public authorInstitutionFullTitle: string[];
    public authorInstitutionRootTitle: string[];
    public authorInstitutionName: string[];
    public authorOrcid: string[];
    public subjects: string[];

    public citedReferencesIds: number[];
    public citedReferencesAuthorIds: number[];
    public citedReferencesAuthorNames: string[];
    public citedReferencesDocTypes: string[];
    public citedReferencesJournalCodes: number[];
    public citedReferencesSourceIds: number[];
    public citedReferencesYears: number[];



    public referencesAuthorIds: number[];
    public referencesAuthorNames: string[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        if (obj["authors.authorId"] && obj["authors.authorId"].length > 0) {
            this.authorId = obj["authors.authorId"];
        }
        if (obj["authors.name"] && obj["authors.name"].length > 0) {
            this.authorName = obj["authors.name"];
        }
        if (obj["authors.duty"] && obj["authors.duty"].length > 0) {
            this.authorDuty = obj["authors.duty"];
        }
        if (obj["authors.institution.city"] && obj["authors.institution.city"].length > 0) {
            this.authorInstitutionCity = obj["authors.institution.city"];
        }
        if (obj["authors.institution.country"] && obj["authors.institution.country"].length > 0) {
            this.authorInstitutionCountry = obj["authors.institution.country"];
        }
        if (obj["authors.institution.code"] && obj["authors.institution.code"].length > 0) {
            this.authorInstitutionCode = obj["authors.institution.code"];
        }
        if (obj["authors.institution.rootCode"] && obj["authors.institution.rootCode"].length > 0) {
            this.authorInstitutionRootCode = obj["authors.institution.rootCode"];
        }
        if (obj["authors.institution.fullTitle"] && obj["authors.institution.fullTitle"].length > 0) {
            this.authorInstitutionFullTitle = obj["authors.institution.fullTitle"];
        }
        if (obj["authors.institution.rootTitle"] && obj["authors.institution.rootTitle"].length > 0) {
            this.authorInstitutionRootTitle = obj["authors.institution.rootTitle"];
        }
        if (obj["authors.institutionName"] && obj["authors.institutionName"].length > 0) {
            this.authorInstitutionName = obj["authors.institutionName"];
        }
        if (obj["authors.orcid"] && obj["authors.orcid"].length > 0) {
            this.authorOrcid = obj["authors.orcid"];
        }
        if (obj["subjects.name"] && obj["subjects.name"].length > 0) {
            this.subjects = obj["subjects.name"];
        }

        if (obj["citedReferences.authors.id"] && obj["citedReferences.authors.id"].length > 0) {
            this.citedReferencesAuthorIds = obj["citedReferences.authors.id"];
        }
        if (obj["citedReferences.authors.name"] && obj["citedReferences.authors.name"].length > 0) {
            this.citedReferencesAuthorNames = obj["citedReferences.authors.name"];
        }

        if (obj["citedReferences.docType"] && obj["citedReferences.docType"].length > 0) {
            this.citedReferencesDocTypes = obj["citedReferences.docType"];
        }
        if (obj["citedReferences.year"] && obj["citedReferences.year"].length > 0) {
            this.citedReferencesYears = obj["citedReferences.year"];
        }


        if (obj["references.authors.id"] && obj["references.authors.id"].length > 0) {
            this.referencesAuthorIds = obj["references.authors.id"];
        }
        if (obj["references.authors.name"] && obj["references.authors.name"].length > 0) {
            this.referencesAuthorNames = obj["references.authors.name"];
        }



        if (obj.id && obj.id.length > 0) {
            this.id = obj.id[0];
        } else if (obj.id) {
            this.id = obj.id;
        }


        if (obj.publicationType && obj.publicationType.length > 0) {
            this.publicationType = obj.publicationType[0];
        } else if (obj.docType) {
            this.publicationType = obj.publicationType;
        }


        if (obj["issue.year"] && obj["issue.year"].length > 0) {
            this.issueYear = obj["issue.year"][0];
        }

        if (obj["citedReferences.id"]) {
            this.citedReferencesIds = obj["citedReferences.id"];
        }
        if (obj["docType"]) {
            this.docType = obj["docType"];
        }


        if (obj["citedReferences.journalCode"]) {
            this.citedReferencesJournalCodes = obj["citedReferences.journalCode"];
        }


        if (obj["citedReferences.sourceId"]) {
            this.citedReferencesSourceIds = obj["citedReferences.sourceId"];
        }


    }


}
