<div *ngIf="publicationList">
    <div class="latex row" *ngFor="let publication of publicationList">
        <div class="d-flex">
            <div class="d-flex flex-column  col-md-1">
                <div class="mx-auto">
<!--                    <i *ngIf="trashButton==true" class="fas fa-trash-alt mt-4 me-3 pointer" (click)="deletePublicationOnList(publication)"></i>-->
                    <img *ngIf="publication.docType=='PAPER'"  src="https://static.trdizin.gov.tr/images/svg/paper.svg" class="result-img"
                         style="float: right; width: 44px;height: 75px;">
                    <img *ngIf="publication.docType=='PROJECT'"  src="https://static.trdizin.gov.tr/images/svg/project.svg" class="result-img"
                         style="float: right; width: 44px;height: 75px;">
                </div>
            </div>
            <div class="col-md-10 col-9">
                <div class="d-flex justify-content-between ">
                    <div class="col-md-11 " style="text-align: justify; width: 100%; " class="dark-blue">
                        <a  target="_blank" [href]="getCurrentLanguage()+'/yayin/detay/'+publication.id+'/'+urlFormat(publication.abstracts[0].title)"
                           class="title light-blue fsize-1 font-noto-regular">{{publication.abstracts[0].title}}</a>
                    </div>

                </div>
                <div class="hr"></div>
                <div class="d-flex justify-content-start flex-wrap " *ngIf="  publication.authors && publication.showAuthorCollapse==false; else authorMore">
                    <section  class="me-2 " *ngFor="let author of publication.authors.length>7 ? publication.authors.slice(0,7):publication.authors; let isLast=last">
                        <small>
                            <a target="_blank" [href]="getCurrentLanguage()+'/yazar/detay/'+author.authorId+'/'+urlFormat(author.name)">
                                <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                                {{ author.name }}{{ isLast ? '' : ',' }}
                            </a>
                        </small>

                        <small>
                            <a class="ms-2" (click)="publication.showAuthorCollapse = !publication.showAuthorCollapse"  *ngIf="isLast">
                                <i *ngIf="publication.authors.length>7 && publication.showAuthorCollapse==false" class="fas fa-caret-square-down pointer"></i>
                            </a>
                        </small>
                    </section>
                </div>

                <ng-template #authorMore>
                    <div class="d-flex justify-content-start flex-wrap " *ngIf=" publication.authors && publication.showAuthorCollapse==true">
                        <section  class="me-2 light-blue" *ngFor="let author of  publication.authors; index as authorIndex;let isLast=last">
                            <small >
                                <a target="_blank" [href]="getCurrentLanguage()+'/yazar/detay/'+author.authorId+'/'+urlFormat(author.name)">
                                    <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                                    {{ author.name }}{{ isLast ? '' : ',' }}
                                </a>
                            </small>
                            <small>
                                <a  class="ms-2" (click)="publication.showAuthorCollapse = !publication.showAuthorCollapse"  *ngIf="isLast">
                                    <i class="fas fa-caret-square-up pointer"></i>
                                </a>
                            </small>
                        </section>
                    </div>
                </ng-template>
                 <label  class=" title dark-blue fsize-1 font-noto-light" *ngIf="publication.journal">
                     <a target="_blank" class=" f-bold light-blue" [href]="getCurrentLanguage()+'/dergi/detay/'+publication.journal.id">{{publication.journal.name}}</a></label>
                <div *ngIf="publication.docType=='PAPER'">
                   <div class="d-flex justify-content-start font-noto-light">
                       <small *ngIf="publication.publicationYear">
                           <label  class=" f-bold me-1">{{'year'|localize}}:</label>
                           {{publication.publicationYear}}
                       </small>

                       <small *ngIf="publication.issue.volume" class="ms-2">
                           <label  class=" f-bold me-1">{{'volume'|localize}}:</label>
                           {{publication.issue.volume}}
                       </small>
                       <small *ngIf="publication.issue.number" class="ms-2">
                           <label  class=" f-bold me-1">{{'number'|localize}}:</label>
                           {{publication.issue.number}}
                       </small>
                       <small *ngIf="publication.startPage ||publication.endPage " class="ms-2">
                           <label  class=" f-bold me-1">{{'page'|localize}}:</label>
                           {{(publication.startPage+' - '+publication.endPage)}}
                       </small>
                   </div>

                    <small  class="dark-blue float-right pointer" (click)="render(publication)">
                        <i *ngIf="publication.showAbstractCollapse==false" class="fas fa-angle-double-down" style="font-size: 1.2em; line-height: unset;"></i>
                        <i *ngIf="publication.showAbstractCollapse==true" class="fas fa-angle-double-up" style="font-size: 1.2em; line-height: unset;"></i>
                        {{'abstractContent' |localize}}
                    </small>
                    <small class="dark-blue float-right  pointer me-2">
                        <i *ngIf="!publication.pdf" class="fas fa-lock" title="{{'FullTextAccessDenied' |localize}}" style="font-size: 1.2em; line-height: unset;"></i>
                        <i *ngIf="publication.pdf"  class="far fa-file-pdf pointer fsize-13" (click)="getPdf(publication)"
                           title="{{'FullText' |localize}}" style="line-height: unset;"></i>
                    </small>
                </div>
                <div *ngIf="publication.docType=='PROJECT'">
                    <small *ngIf="publication.publicationYear" class="  me-2">{{"ProjectDeadline"|localize}}: {{publication.endDate|utcToLocalTime:'DD.MM.YYYY'}}</small>
                    <small  class="dark-blue float-right pointer" (click)="render(publication)">
                        <i *ngIf="publication.showAbstractCollapse==false" class="fas fa-angle-double-down" style="font-size: 1.2em; line-height: unset;"></i>
                        <i *ngIf="publication.showAbstractCollapse==true" class="fas fa-angle-double-up" style="font-size: 1.2em; line-height: unset;"></i>
                        {{'abstractContent' |localize}}
                    </small>
                    <small class="dark-blue float-right  pointer me-2">
                        <i *ngIf="!publication.pdf" class="fas fa-lock" title="{{'FullTextAccessDenied' |localize}}" style="font-size: 1.2em; line-height: unset;"></i>
                        <i *ngIf="publication.pdf"  class="far fa-file-pdf pointer fsize-13" (click)="getPdf(publication)"
                           title="{{'FullText' |localize}}" style="line-height: unset;"></i>
                    </small>
                </div>


                <small *ngIf="libraryView" class="ms-2  me-2">
                    <i class="pointer fa-star fsize-1 fas" (click)="DeleteLibraryDocument(publication)">{{'RemoveFromLibrary'|localize}}</i>
                </small>
                <small class="text-muted dark-blue float-left  pointer  pointer img-btn"
                       *ngIf="isExistAnalysisList(publication)==false"
                       (click)="addAnalysisList(publication)">
                    <i class="far fa-plus-square me-1 text-muted fsize-1 "></i>
                    <label class="pointer text-muted fsize-1 ">{{'addAnalysisList'|localize}} </label>
                </small>
                <small class="text-muted dark-blue float-left  pointer  pointer img-btn"
                       *ngIf="isExistAnalysisList(publication)==true"
                       (click)="removeAnalysisList(publication)">
                    <i class="far fa-minus-square me-1 text-muted fsize-1 "></i>
                    <label class="pointer text-muted fsize-1" >{{'removeAnalysisList'|localize}} </label>
                </small>
            </div>

            <div class="light-blue col-md-1 col-2 text-right d-flex flex-column reference-label">
                <ng-container *ngIf="publication.citedReferences && publication.citedReferences.length > 0">
                    <h3>{{ publication.citedReferences.length }}</h3>
                    <small>{{ 'Reference' | localize }}</small>
                </ng-container>
            </div>
        </div>
        <div class="ps-5 pt-2 pe-1 dark-blue " style="width:100%; text-align: justify; max-height: 25vh;  overflow-y: auto " *ngIf=" publication.showAbstractCollapse==true">
            <p class="text-break font-noto-regular">{{publication.abstracts[0].abstract}}</p>
        </div>
        <hr class="mt-2 mb-2" style="margin: 0;">
    </div>
</div>
