import {BaseDTO} from "../../core/BaseDTO";

export class InstitutionChildDTO extends BaseDTO {
    public id: string;
    public name: string;
    public labels: string[];
    public codes: string[];

    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);

        this.id = obj.id;
        this.name = obj.fullName;
        this.labels = obj.otherNames;
        this.codes = obj.codes;
    }

}
