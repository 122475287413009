<div class="modal fade" [id]="'publicationAnalysisModal'" aria-hidden="true" data-bs-backdrop="false"
     aria-labelledby="libraryCreatedModal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header header-background">
                <h3 class="modal-title">{{'analysisList' | localize}}</h3>
                <i class="fas fa-times pointer  fsize-12 img-btn" #closeModalAnalysis (click)="closeModal()" style="color: white"
                   data-bs-dismiss="modal"></i>
            </div>
            <div class="modal-body">
                <a *ngIf="publicationList.length>0" (click)="cleanAll()">{{'cleanAll' | localize}}</a>
                <app-publication-list [libraryView]="false" [libraryId]="10000000" [publicationList]="publicationList" (reloadRequest)="updateList($event)"
                [trashButton]="true" (onDeletePublication)="updateList($event)">
                </app-publication-list>
            </div>
            <div class="modal-footer">
                <label style="float:right !important" class="blink info fsize-08 me-3">{{'analysisDocumentLimitInfo'|localize}}</label>
                <button class="btn btn-primary" *ngIf="publicationList.length>0" (click)="doAnalysis()">
                    {{'doAnalysis' | localize}}
                </button>
                <div class="btn btn-primary" *ngIf="publicationList.length>0 && this.activeUser">
                        <a class="text-white pointer" data-bs-toggle="modal" [attr.data-bs-target]="'#library-modal-analysis-'+publicationList[0].id " (click)="doLibrary(this.publicationList)">
                            {{'AddToLibraryAnalysis' | localize}}
                        </a>
                </div>
            </div>
        </div>
    </div>
</div>
<span class="position-relative pointer" (click)="getListPublicationFormat()">
  <i class="fas fa-chart-line pointer text-white" data-bs-toggle="modal" data-bs-target="#publicationAnalysisModal"></i>
    <span *ngIf="getList().length>0"
          class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
  </span>
</span>

<app-my-library #LibraryAnalysis [publicationListAnalysis]="this.publicationList" [documentType]="'Publication'"></app-my-library>
