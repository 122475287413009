<div class="container mx-auto" *ngIf="selectedPublication">
    <div class="row d-flex col-12 col-md-11 justify-content-around mx-auto">
        <div class="col-12  d-flex justify-content-between">
            <small>
                <ul class=" mb-2 nav nav-tabs" role="tablist" *ngIf="!selectedPublication.abstracts || (selectedPublication.abstracts && selectedPublication.abstracts.length>0)">
                    <li class="nav-item" *ngFor="let abstract of selectedPublication.abstracts;index as i;">
                        <button class="tab-btn nav-link me-2 {{i==0?'active':''}}"
                                type="button"
                                [id]="'language-tab-'+abstract.language+i" data-bs-toggle="tab"
                                data-bs-target="{{abstract.language+'nav-tab'+i}}"
                                [attr.aria-selected]="selectedAbstract.language===abstract.language?'true':'false'"
                                (click)="selectAbstract(abstract)"
                                role="tab" aria-controls="{{abstract.language+i}}">{{abstract.language |localize}}
                        </button>
                    </li>
                </ul>
            </small>
            <div class="d-flex justify-content-end align-items-end">
                <a *ngIf="permissionService.hasPermission('UPDATE_ALL_PUBLICATION')"
                   href="https://yonetim.trdizin.gov.tr/#/publication/detail/{{selectedPublication.id}}">
                    <i class="img-btn  fas fa-pen me-2 pointer fsize-12"></i>
                </a>
                <app-my-library [addFavorite]="true" [id]="'publication-'+selectedPublication.id "
                                [documentId]="selectedPublication.id" [documentType]="'Publication'"></app-my-library>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-8   ">
            <h4 class="title light-blue font-noto-regular"
                *ngIf="selectedAbstract">{{selectedAbstract.title}}</h4>
            <h6 class="title dark-blue mt-2 fsize-11"
                *ngIf="selectedPublication.journal"><strong> <a target="_blank"
                                                                [href]="getCurrentLanguage()+'/dergi/detay/'+selectedPublication.journal.id">{{selectedPublication.journal.name}}</a>
            </strong></h6>

            <small class="fsize-1" *ngIf="selectedPublication.docType=='PAPER' ">
                <div class="d-flex align-items-start flex-wrap mt-1">
                    <div *ngIf="selectedPublication.publicationYear" class="me-2">
                        <strong class="dark-blue">{{"year"|localize}}
                            : </strong><label>{{selectedPublication.publicationYear}}</label>
                    </div>
                    <div *ngIf="selectedPublication.issue" class="me-2">
                        <strong class="dark-blue">{{"volume"|localize}}
                            : </strong><label>{{selectedPublication.issue.volume}}</label>
                    </div>
                    <div *ngIf="selectedPublication.issue" class="me-2">
                        <strong class="dark-blue">{{"number"|localize}}
                            : </strong><label>{{selectedPublication.issue.number}}</label>
                    </div>
                    <div class="me-2">
                        <strong class="dark-blue">{{"page"|localize}}
                            : </strong><label>{{selectedPublication.startPage + '-' + selectedPublication.endPage}}
                    </label></div>
                    <div *ngIf="selectedPublication.language" class="me-3">
                        <strong class="dark-blue">{{"TextLanguage"|localize}}
                            : </strong><label>{{selectedPublication.language|localize}}</label>
                    </div>
                    <div *ngIf="selectedPublication.doi && selectedPublication.docType=='PAPER'" class="me-2">
                        <strong class="dark-blue">DOI: </strong><a href="https://doi.org/{{selectedPublication.doi}}"
                                                                   target="_blank">{{selectedPublication.doi}}</a></div>
                </div>
            </small>
            <small class="fsize-1" *ngIf="selectedPublication.docType=='PROJECT' ">
                <div class="d-flex align-items-start flex-wrap mt-1">
                    <div *ngIf="selectedPublication.projectGroup" class="me-3">
                        <strong class="dark-blue">{{"GroupsOfProject"|localize}}
                            : </strong><label>{{selectedPublication.projectGroup}}</label>
                    </div>
                    <div *ngIf="selectedPublication.startPage || selectedPublication.endPage" class="me-3">
                        <strong class="dark-blue">{{"PageNumber"|localize}}
                            : </strong><label>{{selectedPublication.endPage ? selectedPublication.endPage : ""}}</label>
                    </div>
                    <div *ngIf="selectedPublication.projectnumber" class="me-3">
                        <strong class="dark-blue">{{"ProjectNo"|localize}}
                            : </strong><label>{{selectedPublication.projectnumber}}</label>
                    </div>
                    <div *ngIf="selectedPublication.endDate" class="me-3">
                        <strong class="dark-blue">{{"ProjectDeadline"|localize}}
                            : </strong><label>{{selectedPublication.endDate|utcToLocalTime:'DD.MM.YYYY'}}</label>
                    </div>
                    <div *ngIf="selectedPublication.language" class="me-3">
                        <strong class="dark-blue">{{"TextLanguage"|localize}}
                            : </strong><label>{{selectedPublication.language|localize}}</label>
                    </div>
                    <div *ngIf="selectedPublication.doi && selectedPublication.docType=='PROJECT'" class="me-3">
                        <strong class="dark-blue">DOI: </strong><span>{{selectedPublication.doi}}</span></div>

                </div>
            </small>


            <div style="text-align: justify;" class="mt-2 mx-auto">
                  <span class="dark-blue mx-auto">
    <div *ngIf="filterAuthorType('MANAGER') > 0" class="d-flex flex-column">
        <label class="light-blue f-bold" >{{ 'ProjectCoordinator' | localize }}:</label>
         <span *ngFor="let author of filterAuthorTypeAndSort('MANAGER'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex  flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
    <div *ngIf="filterAuthorType('TRANSLATOR') > 0" class="d-flex flex-column">
        <label class="light-blue f-bold">{{ 'Translator' | localize }}:</label>
         <span *ngFor="let author of filterAuthorTypeAndSort('TRANSLATOR'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
    <div *ngIf="filterAuthorType('AUTHOR') > 0" class="d-flex flex-column flex-wrap">
        <span *ngFor="let author of filterAuthorTypeAndSort('AUTHOR'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer  fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle pointer fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
    <div *ngIf="filterAuthorType('CONSULTANT') > 0" class="d-flex flex-column">
        <label class="light-blue f-bold">{{ 'Consultants' | localize }}:</label>
        <span *ngFor="let author of filterAuthorTypeAndSort('CONSULTANT'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted  pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
    <div *ngIf="filterAuthorType('RESEARCHER') > 0" class="d-flex flex-column">
        <label class="light-blue f-bold">{{ 'Researchers' | localize }}:</label>
        <span *ngFor="let author of filterAuthorTypeAndSort('RESEARCHER'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
    <div *ngIf="filterAuthorType('SCHOLARSHIP_STUDENT') > 0" class="d-flex flex-column">
        <label class="light-blue f-bold">{{ 'ScholarshipStudent' | localize }}:</label>
        <span *ngFor="let author of filterAuthorTypeAndSort('SCHOLARSHIP_STUDENT'); let last = last;">
            <div class="d-flex justify-content-start flex-wrap">
                    <a [href]="getCurrentLanguage() + '/yazar/detay/' + author.authorId + '/' + author.name" target="_blank"
                       class="">
                        <label class="ms-1 pointer fsize-09" style="font-weight: normal!important;">
                            <i [ngClass]="author.isVerified ? 'fas fa-user-check' : 'fas fa-user'" aria-hidden="true"></i>
                            {{ author.name }}
                        </label>
                    </a>

                     <a class="ms-1 pointer fsize-09" style="font-weight: normal!important;" target="_blank"
                        *ngIf=" author.institutionName && author.institutionName.length<2"
                        [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[0] + '/' + (author.institutionName[0] | urlSlugify)">
                              <label class="ms-1 text-muted pointer fst-italic fsize-09"
                                     style="text-decoration: underline;"
                                     title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[0]:''}}"
                              >{{author.institutionName[0]}}{{ last ? '' : ',' }}</label>
                        </a>
             </div>

            <div class="ms-3 d-flex flex-column fsize-09" *ngIf=" author.institutionName && author.institutionName.length>1">
                <a class="ms-1 pointer " style="font-weight: normal!important;" target="_blank"
                   *ngFor="let institutionFullTitle of author.institutionName; let instLast = last; let i = index;"
                   [href]="getCurrentLanguage() + '/kurum/detay/' + author.institution.rootCode[i] + '/' + (institutionFullTitle | urlSlugify)">
                      <label class="ms-1 text-muted pointer fst-italic fsize-09"
                             style="text-decoration: underline;"
                             title="{{author.institution && author.institution.fullTitle && author.institution.fullTitle.length>0 ? author.institution.fullTitle[i]:''}}"
                      ><i class="fas fa-circle fsize-05 me-1"></i>{{institutionFullTitle}}{{ last ? '' : ',' }}</label>
                </a>
            </div>
        </span>
    </div>
</span>

            </div>

            <div style="text-align: justify;" class="mt-2 font-noto-light">
                <hr  class="mb-1 text-muted">
                <strong class="me-2 fsize-13 f-bold">{{'abstract' | localize}}:</strong>
                <p *ngIf="selectedAbstract && selectedAbstract.abstract && selectedAbstract.abstract.length>100">
                    <span class="fsize-11">{{selectedAbstract.abstract}}</span>
                </p>
            </div>


            <div class="cards mt-4 d-flex flex-wrap justify-content-start fsize-09"
                 *ngIf="selectedAbstract && selectedAbstract.keywords && selectedAbstract.keywords.length>0">
                <label class="f-bold me-2">{{'Keywords'|localize}} :</label>
                <button class="card-button zoom-05 me-2 mb-1" *ngFor="let keywords of selectedAbstract.keywords">
                    <p class="tip">{{keywords}}</p>
                </button>
                <hr class="text-muted mt-1 w-100">
            </div>



            <div class="cards mt-4   d-flex flex-wrap justify-content-start fsize-09" *ngIf="selectedPublication.subjects && selectedPublication.subjects.length>0">
                <label class="f-bold me-2">{{'Subjects'|localize}} :</label>
                <button class="card-button zoom-05  me-2 mb-1" *ngFor="let subject of selectedPublication.subjects">
                    <p class="tip">{{subject.name}}</p>
                </button>
                <hr class="text-muted mt-1  w-100">
            </div>
        </div>

        <div class="sticky-info float-right  col-12 col-sm-12 col-md-12 col-lg-4  col-xl-4  col-xxl-4  mx-auto">

            <button style="width: 100%" class="mx-auto pt-0 pb-0 mt-1 mb-1 btn btn-primary"
                    *ngIf="selectedPublication.pdf && selectedPublication.accessType && selectedPublication.accessType=='OPEN'"
                    (click)="getPdf(selectedPublication)"><i
                    class="me-2 far fa-file-pdf"></i>{{'FullText' | localize}}
            </button>

            <button style="background-color: #343646 !important; opacity: 1 !important; width: 100%" class="mx-auto pt-0 pb-0 mt-1 mb-1 btn btn-primary" disabled
                    *ngIf="!selectedPublication.pdf || !selectedPublication.accessType || selectedPublication.accessType=='CLOSED'">
                <i class="text-white me-2 fas fa-lock"></i>{{'FullText' | localize}}
            </button>

            <div class="bg-card mt-1  px-3 text-center">
                <small><label class="f-bold">{{'FirstIndexDate'|localize}}
                    :</label> {{selectedPublication.firstIndexDate|utcToLocalTime:'DD.MM.YYYY'}}</small>
            </div>

            <div class="bg-card mt-1  px-3 text-center">
                <small><label class="f-bold">{{'DateOfUpdate'|localize}}
                    :</label> {{selectedPublication.indexDate|utcToLocalTime:'DD.MM.YYYY'}}</small>
            </div>

            <div class="bg-card mt-1  px-3 text-center">
                <small>
                    <label class="f-bold">{{'DocumentType'|localize}}
                    :</label> {{selectedPublication.docType|localize}}
                </small>
            </div>
            <div class="bg-card mt-1  px-3 text-center" *ngIf="selectedPublication.docType && selectedPublication.docType=='PAPER'">
                <small><label class="f-bold">{{'PaperType'|localize}}
                    :</label> {{selectedPublication.publicationType|localize}}</small>
            </div>
            <div class="bg-card mt-1  px-3 text-center">
                <small><label class="f-bold">{{'AccessType'|localize}}
                    :</label> {{this.getAccessType()|localize}}</small>
            </div>

            <div class="bg-card mt-1  py-1 px-3 text-center">
                <div class="card-body py-1 d-flex justify-content-center">
                    <div class="text-center  col-sm-6 col-md-6 col-lg-6  col-xl-6  col-xxl-6">
                        <h5 class="m-0">{{selectedPublication.viewCount}}</h5>
                        <small><i class="me-1 fa fa-eye "></i>{{'Views'|localize}}</small>
                    </div>
                    <div class="text-center col-sm-6 col-md-6 col-lg-6  col-xl-6  col-xxl-6">
                        <h5 class="m-0">{{selectedPublication.downloadCount}}</h5>
                        <small><i class="me-1 fa fa-download "></i>{{'Downloads'|localize}}</small>
                    </div>
                </div>
            </div>


            <div class="nselect card mt-1 bg-card">
                <div class="text-center ">
                    <small>
                        <label role="title">{{'trdizinCitationCount'|localize}}
                            ({{selectedPublication.citedReferences ? selectedPublication.citedReferences.length : '0'}})
                        </label>
                    </small>
                    <div style="width: 100%;" class="d-flex justify-content-center"
                         *ngIf="selectedPublication.citedReferences && selectedPublication.citedReferences.length>0">
                        <div style="width: 90%; height:250px;" echarts [options]="chartOption"></div>
                    </div>
                </div>
            </div>

            <div class="mt-1 bg-card pt-1 pb-3 px-3">
                <div class="row text-center mb-2" style="width: 100%;">
                    <small><label>{{ 'citationCountsOutsideTrIndex' | localize}}</label></small>
                </div>
                <small>
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="text-center me-2">
                            <strong>OpenCitations</strong>
                            <br>
                            <label>{{this.getOtherCitations("OpenCitations")}}</label>
                        </div>
                        <div class="vr"></div>
                        <div class="text-center me-2">
                            <strong>OpenAlex</strong>
                            <br>
                            <label>{{this.getOtherCitations("OpenAlex")}}</label>
                        </div>
                        <div class="vr"></div>

                        <div class="text-center me-2">
                            <strong>Crossref</strong>
                            <br>
                            <label>{{this.getOtherCitations("CrossRef")}}</label>
                        </div>
                    </div>
                </small>
            </div>


            <div class="nselect mt-1 bg-card pt-1 pb-3 px-3 ">
                <div class="text-center mb-2"><small><label>{{'citeThis'|localize}}</label></small></div>
                <div class="d-flex justify-content-between">
                    <a class="img-btn " title="Bibtex"
                       (click)="getBibtext(selectedPublication)"> <img
                            style=" filter: grayscale(100%); width: 1.8em;" src="/assets/images/bibtex.svg"> </a>
                    <a class="img-btn  " title="Ris" (click)="getRis(selectedPublication)">
                        <img
                                style=" filter:  brightness(0.5) grayscale(100%); width: 1.8em;"
                                src="/assets/images/ris.svg">
                    </a>

                    <a class="img-btn  " (click)="getEndNote(selectedPublication)"
                       title="EndNote"> <img
                            style=" filter:  brightness(0.5) grayscale(100%); width: 2em;"
                            src="/assets/images/endnote.svg">
                    </a>
                    <a class="img-btn  " (click)="getZotero()" title="Zotero"> <img
                            style=" filter: brightness(0.5) grayscale(100%);width: 2.3em;"
                            src="/assets/images/zotero.svg">
                    </a>
                </div>
            </div>

        </div>

        <div class="col-12  col-sm-12 col-md-12 col-lg-8 " >
            <small class="">
                <strong>
                    <ul class=" nav nav-tabs nav-justified" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="tab-btn nav-link active" id="justified-tab-0" data-bs-toggle="tab"
                               href="#references-panel" role="tab" aria-controls="references-panel"
                               aria-selected="true">{{"bibliography"|localize}} </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="tab-btn nav-link" id="justified-tab-1" data-bs-toggle="tab" href="#citation-panel"
                               (click)="fillCitationIdList()"
                               role="tab" aria-controls="citation-panel"
                               aria-selected="false"> {{"publicationCitation"|localize}} </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="tab-btn nav-link" id="justified-tab-2" data-bs-toggle="tab" href="#smilarty-panel"
                               role="tab" aria-controls="smilarty-panel"
                               (click)="getSimilarArticlesOfThePublication(selectedPublication.id)"
                               aria-selected="false"> {{"smiliarPublications" |localize}} </a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="selectedPublication.docType=='PAPER'">
                            <a class="tab-btn nav-link" id="justified-tab-3" data-bs-toggle="tab" href="#resource-panel"
                               role="tab" aria-controls="show-citation-panel"
                               aria-selected="false"> {{"citeThis" |localize}}  </a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="selectedPublication.docType=='PROJECT'">
                            <a class="tab-btn nav-link" id="justified-tab-4" data-bs-toggle="tab"
                               href="#publicationProduct-panel" role="tab" aria-controls="publicationProduct-panel"
                               aria-selected="true">{{"PublicationsFromTheProject"|localize}} </a>
                        </li>
                    </ul>
                </strong>
            </small>
            <div class="tab-content mt-3 " id="tab-content">
                <div class="tab-pane active" id="references-panel" role="tabpanel"
                     aria-labelledby="justified-tab-0">
                    <small class=" fsize-09" *ngIf="referencesMoreLessComponent">
                        <div style="text-align: justify;"
                             *ngFor="let reference of referencesMoreLessComponent.list;index as  i;">
                             {{reference.context}}
                            <hr *ngIf="i!=referencesMoreLessComponent.list.length-1" class="mb-2" style="margin: 0;">
                        </div>
                    </small>
                    <app-more-less #referencesMoreLess [input]="selectedPublication.references"
                                   [size]="20"></app-more-less>
                </div>
                <div class="tab-pane " id="publicationProduct-panel" role="tabpanel"
                     aria-labelledby="justified-tab-0">
                    <small class="fsize-09"
                           *ngIf="publicationProductComponent && selectedPublication.publicationProduct && selectedPublication.publicationProduct.length>0">
                        <div style="text-align: justify;"
                             *ngFor="let product of publicationProductComponent.list;index as  i;">
                            <label class="f-bold light-blue me-1 ">{{i + 1}}.</label> {{product.title}}
                            <hr *ngIf="i!= publicationProductComponent.list.length-1" class="mb-2" style="margin: 0;">
                        </div>
                    </small>
                    <app-more-less #publicationProduct [input]="selectedPublication.publicationProduct"
                                   [size]="20"></app-more-less>
                </div>
                <div class="tab-pane mx-auto" id="citation-panel" role="tabpanel" aria-labelledby="justified-tab-1">
                    <app-publication-list [publicationList]="citationSourcePublicationList"></app-publication-list>
                    <div class="w-100 row  mt-2">
                        <div class="col-12 col-md-9">
                            <trd-paginater [pageList]="[10,50,100]" [pageModel]="citationPageModel"
                                           (pageChangeEvent)="changeCitationResultPage($event)"
                                           [exportable]="true">
                            </trd-paginater>
                        </div>
                        <div *ngIf="citationSourcePublicationList &&citationSourcePublicationList.length>0"
                             class="col-12 col-md-3 float-right text-right">
                            <export-component [fieldSelect]="false" [documentType]="'publication'"
                                              [documentList]="citationSourcePublicationList"
                            ></export-component>
                        </div>
                    </div>
                </div>
                <div class="tab-pane mx-auto" id="smilarty-panel" role="tabpanel" aria-labelledby="justified-tab-2">
                        <app-publication-list
                                [publicationList]="similarArticlesOfThePublicationList"></app-publication-list>
                        <div class="float-right"
                             *ngIf="similarArticlesOfThePublicationList &&similarArticlesOfThePublicationList.length>0">
                            <export-component [fieldSelect]="false" [documentType]="'publication'"
                                              [documentList]="similarArticlesOfThePublicationList"
                            ></export-component>
                        </div>
                </div>
                <div class="tab-pane mx-auto" id="resource-panel" role="tabpanel" aria-labelledby="justified-tab-2">
                    <table class="table citationStyle__table fw-light mt-3"
                           style="font-size: .9em;vertical-align: middle">
                        <tbody>
                        <tr class="apaStyle">
                            <td style="white-space: nowrap;">
                                <i class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                   title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                   (click)="copySelectedField(apaScriptForCopy(selectedPublication), $event)">
                                </i>
                                <span class="py-1">APA</span>
                            </td>
                            <td>
                                <label class="pt-1">
                                    <ng-container *ngIf="selectedPublication">
                                        {{ selectedPublication.getApaStyleForAuthorsName() }}
                                        ({{ selectedPublication.publicationYear }}).
                                        {{ selectedPublication.abstracts[0].title }}.
                                        <em *ngIf="selectedPublication.journal">{{ selectedPublication.journal.name }}
                                            ,</em>
                                        {{ selectedPublication.issue.volume }}({{ selectedPublication.issue.number }}),
                                        {{ selectedPublication.startPage }} - {{ selectedPublication.endPage }}.
                                        <ng-container *ngIf="selectedPublication.doi">
                                            doi.org/{{ selectedPublication.doi }}
                                        </ng-container>
                                    </ng-container>
                                </label>

                            </td>
                        </tr>
                        <tr class="chicagoStyle">
                            <td style="white-space: nowrap;">
                                <i class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                   title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                   (click)="copySelectedField(chicagoStyle(selectedPublication), $event)">
                                </i>
                                <span class="py-1">Chicago</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1">{{chicagoStyle(selectedPublication)}}</label>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="mlaStyle">
                            <td style="white-space: nowrap;"><i
                                    class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                    title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                    (click)="copySelectedField(mlaStyle(selectedPublication), $event)">
                            </i>
                                <span class="py-1">MLA</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1"> {{mlaStyle(selectedPublication) }} </label>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="amaStyle">
                            <td style="white-space: nowrap;"><i
                                    class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                    title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                    (click)="copySelectedField(amaAndVancouverStyle(selectedPublication), $event)">
                            </i>
                                <span class="py-1">AMA</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1">{{amaAndVancouverStyle(selectedPublication) }}</label>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="vancouverStyle">
                            <td style="white-space: nowrap;"><i
                                    class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                    title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                    (click)="copySelectedField(amaAndVancouverStyle(selectedPublication), $event)">
                            </i>
                                <span class="py-1">Vancouver</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1">{{amaAndVancouverStyle(selectedPublication) }}</label>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="iEEEStyle">
                            <td style="white-space: nowrap;"><i
                                    class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                    title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                    (click)="copySelectedField(ieeeStyle(selectedPublication), $event)">
                            </i>
                                <span class="py-1">IEEE</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1">{{ieeeStyle(selectedPublication) }}</label>
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="isnadStyle">
                            <td style="white-space: nowrap;"><i
                                    class="far fa-copy me-2 ms-4 py-1 text-muted pointer"
                                    title="{{'CopyTheField' | localize}}" style="font-size: 1.3em"
                                    (click)="copySelectedField(isnadStyle(selectedPublication), $event)">
                            </i>
                                <span class="py-1">ISNAD</span>
                            </td>
                            <td>
                                <ng-container *ngIf="selectedPublication">
                                    <label class="pt-1">{{isnadStyle(selectedPublication) }}</label>
                                </ng-container>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="float-right ps-0 col-12 col-sm-12 col-md-12 col-lg-4  col-xl-4  col-xxl-3  mx-auto">

        </div>

    </div>
</div>
<app-my-library #libraryAddModal></app-my-library>
