import {BaseDTO} from "../../core/BaseDTO";

export class PublicationInstitutionDTO extends BaseDTO {
    public code: string[];
    public title: string[];
    public rootTitle: string[];
    public fullTitle: string[];
    public rootCode: string[];
    public city: string[];
    public country: string[];
    public status: string[];
    public type: string[];


    bindData(obj: any): void {
        if (obj == null) {
            return;
        }
        super.bindData(obj);
        this.code = obj.relationId;
        this.title = obj.title;
        this.rootTitle = obj.abstract;
        this.fullTitle = obj.language;
        this.rootCode = obj.rootCode;
        this.city = obj.city;
        this.country = obj.country;
        this.status = obj.status;
        this.type = obj.type;
    }


}
