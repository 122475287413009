import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {LocalizationService} from "./localizationService";
@Injectable({ providedIn: 'root' })
export class ModalAlertService{

  constructor(
    private localizationService: LocalizationService
  ) { }

  simpleAlert() {
    Swal.fire('Hello Angular');
  }

  alertWithSuccess() {
    Swal.fire('Başarılı', 'İşleminiz Başarıyla Tamamlandı', 'success')
  }

  erroralert(title) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: 'Bir Hata oluştu!',
      footer: '<a href>Why do I have this issue?</a>'
    })
  }

  topend(title) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  confirmBox(title, subtitle, yesfunc, nofunc) {
    Swal.fire({
      title: title,
      text: subtitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.localizationService.getLocalizationValue('true'),
      cancelButtonText: this.localizationService.getLocalizationValue('false')
    }).then((result) => {
      if (result.value) {
        if(yesfunc) {
          yesfunc();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if(nofunc) {
          nofunc();
        }
      }
    })
  }
  selectBox(title, subtitle, yesfunc, nofunc) {
    Swal.fire({
      title: title,
      text: subtitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sadece Geçerli Sayfadaki Veriler',
      cancelButtonText: 'Bütün Filtrelenmiş Veriler'
    }).then((result) => {
      if (result.value) {
        if(yesfunc) {
          yesfunc();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if(nofunc) {
          nofunc();
        }
      }
    })
  }



  confirmBoxWithHtml(title, subtitle, yesfunc, nofunc) {
    Swal.fire({
      title: title,
      html: subtitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır'
    }).then((result) => {
      if (result.value) {
        if(yesfunc) {
          yesfunc();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if(nofunc) {
          nofunc();
        }
      }
    })
  }

  alertBox(title, subtitle, yesfunc) {
    Swal.fire({
      title: title,
      html: subtitle,
      icon: 'warning',
      confirmButtonText: 'Tamam'
    }).then((result) => {
      if (result.value) {
        if(yesfunc) {
          yesfunc();
        }
      }
    })
  }
}

