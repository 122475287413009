import { Pipe, PipeTransform } from '@angular/core';
import {LocalizationService} from "../service/localizationService";

@Pipe({name: 'localize'})
export class LocalizationPipe implements PipeTransform {

  constructor(private localizationService: LocalizationService) {
  }

  transform(value: string,markNotFound?: boolean): string {
    if(value){
      return this.localizationService.getLocalizationValue(value,markNotFound);
    } else{
      return value;
    }
  }
}
